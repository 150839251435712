import React, { useEffect, useState } from 'react';
import {
	IconVault,
	trimLongString,
} from '../../../../../../../utils/helper/Helper';
import { FaTimes } from 'react-icons/fa';
import { brands, icons } from '../../../../../../../assets/icons';
import './styles/viewall.css';
import ContainerLayout from '../../../../../../../layout/dashboard/container';

const ViewAll = ({ items, onChange, onBack }: any) => {
	const [viewAll, setViewAll] = useState(false);
	const [details, setDetails] = useState<any>(items);

	const handleRemoveFromList = (obj: { username: any }) => {
		if (items?.bulk_list?.length === 1) {
			setViewAll(false);
		}
		const newList = items?.bulk_list?.filter(
			(chi: { username: any }) => chi?.username !== obj?.username
		);
		setDetails((prev: any) => {
			return {
				...prev,
				bulk_list: newList,
			};
		});
	};

	useEffect(() => {
		onChange && onChange(details);
	}, [details]);

	return (
		<ContainerLayout className="preview-box-wrap">
			<div className="preview-box-wrap__inner">
				<figure onClick={onBack} className="wrap__arrow-back">
					{icons.arrow_back}
				</figure>

				<div className="wrap__title-contain">
					<div className="title-contain__main">
						<h5>All Recipients</h5>
						<small>Here is a list of all your recipients</small>
					</div>
					<div className="title-contain__item-count">
						<p>{items.bulk_list.length}</p>
					</div>
				</div>

				<div className="wrap__items">
					{items?.bulk_list.map(
						(
							chi: { username: any; amount: any },
							idx: React.Key | null | undefined
						) => {
							const { username, amount } = chi;
							return (
								<div className="box box-card grey-bg" key={idx}>
									<div
										onClick={() => {
											handleRemoveFromList(chi);
										}}
										className="cancel-box"
									>
										<FaTimes className="icon" />
									</div>
									<figure className="img-box">
										<img
											src={IconVault(brands.raven_deep_green)}
											alt=""
											className="img"
										/>
									</figure>
									<p className="name  raven-name">{'Emmanuel Ezeani'}</p>
									<p className="text grey-white-color">{`${
										'@' + username || '---'
									} • ${amount}`}</p>
								</div>
							);
						}
					)}
				</div>
			</div>
		</ContainerLayout>
	);
};

export default ViewAll;
