import { RavenButton } from '@ravenpay/raven-bank-ui';
import React from 'react';
import { useDispatch } from 'react-redux';

function EmptyBankBoxOverview({ onClick }: any) {
	return (
		<div className="bankbox-overview">
			<div className="bankbox-overview__left"></div>

			<div className="bankbox-overview__right">
				<div className="bankbox-overview__right--title">
					<h6>BankBox: The pocket friendly payment terminal.</h6>

					{/* <p>Your Business, Your Brand, Your Payment Solution.</p> */}
					<p>
						Seamlessly manage offline in-person payments for your business, with
						the BankBox.
					</p>
				</div>

				<div className="bankbox-overview__right--points">
					<div className="main">
						<div className="bullet">
							<p></p>
						</div>
						<p className="point">
							BankBox offers card and NFC payments for a seamless and adaptable
							customer experience
						</p>
					</div>
					<div className="main">
						<div className="bullet">
							<p></p>
						</div>
						<p className="point">
							Customize BankBox with your brand identity for a professional,
							consistent payment solution.
						</p>
					</div>
				</div>

				<div className="bankbox-overview__right--activate-btn">
					<RavenButton
						onClick={onClick}
						label="Activate BankBox"
						color="deep-green-light"
					/>
				</div>
			</div>
		</div>
	);
}

export default EmptyBankBoxOverview;
