import axios from 'axios';
import env from '../env';
import { decrypt, decrypt2, encrypt2 } from './encrypt';
import { logger } from '../services/logger';
import { getCookie } from './helper/Helper';

const API = env.bankbox_api;

/** base url to make request to the BE end point */

const instance = axios.create({
	baseURL: API,
});

instance.interceptors.request.use(
	async (config) => {
		const bankbox_token = getCookie('x-pos-key');
		if (bankbox_token) {
			config.headers['x-pos-key'] = bankbox_token;
		}

		try {
			const encryptedData = await encrypt2(config.data ?? '');
			config.data = { data: encryptedData };
		} catch (error) {
			logger.log(error);
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	async (response) => {
		response.data.data = decrypt2(response.data.data);
		delete response.config.transformRequest;
		delete response.data?.header;
		return response;
	},
	async (error) => {
		let val;

		// logger.log(decrypt2(error.response.data.data), 'the error');
		if (error.response.data.status === 'fail') {
			val = error.response;
			delete val.config.transformRequest;
		}

		val.data.data = decrypt2(val.data.data);
		return val;
	}
);

export default instance;
