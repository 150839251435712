import {
	RavenButton,
	RavenCheckBox,
	RavenInputField,
	toast,
} from '@ravenpay/raven-bank-ui';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DashboardLayout from '../../../../../../../layout/dashboard/dashboardLayout';
import { FaTimes } from 'react-icons/fa';
import PreviewDetailsBox from './PreviewDetailsBox';
// import ConfirmTransactionPinModal from "../ConfirmPinModal";

import { useDispatch } from 'react-redux';
import {
	IconVault,
	formatNumWithComma,
	formatNumWithCommaNairaSymbol,
	removeCommaAndNairaSign,
	trimLongString,
} from '../../../../../../../utils/helper/Helper';
import ActionContext from '../../../../../../../context/ActionContext';
import { useSelector } from 'react-redux';
import { formatNumWithoutCommaNaira } from '../../../../../../../utils/helper/Helper';

import useDebounce from '../../../../../../../hooks/UseDebounce';
import useWindowSize from '../../../../../../../hooks/UseWindowSize';
import ContainerLayout from '../../../../../../../layout/dashboard/container';
import { brands, icons } from '../../../../../../../assets/icons';
import './styles/index.css';
import ViewAll from './ViewAll';
import ConfirmTransactionPinModal from '../../common/ConfirmPinModal';
import CurrencySelector from '../../../../../../../components/common/currencySelector';
import { AppDispatch } from '../../../../../../../redux/store';
import {
	getBankList,
	initiateMultipleTransfer,
	initiateSingleTransfer,
	omniSearch,
	resolveAccountNumber,
} from '../../../../../../../redux/transaction';
import RootState, { TransferPayload } from '../../../../../../../redux/types';
import { logger } from '../../../../../../../services/logger';
import { verificationLoader } from '../../../../../../../components/common/verificationLoader';
import PreviewModal from './PreviewModal';

type Modal = {
	pin: boolean;
	success: boolean;
};

type FormDetails = {
	username?: string;
	amount: number | string;
	narration?: string;
	bulk_list: FormDetails[];
	beneficiary?: boolean;
	schedule?: string;
	transfer_pin?: string;
	bank: string;
	bank_code: string | number;
	bank_data?: { value: string; label: string };
	account_name: string;
	validated_name?: string;
	currency: string;
	account_number: string;
	identifier?: string;
};
const TransferOthers = () => {
	const navigate = useNavigate();
	const [step, setStep] = useState<number | string>(1);

	const [showPreview, setShowPreview] = useState(false);
	const [hovered, setHovered] = useState(false);
	const [viewAll, setViewAll] = useState(false);
	const [ravenName, setRavenName] = useState<number | string>('');
	const [showModal, setShowModal] = useState<Modal>({
		pin: false,
		success: false,
	});

	const [details, setDetails] = useState<FormDetails>({
		username: '',
		amount: '',
		narration: 'Sent from raven',
		bulk_list: [],
		bank: '',
		account_name: '',
		bank_code: '',
		currency: 'ngn',
		beneficiary: false,
		account_number: '',
		schedule: '',
		transfer_pin: '',
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...details, [name]: value };
		setDetails(obj);
	};

	const dispatch = useDispatch<AppDispatch>();
	const { banks, loading } = useSelector(
		(state: RootState) => state.transaction
	);

	const stepList = ['Transfer detail', 'Confirm Transactions'];
	const { dashboard_data } = useSelector((state: RootState) => state.wallet);

	const handleBulkTransfer = () => {
		const checkList = details?.bulk_list?.some(
			(chi: any) => chi?.account_number === details?.account_number
		);
		if (!checkList) {
			const obj: TransferPayload = {
				account_name: details?.validated_name as string,
				account_number: details?.account_number,
				amount: removeCommaAndNairaSign(details?.amount) as unknown as number,
				bank: details?.bank_data?.label as string,
				bank_code: details?.bank_data?.value as string,
				currency: details?.currency,
				narration: details?.narration,
			};
			const newList = [obj, ...details?.bulk_list];
			setDetails((prev: any) => {
				return {
					...prev,
					bulk_list: newList,
					validated_name: '',
					account_number: '',
					amount: '',
					bank: '',
					narration: 'Sent from raven',
				};
			});
			setRavenName('');
			// setVerify(false);l
		} else {
			toast.error('Recepient already exist in bulk list');
		}
	};

	const handleRemoveFromList = (obj: {
		username?: FormDetails['username'];
	}) => {
		if (details?.bulk_list?.length === 1) {
			setViewAll(false);
		}
		const newList = details?.bulk_list?.filter(
			(chi: { username?: FormDetails['username'] }) =>
				chi?.username !== obj?.username
		);
		setDetails((prev: any) => {
			return {
				...prev,
				bulk_list: newList,
			};
		});
	};

	const [error, setError] = useState({
		account_number: false,
	});
	const [loadingVery, setLoadingVerify] = useState(false);

	const validateUsername = async (account: string | number) => {
		setLoadingVerify(true);

		const name = await dispatch(
			resolveAccountNumber({
				account_number: account,
				bank: details?.bank_data?.value,
			})
		);
		setError({
			...error,
			account_number: true,
		});

		if (name?.payload?.status === 'success') {
			setError({
				...error,
				account_number: false,
			});
			setRavenName(name?.payload?.data?.data);
			setLoadingVerify(false);
			setDetails((e) => {
				return {
					...e,
					validated_name: name?.payload?.data?.data,
				};
			});
		} else {
			setError({
				...error,
				account_number: true,
			});
			setRavenName('');
			setLoadingVerify(false);
		}
	};

	const debouncedAccountNo = useDebounce(details?.account_number, 1000);
	useEffect(() => {
		let isMount = true;

		if (isMount && debouncedAccountNo?.length > 3) {
			const payload = {
				username: details?.account_number as string | number,
			};
			validateUsername(payload?.username);
		}

		if (isMount && debouncedAccountNo?.length < 1) {
			setRavenName('');
		}

		return () => {
			isMount = false;
		};
	}, [debouncedAccountNo]);

	const [fromDate, setFromDate] = useState('');

	function disableAlgorithm() {
		if (details.amount && details.account_number) {
			return false;
		} else if (
			(details.account_number && !details.amount) ||
			!details.narration
		) {
			return true;
		} else if (
			details.amount &&
			(!details.narration || !details.account_number)
		) {
			return true;
		} else if (details.bulk_list.length === 0) {
			return true;
		} else {
			return false;
		}
	}

	function handleInitPayment() {
		if (!details.account_number) {
			return true;
		}
		if (!details.amount) {
			return true;
		} else return false;
	}

	async function initiateTransfer() {
		interface T extends FormDetails {
			amount: number | string;
			account_name: string;
			currency: string;
		}
		const payload: { recipients: T[] } = {
			recipients: details?.bulk_list,
		};
		logger.log(payload);

		const response = await dispatch(initiateMultipleTransfer(payload));

		if (response?.payload?.status === 'success') {
			setShowModal({
				pin: false,
				success: false,
			});
			navigate('/dashboard-payment/transfer');
		}
	}

	useEffect(() => {
		dispatch(getBankList());
	}, []);

	function formatBanks(bk: { code: string; name: string | number }[]) {
		const ak = bk.map((bank) => {
			return {
				value: bank?.code,
				label: bank?.name,
			};
		});

		return ak;
	}

	return (
		<>
			<DashboardLayout>
				{step !== 'view-all' && (
					<ContainerLayout
						isLatest
						activeStep={step as number}
						steps={stepList}
						btnLoading={loading}
						btnDisabled={disableAlgorithm()}
						onBtnClick={() => {
							if (step !== 2) {
								if (handleInitPayment()) setStep(2);
								else setStep(2), handleBulkTransfer();
							} else {
								initiateTransfer();
							}
						}}
						btnColor="deep-green-light"
						onBack={() => {
							navigate('/dashboard-payment/transfer');
						}}
						onPrev={() => {
							step === 1
								? navigate('/dashboard-payment/transfer/new')
								: setStep(1);
						}}
						pageTitle="Make a Transfer"
						pageSubtitle="Quickly make a transfer in the most efficient way."
						style={{
							width: '100%',
							backgroundColor: step === 2 ? 'unset' : '',
							border: step === 2 ? 'unset' : '',
							padding: step === 2 ? 'unset' : '',
						}}
					>
						<div
							className={`atlas-payment-container animate-move-up-class ${
								step === 2 && 'expanded'
							} `}
						>
							<form
								onSubmit={(e) => {
									e.preventDefault();
								}}
								action=""
								className="form"
							>
								{/* step 1 start ------------------------------ */}
								{step === 1 && (
									<>
										<div className="raven-transfer__title">
											<h5>Transfer to Other Banks</h5>
											<p>Send money to a any local bank account</p>
										</div>

										<div className="raven__form-global">
											<div className="raven-transfer__grouped-input">
												<div className="grouped-input__currency-wrap">
													<label htmlFor="" className="currency-label">
														Currency*
													</label>
													<CurrencySelector
														defaultCurrency="ngn"
														border={'0.1rem solid #ccc'}
														className="grouped-input__currency-selector"
													/>
												</div>

												<RavenInputField
													style={{ zIndex: '3000' }}
													className="grouped-input__field2"
													type={`select`}
													color={`purple-light`}
													label={`Bank `}
													// selectMenuOpen
													value={details?.bank_data}
													selectOption={formatBanks(banks)}
													onChange={(e: any) => {
														setDetails({
															...details,
															bank_data: e,
															validated_name: '',
														});
														setRavenName('');
														setError((prev) => {
															return { ...prev, username: false };
														});
													}}
													placeholder={`Select Bank`}
													labelColor={`purple-light`}
													// showError={error?.username}
													name={`bank_data`}
													onActionClick={() => {}}
												/>
											</div>

											<RavenInputField
												type={`text`}
												color={`black-light`}
												label={`Account number* `}
												name={`account_number`}
												value={details?.account_number}
												onChange={handleChange}
												placeholder={`e.g 3028422066`}
												labelColor={`black-light`}
												showError={error?.account_number}
												labelSpanText={verificationLoader(loadingVery)}
												labelClassName={`label-class-span ${
													details?.account_number.length >= 10 &&
													'label-class-span-active'
												}`}
												onActionClick={() => {
													details.account_number.length >= 10 &&
														validateUsername(details.account_number);
												}}
											/>
											{/* verify name box start */}
											<div
												className={`verify-account-box ${
													ravenName && 'verify-account-box-show'
												}`}
											>
												<RavenCheckBox
													checked={true}
													onChange={() => {}}
													id={1}
													color={`black-light`}
													className={`check`}
												/>
												<p className="text">{ravenName}</p>
											</div>
											{/* verify name box end */}
											<RavenInputField
												type={`number`}
												color={`black-light`}
												label={`Amount* `}
												name={`amount`}
												value={details?.amount}
												onChange={handleChange}
												placeholder={`How much are you sending`}
												labelColor={`black-light`}
												labelSpanText={
													typeof dashboard_data?.balances !== 'undefined'
														? `Bal: ₦ ${formatNumWithComma(
																dashboard_data?.balances[0]?.available_bal,
																'ngn'
														  )} `
														: 'Bal: ₦ ••••••'
												}
												labelClassName={`label-span-amount label-span-theme`}
												thousandFormat
												numberPrefix={`₦`}
												onActionClick={() => {}}
											/>
											<RavenInputField
												type={`text`}
												color={`black-light`}
												label={`Narration* `}
												value={details?.narration}
												onChange={handleChange}
												placeholder={`E.g From timi`}
												name={`narration`}
											/>

											<div
												onClick={() => {
													details?.amount &&
														details?.account_number &&
														ravenName &&
														handleBulkTransfer();
												}}
												className={`add-another-box ${
													details?.amount &&
													details?.account_number &&
													ravenName &&
													'add-another-box-active'
												}`}
											>
												<figure className="img-box">
													<img
														src={IconVault(icons.plus_circle_white)}
														alt=""
														className="img"
													/>
													{}
												</figure>
												<p className="text">Add New Recipient</p>
											</div>

											<div
												className={`recepient-box ${
													details?.bulk_list &&
													details?.bulk_list?.length > 0 &&
													'recepient-box-show'
												}`}
											>
												{/* <div className="text-line">
													<span></span>
													<p className="text">Recipients</p>
													<span></span>
												</div> */}
												<div className="view-total-box">
													<div className="view-wrap">
														{viewAll ? (
															<p
																onClick={() => {
																	setViewAll(false);
																	details?.bulk_list?.length === 1 &&
																		setViewAll(false);
																}}
																className="text"
															>
																Close
															</p>
														) : (
															<div className="view-wrap__container">
																{details?.bulk_list?.length > 2 && (
																	<span>
																		<p>+{details?.bulk_list?.length - 2}</p>
																	</span>
																)}
																<p
																	// onClick={() => {
																	// 	setStep('view-all');
																	// }}

																	onMouseEnter={() => setShowPreview(true)}
																	className="text"
																>
																	View all
																	<figure>{icons.arrow_right_purple}</figure>
																</p>
															</div>
														)}
													</div>

													<div
														onMouseLeave={() => {
															setShowPreview(false);
															setHovered(false);
														}}
														onMouseEnter={() => setHovered(true)}
														className={`preview-modal ${showPreview && 'show'}`}
													>
														<PreviewModal
															onRemove={(e) =>
																setDetails({
																	...details,
																	bulk_list: e.length > 0 ? e : [],
																})
															}
															content={details?.bulk_list}
														/>
													</div>
												</div>
												{/* end of view total box */}
												{/* preview list start */}
												{!viewAll && (
													<div className="preview-box">
														{details?.bulk_list
															?.slice(0, 2)
															.map(
																(
																	chi: FormDetails,
																	idx: React.Key | null | undefined
																) => {
																	const {
																		account_number,
																		account_name,
																		amount,
																	} = chi;

																	return (
																		<div
																			className="box box-card grey-bg"
																			key={idx}
																		>
																			<div
																				onClick={() => {
																					handleRemoveFromList(chi);
																				}}
																				className="cancel-box"
																			>
																				<FaTimes className="icon" />
																			</div>
																			<figure className="img-box">
																				<img
																					src={IconVault(brands.raven)}
																					alt=""
																					className="img"
																				/>
																			</figure>
																			<p className="name  raven-name">
																				{account_name}
																			</p>
																			<p className="text grey-white-color">{`${
																				account_number || '---'
																			} • ${amount}`}</p>
																		</div>
																	);
																}
															)}
													</div>
												)}
												{/* preview list end */}
											</div>
										</div>
									</>
								)}
								{/* step 1 end -------------------------------- */}

								{/* step 2 start ----------------------------- */}
								{step === 2 && (
									<div className="step-two-transfer-raven animate-move-up-class">
										{details?.bulk_list.length === 1 ? (
											<>
												{details?.bulk_list?.map(
													(chi: any, idx: React.Key | null | undefined) => {
														return (
															<PreviewDetailsBox
																key={idx}
																loading={loading}
																onBtnClick={() => {
																	// setShowModal({
																	// 	pin: true,
																	// 	success: false,
																	// });
																	initiateTransfer();
																}}
																content={chi}
															/>
														);
													}
												)}
											</>
										) : (
											<>
												<PreviewDetailsBox
													multi
													loading={loading}
													onBtnClick={() => {
														// setShowModal({
														// 	pin: true,
														// 	success: false,
														// });
														initiateTransfer();
													}}
													content={details?.bulk_list}
												/>
											</>
										)}
									</div>
								)}
							</form>

							{/* view all end ----------------------------- */}
						</div>
					</ContainerLayout>
				)}

				{step === 'view-all' && (
					<ViewAll
						onBack={() => {
							setStep(1);
						}}
						onChange={(e: any) => setDetails(e)}
						items={details}
					/>
				)}
			</DashboardLayout>
			<ConfirmTransactionPinModal
				onBtnClick={() => {
					handleInitPayment();
					toast.success(
						'Transfer of ₦20,000 to 2135876118  UBA is on it’s way.'
					);
					setShowModal({
						pin: false,
						success: false,
					});
					navigate('/dashboard-payment/transfer');
				}}
				onClose={() =>
					setShowModal({
						pin: false,
						success: false,
					})
				}
				visible={showModal.pin}
			/>
		</>
	);
};

export default TransferOthers;
