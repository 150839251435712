import { RavenTabLink } from '@ravenpay/raven-bank-ui';
import './style/index.css';
import React, { useEffect, useState } from 'react';
import { icons } from '../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../redux/types';
import { AppDispatch } from '../../redux/store';
import { omniSearch } from '../../redux/transaction';
import { logger } from '../../services/logger';
import {
	formatDateTime,
	formatNumWithComma,
	insertUrlParam,
	removeUrlParam,
	symbol,
} from '../../utils/helper/Helper';
import { useNavigate } from 'react-router-dom';
import EmptyScreen from '../common/emptyScreen';

const OmniSearch = ({ q, open }: { q: string; open: boolean }) => {
	const dispatch = useDispatch<AppDispatch>();
	const [result, setResult] = React.useState<any>([]);
	const [isNavigating, setIsNavigating] = React.useState<boolean>(false);

	const { loading } = useSelector((state: RootState) => state.transaction);
	const [tab, setTab] = useState({ label: 'All', value: 'all' });
	const tabs = [
		{ label: 'All', value: 'all' },
		// { label: 'Wallets', value: 'joha' },
		// { label: 'Transfer', value: 'transfers' },
		// { label: 'Payment Links', value: 'joha' },
		{ label: 'Collections', value: 'collections' },
		{ label: 'Accounts', value: 'virtual_accounts' },
		{ label: 'Transfers', value: 'transfers' },

		// { label: 'Cards', value: 'joha' },
		// { label: 'KYC', value: 'joha' },
		// { label: 'Settings', value: 'joha' },
	];

	useEffect(() => {
		if (q.length >= 1) {
			dispatch(omniSearch(q)).then((resp) => {
				if (resp?.payload?.status === 'success') {
					setResult(resp?.payload.data?.data);
				}
			});
		}
	}, [q.length >= 1 ? q : '']);

	const totalFound =
		result?.collections?.collections_count +
		result?.virtual_accounts?.virtual_accounts_count +
		result?.transfers?.transfers_count;

	useEffect(() => {
		if (q.length > 0) {
			insertUrlParam('q', q);
		} else {
			setTimeout(() => {
				if (!isNavigating) {
					removeUrlParam('q');
				}
			}, 2000);
		}
	}, [q, isNavigating]);

	const navigate = useNavigate();

	return open ? (
		<div className="omnisearch">
			<div className="omnisearch__tabs">
				{tabs
					.sort((a, b) => {
						// 'All' should always come first
						if (a.label === 'All') {
							return -1;
						}
						if (b.label === 'All') {
							return 1;
						}

						// Sort other tabs alphabetically
						if (a.label < b.label) {
							return -1;
						}
						if (a.label > b.label) {
							return 1;
						}
						return 0;
					})
					.map((chi, idx) => {
						return (
							<div
								onClick={() => setTab(chi)}
								key={idx}
								className={`omnisearch__tabs--item ${
									chi.label === tab.label && 'active'
								}`}
							>
								<span>{chi.label}</span>
							</div>
						);
					})}
			</div>

			{!loading && (
				<div className="omnisearch__showing-n-result-of">
					<p>
						{tab.value === 'all' ? (
							<>
								{' '}
								Showing {totalFound ?? 0} Result of <b>‘{`${q}`}’</b> in{' '}
								{tab.label}
							</>
						) : (
							<>
								Showing {result[tab.value]?.[`${tab.value}_count`]} Result of{' '}
								<b>‘{`${q}`}’</b> in {tab.label}
							</>
						)}
					</p>
				</div>
			)}
			{!loading ? (
				tab.label === 'All' ? (
					<>
						{tabs
							.sort((a, b) => {
								const aHasValues = result[a.value]?.[`${a.value}_count`] > 0;
								const bHasValues = result[b.value]?.[`${b.value}_count`] > 0;

								// If both have values or both don't have values, sort alphabetically
								if (aHasValues === bHasValues) {
									if (a.label < b.label) {
										return -1;
									}
									if (a.label > b.label) {
										return 1;
									}
									return 0;
								}

								// If only one has values, prioritize it
								return bHasValues ? 1 : -1;
							})
							.map((chi, idx) => {
								logger.log(chi, 'd ');

								const hasValues =
									result[chi.value]?.[`${chi?.value}_count`] > 0;

								return (
									<React.Fragment key={idx}>
										{hasValues ? (
											<div
												onClick={() => setTab(chi)}
												className="omnisearch__search-results"
											>
												<p style={{ fontWeight: '500' }}>{chi.label}</p>
												<div>
													{result[chi.value]?.[`${chi?.value}_count`] ?? 0}{' '}
													Results found in {chi.label}
												</div>
											</div>
										) : (
											<>
												{chi.label !== 'All' && (
													<div className="omnisearch__empty-search-results">
														<p>{chi.label}</p>
														<p>No result for your search here</p>
													</div>
												)}
											</>
										)}
									</React.Fragment>
								);
							})}
					</>
				) : result[tab.value]?.[`${tab?.value}_count`] > 0 ? (
					<div className="omnisearch__search-results">
						{result[tab.value][tab.value + '_data']?.map(
							(i: any, idx: React.Key | null | undefined) => {
								return (
									<div className="search-results" key={idx}>
										{tab.value === 'virtual_accounts' && (
											<div
												onClick={() => {
													setIsNavigating(true);
													navigate(`/dashboard-virtual-account?q=${q}`);
												}}
												className="search-results__item"
											>
												<p style={{ fontSize: '1.4rem', fontWeight: '500' }}>
													{i?.first_name + ' ' + i?.last_name}
												</p>
												<div>
													<b>{i?.account_name} </b> • {i?.bank} •{' '}
													{i?.account_number} •{formatDateTime(i?.created_at)}
													{/* • Paymable • <span>Successful</span> */}
												</div>
											</div>
										)}
										{tab.value === 'transfers' && (
											<div
												onClick={() => {
													setIsNavigating(true);
													navigate(`/dashboard-payment/transfer?q=${q}`);
												}}
												className="search-results__item"
											>
												<p style={{ fontSize: '1.4rem', fontWeight: '500' }}>
													{i?.account_name}
												</p>
												<div>
													<b>{i?.trx_ref} </b> • {i?.bank} • {i?.account_number}{' '}
													• {formatDateTime(i?.created_at)}
													<span
														className={
															i?.status === 0 ? 'pending' : 'succesfull'
														}
													>
														{i?.status === 0 ? 'Pending' : 'Succesfull'}
													</span>
												</div>
											</div>
										)}
										{tab.value === 'collections' && (
											<div
												onClick={() => {
													setIsNavigating(true);
													navigate(`/dashboard-collections?q=${q}`);
												}}
												className="search-results__item"
											>
												<p style={{ fontSize: '1.4rem', fontWeight: '500' }}>
													{JSON.parse(i?.source)?.first_name +
														' ' +
														JSON.parse(i?.source)?.last_name}
												</p>
												<div>
													<b>{JSON.parse(i?.source)?.bank} </b> •{' '}
													{i?.account_number} • {formatDateTime(i?.created_at)}•{' '}
													{symbol('ngn') + formatNumWithComma(i?.amount, 'n')}
													{/* •{' '}
												<span
													className={i?.status === 0 ? 'pending' : 'succesfull'}
												>
													{i?.status === 0 ? 'Pending' : 'Succesfull'}
												</span> */}
												</div>
											</div>
										)}
									</div>
								);
							}
						)}
					</div>
				) : (
					<div className="omnisearch__single-tab">
						<div className="omnisearch__single-tab--no-result">
							<figure>{icons.omni_no_result}</figure>
							<span>
								Showing 0 Results for <p>“{q}”</p> In {tab.label}
							</span>
						</div>
					</div>
				)
			) : (
				<div className="empty_screen_wrap">
					<EmptyScreen loadingText="Searching Atlas" loading={loading} />
				</div>
			)}
		</div>
	) : (
		<></>
	);
};

export default OmniSearch;
