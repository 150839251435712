import React, { useState } from 'react';
import DashboardLayout from '../../../../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../../../../layout/dashboard/tableLayout';
import { icons } from '../../../../../../assets/icons';
import '../styles/index.css';
import { RavenTable, RavenTableRow } from '@ravenpay/raven-bank-ui';
import Pagination from '../../../../../../components/common/pagination';
import { faker } from '@faker-js/faker';
import EmptyScreen from '../../../../../../components/common/emptyScreen';
import {
	formatDateTime,
	formatNumWithComma,
	formatStatus,
	returnInitial,
	symbol,
} from '../../../../../../utils/helper/Helper';
import { generateRandomColor } from '../../../../../../utils/helper/color';

const SingleViewLink = () => {
	const tableData: {
		account_name?: string;
		account_no?: string;
		narration?: string;
		type?: string;
		direction?: string;
		amount: string;
		status: string;
		date: Date;
		reference: string;
		fee: string;
	}[] = [];

	// Generate 10 objects with fake data for the table rows
	for (let i = 0; i < 10; i++) {
		const type = faker.helpers.arrayElement(['Deposit', 'Transfer']);
		const rowData = {
			narration: faker.finance.transactionDescription(),
			type: faker.helpers.arrayElement([
				'GT Bank',
				'AFRO Bank',
				'FCMB',
				'UBA',
				'Raven Bank',
			]),
			account_name: faker.person.fullName(),
			account_no: faker.finance.accountNumber(),
			direction: type === 'Deposit' ? 'successful' : 'failed',
			status: faker.helpers.arrayElement(['Successful', 'Pending', 'Failed']),
			amount: faker.finance.amount({ min: 20000, max: 10020032 }),
			fee: faker.finance.amount(),
			date: faker.date.past(),
			reference: faker.finance.litecoinAddress(),
		};

		tableData.push(rowData);
	}

	const [demo, setDemo] = useState(true);
	const [page, setCurrentPage] = useState(1);

	return (
		<DashboardLayout>
			<PageLayout
				pageTitle="Link Details"
				pageSubtitle="All links used for payment created"
				topRightContent={
					<div className="single-preview-top-acts">
						<span>{icons.edit_plain}</span>
						<span>{icons.broken_link}</span>
						<span>{icons.delete_plain}</span>
					</div>
				}
			>
				<div className="single-preview">
					<div className="single-preview__left">
						<div className="left__preview-title">
							<figure>
								<img
									src="https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2hvZXN8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
									alt=""
								/>
							</figure>

							<span>
								<h5>Payment for PS5</h5>
								<p>All links used for payment created</p>
							</span>
						</div>

						<div className="left__link-details">
							<div className="link-details__item">
								<p>Link Type</p>
								<p>Single Charge</p>
							</div>{' '}
							<div className="link-details__item">
								<p>Date Created</p>
								<p>5, Jan 2023 - 12: 48PM</p>
							</div>
							<div className="link-details__item">
								<p>Payment Url</p>
								<span>
									<p>https://www.figma.com/file/NEDm71....</p>
									<figure>{icons.copy}</figure>
								</span>
							</div>
							<div className="link-details__item">
								<p>Amount</p>
								<p>₦500,000.00</p>
							</div>
						</div>
					</div>
					<div className="single-preview__right">
						<div className="right__pagination-wrap">
							<h5>Transaction</h5>
							<Pagination
								// className="top-bar__pagination"
								currentPage={page}
								itemsPerPage={10}
								totalItems={100}
								onPageChange={function (page: number): void {
									setCurrentPage(page);
								}}
							/>
						</div>

						<div className="main__wrap">
							{!demo ? (
								<EmptyScreen
									title="Configure Settlement Balance"
									subTitle="Please configure your Settlement Balance to suit your preferences."
									withMascot
									button
									// onClick={() => setShowConfiguration(true)}
									buttonLabel="Start Configuration"
									btnIcon={false}
								/>
							) : (
								<div className="transaction__table">
									<RavenTable
										action={false}
										className="table__main"
										headerList={[
											'CUSTOMER DETAILS',
											'AMOUNT',
											'DATE INITIATED',
										]}
									>
										{tableData.map((chi, idx) => {
											return (
												<React.Fragment key={idx}>
													<RavenTableRow
														className="table__main"
														four={
															<div className="status-type-wrap ">
																<span>
																	{symbol('ngn') +
																		formatNumWithComma(chi.amount, 'ngn')}
																</span>
															</div>
														}
														five={formatDateTime(chi.date)}
														one={
															<div className="account_name">
																<span
																	style={{
																		backgroundColor:
																			generateRandomColor('#e9f5ff'),
																	}}
																>
																	<p>{returnInitial(chi.account_name ?? '')}</p>
																</span>
																<p className="text"> {chi.account_name}</p>
															</div>
														}
													/>
												</React.Fragment>
											);
										})}
									</RavenTable>
								</div>
							)}
						</div>
					</div>
				</div>
			</PageLayout>
		</DashboardLayout>
	);
};

export default SingleViewLink;
