import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import { toast } from '@ravenpay/raven-bank-ui';
import { logger } from '../services/logger';
import { CreateVirtualAccountPayload, WalletState } from './types';
import FundingHistory from '../pages/dashboard/wallet/components/fundingHistory/index';
import { PaymentMethod } from '../components/common/topupModal';

interface PaginatedPayload {
	page: string | number;
	limit: string | number;
}

export const getDashboardData = createAsyncThunk(
	'web/dashboard_data',
	async (payload, thunkAPI) => {
		try {
			const { data } = await axios.get('web/dashboard_data?days=300');

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				thunkAPI.dispatch(setDashboardData(data?.data?.data));

				return data;
			}
		} catch (error: any) {
			logger.log(error.message);
		}
	}
);
export const getTransfers = createAsyncThunk(
	'web/dashboard_data',
	async (payload: PaginatedPayload, thunkAPI?: any) => {
		try {
			const { data } = await axios.get(
				`/web/paginated_transfers?page=${payload.page}&perPage=${payload.limit}`
			);
			logger.log(data);

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				thunkAPI.dispatch(setTransfers(data?.data?.data));

				return data;
			}
		} catch (error: any) {
			logger.log(error.message);
		}
	}
);
export const getWalletBalance = createAsyncThunk(
	'/web/login',
	async (payload, thunkAPI) => {
		try {
			const { data } = await axios.get('web/wallet_balance');

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setWalletBalance(data?.data?.data));

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getSupportProfile = createAsyncThunk(
	'/web/support_profile',
	async (payload, thunkAPI) => {
		try {
			const { data } = await axios.get('/web/support_profile');
			logger.log(data, 'profille');

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setProfile(data?.data?.data));

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getTransactions = createAsyncThunk(
	'/transactions/wallet',
	async (
		payload: { page?: string | number; perPage?: string | number },
		thunkAPI
	) => {
		try {
			const { data } = await axios.get(
				`/web/transactions?page=${payload.page}&perPage=${payload.perPage}`
			);
			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setTransactions(data?.data?.data));

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getVirtualAccounts = createAsyncThunk(
	'/web/virtual-accounts',
	async (
		payload: { page: string | number; perPage: string | number },
		thunkAPI
	) => {
		try {
			const { data } = await axios.get(
				`web/virtual_accounts?page=${payload.page}&perPage=${payload.perPage}`
			);

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setVirtualAccounts(data?.data?.data));

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getCustomers = createAsyncThunk(
	'/web/customers',
	async (
		payload: { page: string | number; perPage: string | number },
		thunkAPI
	) => {
		try {
			const { data } = await axios.get(
				`web/all_customers?page=${payload.page}&perPage=${payload.perPage}`
			);

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setCustomers(data?.data?.data));

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const topupWithWebpay = createAsyncThunk(
	'/web/topup',
	async (
		payload: { amount: string | number; payment_method: PaymentMethod[] },
		thunkAPI
	) => {
		try {
			const { data } = await axios.post(`web/create_webpay_payment`, payload);

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setCustomers(data?.data?.data));

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getSingleCustomer = createAsyncThunk(
	'/web/customer',
	async (payload: { email: string | null }, thunkAPI) => {
		try {
			const { data } = await axios.get(`web/customer/${payload.email}`);

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setCustomer(data?.data?.data));

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getCollections = createAsyncThunk(
	'/web/collections',
	async (
		payload: { page: string | number; perPage: string | number },
		thunkAPI
	) => {
		try {
			const { data } = await axios.get(
				`web/paginated_collections?page=${payload.page}&perPage=${payload.perPage}`
			);

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setCollections(data?.data?.data));

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getFundingHistory = createAsyncThunk(
	'/web/funding-history',
	async (
		payload: { page: string | number; perPage: string | number },
		thunkAPI
	) => {
		try {
			const { data } = await axios.get(
				`web/funding_history?page=${payload.page}&perPage=${payload.perPage}`
			);

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setFundingHistory(data?.data?.data));

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const createVirtualAccount = createAsyncThunk(
	'/generate-virtual-account',
	async (payload: CreateVirtualAccountPayload, thunkAPI) => {
		try {
			const data = await axios.post('/web/generate_virtual_account', payload);
			// logger.log(data, 'reg');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else {
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				}
				return thunkAPI.rejectWithValue(data);
			}

			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const initialState: WalletState = {
	loading: false,
	isAuth: false,
	dashboard_data: {},
	wallet_balance: {},
	transactions: {},
	transfers: {},
	fundingHistory: {},
	virtualAccounts: {},
	customers: {},
	collections: {},
	customer: {},
	profile: {},
	// initialize other state properties
};

export const walletSlice = createSlice({
	name: 'auth',
	initialState,

	reducers: {
		setDashboardData: (state, action) => {
			state.dashboard_data = action.payload;
			state.isAuth = true;
		},
		setWalletBalance: (state, action) => {
			state.wallet_balance = action.payload;
			state.isAuth = true;
		},
		setTransactions: (state, action) => {
			state.transactions = action.payload;
			state.isAuth = true;
		},

		setProfile: (state, action) => {
			state.profile = action.payload;
			state.isAuth = true;
		},

		setWalletLoader: (state, action) => {
			state.loading = action.payload;
			state.isAuth = true;
		},

		setCollections: (state, action) => {
			state.collections = action.payload;
			state.isAuth = true;
		},

		setTransfers: (state, action) => {
			state.transfers = action.payload;
			state.isAuth = true;
		},
		setVirtualAccounts: (state, action) => {
			state.virtualAccounts = action.payload;
			state.isAuth = true;
		},
		setCustomers: (state, action) => {
			state.customers = action.payload;
			state.isAuth = true;
		},
		setCustomer: (state, action) => {
			state.customer = action.payload;
			state.isAuth = true;
		},
		setFundingHistory: (state, action) => {
			state.fundingHistory = action.payload;
			state.isAuth = true;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(getDashboardData.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getDashboardData.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(getDashboardData.rejected, () => {
			return initialState;
		});

		builder.addCase(getWalletBalance.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getWalletBalance.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(getWalletBalance.rejected, () => {
			return initialState;
		});

		builder.addCase(getSupportProfile.pending, (state) => {
			state.profile = true;
		});
		builder.addCase(getSupportProfile.fulfilled, (state) => {
			state.profile = false;
		});
		builder.addCase(getSupportProfile.rejected, () => {
			return initialState;
		});

		builder.addCase(getVirtualAccounts.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getVirtualAccounts.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(getVirtualAccounts.rejected, () => {
			return initialState;
		});
		builder.addCase(getCustomers.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getCustomers.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(getCustomers.rejected, () => {
			return initialState;
		});

		builder.addCase(getCollections.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getCollections.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(getCollections.rejected, () => {
			return initialState;
		});

		builder.addCase(getSingleCustomer.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getSingleCustomer.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(getSingleCustomer.rejected, () => {
			return initialState;
		});

		builder.addCase(createVirtualAccount.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(createVirtualAccount.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(createVirtualAccount.rejected, () => {
			return initialState;
		});

		builder.addCase(getTransactions.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getTransactions.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(getTransactions.rejected, () => {
			return initialState;
		});

		builder.addCase(getFundingHistory.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getFundingHistory.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(getFundingHistory.rejected, () => {
			return initialState;
		});
	},
});

// Action creators are generated for each case reducer function
export const {
	setDashboardData,
	setTransfers,
	setTransactions,
	setWalletBalance,
	setVirtualAccounts,
	setCustomers,
	setCollections,
	setProfile,
	setFundingHistory,
	setCustomer,
	setWalletLoader,
} = walletSlice.actions;

export default walletSlice.reducer;
