import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../layout/dashboard/tableLayout';
import {
	RavenButton,
	RavenInputField,
	RavenModal,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import {
	capitalizeFirstLetter,
	formatDateTime,
	formatNumWithComma,
	mapHotkey,
	symbol,
} from '../../../utils/helper/Helper';
import { faker } from '@faker-js/faker';
import { icons } from '../../../assets/icons';
import Pagination from '../../../components/common/pagination';
import EmptyScreen from '../../../components/common/emptyScreen';
import './style/index.css';
import { useNavigate } from 'react-router-dom';
import RootState from '../../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import { logger } from '../../../services/logger';
import { AppDispatch } from '../../../redux/store';
import { getTransactions } from '../../../redux/wallet';
import Skeleton from 'react-loading-skeleton';
import SmartFilter from '../../../components/common/smartFilter';
import TransactionModal from '../../../components/common/transactionModal';
import ExportModal from '../../../components/common/exportModal';
import MobileTableCard from '../../../components/common/table/mobile/MobileTable';
const Transaction = () => {
	const [demo, setDemo] = useState(true);
	const [showExport, setShowExport] = useState<boolean>(false);
	const [activeCurrency, setActiveCurrency] = useState('ngn');
	const [currentPage, setCurrentPage] = useState(1);
	const [activeFilter, setActiveFilter] = useState<string>('');
	const [showConfiguration, setShowConfiguration] = useState<boolean>(false);
	const [view, onView] = useState<any>({
		show: false,
		view: '',
	});

	// useState<string>('business-account');
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});
	const [boot, onBoot] = useState<boolean>(false);

	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		mapHotkey('m', () => setDemo(!demo));
	}, [demo]);

	useEffect(() => {
		if (
			typeof dateFilter.from === 'object' ||
			typeof dateFilter.to === 'object'
		) {
			setDateFilter({
				to: '',
				from: '',
			});
		}
	}, [activeFilter]);

	useEffect(() => {
		if (typeof activeFilter !== undefined) {
			setActiveFilter('');
		}
	}, [dateFilter.to || dateFilter.from]);

	function formatStatus(status: string) {
		return (
			<div className={`transaction-status ${status?.toLowerCase()}`}>
				<p>{status}</p>
			</div>
		);
	}

	const { wallet_balance, transactions, loading } = useSelector(
		(state: RootState) => state.wallet
	);

	useEffect(() => {
		if (!boot && transactions?.transactions) {
			onBoot(true);
		}
		dispatch(
			getTransactions({
				perPage: 20,
				page: currentPage,
			})
		);
	}, [currentPage]);

	const trx = transactions?.transactions;
	const navigate = useNavigate();

	logger.log(trx);

	const typeList = [
		{ label: 'Airtime', value: 'Airtime Recharge' },
		{ label: 'Data', value: 'Data Recharge' },
		{ label: 'Electricity', value: 'Electricity Recharge' },
		{ label: 'Transfers', value: 'transfer' },
		{ label: 'Collection', value: 'collection' },
		{
			label: 'Data Recharge Commision',
			value: 'Data Recharge Commision',
		},
		{
			label: 'Betting Payment',
			value: 'Betting Payment',
		},
	];
	return (
		<>
			<DashboardLayout>
				<PageLayout
					pageSubtitle="Here is an overview of your transactions."
					pageTitle="Transactions"
				>
					{loading || !trx || trx.length > 0 ? (
						<>
							<SmartFilter
								page="transaction"
								onExport={() => setShowExport(!showExport)}
								filters={[
									{ label: 'Types', options: typeList },
									{
										label: 'Status',
										options: [
											{ label: 'Pending', value: 'pending' },
											{ label: 'Failed', value: 'failed' },
											{ label: 'Succesful', value: 'succesful' },
										],
									},
								]}
								value={{
									type: [],
									days: '',
								}}
							/>
							<div className="content__main--top-bar">
								<div className="top-bar__title"></div>
								<Pagination
									className="top-bar__pagination"
									currentPage={currentPage}
									itemsPerPage={transactions?.pagination?.perPage}
									totalItems={transactions?.pagination?.total}
									onPageChange={function (page: number): void {
										setCurrentPage(page);
									}}
								/>
							</div>
						</>
					) : (
						''
					)}

					<div className="content__main">
						<div className="main__wrap">
							{loading || !trx || trx.length === 0 ? (
								<EmptyScreen
									loading={loading}
									// title="Configure Settlement Balance"
									subTitle="Your transactions will show up here"
									withMascot
									// button
									// onClick={() => setShowConfiguration(true)}
									// buttonLabel="Start Configuration"
									// btnIcon={false}
								/>
							) : (
								<>
									<div className="transaction__table non-mobile">
										<RavenTable
											action={false}
											className="table__main"
											headerList={[
												'REFERENCE ID',
												'TYPE',
												'AMOUNT',
												'BALANCE BEFORE',
												'BALANCE AFTER',
												'STATUS',
												'TRANSACTION DATE',
											]}
										>
											{trx?.map(
												(
													chi: {
														amount: string | number;
														b_after: any;
														b_before: string | number;
														meta_data: string;
														_fee: any;
														created_at: string | Date;
														direction: string | number;
														reference: string;
														_value: string | number;
														type: any;
													},
													idx: React.Key | null | undefined
												) => {
													const meta = chi?.meta_data
														? JSON.parse(chi?.meta_data)
														: chi?.meta_data;

													// logger.log(meta);
													return (
														<React.Fragment key={idx}>
															<RavenTableRow
																onRowClick={() =>
																	onView({
																		show: true,
																		content: { ...meta, ...chi },
																	})
																}
																className="table__main"
																five={
																	<div className="status-type-wrap ">
																		{loading ? (
																			<Skeleton width={50} height={20} />
																		) : (
																			<span>{`${symbol(
																				activeCurrency
																			)}${formatNumWithComma(
																				chi.b_after,
																				'ngn'
																			)}`}</span>
																		)}
																	</div>
																}
																six={
																	loading ? (
																		<Skeleton
																			width={70}
																			style={{ borderRadius: '7.8rem' }}
																			height={20}
																		/>
																	) : (
																		formatStatus('successful')
																	)
																}
																seven={
																	loading ? (
																		<Skeleton width={60} height={20} />
																	) : (
																		formatDateTime(chi.created_at)
																	)
																}
																one={
																	<div className="main__direction">
																		{loading ? (
																			<Skeleton
																				width={20}
																				height={20}
																				circle
																				enableAnimation
																			/>
																		) : (
																			<figure>{icons[chi.direction]}</figure>
																		)}
																		<p className="text">
																			{loading ? (
																				<Skeleton width={100} height={20} />
																			) : (
																				chi?.reference?.slice(0, 20) + '...'
																			)}
																		</p>
																	</div>
																}
																four={
																	<div className="type-wrap">
																		{loading ? (
																			<Skeleton width={60} height={20} />
																		) : (
																			<span>
																				{symbol('ngn') +
																					formatNumWithComma(
																						chi.b_before,
																						'ngn'
																					)}
																			</span>
																		)}
																	</div>
																}
																three={
																	<div className="type-wrap">
																		{loading ? (
																			<Skeleton width={60} height={20} />
																		) : (
																			<span>
																				{symbol('ngn') +
																					formatNumWithComma(chi._value, 'ngn')}
																			</span>
																		)}
																	</div>
																}
																two={
																	loading ? (
																		<Skeleton width={70} height={20} />
																	) : (
																		capitalizeFirstLetter(chi?.type).replaceAll(
																			'_',
																			' '
																		)
																	)
																}
															/>
														</React.Fragment>
													);
												}
											)}
										</RavenTable>
									</div>

									<div className="atlas-mobile-table-wrap">
										{trx?.map(
											(
												chi: {
													b_after: any;
													b_before: string | number;
													meta_data: string;
													_fee: any;
													created_at: string | Date;
													direction: string | number;
													reference: string;
													_value: string | number;
													type: any;
												},
												idx: React.Key | null | undefined
											) => {
												const meta = chi?.meta_data
													? JSON.parse(chi?.meta_data)
													: chi?.meta_data;

												// logger.log(meta);
												return (
													<MobileTableCard
														title={capitalizeFirstLetter(chi?.type)}
														text={formatDateTime(chi.created_at)}
														amount={
															symbol('ngn') +
															formatNumWithComma(chi._value, 'ngn')
														}
														img={undefined}
														svg={undefined}
														avatar={''}
														amountColor={'#014345'}
														type={chi.direction}
														showImg={true}
														loading={loading}
														onRowClick={() =>
															onView({
																show: true,
																content: { ...meta, ...chi },
															})
														}
														textTwo={''}
													/>
												);
											}
										)}
									</div>
								</>
							)}
						</div>
					</div>
				</PageLayout>
			</DashboardLayout>
			<TransactionModal
				onClose={() =>
					onView({
						show: false,
						content: '',
					})
				}
				visible={view.show}
				direction={view?.content?.direction}
				title={'Transaction Details'}
				amount={view?.content?._value}
				onClick={() => {}}
				meta={view?.content?.meta_data}
				content={[
					{
						label: 'Amount',
						value:
							symbol(activeCurrency) +
							formatNumWithComma(view?.content?._value, activeCurrency),
					},
					{
						label: 'Narration',
						value: view?.content?.narration,
					},
					{
						label: 'Type',
						value: capitalizeFirstLetter(view?.content?.type),
					},
					{
						label: 'Bal Before',
						value:
							symbol(activeCurrency) +
							formatNumWithComma(view?.content?.b_before, activeCurrency),
					},
					{
						label: 'Bal After',
						value:
							symbol(activeCurrency) +
							formatNumWithComma(view?.content?.b_after, activeCurrency),
					},
					{
						label: 'Reference ID',
						value: view?.content?.reference,
						copy: true,
					},
					{
						label: 'Fee',
						value:
							symbol(activeCurrency) +
							formatNumWithComma(view?.content?._fee, 'ngn'),
					},
				]}
			/>
			<ExportModal
				onClose={() => setShowExport(false)}
				page="transactions"
				visible={showExport}
			/>
		</>
	);
};

export default Transaction;
