import {
	RavenButton,
	RavenCheckBox,
	RavenInputField,
	toast,
} from '@ravenpay/raven-bank-ui';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DashboardLayout from '../../../../../../../layout/dashboard/dashboardLayout';
import { FaTimes } from 'react-icons/fa';
import PreviewDetailsBox from './PreviewDetailsBox';
// import ConfirmTransactionPinModal from "../ConfirmPinModal";

import { useDispatch, useSelector } from 'react-redux';
import {
	IconVault,
	formatNumWithComma,
	formatNumWithCommaNairaSymbol,
	generateReference,
	removeCommaAndNairaSign,
	trimLongString,
} from '../../../../../../../utils/helper/Helper';

import { formatNumWithoutCommaNaira } from '../../../../../../../utils/helper/Helper';

import useDebounce from '../../../../../../../hooks/UseDebounce';
import useWindowSize from '../../../../../../../hooks/UseWindowSize';
import ContainerLayout from '../../../../../../../layout/dashboard/container';
import { brands, icons } from '../../../../../../../assets/icons';
import './styles/index.css';
import ViewAll from './ViewAll';
import ConfirmTransactionPinModal from '../../common/ConfirmPinModal';
import { DetectNetwork } from '../../../../../../../utils/helper/DetectNetwork';
import { AppDispatch } from '../../../../../../../redux/store';
import RootState, {
	ElectricityPaymentPayload,
} from '../../../../../../../redux/types';
import {
	getElectricityDiscos,
	payForElectricity,
	validateMeterNumber,
} from '../../../../../../../redux/bills';
import { logger } from '../../../../../../../services/logger';
import { verificationLoader } from '../../../../../../../components/common/verificationLoader';
import VerifiedName from '../../../../../../../components/common/verifiedName';
type Modal = {
	pin: boolean;
	success: boolean;
};
const NewElectricityPurchase = () => {
	const navigate = useNavigate();
	const [step, setStep] = useState<number | string>(1);
	const [selectedType, setSelectedType] = useState<'prepaid' | 'postpaid'>();
	const [viewAll, setViewAll] = useState(false);
	const [validatedName, setValidatedName] = useState('');
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [showModal, setShowModal] = useState<Modal>({
		pin: false,
		success: false,
	});
	const [details, setDetails] = useState<any>({
		electricity_provider: '',
		amount: '',
		meter_no: '',
		transfer_pin: '',
	});

	const dispatch = useDispatch<AppDispatch>();
	const { electricityDiscos, loading } = useSelector(
		(state: RootState) => state.bills
	);
	const { dashboard_data } = useSelector((state: RootState) => state.wallet);

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...details, [name]: value };
		setDetails(obj);
	};

	const [error, setError] = useState({
		electricity_provider: false,
		meter_no: false,
		phone_number: false,
	});
	const [loadingVery, setLoadingVerify] = useState(false);
	const validateMeterNo = async (s: string) => {
		setLoadingVerify(true);

		const payload: {
			provider: string;
			meter_no: string | number;
			meter_type: string;
		} = {
			provider: details?.electricity_provider?.value,
			meter_no: details?.meter_no,
			meter_type: details?.type,
		};

		// logger.log(payload);
		const resp = await dispatch(validateMeterNumber(payload));

		if (resp?.payload?.status === 'success') {
			setValidatedName(resp?.payload?.data?.data?.cust_name);
			setDetails({
				...details,
				validated_name: resp?.payload?.data?.data?.cust_name,
			});
			setLoadingVerify(false);
		} else {
			setLoadingVerify(false);
			setValidatedName('');
		}
	};

	const debouncedSearchTerm = useDebounce(details?.meter_no, 1000);
	useEffect(() => {
		let isMount = true;

		if (
			isMount &&
			debouncedSearchTerm?.length > 3 &&
			details?.electricity_provider &&
			details?.type
		) {
			validateMeterNo(details?.meter_no);
		}

		if (isMount && debouncedSearchTerm?.length < 1) {
			setValidatedName('');
		}

		return () => {
			isMount = false;
		};
	}, [debouncedSearchTerm]);

	function disableAlgorithm() {
		if (!details.electricity_provider || !details.amount) {
			return true;
		} else if (details.amount && !validatedName) {
			return true;
		} else {
			return false;
		}
	}

	function handleInitPayment() {
		if (details.meter_no) {
			return true;
		}
		if (details.amount) {
			return true;
		} else return false;
	}

	useEffect(() => {
		dispatch(getElectricityDiscos());
	}, []);

	function formatDiscos(d?: { code: string; name: string }[]) {
		const r: { label: string; value: string }[] = [];

		d?.map((e: { name: string; code: string }) => {
			r.push({
				label: e.name,
				value: e.code,
			});
		});

		return r;
	}

	useEffect(() => {
		if (details?.meter_no.length > 3) validateMeterNo(details?.meter_no);
	}, [details?.electricity_provider, details?.type]);

	async function handlePayment() {
		const payload: ElectricityPaymentPayload = {
			provider: details?.electricity_provider?.value,
			meter_no: details?.meter_no,
			phone_number: details?.phone_number,
			meter_type: details?.type,
			amount: Number(
				removeCommaAndNairaSign(details?.amount)
			) as unknown as number,
			merchant_reference: generateReference(),
		};

		logger.log(payload);

		const resp: any = await dispatch(payForElectricity(payload));
		if (resp?.payload?.status === 'success') {
			navigate('/dashboard-payment/bill-payments?bill-type=electricity');
		}
	}

	// logger.log(details, 'discos');
	return (
		<>
			<DashboardLayout>
				{step !== 'view-all' && (
					<ContainerLayout
						onBack={() =>
							step !== 1
								? setStep(1)
								: navigate(
										'/dashboard-payment/bill-payments?bill-type=electricity'
								  )
						}
						btnDisabled={disableAlgorithm()}
						onBtnClick={() => {
							if (step === 1) {
								if (handleInitPayment()) setStep(2);
							} else {
								handlePayment();
							}
						}}
						btnLoading={loading}
						isLatest
						btnColor="deep-green-light"
						pageTitle="Buy Electricity"
						pageSubtitle="Quickly purchase electricity from your preffered provider"
						style={{
							width: '100%',
							maxHeight: '95%',
							backgroundColor: step === 2 ? 'unset' : '',
							border: step === 2 ? 'unset' : '',
							padding: step === 2 ? 'unset' : '',
						}}
					>
						<div
							className={`bill-purchase  atlas-payment-container  animate-move-up-class ${
								step === 2 && 'step2'
							} `}
						>
							<form
								onSubmit={(e) => {
									e.preventDefault();
								}}
								action=""
								className="form"
							>
								{/* step 1 start ------------------------------ */}
								{step === 1 && (
									<>
										<div className="bill-purchase__title">
											{icons.arrow_back}
										</div>

										{/* end of bg box */}
										<RavenInputField
											type={`select`}
											color={`deep-green-light`}
											label={`Electricity Provider `}
											style={{ zIndex: 10 }}
											value={details?.electricity_provider}
											onChange={(e: any) => {
												setDetails({
													...details,
													electricity_provider: e,
												});
												setError((prev) => {
													return { ...prev, electricity_provider: false };
												});
											}}
											placeholder={`Select Electricity Provider`}
											labelColor={`purple-light`}
											showError={error?.electricity_provider}
											name={`electricity-provider`}
											selectOption={formatDiscos(electricityDiscos)}
										/>

										<div className="postpaid-prepaid">
											<div
												onClick={() => {
													setSelectedType('prepaid');
													setDetails({
														...details,
														type: 'prepaid',
													});
												}}
												className={`postpaid-prepaid__item ${
													selectedType === 'prepaid' && 'active'
												}`}
											>
												<span className="item__content">
													{selectedType === 'prepaid'
														? icons.light_bulb_on
														: icons.light_bulb_off}
													<p>Prepaid</p>
												</span>
												{selectedType === 'prepaid' && (
													<figure>{icons.checked}</figure>
												)}
											</div>
											<div
												onClick={() => {
													setSelectedType('postpaid');
													setDetails({
														...details,
														type: 'postpaid',
													});
												}}
												className={`postpaid-prepaid__item ${
													selectedType === 'postpaid' && 'active'
												}`}
											>
												<span className="item__content">
													{selectedType === 'postpaid'
														? icons.light_bulb_on
														: icons.light_bulb_off}
													<p>Postpaid</p>
												</span>
												{selectedType === 'postpaid' && (
													<figure>{icons.checked}</figure>
												)}
											</div>
										</div>

										<RavenInputField
											type={`phone`}
											color={`deep-green-light`}
											label={`Phone Number `}
											value={details?.phone_number}
											onChange={(e: any) => {
												handleChange(e);
												setError((prev) => {
													return { ...prev, phone_number: false };
												});
											}}
											placeholder={`E.g 09063*******`}
											labelColor={`purple-light`}
											showError={error?.phone_number}
											name={`phone_number`}
										/>

										<RavenInputField
											type={`text`}
											color={`deep-green-light`}
											label={`Meter Number `}
											value={details?.meter_no}
											onChange={(e: any) => {
												handleChange(e);
												setError((prev) => {
													return { ...prev, meter_no: false };
												});
											}}
											labelSpanText={verificationLoader(loadingVery)}
											labelClassName={`label-class-span ${
												details?.meter_no?.length >= 10 &&
												'label-class-span-active'
											}`}
											placeholder={`E.g ‘5676904735589467586736’`}
											labelColor={`purple-light`}
											showError={error?.meter_no}
											name={`meter_no`}
											onActionClick={() => {
												details?.meter_no?.length > 5 &&
													validateMeterNo(details?.meter_no);
											}}
										/>
										<VerifiedName name={validatedName} />
										{/* verify name box end */}
										<RavenInputField
											type={`number`}
											color={`black-light`}
											label={`Amount* `}
											name={`amount`}
											value={details?.amount}
											onChange={handleChange}
											placeholder={`E.g ‘50,000’`}
											labelColor={`black-light`}
											labelSpanText={
												typeof dashboard_data?.balances !== 'undefined'
													? `Bal: ₦ ${formatNumWithComma(
															dashboard_data?.balances[0]?.available_bal,
															'ngn'
													  )} `
													: 'Bal: ₦ ••••••'
											}
											labelClassName={`label-span-amount label-span-theme`}
											thousandFormat
											numberPrefix={`₦`}
											onActionClick={() => {}}
										/>

										{/* recepient box end */}
									</>
								)}
								{/* step 1 end -------------------------------- */}

								{/* step 2 start ----------------------------- */}
								{step === 2 && (
									<div className="step-two-bills animate-move-up-class">
										<div className="preview-bill__logo">
											<figure>
												{
													<img
														src={IconVault(icons.electricity_purchase)}
														alt=""
													/>
												}
											</figure>
										</div>

										<PreviewDetailsBox
											currentIndex={(d: any) => setCurrentIndex(d)}
											onBtnClick={
												() => handlePayment()
												// setShowModal({
												// 	pin: true,
												// 	success: false,
												// })
											}
											loading={loading}
											content={details}
										/>
									</div>
								)}
							</form>

							{/* view all end ----------------------------- */}
						</div>
					</ContainerLayout>
				)}

				{step === 'view-all' && (
					<ViewAll
						onBack={() => {
							setStep(1);
						}}
						onChange={(e: any) => setDetails(e)}
						items={details}
					/>
				)}
			</DashboardLayout>
			{/* <ConfirmTransactionPinModal
				onBtnClick={() => {
					toast.success(
						'Your Airtime Purchase of Airtel - 200 has been successful'
					);
					setShowModal({
						pin: false,
						success: false,
					});
					navigate('/dashboard-payment/bill-payments');
				}}
				onClose={() =>
					setShowModal({
						pin: false,
						success: false,
					})
				}
				visible={showModal.pin}
			/> */}
		</>
	);
};

export default NewElectricityPurchase;
