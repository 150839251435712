import { RavenActionBar, RavenInputField } from '@ravenpay/raven-bank-ui';
import React, {
	ChangeEvent,
	ReactElement,
	useEffect,
	useRef,
	useState,
} from 'react';
import { icons } from '../../../../../assets/icons';
import ColorSwatch from './colorSwatch/';
import Loader from '../../../../../components/common/loader';
import { BankboxMerchantProfile } from '../../../../../redux/types';
import Colorful from '@uiw/react-color-colorful';
import { validateEmail } from '../../../../../utils/helper/ValidateInput';
import axios from 'axios';
import { bankboxAPI } from '../../../../../redux/bankbox';
import { useDispatch } from 'react-redux';
import { nigerianStates } from '../../data/statesInNigeria';
import { getInverseColor } from './colorConvert';
import Copy from '../../../../../components/common/copyCheck';
import { logger } from '../../../../../services/logger';

function BasicProfile({
	activeSettings,
	loading,
	onChange,
	value,
	logoFile,
	logoChanged,
}: {
	loading: boolean;
	onChange: (value: any) => void;
	logoChanged: (value: boolean) => void;
	value: BankboxMerchantProfile;
	activeSettings: (e?: string) => void;
	logoFile: (e?: File) => void;
}) {
	const [isSwatchOpen, setIsSwatchOpen] = React.useState(false);
	const [cardFee, setCardFee] = React.useState(0);
	// const [isLogoChanged, setIsLogoChanged] = React.useState(false);
	const [incomplete, setInCompleted] = React.useState<{
		1?: boolean;
		2?: boolean;
		3?: boolean;
	}>({});

	const [priceComplete, setPriceComplete] = useState<boolean>(false);
	const [webhookComplete, setWebhookComplete] = useState<boolean>(false);
	const [supportComplete, setSupportComplete] = useState<boolean>(false);

	// let appInputEl: ReactElement | any = <></>;
	const fileInputRef = useRef<HTMLInputElement>(null);
	const imgPreviewRef = useRef<HTMLImageElement>(null);

	const handleUploadClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];

		logoFile(file);
		if (file && imgPreviewRef.current) {
			const reader = new FileReader();
			logoChanged(true);
			reader.onload = (e) => {
				if (imgPreviewRef.current) {
					imgPreviewRef.current.src = e.target?.result as string;
				}
			};

			reader.readAsDataURL(file);
		}
	};

	// React.useEffect(() => {
	// 	return () => {
	// 		appInputEl = document.querySelector('.input-group__color-swatch');

	// 		const appInputEl2 = appInputEl?.querySelector('.form-input');
	// 		// logger.log(appInputEl);
	// 	};
	// }, [appInputEl]);

	logger.log(value.card_fee, value.card_fee_cap, value.mandatory_fee);
	const [revealed, setRevealed] = useState(false);

	function markComplete() {
		if (
			String(value.card_fee_cap).length >= 1 &&
			String(value.card_fee).length >= 1 &&
			String(value.device_price).length >= 1 &&
			String(value.mandatory_fee).length >= 1
		) {
			setPriceComplete(true);
		}

		if (validateEmail(value.support_email) && value.region.length >= 2) {
			setSupportComplete(true);
		}

		if (
			value.webhook_url &&
			value.webhook_url?.length > 3 &&
			value.webhook_secret &&
			value.webhook_secret?.length > 3 &&
			value.website &&
			value.website.length > 3
		) {
			setWebhookComplete(true);
		}
	}

	useEffect(() => {
		markComplete();
	}, [value]);

	useEffect(() => {
		setCardFee(value.card_fee);
	}, []);
	// logger.log(incomplete, 'icp');

	function formatSecrets(secret: string) {
		return secret?.length > 45
			? (secret?.slice(0, 45) as string) + '...'
			: (secret as string);
	}
	return (
		<div className="basic-details-settings">
			{loading ? (
				<Loader />
			) : (
				<>
					<div
						id="basic"
						onClick={() => activeSettings('basic')}
						className="basic-details-settings__app-name"
					>
						<figure
							style={{ cursor: 'pointer' }}
							onClick={handleUploadClick}
							className="basic-details-settings__app-name--avatar"
						>
							<input
								ref={fileInputRef}
								type="file"
								accept="image/*"
								style={{ display: 'none' }}
								onChange={handleFileChange}
							/>
							<img
								ref={imgPreviewRef}
								src={
									value?.logo_url ??
									'https://sm.ign.com/ign_nordic/cover/a/avatar-gen/avatar-generations_prsz.jpg'
								}
								alt=""
							/>
							<p>
								Click on the camera icon icon the image to change Business logo
							</p>
						</figure>

						<div className="basic-details-settings__app-name--input-group">
							<RavenInputField
								placeholder="e.g Sharons's Hair"
								color="deep-green-light"
								value={value.app_name}
								onChange={onChange}
								name="app_name"
								label="App Name"
							/>

							<div className="input-group__color-swatch">
								<div
									className="input-group__color-swatch--color-preview"
									style={{ backgroundColor: value.color_code }}
								>
									<p style={{ color: getInverseColor(value?.color_code) }}>
										{value.color_code}
									</p>
								</div>
								{/* color swatch starts here */}
								<div
									className={`more-wrap ${
										isSwatchOpen && 'show-drop swatch-drop'
									}`}
								>
									{isSwatchOpen && (
										<div
											style={{
												position: 'fixed',
												top: '0',
												height: '100%',
												width: '100%',
											}}
											onClick={() => setIsSwatchOpen(false)}
											className="open-swatch-overlay"
										></div>
									)}
									<div className="colour-pallete">
										<Colorful
											color={value.color_code}
											disableAlpha={true}
											onChange={(color: { hexa: string }) => {
												onChange({
													target: {
														name: 'color_code',
														value: color.hexa,
													},
												});
												// dispatch(setBrandColor(color.hexa));
											}}
										/>

										<div className="color-input">
											<RavenInputField
												value={value.color_code}
												onChange={onChange}
												name="color_code"
												color="deep-green-light"
												label="Colour Code"
											/>
										</div>
									</div>
								</div>

								<div
									onClick={() => setIsSwatchOpen(!isSwatchOpen)}
									className="input-group__color-swatch--dropdown"
								>
									<figure>{icons.chevron_down}</figure>
								</div>
							</div>
						</div>
						<RavenInputField
							// style={{ opacity: '0' }}
							value={value.website}
							onChange={onChange}
							name="website"
							color="deep-green-light"
							placeholder="e.g www.laura.com"
							label="Website *"
						/>
					</div>

					<div
						id="prices"
						onClick={() => activeSettings('prices')}
						className="basic-details-settings__prices"
					>
						<h6 className="basic-details-settings__prices--title">
							Prices, Rates and Fees
						</h6>

						<RavenActionBar
							className="basic-details-settings__prices--actionbar"
							visible={!priceComplete}
							onCancel={() => {
								setPriceComplete(true);
							}}
							type={'warning'}
							msg={'This setup is incomplete at the moment'}
						/>

						<div
							className={`basic-details-settings__prices--input-group ${
								!priceComplete && 'incomplete'
							}`}
						>
							<div className="inputs">
								<RavenInputField
									placeholder="e.g ₦30,000"
									color="deep-green-light"
									value={value.device_price}
									onChange={onChange}
									name="device_price"
									thousandFormat
									type="number"
									numberPrefix={`₦`}
									label="Device Price*"
								/>
								<RavenInputField
									color="deep-green-light"
									label="Amount Capped*"
									value={value.card_fee_cap}
									onChange={onChange}
									thousandFormat
									type="number"
									numberPrefix={`₦`}
									name="card_fee_cap"
								/>
							</div>
							<div className="inputs">
								<RavenInputField
									value={value.card_fee}
									onChange={(e: any) => {
										// Number(e.target.value) <= 100 && setCardFee(e.target.value);
										Number(e.target.value) <= 100 && onChange(e);
									}}
									name="card_fee"
									color="deep-green-light"
									thousandFormat
									placeholder="e.g 5"
									type="text"
									label="Rate %*"
								/>
								<RavenInputField
									color="deep-green-light"
									value={value.mandatory_fee}
									onChange={onChange}
									type="number"
									thousandFormat
									numberPrefix={`₦`}
									name="mandatory_fee"
									label="Mandatory Fee*"
								/>
							</div>
						</div>
					</div>

					<div
						id="operations"
						onClick={() => activeSettings('operations')}
						className="basic-details-settings__prices"
					>
						<h6 className="basic-details-settings__prices--title">
							Operations and Support
						</h6>

						<RavenActionBar
							className="basic-details-settings__prices--actionbar"
							visible={!supportComplete}
							onCancel={() => {
								setSupportComplete(true);
							}}
							type={'warning'}
							msg={'This setup is incomplete at the moment'}
						/>

						<div
							className={`basic-details-settings__prices--input-group ${
								!supportComplete && 'incomplete'
							}`}
						>
							<div className="inputs">
								<RavenInputField
									value={value.support_email}
									onChange={onChange}
									name="support_email"
									color="deep-green-light"
									placeholder="e.g bankbox.support@riggs.com"
									label="Support Email*"
								/>
							</div>

							<div className="inputs">
								{/* <RavenInputField
									color="deep-green-light"
									value={value.app_id}
									onChange={onChange}
									name="app_name"
									label="Operating Price*"
								/> */}
								<RavenInputField
									style={{ zIndex: 10000 }}
									value={{
										value: value.region,
										label: value.region,
									}}
									onChange={(e: { label: any }) => {
										onChange({
											target: {
												name: 'region',
												value: e.label,
											},
										});
									}}
									type="select"
									name="region"
									selectOption={nigerianStates}
									placeholder="e.g Portharchout"
									color="deep-green-light"
									label="Region*"
								/>
								{/* <RavenInputField
									style={{ opacity: '0' }}
									value={value.website}
									onChange={onChange}
									name="website"
									color="deep-green-light"
									placeholder="e.g www.laura.com"
									label="Website *"
								/> */}
							</div>
						</div>
					</div>

					<div
						id="webhook"
						onClick={() => activeSettings('webhook')}
						className="basic-details-settings__prices"
					>
						<h6 className="basic-details-settings__prices--title">
							Webhook & Keys
						</h6>

						<RavenActionBar
							className="basic-details-settings__prices--actionbar"
							visible={!webhookComplete}
							onCancel={() => {
								setWebhookComplete(true);
							}}
							type={'warning'}
							msg={'This setup is incomplete at the moment'}
						/>

						<div
							className={`basic-details-settings__prices--input-group ${
								!webhookComplete && 'incomplete'
							}`}
						>
							<div className="inputs">
								<RavenInputField
									value={value.webhook_url}
									onChange={onChange}
									name="webhook_url"
									placeholder="e.g. https://api.riggs.com/v1/webhook"
									color="deep-green-light"
									label="Webhook URL*"
								/>
							</div>

							<div className="inputs">
								<RavenInputField
									value={value.webhook_secret}
									onChange={onChange}
									name="webhook_secret"
									color="deep-green-light"
									placeholder="Enter your webhook secret"
									label="Webhook Secret*"
								/>
							</div>
						</div>

						<div className="grouped__input">
							<RavenInputField
								label={'Live Public Key'}
								type="text"
								readOnly
								color="deep-green-light"
								placeholder={value?.app_id}
							/>
							<Copy
								icon={icons.copy_black}
								className="input__copy-icon"
								text={value?.app_id}
							/>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default BasicProfile;
