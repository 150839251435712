import { RavenButton, RavenInputField, toast } from '@ravenpay/raven-bank-ui';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DashboardLayout from '../../../../../../../layout/dashboard/dashboardLayout';
import PreviewDetailsBox from './PreviewDetailsBox';
import {
	IconVault,
	formatNumWithComma,
} from '../../../../../../../utils/helper/Helper';

import useDebounce from '../../../../../../../hooks/UseDebounce';

import ContainerLayout from '../../../../../../../layout/dashboard/container';
import { brands, icons } from '../../../../../../../assets/icons';
import arikAir from '../../../../../../../assets/images/arik-air.png';
import danaAir from '../../../../../../../assets/images/dana-air.png';
import discoveryAir from '../../../../../../../assets/images/discovery-air.png';
import wakanow from '../../../../../../../assets/images/wakanow.png';
import aeroAir from '../../../../../../../assets/images/aero-air.png';
import hakAir from '../../../../../../../assets/images/hak-air.png';
import './styles/index.css';
import ViewAll from './ViewAll';
import ConfirmTransactionPinModal from '../../common/ConfirmPinModal';
import { useSelector } from 'react-redux';
import RootState from '../../../../../../../redux/types';

type Modal = {
	pin: boolean;
	success: boolean;
};

type Provider = 'gotv' | 'dstv' | 'consat' | 'startimes' | '';

const NewFlightPurchase = () => {
	const navigate = useNavigate();
	const [step, setStep] = useState<number | string>(1);

	const [viewAll, setViewAll] = useState(false);
	const [ravenName, setRavenName] = useState('');
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [showModal, setShowModal] = useState<Modal>({
		pin: false,
		success: false,
	});
	const [details, setDetails] = useState<any>({
		platform: '',
		amount: '',
		booking_number: '',
		transfer_pin: '',
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...details, [name]: value };
		setDetails(obj);
	};

	const handleBulkAdd = () => {
		const checkList = details?.bulk_list?.some(
			(chi: any) => chi?.phone === details?.phone
		);
		if (!checkList) {
			const obj = {
				phone: details?.phone,
				amount: details?.amount,
				provider: details?.provider,
			};
			const newList = [obj, ...details?.bulk_list];
			setDetails((prev: any) => {
				return {
					...prev,
					bulk_list: newList,
					phone: '',
					amount: '',
					provider: '',
				};
			});
			setRavenName('');
			// setVerify(false);
		} else {
			toast.error('Recepient already exist in bulk list');
		}
	};

	const [error, setError] = useState({
		platform: false,
		booking_number: false,
	});
	const [loadingVery, setLoadingVerify] = useState(false);
	const validateUsername = async (param: { username: any }) => {
		setLoadingVerify(true);
		setRavenName('Emmanuel Ezeani');
	};

	const { dashboard_data } = useSelector((state: RootState) => state.wallet);

	const debouncedSearchTerm = useDebounce(details?.username, 1000);
	useEffect(() => {
		let isMount = true;

		if (isMount && debouncedSearchTerm?.length > 3) {
			const payload = {
				username: details?.username,
			};
			validateUsername(payload);
		}

		if (isMount && debouncedSearchTerm?.length < 1) {
			setRavenName('');
		}

		return () => {
			isMount = false;
		};
	}, [debouncedSearchTerm]);

	function disableAlgorithm() {
		if (!details.platform || !details.booking_number || !details.amount) {
			return true;
		} else {
			return false;
		}
	}

	function handleInitPayment() {
		if (!details.username) {
			return true;
		}
		if (!details.amount) {
			return true;
		} else return false;
	}

	const CustomOption = ({
		innerProps,
		label,
		data,
	}: {
		innerProps: any;
		label: string;
		data: any;
	}) => (
		<div {...innerProps} className="dropdown__select-item">
			<figure>
				<img src={data.image} alt="" />
			</figure>
			<span>{label}</span>
		</div>
	);

	const providerList = [
		{
			label: 'Arik Airlines • Book on Hold',
			value: 'arik-air',
			image: arikAir,
		},
		{
			label: 'Dana Airlines • Book on Hold',
			value: 'dana-air',
			image: danaAir,
		},
		{
			label: 'Discover Airlines • Book on Hold',
			value: 'arik-air',
			image: discoveryAir,
		},
		{
			label: 'Aero Airlines • Book on Hold',
			value: 'arik-air',
			image: aeroAir,
		},
		{
			label: 'Hak Airlines • Book on Hold',
			value: 'arik-air',
			image: hakAir,
		},
		{
			label: 'Wakanow',
			value: 'arik-air',
			image: wakanow,
		},
	];

	return (
		<>
			<DashboardLayout>
				{step !== 'view-all' && (
					<ContainerLayout
						onBack={() => {}}
						pageTitle="Book a Flight"
						pageSubtitle="Quickly book a flight from your preffered provider"
						style={{
							width: '100%',
							maxHeight: '95%',
							backgroundColor: step === 2 ? 'unset' : '',
							border: step === 2 ? 'unset' : '',
							padding: step === 2 ? 'unset' : '',
						}}
					>
						<div
							className={`bill-purchase flight animate-move-up-class ${
								step === 2 && 'step2'
							} `}
						>
							<form
								onSubmit={(e) => {
									e.preventDefault();
								}}
								action=""
								className="form"
							>
								{/* step 1 start ------------------------------ */}
								{step === 1 && (
									<>
										<div className="bill-purchase__title">
											{icons.arrow_back}
										</div>

										<RavenInputField
											type={`select`}
											selectComponents={{ Option: CustomOption }}
											style={{ zIndex: '676' }}
											color={`deep-green-light`}
											label={`Flight Booking Service`}
											value={details?.platform}
											onChange={(e: any) => {
												setDetails({
													...details,
													platform: e,
												});
												setError((prev) => {
													return { ...prev, platform: false };
												});
											}}
											placeholder={`Select Flight Booking Service`}
											labelColor={`purple-light`}
											showError={error?.platform}
											name={`platform`}
											selectOption={providerList}
										/>

										<RavenInputField
											type={`text`}
											color={`deep-green-light`}
											label={`Booking Confirmation Number`}
											value={details?.booking_number}
											onChange={(e: any) => {
												handleChange(e);
												setError((prev) => {
													return { ...prev, booking_number: false };
												});
											}}
											placeholder={`E.g ‘5676904735589467586736’`}
											labelColor={`purple-light`}
											showError={error?.booking_number}
											name={`booking_number`}
										/>
										<RavenInputField
											type={`number`}
											color={`black-light`}
											label={`Amount* `}
											name={`amount`}
											value={details?.amount}
											onChange={handleChange}
											placeholder={`E.g ‘50,000’`}
											labelColor={`black-light`}
											labelSpanText={
												typeof dashboard_data?.balances !== 'undefined'
													? `Bal: ₦ ${formatNumWithComma(
															dashboard_data?.balances[0]?.available_bal,
															'ngn'
													  )} `
													: 'Bal: ₦ ••••••'
											}
											labelClassName={`label-span-amount label-span-theme`}
											thousandFormat
											numberPrefix={`₦`}
											onActionClick={() => {}}
										/>

										<RavenButton
											className="bill-purchase-button"
											style={{ width: '100%' }}
											label="Proceed"
											disabled={disableAlgorithm()}
											onClick={() => {
												if (handleInitPayment()) setStep(2);
												else setStep(2), handleBulkAdd();
											}}
											color="deep-green-light"
										/>
										{/* recepient box end */}
									</>
								)}
								{/* step 1 end -------------------------------- */}

								{/* step 2 start ----------------------------- */}
								{step === 2 && (
									<div className="step-two-bills animate-move-up-class">
										<div className="preview-bill__logo">
											<figure>
												{<img src={IconVault(icons.flight)} alt="" />}
											</figure>
										</div>

										<PreviewDetailsBox
											currentIndex={(d: any) => setCurrentIndex(d)}
											onBtnClick={() =>
												setShowModal({
													pin: true,
													success: false,
												})
											}
											content={details}
										/>
									</div>
								)}
							</form>

							{/* view all end ----------------------------- */}
						</div>
					</ContainerLayout>
				)}

				{step === 'view-all' && (
					<ViewAll
						onBack={() => {
							setStep(1);
						}}
						onChange={(e: any) => setDetails(e)}
						items={details}
					/>
				)}
			</DashboardLayout>
			<ConfirmTransactionPinModal
				onBtnClick={() => {
					toast.success(
						'Your Airtime Purchase of Airtel - 200 has been successful'
					);
					setShowModal({
						pin: false,
						success: false,
					});
					navigate('/dashboard-payment/bill-payments');
				}}
				onClose={() =>
					setShowModal({
						pin: false,
						success: false,
					})
				}
				visible={showModal.pin}
			/>
		</>
	);
};

export default NewFlightPurchase;
