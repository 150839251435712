import { ThreeDots } from 'react-loader-spinner';

export const verificationLoader = (loading: boolean, text?: string) => {
	return loading
		? ((
				<div
					style={{
						padding: '0rem',
						display: 'flex',
						justifyContent: 'flex-end',
						transform: 'translateX(1.5rem)',
					}}
					className="load-wrap"
				>
					<ThreeDots
						height="10"
						width="80"
						radius="9"
						color="#012223"
						ariaLabel="three-dots-loading"
						wrapperStyle={{}}
						visible={true}
					/>
				</div>
		  ) as unknown as string)
		: ((text || 'Verify') as string);
};
