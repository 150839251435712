import './style/index.css';

export function AtlasDropDown({
	className,
	activeClassName,
	onClick,
	options,
	style,
	value,
}: any) {
	return (
		<div style={style} className={`atlas-drop-wrap ${className}`}>
			{options?.map((chi: any, idx: number) => {
				return (
					<div
						key={idx}
						onClick={() => {
							chi?.action && chi.action();
							onClick && onClick(chi);
						}}
						className={`more_item ${
							value.label === chi.label && activeClassName
						}`}
					>
						{/* <figure>{icons.arrow_block_right}</figure> */}
						<p>{chi?.label}</p>
					</div>
				);
			})}
		</div>
	);
}
