import React from 'react';
import '../styles/index.css';
import { icons } from '../../../../../assets/icons';
import RootState from '../../../../../redux/types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const VerificationSettings = () => {
	const { profile } = useSelector((state: RootState) => state.wallet);

	const navigate = useNavigate();

	//TODO: add navigation support for the pending kyc

	const { businessInfo, merchantInfo } = profile;
	return (
		<div className="verification-settings">
			<div className="verification-settings__title">
				<h5>Verifications</h5>
				<p>Manage all your notifications in one place.</p>
			</div>

			<div className="verification-settings__main">
				<div className="main__items">
					<div className="items__grouped">
						<figure>{icons.settings_bvnnin}</figure>
						<span>
							<h5>BVN Verifications</h5>
							<p>Confirm your bank verification number</p>
						</span>
					</div>
					<div
						className={`items__status ${
							merchantInfo?.bvn === 3 ? 'verified' : 'pending'
						} `}
					>
						{merchantInfo?.bvn === 3 && (
							<figure>{icons.green_checkmark}</figure>
						)}
						<p>{merchantInfo?.bvn === 3 ? 'Verified' : 'Pending'}</p>
					</div>
				</div>
				<div className="main__items">
					<div className="items__grouped">
						<figure>{icons.settings_bvnnin}</figure>
						<span>
							<h5>ID Verifications</h5>
							<p>Submit your identity for verification</p>
						</span>
					</div>
					<div
						className={`items__status ${
							businessInfo?.document_verification_status === 1
								? 'verified'
								: 'pending'
						} `}
					>
						{businessInfo?.document_verification_status === 1 && (
							<figure>{icons.green_checkmark}</figure>
						)}
						<p>
							{businessInfo?.document_verification_status === 1
								? 'Verified'
								: 'Pending'}
						</p>{' '}
					</div>
				</div>
				<div className="main__items">
					<div className="items__grouped">
						<figure>{icons.settings_bvnnin}</figure>
						<span>
							<h5>Address Verification</h5>
							<p>Access to receiving SMS notifications for your transactions</p>
						</span>
					</div>
					<div
						className={`items__status ${
							businessInfo?.address_status === 1
								? 'verified'
								: businessInfo?.business_address?.length > 1
								? 'review'
								: 'pending'
						}
						} `}
					>
						{businessInfo?.address_status === 1 && (
							<figure>{icons.green_checkmark}</figure>
						)}
						<p>
							{businessInfo?.address_status === 1
								? 'Verified'
								: businessInfo?.business_address?.length > 1
								? 'Ongoing Review'
								: 'Pending'}
						</p>{' '}
					</div>
				</div>

				<div className="main__items">
					<div className="items__grouped">
						<figure>{icons.settings_cac}</figure>
						<span>
							<h5>Business Details</h5>
							<p>Access to receiving SMS notifications for your transactions</p>
						</span>
					</div>
					<div
						className={`items__status ${
							businessInfo?.industry?.length > 1 &&
							businessInfo?.transaction_range?.length > 1
								? 'verified'
								: 'pending'
						} `}
					>
						{businessInfo?.industry?.length > 1 &&
							businessInfo?.transaction_range?.length > 1 && (
								<figure>{icons.green_checkmark}</figure>
							)}
						<p>{businessInfo?.industry ? 'Verified' : 'Pending'}</p>
					</div>
				</div>
				<div className="main__items">
					<div className="items__grouped">
						<figure>{icons.settings_cac}</figure>
						<span>
							<h5>CAC Verification</h5>
							<p>Access to receiving SMS notifications for your transactions</p>
						</span>
					</div>
					<div
						className={`items__status ${
							businessInfo?.registration_number?.length > 1
								? 'review'
								: businessInfo?.cac_verified === 1
								? 'verified'
								: 'pending'
						} `}
					>
						{businessInfo?.cac_verified === 1 && (
							<figure>{icons.green_checkmark}</figure>
						)}
						<p>
							{businessInfo?.registration_number?.length > 1
								? 'Ongoing Review'
								: businessInfo?.cac_verified === 1
								? 'Verified'
								: 'Pending'}
						</p>{' '}
					</div>
				</div>
			</div>
		</div>
	);
};

export default VerificationSettings;
