import React, { useEffect } from 'react';
import './AuthOneLayoutWrap.css';
import hometown from '../../assets/images/hometown.png';
import raven from '../../assets/images/raven-logo-green.png';
import paymable from '../../assets/images/paymable.png';
import { icons } from '../../assets/icons';
import { toast } from '@ravenpay/raven-bank-ui';
import LegacySwitch from './LegacySwitch';
import { getCookie } from '../../utils/helper/Helper';
import { useNavigate } from 'react-router-dom';

interface MyComponentProps {
	children?: React.ReactNode;
	secondFrame?: boolean;
}

const AuthOneLayoutWrap: React.FC<MyComponentProps> = ({
	children,
	secondFrame,
}) => {
	const navigate = useNavigate();

	async function authCheckpoint() {
		const auth = getCookie('token');

		const matches = location.href.includes('/register');

		if (auth && matches) {
			navigate('/dashboard-overview');
		}
	}

	useEffect(() => {
		authCheckpoint();
	}, []);
	return (
		<div className="auth-layout-wrap">
			{/* left wrap start */}
			<LegacySwitch />
			<div className={`left-wrap ${secondFrame && 'left-wrap-two'}`}>
				{!secondFrame && (
					<>
						<div className="text-social-link-box">
							<div className="big-text-box">
								<p className="text">Empower your Business </p>
								<p className="text">
									with <span>cutting-edge</span> banking
								</p>
								<p className="text"> solution. </p>
							</div>
							<div className="small-text-box">
								<p className="text">TRUSTED COMPANIES</p>
							</div>
							<div className="round-icon-box">
								<figure className="img-box">
									<img src={raven} alt="" />
								</figure>
								<figure className="img-box">
									{' '}
									<img src={hometown} alt="" />
								</figure>
							</div>
						</div>
					</>
				)}
				{secondFrame && (
					<>
						<div className="text-social-link-box">
							<div className="small-text-box">
								<p className="text">TRUSTED COMPANIES</p>
							</div>
							<div className="round-icon-box">
								<figure className="img-box">
									<img src={raven} alt="" />
								</figure>
								<figure className="img-box">
									{' '}
									<img src={hometown} alt="" />
								</figure>
							</div>
						</div>
					</>
				)}
			</div>
			{/* left wrap end */}
			{/* right wrap start */}
			<div className="right-wrap">
				<div className="wrap">{children}</div>
			</div>
			{/* right wrap end */}
		</div>
	);
};

export default AuthOneLayoutWrap;
