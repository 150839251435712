import React, { useState } from 'react';
import ContainerLayout from '../../../../layout/dashboard/container';
import { RavenActionBar } from '@ravenpay/raven-bank-ui';
import { icons } from '../../../../assets/icons';
import CodePreview from './CodePreview';
import { formatDateTime, returnInitial } from '../../../../utils/helper/Helper';
import { logger } from '../../../../services/logger';

const VerifiedDetails = ({ details, onBack }: any) => {
	const [preview, setPreview] = useState(false);

	logger.log(details);
	return (
		<ContainerLayout
			onBack={() => onBack('form')}
			pageTitle="User KYC"
			pageSubtitle="Easily validate and verify identities"
			style={{ width: '100%', maxWidth: '90%', height: '100%' }}
		>
			<div className="kyc-dashboard-wrapper__verified-details">
				<RavenActionBar visible msg="Details fetched from the Data Provided" />
				<div className="verified-details__information">
					{preview ? (
						<CodePreview json={details} />
					) : (
						<React.Fragment>
							<div className="verified-details__information--owner animate-move-up-class">
								{details?.photo ? (
									<figure>
										<img src={details?.photo} alt="" />
									</figure>
								) : (
									<div className="avatar">
										<p>{returnInitial(details.fname + ' ' + details.lname)}</p>
									</div>
								)}
								<span>
									<h5>{details?.firstname + ' ' + details?.lastname}</h5>
									<p>
										{details?.identity ||
											details?.phone_number ||
											details?.phone ||
											'-'}
									</p>
								</span>
							</div>
							<div className="verified-details__information--details animate-move-up-class non-mobile">
								<div className="details__item">
									<span>
										<small>First Name</small>
										<p>{details?.firstname}</p>
									</span>
									<span>
										<small>Date of Birth</small>
										<p>
											{details?.date_of_birth
												? formatDateTime(details?.date_of_birth)
												: '--'}
										</p>
									</span>{' '}
								</div>
								<div className="details__item">
									<span>
										<small>Last Name</small>
										<p>{details?.lastname ?? '--'}</p>
									</span>{' '}
									<span>
										<small>Mobile Number</small>
										<p>{details?.phone_number || details?.phone || '--'}</p>
									</span>{' '}
								</div>
								<div className="details__item">
									<span>
										<small>Middle Name</small>
										<p>{details?.middlename ?? '--'}</p>
									</span>{' '}
									<span>
										<small>Address</small>
										<p>{details?.residentialAddress}</p>
									</span>{' '}
								</div>
							</div>

							<div className="verified-details__information--details animate-move-up-class non-desktop">
								<div className="details__item">
									<span>
										<small>First Name</small>
										<p>{details?.firstname}</p>
									</span>
									<span>
										<small>Last Name</small>
										<p>{details?.lastname ?? '--'}</p>
									</span>{' '}
									<span>
										<small>Middle Name</small>
										<p>{details?.middlename ?? '--'}</p>
									</span>{' '}
									<span>
										<small>Mobile Number</small>
										<p>{details?.phone_number || details?.phone || '--'}</p>
									</span>{' '}
									<span>
										<small>Address</small>
										<p>{details?.residentialAddress}</p>
									</span>{' '}
									<span>
										<small>Date of Birth</small>
										<p>
											{details?.date_of_birth
												? formatDateTime(details?.date_of_birth)
												: '--'}
										</p>
									</span>{' '}
								</div>
							</div>
						</React.Fragment>
					)}

					<div className="verified-details__information--preview-json ">
						<div
							onClick={() => setPreview(!preview)}
							className="preview-json__button"
						>
							<p>{preview ? 'Data Preview' : 'JSON Preview'}</p>
							<figure>{icons.code}</figure>
						</div>
					</div>
				</div>
			</div>
		</ContainerLayout>
	);
};

export default VerifiedDetails;
