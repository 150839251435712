import Bills from '../../../pages/dashboard/payments/billPayment';
import Collections from '../../../pages/dashboard/collections';
import Onboarding from '../../../pages/dashboard/onboarding';
import OverviewIndex from '../../../pages/dashboard/overview';
import PaymentLinks from '../../../pages/dashboard/payments/paymentLinks';
import PaymentLinkPay from '../../../pages/dashboard/payments/paymentLinks/components/linkPayment';
import NewPaymentLink from '../../../pages/dashboard/payments/paymentLinks/components/newLink';
import SingleViewLink from '../../../pages/dashboard/payments/paymentLinks/components/singleView';
import Transfers from '../../../pages/dashboard/payments/transfer';
import NewTransfer from '../../../pages/dashboard/payments/transfer/components/newTransfer';
import BulkTransfer from '../../../pages/dashboard/payments/transfer/components/newTransfer/bulk';
import ManualBulkTransfer from '../../../pages/dashboard/payments/transfer/components/newTransfer/manual-bulk';
import TransferOthers from '../../../pages/dashboard/payments/transfer/components/newTransfer/others';
import TransferRaven from '../../../pages/dashboard/payments/transfer/components/newTransfer/raven';
import Transaction from '../../../pages/dashboard/transaction';
import TransactionReport from '../../../pages/dashboard/transaction/components/report';
import Wallet from '../../../pages/dashboard/wallet';
import BalanceHistory from '../../../pages/dashboard/wallet/components/balanceHistory';
import FundingHistory from '../../../pages/dashboard/wallet/components/fundingHistory';
import SettlementHistory from '../../../pages/dashboard/wallet/components/settlementHistory';
import NewAirtimePurchase from '../../../pages/dashboard/payments/billPayment/components/newBills/airtime';
import NewDataPurchase from '../../../pages/dashboard/payments/billPayment/components/newBills/data';
import NewElectricityPurchase from '../../../pages/dashboard/payments/billPayment/components/newBills/electricity';
import NewCablePurchase from '../../../pages/dashboard/payments/billPayment/components/newBills/cableTv';
import NewBettingPurchase from '../../../pages/dashboard/payments/billPayment/components/newBills/betting';
import NewFlightPurchase from '../../../pages/dashboard/payments/billPayment/components/newBills/flight';
import VirtualAccounts from '../../../pages/dashboard/virtualAccounts';
import ViewVirtualAccount from '../../../pages/dashboard/virtualAccounts/components/singleView';
import Customers from '../../../pages/dashboard/customers';
import Settings from '../../../pages/dashboard/settings';
import DashboardKYC from '../../../pages/dashboard/kyc';
import CardIssuing from '../../../pages/dashboard/issuing';
import BankBoxOverview from '../../../pages/dashboard/bankbox';
import BankBoxUsers from '../../../pages/dashboard/bankbox/users';
import BankboxRequest from '../../../pages/dashboard/bankbox/components/request';
import BankboxTerminals from '../../../pages/dashboard/bankbox/terminals';
import BankBoxTransactions from '../../../pages/dashboard/bankbox/transactions';
import BankboxSettings from '../../../pages/dashboard/bankbox/settings';
import SingleViewBankbox from '../../../pages/dashboard/bankbox/terminals/component/SingleViewBankbox';

export const overview_routes_group = [
	{
		path: '/dashboard-overview',
		element: OverviewIndex,
	},
	{
		path: '/dashboard-onboarding',
		element: Onboarding,
	},

	{
		path: '/dashboard-wallet/balances',
		element: Wallet,
	},

	{
		path: '/dashboard-wallet/balance-history',
		element: BalanceHistory,
	},

	{
		path: '/dashboard-wallet/settlement-history',
		element: SettlementHistory,
	},
	{
		path: '/dashboard-wallet/funding-history',
		element: FundingHistory,
	},
	{
		path: '/dashboard-collections',
		element: Collections,
	},
	{
		path: '/dashboard-transactions',
		element: Transaction,
	},
	{
		path: '/dashboard-transactions/report',
		element: TransactionReport,
	},

	{
		path: '/dashboard-payment/transfer',
		element: Transfers,
	},
	{
		path: '/dashboard-payment/transfer/new',
		element: NewTransfer,
	},

	{
		path: '/dashboard-payment/transfer/raven',
		element: TransferRaven,
	},

	{
		path: '/dashboard-payment/transfer/others',
		element: TransferOthers,
	},
	{
		path: '/dashboard-payment/transfer/bulk',
		element: BulkTransfer,
	},
	{
		path: '/dashboard-payment/transfer/manual-bulk',
		element: ManualBulkTransfer,
	},
	{
		path: '/dashboard-payment/payment-links',
		element: PaymentLinks,
	},
	{
		path: '/dashboard-payment/payment-links/new',
		element: NewPaymentLink,
	},
	{
		path: '/dashboard-payment/payment-links/single-preview',
		element: SingleViewLink,
	},
	{
		path: '/dashboard-payment/payment-links/:id/pay',
		element: PaymentLinkPay,
	},
	{
		path: '/dashboard-payment/bill-payments',
		element: Bills,
	},
	{
		path: '/dashboard-payment/bill-payments/airtime/new',
		element: NewAirtimePurchase,
	},
	{
		path: '/dashboard-payment/bill-payments/data/new',
		element: NewDataPurchase,
	},
	{
		path: '/dashboard-payment/bill-payments/electricity/new',
		element: NewElectricityPurchase,
	},
	{
		path: '/dashboard-payment/bill-payments/cable/new',
		element: NewCablePurchase,
	},
	{
		path: '/dashboard-payment/bill-payments/betting/new',
		element: NewBettingPurchase,
	},
	{
		path: '/dashboard-payment/bill-payments/flight/new',
		element: NewFlightPurchase,
	},
	{
		path: '/dashboard-virtual-account',
		element: VirtualAccounts,
	},
	{
		path: '/dashboard-card-issuing',
		element: CardIssuing,
	},
	{
		path: '/dashboard-virtual-account/customer',
		element: ViewVirtualAccount,
	},
	{
		path: '/dashboard-customer',
		element: Customers,
	},
	{
		path: '/dashboard-setting',
		element: Settings,
	},
	{
		path: '/dashboard-user-kyc',
		element: DashboardKYC,
	},
	{
		path: '/dashboard-bankbox/overview',
		element: BankBoxOverview,
	},
	{
		path: '/dashboard-bankbox/users',
		element: BankBoxUsers,
	},

	{
		path: '/dashboard-bankbox/requests',
		element: BankboxRequest,
	},

	{
		path: '/dashboard-bankbox/terminals',
		element: BankboxTerminals,
	},
	{
		path: '/dashboard-bankbox/terminals/view',
		element: SingleViewBankbox,
	},
	{
		path: '/dashboard-bankbox/transactions',
		element: BankBoxTransactions,
	},
	{
		path: '/dashboard-bankbox/settings',
		element: BankboxSettings,
	},
];
