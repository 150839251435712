import { RavenInputField } from '@ravenpay/raven-bank-ui';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import { validateBVN } from '../../../../redux/verification';
import { logger } from '../../../../services/logger';

export interface KYCFormProps {
	bvn?: string;
	nin?: string;
	passport_number?: string;
	issuing_state: {
		label: string;
		value: string;
	};
}
const KYCForm = ({ selected, onChange }: any) => {
	const isSelected = (e: string) => selected.value === e;
	const [formData, setFormData] = useState<KYCFormProps>({
		bvn: '',
		issuing_state: {
			label: '',
			value: '',
		},
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...formData, [name]: value };
		setFormData(obj);
	};

	useEffect(() => {
		onChange(formData);
	}, [formData]);

	return (
		<div className={`kyc-dashboard-wrapper__form-wrap ${selected.value} }`}>
			{isSelected('nin-verification') && (
				<>
					<RavenInputField
						color="green-light"
						name="nin"
						value={formData.nin}
						// maxLength={11}
						// type="account-number"
						onChange={(e: any) => {
							if (e.target.value.length < 12) {
								handleChange(e);
							}
						}}
						placeholder="Input your NIN number"
						label="National Identity Number"
					/>
				</>
			)}

			{isSelected('bvn-verification') && (
				<>
					<RavenInputField
						color="green-light"
						name="bvn"
						// type="account-number"
						value={formData.bvn}
						// maxLength={11}
						onChange={(e: any) => {
							if (e.target.value.length < 12) {
								handleChange(e);
							}
						}}
						placeholder="Input your BVN number"
						label="Bank Verification Number"
					/>
				</>
			)}

			{isSelected('passport-verification') && (
				<>
					<RavenInputField
						color="green-light"
						label="Passport Number"
						name="passport_number"
						placeholder="Insert passport number"
					/>
					<div className="grouped-input">
						<div className="grouped-input__item">
							<RavenInputField
								name="first_name"
								onChange={handleChange}
								placeholder="e.g Emmanuel"
								color="deep-green-dark"
								label="Firstname*"
							/>
						</div>

						<div className="grouped-input__item">
							<RavenInputField
								name="last_name"
								onChange={handleChange}
								placeholder="e.g Ezeani"
								color="deep-green-dark"
								label="Lastname*"
							/>
						</div>
					</div>
					<RavenInputField
						type="phone"
						name="phone_number"
						onChange={handleChange}
						placeholder="09063xxxxxx"
						color="green-light"
						label="Mobile Number"
					/>
					<RavenInputField
						type="date"
						name="date_of_birth"
						onChange={(e: Date | String) => {
							const obj = { ...formData, date_of_birth: e };
							setFormData(obj);
						}}
						placeholder="MM/DD/YYYY"
						color="green-light"
						label="Date of Birth"
					/>
				</>
			)}

			{isSelected('drivers-license-verification') && (
				<>
					<RavenInputField
						name="license_id"
						onChange={handleChange}
						placeholder="Enter passport ID"
						color="deep-green-dark"
						label="License ID*"
					/>
					<div className="grouped-input">
						<div className="grouped-input__item">
							<RavenInputField
								style={{ zIndex: '200' }}
								name="issuing_state"
								onChange={(e: { label: string; value: string }) =>
									setFormData({
										...formData,
										issuing_state: e,
									})
								}
								type="select"
								placeholder="Select State"
								color="deep-green-dark"
								label="Issuing State*"
							/>
						</div>

						<div className="grouped-input__item">
							<RavenInputField
								name="expiry_date"
								type="date"
								onChange={handleChange}
								placeholder="Enter Expiry Date"
								color="deep-green-dark"
								label="Expiry Date*"
							/>
						</div>
					</div>
					<div className="grouped-input">
						<div className="grouped-input__item">
							<RavenInputField
								name="first_name"
								onChange={handleChange}
								placeholder="e.g Emmanuel"
								color="deep-green-dark"
								label="Firstname*"
							/>
						</div>

						<div className="grouped-input__item">
							<RavenInputField
								name="last_name"
								onChange={handleChange}
								placeholder="e.g Ezeani"
								color="deep-green-dark"
								label="Lastname*"
							/>
						</div>
					</div>
					<RavenInputField
						type="date"
						name="dob"
						onChange={handleChange}
						placeholder="Select a date"
						color="green-light"
						label="Date of Birth*"
					/>
				</>
			)}
		</div>
	);
};

export default KYCForm;
