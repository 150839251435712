import React from 'react';
import '../styles/index.css';
import { logger } from '../../../../../services/logger';
import { useSelector } from 'react-redux';
import RootState from '../../../../../redux/types';
const BusinessProfile = (props: any) => {
	const { profile } = useSelector((state: RootState) => state.settings);

	return (
		<div className="business-profile">
			<div className="business-profile__owner">
				<figure>
					{profile?.businessInfo?.business_logo ? (
						<img src={profile?.businessInfo?.business_logo} alt="" />
					) : (
						<div className="business-profile__logo-text">
							<p>
								{profile?.business_name?.split(' ').length < 2
									? profile?.business_name?.split(' ')[0].slice(0, 1) +
									  profile?.business_name?.split(' ')[0].slice(3, 4)
									: profile?.business_name?.split(' ')[0].slice(0, 1) +
									  profile?.business_name?.split(' ')[1].slice(0, 1)}
							</p>
						</div>
					)}
				</figure>
				<span>
					<h5>{profile?.business_name}</h5>
					<p>{profile?.email}</p>
				</span>
			</div>
			<div className="business-profile__details">
				<div className="details__item">
					<span>
						<small>Business Name</small>
						<p>{profile?.business_name}</p>
					</span>
					<span>
						<small>Business Industry</small>
						<p>{profile?.businessInfo?.industry ?? '--'}</p>
					</span>{' '}
					<span>
						<small>Monthly Transaction</small>
						<p>{profile?.businessInfo?.transaction_range ?? '--'}</p>
					</span>{' '}
					<span>
						<small>Amount of Staff</small>
						<p>{profile?.businessInfo?.employee_size ?? '--'}</p>
					</span>
				</div>
				<div className="details__item">
					<span>
						<small>Business Website</small>
						<p>{profile?.businessInfo?.business_website ?? '--'}</p>
					</span>{' '}
					<span>
						<small>Business RC Number</small>
						<p>{profile?.merchantInfo?.cac_number ?? '--'}</p>
					</span>{' '}
					<span>
						<small>Business Address</small>
						<p>
							{profile?.businessInfo?.lga ??
								('--' + ', ' + profile?.businessInfo?.state !== null
									? profile?.businessInfo?.state
									: '--')}
						</p>
					</span>{' '}
					<span>
						<small>Business Description</small>
						<p>{profile?.businessInfo?.business_description ?? '--'}</p>
					</span>
				</div>
			</div>
		</div>
	);
};

export default BusinessProfile;
