import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import { getCookie, setCookie } from '../utils/helper/Helper';
import SessionEndModal from '../layout/dashboard/dashboardLayout/SessionExpired';
import { useDispatch } from 'react-redux';
import { SET_TOKEN } from '../redux/auth';

export const LOGOUT = async () => {
	setCookie('token', '', 0);
	setCookie('x-pos-key', '', 0);
};

const PrivateRoute = ({ children }: any) => {
	const [sessionModal, setSessionModal] = React.useState(false);
	const [isLoggingOut, setIsLoggingOut] = React.useState(true);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		const interceptor = axios.interceptors.response.use(
			async (response: any) => {
				if (response?.data?.data === 'invalid_token') {
					console.log('auth failed', response?.data);

					setIsLoggingOut(false);
					if (!isLoggingOut) {
						setIsLoggingOut(true);
						LOGOUT();
						dispatch(SET_TOKEN(''));
						try {
							setTimeout(() => {
								setSessionModal(true);
							}, 50);
						} catch (error) {}
						return;
					}
				} else {
					if (response?.data?.data === 'no authorization header parsed') {
						return;
					} else return response;
				}
			}
		);

		return () => {
			axios.interceptors.response.eject(interceptor);
		};
	}, []);

	const authenticated = getCookie('token');

	useEffect(() => {
		if (!authenticated) {
			setSessionModal(true);
		}
	}, [authenticated]);

	return (
		<>
			{children}
			<SessionEndModal
				onClick={() => {
					navigate('/login');
					setSessionModal(false);
				}}
				onCancel={() => {
					navigate('/login');
					setSessionModal(false);
				}}
				visible={sessionModal}
			/>
		</>
	);
};

export default PrivateRoute;
