import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../../layout/dashboard/tableLayout';
import {
	RavenButton,
	RavenInputField,
	RavenModal,
	RavenTable,
	RavenTableRow,
	toast,
} from '@ravenpay/raven-bank-ui';
import {
	formatDateTime,
	insertUrlParam,
	removeUrlParam,
	returnInitial,
} from '../../../../utils/helper/Helper';
import Pagination from '../../../../components/common/pagination';
import EmptyScreen from '../../../../components/common/emptyScreen';
import './styles/index.css';
import { generateRandomColor } from '../../../../utils/helper/color';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SmartFilter from '../../../../components/common/smartFilter';
import ExportModal from '../../../../components/common/exportModal';
import MobileTableCard from '../../../../components/common/table/mobile/MobileTable';
import RootState, {
	AssignBankBoxPayload,
	BankBoxUser,
} from '../../../../redux/types';
import { icons } from '../../../../assets/icons';
import BankBoxUserPreview from './component/UserPreview';
import CreateBankboxUser from '../components/modals/CreateBankboxUser';
import { bankboxAPI } from '../../../../redux/bankbox';

const BankBoxUsers = () => {
	const [showExport, setShowExport] = useState<boolean>(false);
	const [assignFormData, setAssignFormData] = useState<AssignBankBoxPayload>({
		affiliate_app_id: '',
		poseidon_business_email: '',
		serial_number: '',
		label: '',
		poseidon_business_name: '',
	});
	const [assignBankbox, setAssignBankbox] = useState<{
		on: boolean;
		chi: BankBoxUser;
	}>({
		on: false,
		chi: {
			id: 0,
			fname: '',
			lname: '',
			poseidon_email: '',
			bvn: '',
			nin: '',
			phone: '',
			affiliate_app: '',
			affiliate_app_id: '',
			poseidon_business_name: '',
			poseidon_business_address: '',
			poseidon_business_type: null,
			poseidon_business_description: '',
			poseidon_profile_reference: '',
			business_category: '',
			region: '',
			created_at: '',
			updated_at: '',
		},
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [activeFilter, setActiveFilter] = useState<string>('');
	const [viewUser, setViewUser] = useState<{ chi: BankBoxUser; on: boolean }>({
		on: false,
		chi: {
			id: 0,
			fname: '',
			lname: '',
			poseidon_email: '',
			bvn: '',
			nin: '',
			phone: '',
			affiliate_app: '',
			affiliate_app_id: '',
			poseidon_business_name: '',
			poseidon_business_address: '',
			poseidon_business_type: null,
			poseidon_business_description: '',
			poseidon_profile_reference: '',
			business_category: '',
			region: '',
			created_at: '',
			updated_at: '',
		},
	});

	console.log(assignFormData);
	useState<string>('business-account');
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});

	const [createModal, setCreateModal] = useState<boolean>(false);

	const tableData: {
		account_name?: string;
		date: Date;
		phone?: string;
		email?: string;
	}[] = [];

	// Generate 10 objects with fake data for the table rows

	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { users, loading } = useSelector((state: RootState) => state.bankbox);

	function isFilter(value: string) {
		if (value === activeFilter) return true;
		else return false;
	}

	useEffect(() => {
		if (
			typeof dateFilter.from === 'object' ||
			typeof dateFilter.to === 'object'
		) {
			setDateFilter({
				to: '',
				from: '',
			});
		}
	}, [activeFilter]);

	useEffect(() => {
		if (typeof activeFilter !== undefined) {
			setActiveFilter('');
		}
	}, [dateFilter.to || dateFilter.from]);

	useEffect(() => {
		dispatch(
			bankboxAPI.getAllUsers({
				per_page: 20,
				page: currentPage,
			})
		);
	}, [currentPage, viewUser]);

	const cust = users?.data;

	const handleViewUser = (chi: BankBoxUser) => {
		// setViewUser({
		// 	on: true,
		// 	chi: chi,
		// });
		navigate('/dashboard-bankbox/users?user_email=' + chi.poseidon_email);
		insertUrlParam(
			'user_email',
			chi.poseidon_email,
			`Atlas: BankBox - #${chi.fname + ' ' + chi.lname}`
		);
	};

	const searchParams = new URLSearchParams(window.location.search);
	const val = searchParams.get('user_email');

	let url = window.location.href;

	document.body.addEventListener(
		'click',
		() => {
			requestAnimationFrame(() => {
				if (url !== window.location.href) {
					// logger.log('url changed');
					url = window.location.href;
				}
			});
		},
		true
	);

	useEffect(() => {
		const d = async () => {
			if (val) {
				setViewUser({
					...viewUser,
					on: true,
				});
			} else {
				setViewUser({
					...viewUser,
					on: false,
				});
			}
		};

		d();
	}, [location, val, url]);

	useEffect(() => {
		setAssignFormData({
			...assignFormData,
			poseidon_business_email: assignBankbox?.chi?.poseidon_email,
			poseidon_business_name: assignBankbox?.chi?.poseidon_business_name,
		});
	}, [assignBankbox]);

	async function handleAssign() {
		const resp = await dispatch(bankboxAPI.assignBankbox(assignFormData));

		if (resp.payload?.status === 'success') {
			setAssignBankbox({
				...assignBankbox,
				on: false,
			});

			setAssignFormData({
				...assignFormData,
				serial_number: '',
				label: '',
			});
		}
	}

	return (
		<>
			<DashboardLayout>
				{!viewUser.on && (
					<PageLayout
						pageSubtitle="Manage Users making use of the BankBox"
						pageTitle="BankBox Users"
						topRightContent={
							<RavenButton
								onClick={() => setCreateModal(true)}
								color="deep-green-light"
								size="small"
							>
								<p>Create User</p>
								<figure className="non-mobile">
									{icons.plus_circle_white}
								</figure>
								<figure className="non-desktop">{icons.plus}</figure>
							</RavenButton>
						}
					>
						<SmartFilter
							page="bankbox_users"
							onExport={() => setShowExport(true)}
						/>

						<div className="content__main">
							<div className="content__main--top-bar">
								<div className="top-bar__title">
									{/* <h5>{activeCurrency.toUpperCase()} Settlement History</h5> */}
									{/* <p className={`${demo && 'active'}`}>{`${faker.number.bigInt({
										min: '50',
										max: '700',
									})}`}</p> */}
								</div>
								<Pagination
									className="top-bar__pagination"
									currentPage={currentPage ?? 0}
									itemsPerPage={users?.pagination?.perPage ?? 0}
									totalItems={users?.pagination?.total ?? 0}
									onPageChange={function (page: number): void {
										setCurrentPage(page);
									}}
								/>
							</div>
							<div className="main__wrap">
								{loading || cust?.length === 0 ? (
									<EmptyScreen
										title="No User's Found"
										subTitle="We couldn't find any user on this bankbox account, your new users will appear here."
										loading={loading}
										button
										loadingText="Loading users..."
										onClick={() => setCreateModal(true)}
										buttonLabel="Create New User"
										btnIcon={false}
									/>
								) : (
									<>
										<div className="transaction__table non-mobile">
											<RavenTable
												action={false}
												className="table__main"
												headerList={[
													'BUSINESS NAME',
													'FULL NAME',
													'EMAIL',
													'DATE JOINED',
													'REGION',
													'',
												]}
											>
												{cust?.map((chi, idx: number) => {
													return (
														<React.Fragment key={idx}>
															<RavenTableRow
																className="table__main"
																six={<p>{icons.forward}</p>}
																five={<p>{chi.region}</p>}
																onRowClick={() => handleViewUser(chi)}
																four={formatDateTime(chi?.created_at)}
																one={
																	<div className="account_name">
																		<span
																			style={{
																				backgroundColor:
																					generateRandomColor('#e9f5ff'),
																			}}
																		>
																			<p>
																				{returnInitial(
																					chi?.poseidon_business_name
																				)}
																			</p>
																		</span>
																		<p className="text">
																			{chi?.poseidon_business_name}
																		</p>
																	</div>
																}
																three={`${chi?.poseidon_email}`}
																two={chi?.fname + ' ' + chi?.lname ?? ''}
															/>
														</React.Fragment>
													);
												})}
											</RavenTable>
										</div>

										<div className="atlas-mobile-table-wrap non-desktop">
											{cust?.map((chi, idx: React.Key | null | undefined) => {
												return (
													<MobileTableCard
														title={chi?.fname + ' ' + chi?.lname}
														bottomRightText={formatDateTime(chi.created_at)}
														amount={chi?.phone}
														text={chi?.poseidon_email}
														img={undefined}
														svg={undefined}
														avatar={''}
														amountColor={'#014345'}
														type={''}
														showImg={false}
														loading={loading}
														onRowClick={() => {}}
														textTwo={''}
													/>
												);
											})}
										</div>
									</>
								)}
							</div>
						</div>
					</PageLayout>
				)}
				{viewUser.on && (
					<BankBoxUserPreview
						chi={viewUser.chi}
						onAssign={(e) =>
							setAssignBankbox({
								on: true,
								chi: e,
							})
						}
						onBack={() => {
							removeUrlParam('user_email');
							setViewUser({
								...viewUser,
								on: false,
							});
						}}
					/>
				)}
			</DashboardLayout>
			<ExportModal
				onClose={() => setShowExport(false)}
				page="bankbox_users"
				visible={showExport}
			/>

			<RavenModal
				visble={createModal}
				onClose={() => setCreateModal(false)}
				className={'bankbox-create-modal'}
				onBtnClick={function (e?: any): void {
					throw new Error('Function not implemented.');
				}}
			>
				<CreateBankboxUser
					onComplete={() => {
						setCreateModal(false),
							toast.success('BankBox user created successfully');
					}}
				/>
			</RavenModal>

			<RavenModal
				onClose={() => setAssignBankbox({ ...assignBankbox, on: false })}
				visble={assignBankbox.on}
				loading={loading}
				onBtnClick={handleAssign}
				btnColor="deep-green-light"
				btnLabel="Assign"
			>
				<div className="assign-bb-modal">
					<div className="assign-bb-modal__title">
						<h6>Assign BankBox</h6>
						<p>Allocate a bankbox to a user</p>
					</div>

					<div className="assign-bb-modal__form-body">
						<RavenInputField
							type="text"
							color="deep-green-light"
							value={assignFormData?.serial_number}
							label="BankBox Serial Number"
							onChange={(e: any) => {
								setAssignFormData({
									...assignFormData,
									serial_number: e.target.value,
								});
							}}
							placeholder="4989q3****"
							name="serial"
						/>
						<RavenInputField
							type="text"
							value={assignFormData?.label}
							onChange={(e: any) => {
								setAssignFormData({
									...assignFormData,
									label: e.target.value,
								});
							}}
							color="deep-green-light"
							label="BankBox Label"
							placeholder="cp-11-finance"
							name="serial"
						/>
					</div>
				</div>
			</RavenModal>
		</>
	);
};

export default BankBoxUsers;
