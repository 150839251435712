import { faker } from '@faker-js/faker';
import {
	RavenButton,
	RavenInputField,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import React, { useEffect, useState } from 'react';
import { icons } from '../../../../../assets/icons';
import EmptyScreen from '../../../../../components/common/emptyScreen';
import {
	capitalizeFirstLetter,
	formatDateTime,
	returnInitial,
} from '../../../../../utils/helper/Helper';
import Pagination from '../../../../../components/common/pagination';
import { generateRandomColor } from '../../../../../utils/helper/color';
import { useNavigate } from 'react-router-dom';
import {
	deleteTeamInvite,
	deleteTeamMember,
	getTeams,
} from '../../../../../redux/settings';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../redux/types';
import { logger } from '../../../../../services/logger';
import DeleteModal from '../../../../../components/common/deleteModal/DeleteModal';
import ComingSoon from '../../../../../components/comingSoon';

export type TeamFormData = {
	fname?: string;
	lname?: string;
	email?: string;
	merchant_email?: string;
	clearance?: string;
};
const SettingsTeam = ({
	addMember,
	manageMember,
}: {
	demo: boolean;
	addMember: () => any;
	manageMember: (chi?: TeamFormData) => any;
}) => {
	// Generate an array to store the table verification
	const tableData: {
		account_name?: string;
		email?: string;
		role?: string;
	}[] = [];
	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});

	const [currentPage, setCurrentPage] = useState(1);
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});

	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState<{
		on: boolean;
		chi: any;
	}>({
		on: false,
		chi: '',
	});
	const [activeFilter, setActiveFilter] = useState<string>('');

	const dispatch = useDispatch();
	const { teams, loading } = useSelector((state: RootState) => state.settings);
	const { profile } = useSelector((state: RootState) => state.wallet);

	function isFilter(value: string) {
		if (value === activeFilter) return true;
		else return false;
	}

	function formatRole(d?: string) {
		return (
			<div className={`settings_role ${d?.toLowerCase()}`}>
				<p>{d}</p>
			</div>
		);
	}

	function DropMore({ className, idx, role, chi }: any) {
		return (
			<div className={`more-wrap ${className}`}>
				<div
					style={{
						opacity:
							role === 'admin' || profile.email === chi?.email ? '0.7' : '1',
						cursor:
							role === 'admin' || profile.email === chi?.email
								? 'no-drop'
								: 'pointer',
					}}
					onClick={() => {
						role === 'admin' || profile.email === chi?.email
							? () => {}
							: manageMember(chi);
						setShowDrop({
							show: !showDrop.show,
							id: idx,
						});
					}}
					className="more_item"
				>
					<figure>{icons.edit_pen}</figure>
					<p>Manage Roles</p>
				</div>

				<div
					style={{
						opacity:
							role === 'admin' || profile.email === chi?.email ? '0.7' : '1',
						cursor:
							role === 'admin' || profile.email === chi?.email
								? 'no-drop'
								: 'pointer',
					}}
					onClick={() => {
						role === 'admin' || profile.email === chi?.email
							? ''
							: setShowDeleteModal({
									on: true,
									chi: chi,
							  });
					}}
					className="more_item"
				>
					<figure>{icons.delete_plain}</figure>
					<p>{chi?.status === 'pending' ? 'Delete Invite' : 'Delete Member'}</p>
				</div>
			</div>
		);
	}

	function isDropped(idx: number | string) {
		if (showDrop.id === idx && showDrop.show) return true;
		else return false;
	}

	async function handleDelete() {
		let resp: any;

		if (showDeleteModal?.chi?.status === 'pending') {
			resp = await dispatch(
				deleteTeamInvite({ email: showDeleteModal?.chi?.email })
			); // TODO Remember to remove any
		} else
			resp = await dispatch(
				deleteTeamMember({ email: showDeleteModal?.chi?.email })
			); // TODO Remember to remove any

		// logger.log(resp, 'delete	k');
		if (resp?.payload?.status === 'success') {
			dispatch(getTeams());
			setShowDeleteModal({
				on: false,
				chi: '',
			});
		}
	}

	useEffect(() => {
		dispatch(getTeams());
	}, []);

	logger.log(teams, 'profiler');
	return (
		<div className="settings__main-wrap">
			<div className="main-wrap__title">
				<div className="title__left">
					<h5>Teams</h5>
					<p>
						Seamlessly invite and manage your teams and accesses across your
						account.
					</p>
				</div>
				<RavenButton onClick={() => {}} color="deep-green-light">
					<div onClick={addMember} className="title__button">
						<p>Add Member</p>
						{icons.plus_circle_alt}
					</div>
				</RavenButton>
			</div>
			{loading || teams.length === 0 ? (
				<EmptyScreen withMascot loading={loading} />
			) : (
				<div className="history__content">
					<div className="content__top-bar">
						<div className="top-bar__search">
							<RavenInputField
								placeholder="Search"
								type="search"
								color="deep-green-light"
							/>
						</div>
						<div className="top-bar__filter-export">
							<div
								onClick={() => setShowFilter(!showFilter)}
								className="filter-export__filter-btn"
							>
								<p>Filter</p> <figure>{icons.filter}</figure>
							</div>

							{/* Section encompassing filter and it controls */}
							<div
								className={`filter-export__drop-down ${
									showFilter && 'filter-export__drop-down--open'
								}`}
							>
								<div className="drop-down__switch">
									<span
										onClick={() => setActiveFilter('All')}
										className={`${isFilter('All') && 'active'}`}
									>
										<p>All</p>
									</span>
									<span
										onClick={() => setActiveFilter('Today')}
										className={`${isFilter('Today') && 'active'}`}
									>
										<p>Today</p>
									</span>
									<span
										onClick={() => setActiveFilter('Last 7 Days')}
										className={`${isFilter('Last 7 Days') && 'active'}`}
									>
										<p>Last 7 Days</p>
									</span>
									<span
										onClick={() => setActiveFilter('Last 30 Days')}
										className={`${isFilter('Last 30 Days') && 'active'}`}
									>
										<p>Last 30 Days</p>
									</span>
								</div>
								<div className="drop-down__custom-date-range">
									<h5>Custom Date Range</h5>
									<div className="custom-date-range__grouped-form">
										<RavenInputField
											type="date"
											color="deep-green-light"
											label="From"
											value={dateFilter.from}
											onChange={(e: Date) => {
												setDateFilter({
													...dateFilter,
													from: e,
												});
											}}
											placeholder="DD MM YYYY"
										/>
										<RavenInputField
											type="date"
											color="deep-green-light"
											label="To"
											value={dateFilter.to}
											onChange={(e: Date) => {
												setDateFilter({
													...dateFilter,
													to: e,
												});
											}}
											placeholder="DD MM YYYY"
										/>
									</div>
									<div className="drop-down__action-btns">
										<RavenButton
											onClick={() => {
												setDateFilter({
													to: '',
													from: '',
												});
												setActiveFilter('');
											}}
											color="green-light"
											label="Clear Filter"
										/>
										<RavenButton
											color="deep-green-light"
											label="Apply Filter"
											onClick={() => setShowFilter(false)}
										/>
									</div>
								</div>
							</div>
							<div
								onClick={() => setShowFilter(false)}
								className={`filter-export__drop-down-closer ${
									showFilter && 'filter-export__drop-down-closer--opened'
								}`}
							></div>
							{/* End of Section encompassing filter and it controls */}

							<div
								// onClick={() => onView(!showFilter)}
								className="filter-export__filter-btn"
							>
								<p>Export</p> <figure>{icons.export}</figure>
							</div>
						</div>
					</div>
					<div className="content__main">
						<div
							className={`main__active-filters ${
								(activeFilter ||
									typeof dateFilter.to === 'object' ||
									typeof dateFilter.from === 'object') &&
								'main__active-filters--show'
							}`}
						>
							<span className="active-filters__item">
								<p>
									<b>Date:</b>
									{typeof dateFilter.to === 'object' ||
									typeof dateFilter.from === 'object'
										? formatDateTime(dateFilter.from).split(',')[0] +
										  ' - ' +
										  formatDateTime(dateFilter.to).split(',')[0]
										: activeFilter}
								</p>
								<figure>{icons.x_circle}</figure>
							</span>
							<span
								onClick={() => {
									setDateFilter({
										to: '',
										from: '',
									});
									setActiveFilter('');
								}}
								className="active-filters__clear-item"
							>
								<p>Clear All</p>
							</span>
						</div>
						<div className="content__main--top-bar">
							<div className="top-bar__title">
								{/* <h5>{activeCurrency.toUpperCase()} Settlement History</h5> */}
								{/* <p className={`${demo && 'active'}`}>{`${faker.number.bigInt({
										min: '50',
										max: '700',
									})}`}</p> */}
							</div>
							{/* <Pagination
								className="top-bar__pagination"
								currentPage={currentPage}
								itemsPerPage={10}
								totalItems={100}
								onPageChange={function (page: number): void {
									setCurrentPage(page);
								}}
							/> */}
						</div>

						<div className="main__wrap">
							<div className="transaction__table">
								<RavenTable
									action={false}
									className="table__main"
									headerList={['ACCOUNT NAME', 'EMAIL ADDRESS', 'ROLE', '']}
								>
									{teams.map((chi, idx) => {
										// logger.log(chi, 'mapped');
										return (
											<React.Fragment key={idx}>
												<RavenTableRow
													className="table__main"
													four={
														<div
															style={{ position: 'relative' }}
															onClick={() =>
																setShowDrop({
																	id: idx,
																	show: !showDrop.show,
																})
															}
														>
															<DropMore
																role={chi?.clearance}
																idx={idx}
																className={isDropped(idx) && 'show-drop'}
																chi={chi}
															/>
															{icons.three_dots}
														</div>
													}
													one={
														<div
															style={{
																opacity: chi?.status === 'pending' ? '1' : '1',
															}}
															className="account_name"
														>
															<span
																style={{
																	backgroundColor:
																		generateRandomColor('#e9f5ff'),
																}}
															>
																<p>
																	{returnInitial(
																		chi?.fname + ' ' + chi?.lname ?? ''
																	)}
																</p>
															</span>
															<p style={{ display: 'flex' }} className="text">
																{' '}
																{chi?.fname + ' ' + chi?.lname}
																{chi.status === 'pending' && (
																	<ComingSoon text="PENDING" />
																)}
															</p>
														</div>
													}
													three={
														<div
															style={{
																opacity:
																	chi?.status === 'pending' ? '0.4' : '1',
															}}
															className="type-wrap"
														>
															<span>
																{formatRole(
																	capitalizeFirstLetter(chi.clearance)
																)}
															</span>
														</div>
													}
													two={
														<span
															style={{
																opacity:
																	chi?.status === 'pending' ? '0.4' : '1',
															}}
														>
															{chi.email}
														</span>
													}
												/>
											</React.Fragment>
										);
									})}
								</RavenTable>
							</div>
						</div>
					</div>
				</div>
			)}
			<DeleteModal
				onDelete={handleDelete}
				loading={loading}
				visible={showDeleteModal.on}
				onCancel={() =>
					setShowDeleteModal({
						on: false,
						chi: '',
					})
				}
				title={
					showDeleteModal.chi?.status === 'pending'
						? 'Delete Team Invite'
						: 'Delete Team Member'
				}
				text={
					showDeleteModal.chi?.status === 'pending'
						? 'Deleting an Invitation, invalidates the invitation link alread sent to the user, do you wish to proceed?'
						: "Remove this Member, from your Team, this mean this person won't be able to access your atlas account again."
				}
			/>
		</div>
	);
};

export default SettingsTeam;
