import React, { useEffect, useState } from 'react';
import '../styles/index.css';
import { icons } from '../../../../../assets/icons';
import {
	RavenButton,
	RavenCheckBox,
	RavenInputField,
	RavenToggleSlide,
} from '@ravenpay/raven-bank-ui';
import { logger } from '../../../../../services/logger';
import { updatePaymentPreference } from '../../../../../redux/settings';
import { useDispatch, useSelector } from 'react-redux';
import RootState, { PaymentPreference } from '../../../../../redux/types';
import GetUser from '../../../../../components/common/getUser';

const SettingsPayments = () => {
	const { profile } = useSelector((state: RootState) => state.wallet);

	const [edited, setEdited] = useState(false);
	const [paymentsConfig, setPaymentsConfig] = useState<{
		stampDuty: string;
		preference: PaymentPreference[];
	}>({
		stampDuty: 'you',
		preference: profile?.merchantInfo?.payment_preferences
			? JSON.parse(profile?.merchantInfo?.payment_preferences)
			: [],
	});

	function isStampDuty(s: string) {
		if (s === paymentsConfig?.stampDuty) return true;
		else return false;
	}

	// Function to handle checkbox changes
	function handlePreferences(
		preference: PaymentPreference,
		isChecked: boolean
	) {
		setEdited(true);
		if (isChecked) {
			// Add the preference to the array if it's not already there
			if (!paymentsConfig.preference.includes(preference)) {
				setPaymentsConfig({
					...paymentsConfig,
					preference: [...paymentsConfig.preference, preference],
				});
			}
		} else {
			// Remove the preference from the array if it exists
			const fil = paymentsConfig.preference.filter((d) => d !== preference);

			setPaymentsConfig({ ...paymentsConfig, preference: fil });
		}
	}

	const dispatch = useDispatch();

	useEffect(() => {
		if (profile?.merchantInfo?.payment_preferences) {
			setPaymentsConfig({
				...paymentsConfig,
				preference: JSON.parse(profile?.merchantInfo?.payment_preferences),
			});
		}
	}, [profile]);

	function handleSubmit() {
		if (edited === true) {
			setEdited(false);
			dispatch(updatePaymentPreference(paymentsConfig.preference));
		}
	}

	return (
		<div className="payment-settings">
			<div className="payment-settings__title">
				<h5>Payments</h5>
				<p>Manage your account passwords and security</p>
			</div>

			<div className="payment-settings__main">
				<div className="main__items">
					<div className="items__grouped">
						<figure>{icons.settings_payment_channel}</figure>
						<div>
							<span>
								<h5>Payments Channel</h5>
								<p>
									Access to receiving SMS notifications for your transactions
								</p>
							</span>

							<RavenButton
								disabled={!edited}
								onClick={handleSubmit}
								color="deep-green-light"
								label="Save changes"
							/>
						</div>
					</div>

					<div className="items__options">
						<div className="options__right">
							<div className="right__item">
								<RavenCheckBox
									onChange={(e) => handlePreferences('ussd', e.target.checked)}
									color="deep-green-light"
									checked={paymentsConfig.preference.includes('ussd')}
									id={1}
								/>
								<span>
									<p>USSD</p>
									<small>Recieve payment through USSD</small>
								</span>
							</div>
							<div className="right__item">
								<RavenCheckBox
									onChange={(e) =>
										handlePreferences('bank_transfer', e.target.checked)
									}
									checked={paymentsConfig.preference.includes('bank_transfer')}
									color="deep-green-light"
									id={2}
								/>
								<span>
									<p>Transfers</p>
									<small>Through generated accounts for you.</small>
								</span>
							</div>
							{/* <div className="right__item">
								<RavenCheckBox
									onChange={() => {}}
									color="deep-green-light"
									id={3}
								/>
								<span>
									<p>NQR Payments</p>
									<small>Generated QR Codes to scan and pay</small>
								</span>
							</div> */}
						</div>
						<div className="options__left">
							<div className="left__item">
								<RavenCheckBox
									onChange={(e) => handlePreferences('card', e.target.checked)}
									color="deep-green-light"
									checked={paymentsConfig.preference.includes('card')}
									id={4}
								/>
								<span>
									<p>Credit & Debit Card</p>
									<small>Payment through Pysical & Virtual Cards</small>
								</span>
							</div>
							<div className="left__item">
								<RavenCheckBox
									onChange={(e) => handlePreferences('raven', e.target.checked)}
									color="deep-green-light"
									checked={paymentsConfig.preference.includes('raven')}
									id={5}
								/>
								<span>
									<p>Raven Pay</p>
									<small>Allow users send money to you from Raven</small>
								</span>
							</div>
						</div>
					</div>
				</div>

				{/* <div className="main__items">
					<div className="items__grouped">
						<figure>{icons.settings_stamp}</figure>
						<span>
							<h5>Who pays the fees?</h5>
							<p>Select which party pays</p>
						</span>
					</div>
					<div className="items__options">
						<div className="options__right">
							<div
								onClick={() =>
									setPaymentsConfig({
										...paymentsConfig,
										stampDuty: 'business',
									})
								}
								className={`right__item ${
									isStampDuty('business') ? 'active' : 'inactive'
								}`}
							>
								{isStampDuty('business')
									? icons.radio_check
									: icons.radio_unchecked}
								<span>
									<p>My Business pays the fees</p>
									<small>
										This means you bear the incured fees for payments
									</small>
								</span>
							</div>
						</div>
						<div className="options__left">
							<div
								onClick={() =>
									setPaymentsConfig({
										...paymentsConfig,
										stampDuty: 'customer',
									})
								}
								className={`left__item ${
									isStampDuty('customer') ? 'active' : 'inactive'
								}`}
							>
								{isStampDuty('customer')
									? icons.radio_check
									: icons.radio_unchecked}
								<span>
									<p>Customers pays the fees</p>
									<small>
										This means your customers will bear incured fees for
										payments
									</small>
								</span>
							</div>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	);
};

export default SettingsPayments;
