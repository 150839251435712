import {
	RavenButton,
	RavenInputField,
	RavenModal,
	toast,
} from '@ravenpay/raven-bank-ui';
import React from 'react';
import './style/index.css';
import { icons } from '../../../assets/icons';
import useWindowSize from '../../../hooks/UseWindowSize';
import { topupWithWebpay } from '../../../redux/wallet';
import { useDispatch } from 'react-redux';
import { useAtlasPay } from 'react-atlas-pay';
// import AtlasPay from 'atlas-pay-sdk';
import { removeCommaAndNairaSign } from '../../../utils/helper/Helper';
import { logger } from '../../../services/logger';
export type PaymentMethod =
	| 'card'
	| 'bank_transfer'
	| 'ussd'
	| 'raven'
	| 'qr'
	| '';

const TopupModal = ({
	visible,
	onClose,
	accountName,
	accountNumber,
	bank,
}: {
	visible: boolean;
	onClose: Function;
	accountName: string;
	accountNumber: string;
	bank: string;
}) => {
	const [copied, setCopied] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [amount, setAmount] = React.useState<string | number>('');
	const [selected, setSelected] = React.useState<any>({
		item: '',
		hide: false,
	});

	const [pay, atlasEvent] = useAtlasPay();

	const handleCopyClick = () => {
		navigator.clipboard?.writeText(accountNumber);

		setCopied(true);

		setTimeout(() => {
			setCopied(false);
		}, 1000);
		// You can add any additional logic or feedback here, like showing a tooltip or toast message.
	};

	function handleSelect(e: PaymentMethod) {
		if (selected.item.length > 0) {
			setSelected({
				item: '',
				hide: false,
			});
		} else {
			setSelected({
				item: e,
				hide: true,
			});
		}
	}

	const dispatch = useDispatch();

	async function handleTopup() {
		setLoading(true);
		const resp = await dispatch(
			topupWithWebpay({
				amount: removeCommaAndNairaSign(amount),
				payment_method: ['card', 'qr', 'raven', 'ussd'],
			})
		);

		if (resp?.payload?.status === 'success') {
			setLoading(false);
			// logger.log(resp?.payload);
			if (resp?.payload?.data?.data?.trx_ref) {
				pay({ trx_ref: resp?.payload?.data?.data?.trx_ref });
				// AtlasPay.init(resp?.payload?.data?.data?.trx_ref);
			}
		} else {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		if (atlasEvent?.type === 'onClose') {
			toast.warning('You closed the payment window 🌚 ');
		}

		if (atlasEvent?.type === 'onLoad') {
			// do something here if the payment window gets loaded
		}

		if (atlasEvent?.type === 'onSuccess') {
			// do something here if the payment was successful
		}
	}, [atlasEvent]);

	// logger.log(AtlasPay, 'Atlas');

	return (
		<RavenModal
			closeColor
			visble={visible}
			onClose={() => {
				onClose();
				setAmount('');
			}}
			onBtnClick={function (e?: any): void {
				throw new Error('Function not implemented.');
			}}
		>
			<div className="topup-modal-wrap">
				<div className="topup-modal-wrap__transfer">
					<div className="details__title">
						<span>
							<p>Bank Transfer</p>
							<p>Receive funds from any local bank.</p>
						</span>
						<figure className="wallet-flag">{icons.ngn}</figure>
					</div>
					<div className="details__bank-account">
						<span>
							<p>
								{accountName ?? '--'} • {bank}
							</p>
							<h5>{accountNumber ?? '--'}</h5>
						</span>
						<button onClick={handleCopyClick}>
							{icons.copy_orange}
							<p>{copied ? 'Copied' : 'Copy'}</p>
						</button>
					</div>
				</div>

				<div className="topup-modal-wrap__others">
					<div className="details__wrap">
						<div
							onClick={() => handleSelect('card')}
							className="details__wrap--title"
						>,
							<figure className="wallet-flag">{icons.card_rounded_gray}</figure>
							<span>
								<p>Top-up with Webpay</p>
								<p>Fund your account via card, ussd or username</p>
							</span>
						</div>

						<div
							className={`details__wrap--amount-field ${
								selected.item === 'card' && 'show'
							}`}
						>
							<RavenInputField
								label="Amount to Topup* "
								color="deep-green-light"
								placeholder="E.g 500,000.00"
								name="amount"
								type="number"
								thousandFormat
								numberPrefix={`₦`}
								onChange={(e: {
									target: { value: React.SetStateAction<string | number> };
								}) => setAmount(e.target.value)}
								value={amount}
							/>

							<small
								style={{
									textAlign: 'left',
									width: '100%',
									paddingBottom: '1rem',
									marginTop: '-1rem',
									color: '#0B8376',
								}}
							>
								<b>Note: </b> Minimum topup amount is ₦100
							</small>

							<RavenButton
								onClick={handleTopup}
								label="Continue"
								loading={loading}
								disabled={
									(removeCommaAndNairaSign(amount) as unknown as number) < 100
								}
								color={'deep-green-light'}
							/>
						</div>
					</div>

					{/* <div onClick={() => handleSelect('ussd')} className="details__wrap">
						<div className="details__wrap--title">
							<figure className="wallet-flag">{icons.ussd_rounded_gray}</figure>
							<span>
								<p>USSD</p>
								<p>Add money to your account using USSD.</p>
							</span>
						</div>

						<div
							className={`details__wrap--amount-field ${
								selected === 'ussd' && 'show'
							}`}
						>
							<RavenInputField
								label="Amount to Topup* "
								color="deep-green-light"
								placeholder="E.g 500,000.00"
								name="amount"
								onChange={(e: {
									target: { value: React.SetStateAction<undefined> };
								}) => setAmount(e.target.value)}
								value={amount}
							/>

							<RavenButton label="Continue" color={'deep-green-light'} />
						</div>
					</div>

					<div onClick={() => handleSelect('qr')} className="details__wrap">
						<div className="details__wrap--title">
							<figure className="wallet-flag">{icons.ussd_rounded_gray}</figure>
							<span>
								<p>USSD</p>
								<p>Show your QR Code to any Raven User.</p>
							</span>
						</div>

						<div
							className={`details__wrap--amount-field ${
								selected === 'qr' && 'show'
							}`}
						>
							<RavenInputField
								label="Amount to Topup* "
								color="deep-green-light"
								placeholder="E.g 500,000.00"
								name="amount"
								onChange={(e: {
									target: { value: React.SetStateAction<undefined> };
								}) => setAmount(e.target.value)}
								value={amount}
							/>

							<RavenButton label="Continue" color={'deep-green-light'} />
						</div>
					</div> */}
				</div>
			</div>
		</RavenModal>
	);
};

export default TopupModal;
