import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../../../layout/dashboard/tableLayout';
import {
	RavenButton,
	RavenInputField,
	RavenModal,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import {
	formatDate,
	formatDateTime,
	formatNumWithComma,
	formatNumWithCommaNaira,
	mapHotkey,
	symbol,
} from '../../../../../utils/helper/Helper';
import { faker } from '@faker-js/faker';
import { icons } from '../../../../../assets/icons';
import Pagination from '../../../../../components/common/pagination';
import EmptyScreen from '../../../../../components/common/emptyScreen';
import './style/index.css';
import { generateRandomColor } from '../../../../../utils/helper/color';
import { useNavigate } from 'react-router-dom';
import { getTransfers } from '../../../../../redux/wallet';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/store';
import Skeleton from 'react-loading-skeleton';
import { logger } from '../../../../../services/logger';
import useDebounce from '../../../../../hooks/UseDebounce';
import { OmniSearch } from '../../..redux/transaction';
import Copy from '../../../../../components/common/copyCheck';
import SmartFilter from '../../../../../components/common/smartFilter';
import ExportModal from '../../../../../components/common/exportModal';
import TransactionModal from '../../../../../components/common/transactionModal';
import MobileTableCard from '../../../../../components/common/table/mobile/MobileTable';
import { omniSearch } from '../../../../../redux/transaction';
import { bankboxAPI } from '../../../../../redux/bankbox';
import './style/index.css';

const BankboxRequest = () => {
	const [demo, setDemo] = useState(true);
	const [showExport, setShowExport] = useState<boolean>(false);
	const [activeCurrency, setActiveCurrency] = useState('ngn');
	const [currentPage, setCurrentPage] = useState(1);
	const [activeFilter, setActiveFilter] = useState<string>('');
	const [showConfiguration, setShowConfiguration] = useState<boolean>(false);
	const [view, onView] = useState<any>({
		show: false,
		view: '',
	});
	const [searchQuery, setSearchQuery] = useState('');

	useState<string>('business-account');
	const [boot, onBoot] = useState(false);
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { transfers } = useSelector((state: RootState) => state.wallet);

	const { searchLoading } = useSelector(
		(state: RootState) => state.transaction
	);

	const { loading, all_bankbox_request } = useSelector(
		(state: RootState) => state.bankbox
	);

	useEffect(() => {
		if (
			typeof dateFilter.from === 'object' ||
			typeof dateFilter.to === 'object'
		) {
			setDateFilter({
				to: '',
				from: '',
			});
		}
	}, [activeFilter]);

	useEffect(() => {
		if (typeof activeFilter !== undefined) {
			setActiveFilter('');
		}
	}, [dateFilter.to || dateFilter.from]);

	function formatStatus(status: string | number) {
		let s;
		if (status === 0 || status === 'pending') {
			s = 'pending';
		}
		if (status === 1) {
			s = 'processing';
		}
		if (status === 2) {
			s = 'declined';
		}
		if (status === 3) {
			s = 'in-transit';
		}
		if (status === 3) {
			s = 'delivered';
		}
		return (
			<div className={`transaction-status ${s && s?.toLowerCase()}`}>
				<p>{s}</p>
			</div>
		);
	}

	//fetch transfers
	useEffect(() => {
		dispatch(
			bankboxAPI.getAllBankBoxRequest({
				page: currentPage,
				per_page: 20,
			})
		);
	}, [currentPage]);

	const debounceSearchTerm = useDebounce(searchQuery, 1000);
	useEffect(() => {
		let isMount = true;
		logger.log('searching');

		if (isMount && debounceSearchTerm?.length > 2) {
			dispatch(omniSearch(searchQuery));
		}

		if (isMount && debounceSearchTerm?.length < 1) {
			dispatch(
				getTransfers({
					page: currentPage,
					limit: 20,
				})
			);
		}

		return () => {
			isMount = false;
		};
	}, [debounceSearchTerm]);
	const req = all_bankbox_request?.data;

	logger.log('req', all_bankbox_request);

	return (
		<>
			<DashboardLayout>
				<PageLayout
					pageSubtitle="All BankBox request made on the platform"
					pageTitle="Request"
					topRightContent={<></>}
				>
					<SmartFilter
						defaultFilterBy="status"
						// filters={[
						// 	{
						// 		label: 'Status',
						// 		filterBy: 'status',
						// 		options: [
						// 			{
						// 				label: 'Pending',
						// 				value: 'pending',
						// 			},
						// 			{
						// 				label: 'Failed',
						// 				value: 'failed',
						// 			},
						// 			{
						// 				label: 'Successful',
						// 				value: 'succesful',
						// 			},
						// 		],
						// 	},
						// ]}
						hideExport
						onExport={() => setShowExport(true)}
						page="bankbox_requests"
					/>

					<div className="content__main--top-bar">
						<div className="top-bar__title"></div>
						<Pagination
							className="top-bar__pagination"
							currentPage={currentPage}
							itemsPerPage={transfers?.pagination?.perPage}
							totalItems={transfers?.pagination?.total}
							onPageChange={function (page: number): void {
								setCurrentPage(page);
							}}
						/>
					</div>

					<div className="content__main">
						<div className="main__wrap">
							{loading || searchLoading || req?.length === 0 ? (
								<EmptyScreen
									loading={loading || searchLoading}
									loadingText={
										searchLoading ? 'Searching...' : 'Loading Requests...'
									}
									title="No Requests Found"
									subTitle="You don't have any bankbox requests yet, place a new request and have them displayed here."
								/>
							) : (
								<>
									<div className="transaction__table non-mobile">
										<RavenTable
											action={false}
											className="table__main"
											headerList={[
												'QUANTITY',
												'AMOUNT',
												'EMAIL',
												'MOBILE',
												'STATUS',
												'REQUEST DATE',
											]}
										>
											{req?.map((chi, idx: any) => {
												return (
													<React.Fragment key={idx}>
														<RavenTableRow
															onRowClick={() =>
																onView({
																	show: true,
																	content: chi,
																})
															}
															className="table__main"
															five={
																loading ? (
																	<Skeleton
																		style={{ borderRadius: '10px' }}
																		width={80}
																		height={20}
																	/>
																) : (
																	formatStatus(chi.status)
																)
															}
															six={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	formatDateTime(chi?.created_at)
																)
															}
															one={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	chi.quantity + ' ' + 'Pieces'
																)
															}
															two={
																<div className="account_name--transfer">
																	{loading ? (
																		<Skeleton width={230} height={20} />
																	) : (
																		<div className="bankbox-trx-tbl-name">
																			<p className="text">
																				{' '}
																				{symbol('ngn') +
																					formatNumWithComma(
																						chi?.totalPrice,
																						'ngn'
																					)}
																			</p>
																			<p>
																				at{' '}
																				{symbol('ngn') +
																					formatNumWithComma(
																						chi?.amount,
																						'ngn'
																					)}{' '}
																				for 1
																			</p>
																		</div>
																	)}
																</div>
															}
															four={
																loading ? (
																	<Skeleton width={80} height={20} />
																) : (
																	<div className="type-wrap">
																		<span>{chi?.phone as string}</span>
																	</div>
																)
															}
															three={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	chi.email
																)
															}
														/>
													</React.Fragment>
												);
											})}
										</RavenTable>
									</div>

									<div className="atlas-mobile-table-wrap">
										{req?.map((chi, idx: React.Key | null | undefined) => {
											// const { first_name, last_name, bank } = meta_data;

											// logger.log(meta_data);
											return (
												<MobileTableCard
													title={chi.email}
													text={formatDateTime(chi.created_at)}
													amount={
														symbol('ngn') +
														formatNumWithComma(chi.amount, 'ngn')
													}
													img={undefined}
													svg={undefined}
													avatar={''}
													amountColor={'#014345'}
													type={''}
													showImg={true}
													loading={loading}
													onRowClick={() =>
														onView({
															show: true,
															content: chi,
														})
													}
													textTwo={''}
												/>
											);
										})}
									</div>
								</>
							)}
						</div>
					</div>
				</PageLayout>
			</DashboardLayout>

			<TransactionModal
				onClose={() =>
					onView({
						show: false,
						content: {
							type: '',
							bank: '',
							description: '',
							amount: '',
							date: '',
							session_id: '',
							account_number: '',
							email: '',
							created_at: function (created_at: any): string {
								throw new Error('Function not implemented.');
							},
							updated_at: function (updated_at: any): string {
								throw new Error('Function not implemented.');
							},
						},
						on: false,
					})
				}
				visible={view.show}
				direction={view?.content?.direction}
				title={'Transactions Details'}
				amount={view?.content?.amount as unknown as number}
				onClick={() => {}}
				meta={view?.content?.source}
				content={[
					{
						label: 'Narration',
						value: view?.content?.narration || '--',
					},
					{
						label: 'Amount',
						value:
							symbol('ngn') + formatNumWithComma(view?.content?.amount, 'ngn'),
					},
					{
						label: 'Bank',
						value: view?.content?.bank || '--',
					},
					{
						label: 'Account Name',
						value: view?.content?.account_name,
					},
					{
						label: 'Account Number',
						value: view?.content?.account_number,
					},
					// {
					// 	label: 'Email',
					// 	value: view?.content?.email || '--',
					// },
					{
						label: 'Merchant Reference',
						value: view?.content?.merchant_ref,
						copy: true,
					},
					{
						label: 'System Reference',
						value: view?.content?.trx_ref,
						copy: true,
						trim: true,
					},
					{
						label: 'Session ID',
						value: view?.content?.session_id,
						copy: true,
						trim: true,
					},
					{
						label: 'Fee',
						value:
							symbol('ngn') + formatNumWithComma(view?.content?.fee, 'ngn'),
					},
					{
						label: 'Created At',
						value: formatDateTime(
							view?.content?.created_at as unknown as string
						),
					},
					// {
					// 	label: 'Updated At',
					// 	value: formatDateTime(
					// 		view?.content?.updated_at as unknown as string
					// 	),
					// },
				]}
			/>
			<ExportModal
				onClose={() => setShowExport(false)}
				visible={showExport}
				page="bankbox_requests"
			/>
		</>
	);
};

export default BankboxRequest;
