import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../layout/dashboard/tableLayout';
import { RavenTable, RavenTableRow } from '@ravenpay/raven-bank-ui';
import { formatDateTime, mapHotkey } from '../../../utils/helper/Helper';
import Pagination from '../../../components/common/pagination';
import EmptyScreen from '../../../components/common/emptyScreen';
import './style/index.css';
import { generateRandomColor } from '../../../utils/helper/color';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomers } from '../../../redux/wallet';
import RootState from '../../../redux/types';
import { logger } from '../../../services/logger';
import SmartFilter from '../../../components/common/smartFilter';
import ExportModal from '../../../components/common/exportModal';
import MobileTableCard from '../../../components/common/table/mobile/MobileTable';

const Customers = () => {
	const [demo, setDemo] = useState(true);
	const [showExport, setShowExport] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [activeFilter, setActiveFilter] = useState<string>('');
	const [showConfiguration, setShowConfiguration] = useState<boolean>(false);

	useState<string>('business-account');
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});

	const tableData: {
		account_name?: string;
		date: Date;
		phone?: string;
		email?: string;
	}[] = [];

	// Generate 10 objects with fake data for the table rows

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { customers, loading } = useSelector(
		(state: RootState) => state.wallet
	);

	function isFilter(value: string) {
		if (value === activeFilter) return true;
		else return false;
	}

	useEffect(() => {
		mapHotkey('m', () => setDemo(!demo));
	}, [demo]);

	useEffect(() => {
		if (
			typeof dateFilter.from === 'object' ||
			typeof dateFilter.to === 'object'
		) {
			setDateFilter({
				to: '',
				from: '',
			});
		}
	}, [activeFilter]);

	useEffect(() => {
		if (typeof activeFilter !== undefined) {
			setActiveFilter('');
		}
	}, [dateFilter.to || dateFilter.from]);

	function returnInitial(name: string) {
		const i = name.split(' ');
		return i[0].slice(0, 1).toUpperCase() + i[1].slice(0, 1).toUpperCase();
	}

	useEffect(() => {
		dispatch(
			getCustomers({
				perPage: 20,
				page: currentPage,
			})
		);
	}, [currentPage]);

	const cust = customers.customers;

	return (
		<>
			<DashboardLayout>
				<PageLayout
					pageSubtitle="Users registered on your platform"
					pageTitle="Customers"
					topRightContent={<></>}
				>
					<SmartFilter page="customers" onExport={() => setShowExport(true)} />

					<div className="content__main">
						<div className="content__main--top-bar">
							<div className="top-bar__title">
								{/* <h5>{activeCurrency.toUpperCase()} Settlement History</h5> */}
								{/* <p className={`${demo && 'active'}`}>{`${faker.number.bigInt({
										min: '50',
										max: '700',
									})}`}</p> */}
							</div>
							<Pagination
								className="top-bar__pagination"
								currentPage={currentPage}
								itemsPerPage={20}
								totalItems={customers?.pagination?.total}
								onPageChange={function (page: number): void {
									setCurrentPage(page);
								}}
							/>
						</div>
						<div className="main__wrap">
							{loading || cust?.length === 0 ? (
								<EmptyScreen
									title="No Customer's Found"
									subTitle="We couldn't find any customers on your account, your new customers will appear here."
									loading={loading}
									button
									loadingText="Loading customers..."
									onClick={() => setShowConfiguration(true)}
									buttonLabel="Start Configuration"
									btnIcon={false}
								/>
							) : (
								<>
									<div className="transaction__table non-mobile">
										<RavenTable
											action={false}
											className="table__main"
											headerList={[
												'CUSTOMERS NAME',
												'EMAIL ADDRESS',
												'MOBILE NUMBER',
												'DATE REGISTERED',
											]}
										>
											{cust?.map((chi: any, idx: number) => {
												return (
													<React.Fragment key={idx}>
														<RavenTableRow
															className="table__main"
															four={formatDateTime(chi.created_at)}
															one={
																<div className="account_name">
																	<span
																		style={{
																			backgroundColor:
																				generateRandomColor('#e9f5ff'),
																		}}
																	>
																		<p>
																			{returnInitial(
																				chi?.first_name +
																					' ' +
																					chi?.last_name ?? ''
																			)}
																		</p>
																	</span>
																	<p className="text">
																		{' '}
																		{chi?.first_name + ' ' + chi?.last_name ??
																			''}
																	</p>
																</div>
															}
															three={`${chi.phone}`}
															two={`${chi?.cust_email}`}
														/>
													</React.Fragment>
												);
											})}
										</RavenTable>
									</div>

									<div className="atlas-mobile-table-wrap non-desktop">
										{cust?.map(
											(chi: any, idx: React.Key | null | undefined) => {
												const meta_data = chi?.source
													? JSON.parse(chi?.source)
													: chi?.source;

												const { first_name, last_name } = meta_data
													? meta_data
													: [{}];

												// logger.log(meta_data);
												return (
													<MobileTableCard
														title={chi?.first_name + ' ' + chi?.last_name}
														bottomRightText={formatDateTime(chi.created_at)}
														amount={chi?.phone}
														text={chi?.cust_email}
														img={undefined}
														svg={undefined}
														avatar={''}
														amountColor={'#014345'}
														type={''}
														showImg={false}
														loading={loading}
														onRowClick={() => {}}
														textTwo={''}
													/>
												);
											}
										)}
									</div>
								</>
							)}
						</div>
					</div>
				</PageLayout>
			</DashboardLayout>
			<ExportModal
				onClose={() => setShowExport(false)}
				page="customers"
				visible={showExport}
			/>
		</>
	);
};

export default Customers;
