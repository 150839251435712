import React from 'react';
import DashboardLayout from '../../../layout/dashboard/dashboardLayout';
import ContainerLayout from '../../../layout/dashboard/container';
import AllCards from './components/allCards';
import SingleCard from './components/singleCard';
import DeleteModal from '../../../components/common/deleteModal/DeleteModal';
import RootState, { CreateCardPayload } from '../../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import { RavenInputField, RavenModal } from '@ravenpay/raven-bank-ui';
import './styles/index.css';
import virtualCard from '../../../assets/images/virtual-card-create.png';
import physicalCard from '../../../assets/images/physical-card-create.png';
import ComingSoon from '../../../components/comingSoon';
import { fundCard, getAllCards, issueCard } from '../../../redux/issuing';
import { validateEmail } from '../../../utils/helper/ValidateInput';

interface CreateFormData extends CreateCardPayload {
	curr: {
		label: string;
		value: string;
	};
}
function CardIssuing() {
	const [view, setView] = React.useState('all');
	const [card, setCard] = React.useState('all');
	const [createCard, showCreateCard] = React.useState(false);
	const [createData, setCreateData] = React.useState<CreateFormData>({
		email: '',
		amount: '' as unknown as number,
		phone: '',
		bvn: '',
		nin: '',
		image: '',
		currency: '',
		curr: {
			label: 'NGN',
			value: 'ngn',
		},
	});
	const [fundModal, showFundModal] = React.useState({
		on: false,
		card_id: '',
		card_pan: '',
	});
	const [cardType, setCardType] = React.useState('virtual');
	const [activeStepper, setActiveStepper] = React.useState(0);
	const [selectedSuggestion, setSelectedSuggestion] = React.useState<
		number | string
	>(0);
	const [amount, setAmount] = React.useState<number | string>('');
	const [showDeleteModal, setShowDeleteModal] = React.useState<{
		on: boolean;
		chi: any;
	}>({
		on: false,
		chi: undefined,
	}); //
	const [showSettings, setShowSettings] = React.useState<{
		on: boolean;
		chi: any;
	}>({
		on: false,
		chi: undefined,
	}); //

	const { loading } = useSelector((state: RootState) => state.issuing);
	const dispatch = useDispatch();

	const suggestionAmount = [20, 50, 100, 200, 500, 1000];

	function handleDelete() {}

	async function handleCreateCard() {
		const resp = await dispatch(
			issueCard({
				...createData,
				currency: createData.curr?.value,
			})
		);

		if (resp.payload.status === 'success') {
			showCreateCard(false);

			dispatch(getAllCards());
		} else {
			dispatch(getAllCards());
		}
	}

	async function handleFundCard() {
		const resp = await dispatch(
			fundCard({ card_id: fundModal.card_id, amount: amount })
		);

		if (resp.payload.status === 'success') {
			showFundModal({
				on: false,
				card_id: '',
				card_pan: '',
			});
		}
	}
	function handleCreateFormChange(e?: any) {
		const { name, value } = e.target;
		const obj = { ...createData, [name]: value };
		setCreateData(obj);
	}

	React.useEffect(() => {
		dispatch(getAllCards());
	}, []);

	const disabledAlgo =
		activeStepper === 1 &&
		(!validateEmail(createData.email) ||
			createData.bvn.length <= 10 ||
			!createData.email ||
			!createData.phone ||
			createData.nin.length <= 11 ||
			!createData.curr);

	React.useEffect(() => {
		selectedSuggestion !== 0 && setAmount(selectedSuggestion);
	}, [selectedSuggestion]);
	return (
		<>
			<DashboardLayout>
				{view === 'all' && (
					<AllCards
						cardSettings={(e) =>
							setShowSettings({
								on: true,
								chi: e,
							})
						}
						onCreate={() => showCreateCard(true)}
						onView={(e?: any) => {
							setView('single');
							setCard(e.chi);
							// logger.log('any', e);
						}}
					/>
				)}
				{view === 'single' && (
					<SingleCard
						onFund={(e: { card_id: string; card_pan: string }) =>
							showFundModal({
								on: true,
								card_id: e.card_id,
								card_pan: e.card_pan,
							})
						}
						card={card}
						onBack={() => setView('all')}
					/>
				)}
			</DashboardLayout>
			<DeleteModal
				onDelete={handleDelete}
				loading={loading}
				cancelText="No, Cancel"
				deleteText="Yes, Destroy"
				visible={showDeleteModal.on}
				onCancel={() =>
					setShowDeleteModal({
						on: false,
						chi: '',
					})
				}
				title={'Terminate Card'}
				text={
					'Are you sure you want to terminate this card? This action is irreversible.'
				}
			/>

			{/* Card Settings Modal */}
			<RavenModal
				onClose={() => setShowSettings({ on: false, chi: '' })}
				onBtnClick={() => {}}
				visble={showSettings.on}
			>
				<div className="card-settings">
					<div className="card-settings__title">
						<h5>Card Settings</h5>
						<p>Manage your card and it's preferences</p>
					</div>
					<div className="card-settings__tabs">
						<span>
							<h6>Spending Limit</h6>
						</span>
						<small>Set Spending Limit for Cards on different platforms</small>
					</div>
					<div className="card-settings__tabs">
						<span>
							<h6>Payment Channels</h6>
						</span>
						<small>Configure Payment Channels for this card</small>
					</div>
					<div className="card-settings__tabs">
						<span>
							<h6>Temporarily Freeze Card</h6>
						</span>
						<small>Temporarily Stop Card Transaction abilities</small>
					</div>
					<div
						onClick={() => {
							setShowSettings({
								...showSettings,
								on: false,
							});
							setShowDeleteModal({
								on: true,
								chi: showSettings.chi,
							});
						}}
						className="card-settings__tabs destroy"
					>
						<span>
							<h6>Terminate Card</h6>
						</span>
						<small>Completely eliminate this card from Usage</small>
					</div>
				</div>
			</RavenModal>

			{/* Fund Card Modal */}
			<RavenModal
				onBtnClick={handleFundCard}
				onClose={() =>
					showFundModal({
						on: false,
						card_id: '',
						card_pan: '',
					})
				}
				disabled={(amount as number) < 3}
				loading={loading}
				visble={fundModal.on}
				btnLabel="Fund Card"
				btnColor="deep-green-light"
			>
				<div className="fund-card">
					<div className="fund-card__title">
						<h5>Fund Card</h5>
						<p>Manage your card and it's preferences</p>
					</div>
					<div className="fund-card__card-no">
						<small>CARD NUMBER</small>
						<h6>{fundModal.card_pan}</h6>
					</div>

					<div className="fund-card__input-contain">
						<RavenInputField
							name="amount"
							label="Amount*"
							value={amount}
							onChange={(e: {
								target: { value: React.SetStateAction<string | number> };
							}) => setAmount(e.target.value)}
							color="deep-green-light"
							placeholder="e.g 50"
						/>

						<div className="fund-card__amount-suggestion">
							{suggestionAmount.map((chi, idx) => {
								return (
									<span
										className={`suggestions ${
											selectedSuggestion === chi && 'active'
										}`}
										onClick={() => setSelectedSuggestion(chi)}
										key={idx}
									>
										<p>${chi}</p>
									</span>
								);
							})}
						</div>
					</div>
				</div>
			</RavenModal>

			{/* Create Card Modal */}
			<RavenModal
				className={'create-card'}
				onBtnClick={() => {
					activeStepper === 0 ? setActiveStepper(1) : handleCreateCard();
				}}
				disabled={disabledAlgo}
				btnLabel={activeStepper === 0 ? 'Continue' : 'Create Card'}
				btnColor="deep-green-light"
				loading={loading}
				visble={createCard}
				onClose={() => showCreateCard(false)}
			>
				<div className="create-card__title">
					<h5>Create Card</h5>
				</div>

				<div className="create-card__stepper">
					<p
						onClick={() => setActiveStepper(0)}
						className={activeStepper === 0 ? 'active' : 'none'}
					></p>
					<p
						onClick={() => setActiveStepper(1)}
						className={activeStepper === 1 ? 'active' : 'none'}
					></p>
				</div>

				{activeStepper === 0 && (
					<div className="create-card__card-type">
						<div
							onClick={() => setCardType('virtual')}
							className={`create-card__type-unit ${
								cardType === 'virtual' && 'active'
							}`}
						>
							<div className="create-card__type-unit--title">
								<h6>Virtual Card</h6>
								<p className="sub-text">Spend, virtually everywhere</p>
							</div>

							<figure>
								<img src={virtualCard} alt="" />
							</figure>
						</div>

						<div
							onClick={() => setCardType('physical')}
							className={`create-card__type-unit ${
								cardType !== 'virtual' && 'active'
							}`}
						>
							{' '}
							<div className="create-card__type-unit--title">
								<h6>Physical Card</h6>

								<div
									style={{
										width: '100%',
										display: 'grid',
										placeItems: 'center',
									}}
								>
									<ComingSoon />
								</div>
							</div>
							<figure>
								<img src={physicalCard} alt="" />
							</figure>
						</div>
					</div>
				)}

				{activeStepper === 1 && (
					<>
						<div className="create-card__form">
							<div className="create-card__input-group">
								<RavenInputField
									color="deep-green-light"
									name="email"
									label="Card owner's email*"
									placeholder="e.g Johnbulus@gmail.com"
									type="text"
									showError={
										createData.email.length > 3 &&
										!validateEmail(createData.email)
									}
									value={createData.email}
									onChange={handleCreateFormChange}
								/>
								<RavenInputField
									color="deep-green-light"
									name="phone"
									label="Mobile Number *"
									value={createData.phone}
									onChange={handleCreateFormChange}
									placeholder="903 6*** ***"
									type="phone"
								/>
							</div>

							<div className="create-card__input-group">
								<RavenInputField
									color="deep-green-light"
									name="nin"
									label="NIN*"
									value={createData.nin}
									onChange={handleCreateFormChange}
									placeholder="Enter 11 digit NIN"
									type="text"
								/>
								<RavenInputField
									color="deep-green-light"
									name="bvn"
									label="BVN*"
									onChange={handleCreateFormChange}
									placeholder="Enter 10 digit BVN"
									type="text"
								/>
							</div>

							<div className="create-card__input-group">
								<RavenInputField
									color="deep-green-light"
									name="amount"
									label="Amount"
									value={createData.amount}
									onChange={handleCreateFormChange}
									placeholder="E.g ‘5000’"
									type="amount"
								/>
								<RavenInputField
									color="deep-green-light"
									name="curr"
									value={createData.curr}
									onChange={(e: any) => {
										setCreateData({
											...createData,
											curr: e,
										});
									}}
									label="Currency*"
									selectOption={[
										{ value: 'ngn', label: 'NGN' },
										{ value: 'usd', label: 'USD' },
									]}
									placeholder="(NGN)"
									type="select"
								/>
							</div>
						</div>
					</>
				)}
			</RavenModal>
		</>
	);
}

export default CardIssuing;
