import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../../../layout/dashboard/dashboardLayout';
import ContainerLayout from '../../../../../../layout/dashboard/container';
import { brands, icons } from '../../../../../../assets/icons';
import { RavenButton } from '@ravenpay/raven-bank-ui';
import '../styles/index.css';
import { useNavigate } from 'react-router-dom';
import ComingSoon from '../../../../../../components/comingSoon';
const NewTransfer = () => {
	const [mode, selectedMode] = useState('');
	const [step, setStep] = useState(1);
	const navigate = useNavigate();

	return (
		<>
			<DashboardLayout>
				{step === 1 && (
					<ContainerLayout
						onBack={() => {
							navigate('/dashboard-payment/transfer');
						}}
						topRightContent={<></>}
						pageTitle="Make a Transfer"
						pageSubtitle="Quickly make a transfer in the most efficient way."
						className={'new-transfer animate-move-up-class'}
					>
						<div className="new-transfer__mode">
							<h5 className="mode__title">Select a Mode of Transfer</h5>

							<div className="mode-items-wrap">
								<div
									onClick={() => {
										selectedMode('raven');
										navigate(`/dashboard-payment/transfer/raven`);
									}}
									className={`mode__items ${mode === 'raven' && 'active'}`}
								>
									<div className="checkmark">{icons.green_checkmark}</div>
									<figure className="items__icons">
										{brands.raven_rounded_grey}
									</figure>
									<div className="items__content">
										<p>To Raven User</p>
										<small>Send funds to users registered with Raven</small>
									</div>
								</div>{' '}
								<div
									onClick={() => {
										selectedMode('others');
										navigate(`/dashboard-payment/transfer/others`);
									}}
									className={`mode__items ${mode === 'others' && 'active'}`}
								>
									<div className="checkmark">{icons.green_checkmark}</div>
									<figure className="items__icons">
										{icons.bank_rounded_grey}
									</figure>
									<div className="items__content">
										<p>To Other Bank Accounts</p>
										<small>Send funds to users registered with Raven</small>
									</div>
								</div>{' '}
								<div
									// onClick={() => setStep(2)}
									style={{ opacity: 0.7, cursor: 'no-drop' }}
									className={`mode__items ${mode === 'bulk' && 'active'}`}
								>
									<div className="checkmark">{icons.green_checkmark}</div>
									<figure className="items__icons">
										{icons.kite_rounded_grey}
									</figure>
									<div className="items__content">
										<p style={{ position: 'relative' }}>
											Make a Bulk Transfer
											<ComingSoon />
										</p>
										<small>Send funds to users registered with Raven</small>
									</div>
								</div>
							</div>

							{/* <RavenButton
								className="mode__button"
								label="Continue"
								onClick={() => navigate(`/dashboard-payment/transfer/${mode}`)}
								disabled={!mode}
								color="deep-green-light"
							/> */}
						</div>
					</ContainerLayout>
				)}

				{step === 2 && (
					<ContainerLayout className={'new-transfer animate-move-up-class'}>
						<div className="new-transfer__mode">
							<div className="mode__title-bulk-mode">
								<h5>Make a Bulk Transfer</h5>
								<p>Select how you would love to make bulk transfer</p>
							</div>
							<div
								onClick={() => selectedMode('bulk')}
								className={`mode__items ${mode === 'bulk' && 'active'}`}
							>
								<div className="checkmark">{icons.green_checkmark}</div>
								<figure className="items__icons">{icons.bulk_send}</figure>
								<div
									onClick={() =>
										navigate(`/dashboard-payment/transfer/${mode}`)
									}
									className="items__content"
								>
									<p>Automatic Bulk Transfer</p>
									<small>Send funds to users registered with Raven</small>
								</div>
							</div>{' '}
							<div
								onClick={() => selectedMode('manual-bulk')}
								className={`mode__items ${mode === 'manual-bulk' && 'active'}`}
							>
								<div className="checkmark">{icons.green_checkmark}</div>
								<figure className="items__icons">
									{brands.raven_rounded_grey}
								</figure>
								<div
									onClick={() =>
										navigate(`/dashboard-payment/transfer/${mode}`)
									}
									className="items__content"
								>
									<p>Manual Bulk Transfer</p>
									<small>Send funds to users registered with Raven</small>
								</div>
							</div>
							{/* <RavenButton
								className="mode__button"
								label="Continue"
								onClick={() => navigate(`/dashboard-payment/transfer/${mode}`)}
								disabled={!mode}
								color="deep-green-light"
							/> */}
						</div>
					</ContainerLayout>
				)}
			</DashboardLayout>
		</>
	);
};

export default NewTransfer;
