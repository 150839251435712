import React, { useEffect, useState } from 'react';
import PageLayout from '../../../../../layout/dashboard/tableLayout';
import {
	RavenButton,
	RavenInputField,
	RavenModal,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import SmartFilter from '../../../../../components/common/smartFilter';
import { brands, icons } from '../../../../../assets/icons';
import visa from '../../../../../assets/images/visa.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../../../components/common/pagination';
import EmptyScreen from '../../../../../components/common/emptyScreen';
import Skeleton from 'react-loading-skeleton';
import {
	formatDateTime,
	formatNumWithComma,
	insertUrlParam,
	returnInitial,
	symbol,
} from '../../../../../utils/helper/Helper';
import { generateRandomColor } from '../../../../../utils/helper/color';
import MobileTableCard from '../../../../../components/common/table/mobile/MobileTable';
import '../styles/index.css';
import { FaEye, FaRegEye } from 'react-icons/fa';
import RootState, {
	AllBankboxResponse,
	AssignBankBoxPayload,
	BankBoxData,
	BankBoxUser,
} from '../../../../../redux/types';
import DashboardLayout from '../../../../../layout/dashboard/dashboardLayout';
import { getAllCards } from '../../../../../redux/issuing';
import { bankboxAPI } from '../../../../../redux/bankbox';
import DeleteModal from '../../../../../components/common/deleteModal/DeleteModal';

const AllBankBox = ({ onExport }: any) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [showDeactivate, setShowDeactivate] = useState<{
		on: boolean;
		chi: BankBoxData;
	}>({
		on: false,
		chi: {} as BankBoxData,
	});
	const [assignFormData, setAssignFormData] = useState<AssignBankBoxPayload>({
		affiliate_app_id: '',
		poseidon_business_email: '',
		serial_number: '',
		label: '',
		poseidon_business_name: '',
	});
	const [assignBankbox, setAssignBankbox] = useState<{
		on: boolean;
		chi: BankBoxUser;
	}>({
		on: false,
		chi: {
			id: 0,
			fname: '',
			lname: '',
			poseidon_email: '',
			bvn: '',
			nin: '',
			phone: '',
			affiliate_app: '',
			affiliate_app_id: '',
			poseidon_business_name: '',
			poseidon_business_address: '',
			poseidon_business_type: null,
			poseidon_business_description: '',
			poseidon_profile_reference: '',
			business_category: '',
			region: '',
			created_at: '',
			updated_at: '',
		},
	});
	function onView(e?: any) {}
	function cardSettings(e?: any) {}

	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});
	const searchLoading = false;
	const { all_bankbox, loading }: { all_bankbox: any; loading: boolean } =
		useSelector((state: RootState) => state.bankbox);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const bankboxes: AllBankboxResponse = all_bankbox;

	function formatStatus(status: string) {
		return (
			<div className={`bankbox-status ${status && status?.toLowerCase()}`}>
				<p>{status}</p>
			</div>
		);
	}

	React.useEffect(() => {
		dispatch(
			bankboxAPI.getAllBankBox({
				per_page: 20,
				page: currentPage,
			})
		);
	}, [currentPage]);

	// logger.log(all_bankbox);

	async function handleView(e: string) {
		// insert url param

		await navigate({
			pathname: '/dashboard-bankbox/terminals/view',
		});

		// append the query parameters
		await insertUrlParam('serial', e, `Atlas: BankBox - SN:${e}`);
	}

	function DropMore({ className, idx, chi }: any) {
		return (
			<div className={`more-wrap ${className}`}>
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						onView &&
							onView({
								on: true,
								chi: chi,
							});
						handleView(chi.serial);
						setShowDrop({
							show: !showDrop.show,
							id: idx,
						});
					}}
					className="more_item"
				>
					<figure>
						<FaRegEye />
					</figure>
					<p>View BankBox</p>
				</div>

				{/* <div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setAssignBankbox({ on: true, chi: chi });
					}}
					className="more_item"
				>
					<figure>{icons.bankbox_assign}</figure>
					<p>Assign BankBox</p>
				</div> */}
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setShowDeactivate({
							on: true,
							chi: chi,
						});
					}}
					className="more_item"
				>
					<figure>{icons.deactivate_terminal}</figure>
					<p>
						{chi?.status === 1 ? 'Deactivate Terminal' : 'Activate BankBox'}
					</p>
				</div>
			</div>
		);
	}

	function isDropped(idx: number | string) {
		if (showDrop.id === idx && showDrop.show) return true;
		else return false;
	}

	useEffect(() => {
		setAssignFormData({
			...assignFormData,
			poseidon_business_email: assignBankbox?.chi?.poseidon_email,
			poseidon_business_name: assignBankbox?.chi?.poseidon_business_name,
		});
	}, [assignBankbox]);

	async function handleAssign() {
		const resp = await dispatch(bankboxAPI.assignBankbox(assignFormData));

		if (resp.payload.status === 'success') {
			setAssignBankbox({
				chi: {
					id: 0,
					fname: '',
					lname: '',
					poseidon_email: '',
					bvn: '',
					nin: '',
					phone: '',
					affiliate_app: '',
					affiliate_app_id: '',
					poseidon_business_name: '',
					poseidon_business_address: '',
					poseidon_business_type: null,
					poseidon_business_description: '',
					poseidon_profile_reference: '',
					business_category: '',
					region: '',
					created_at: '',
					updated_at: '',
				},
				on: false,
			});
		}
	}

	async function handleDeactivate() {
		const resp = await dispatch(
			bankboxAPI.deactivateBankbox({ serial_no: showDeactivate?.chi?.serial })
		);

		if (resp.payload.status === 'success') {
			setShowDeactivate({
				on: false,
				chi: {} as BankBoxData,
			});

			dispatch(
				bankboxAPI.getAllBankBox({
					per_page: 20,
					page: currentPage,
				})
			);
		}
	}

	async function handleReactivate() {
		const resp = await dispatch(
			bankboxAPI.reactivateBankbox({ serial_no: showDeactivate?.chi?.serial })
		);

		if (resp.payload.status === 'success') {
			setShowDeactivate({
				on: false,
				chi: {} as BankBoxData,
			});

			dispatch(
				bankboxAPI.getAllBankBox({
					per_page: 20,
					page: currentPage,
				})
			);
		}
	}

	console.log(bankboxes);
	return (
		<>
			<>
				<PageLayout
					pageSubtitle="All BankBox created on the platform"
					pageTitle="BankBox Terminals"
					topRightContent={<></>}
				>
					<SmartFilter
						defaultFilterBy="status"
						// hideExport
						// hideFilter
						// filters={[
						// 	{
						// 		label: 'Status',
						// 		filterBy: 'status',
						// 		options: [
						// 			{
						// 				label: 'Pending',
						// 				value: 'pending',
						// 			},
						// 			{
						// 				label: 'Failed',
						// 				value: 'failed',
						// 			},
						// 			{
						// 				label: 'Successful',
						// 				value: 'succesful',
						// 			},
						// 		],
						// 	},
						// ]}
						onExport={onExport}
						page="bankbox_terminals"
					/>

					<div className="content__main--top-bar">
						<div className="top-bar__title"></div>
						<Pagination
							className="top-bar__pagination"
							currentPage={currentPage}
							itemsPerPage={all_bankbox?.pagination?.per_page}
							totalItems={all_bankbox?.pagination?.total}
							onPageChange={function (page: number): void {
								setCurrentPage(page);
							}}
						/>
					</div>

					<div className="content__main issuing">
						<div className="main__wrap">
							{loading || bankboxes?.data?.length < 1 ? (
								<EmptyScreen
									loading={searchLoading || loading}
									loadingText={
										searchLoading ? 'Searching...' : 'Loading Bankboxes...'
									}
									title="No BankBox Found"
									subTitle="You don't have any record yet, create new bankbox requests and have them appear here."
								/>
							) : (
								<>
									<div className="transaction__table non-mobile">
										<RavenTable
											action={false}
											className="table__main"
											headerList={[
												'SERIAL NO',
												'BUSINESS',
												'EMAIL ADDRESS.',
												'DATE DISBURSED',
												'STATUS',
												'        ',
											]}
										>
											{bankboxes?.data?.map((chi, idx: any) => {
												return (
													<React.Fragment key={idx}>
														<RavenTableRow
															onRowClick={() => {}}
															// manualActionIcon={icons.logout}
															className="table__main"
															five={
																loading ? (
																	<Skeleton
																		style={{ borderRadius: '10px' }}
																		width={80}
																		height={20}
																	/>
																) : (
																	formatStatus(
																		chi.status === 1
																			? 'Activated'
																			: 'Deactivated'
																	)
																)
															}
															six={
																<div
																	style={{ position: 'relative' }}
																	onClick={() =>
																		setShowDrop({
																			id: idx,
																			show: !showDrop.show,
																		})
																	}
																>
																	<DropMore
																		idx={idx}
																		className={isDropped(idx) && 'show-drop'}
																		chi={chi}
																	/>
																	{icons.three_dots}
																</div>
															}
															four={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	formatDateTime(chi?.created_at)
																)
															}
															one={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	<div className="table__card-pan">
																		<p>{chi?.serial}</p>
																	</div>
																)
															}
															two={
																<div className="account_name--transfer">
																	{loading ? (
																		<Skeleton circle width={35} height={35} />
																	) : (
																		<span
																			className="account_name__span"
																			style={{
																				backgroundColor:
																					generateRandomColor('#e9f5ff'),
																			}}
																		>
																			<p>
																				{returnInitial(
																					chi?.poseidon_business_name ?? ''
																				)}
																			</p>
																		</span>
																	)}

																	{loading ? (
																		<Skeleton width={230} height={20} />
																	) : (
																		<p className="text">
																			{' '}
																			{chi?.poseidon_business_name}
																		</p>
																	)}
																</div>
															}
															three={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	chi.email
																)
															}
														/>
													</React.Fragment>
												);
											})}
										</RavenTable>
									</div>

									<div className="atlas-mobile-table-wrap">
										{bankboxes?.data?.map(
											(chi, idx: React.Key | null | undefined) => {
												// const { first_name, last_name, bank } = meta_data;

												// logger.log(meta_data);
												return (
													<MobileTableCard
														title={chi?.poseidon_business_email}
														text={chi?.serial}
														amount={`Volume: 
														${symbol('ngn') + formatNumWithComma(chi.volume, 'ngn')}`}
														img={visa}
														svg={undefined}
														avatar={''}
														amountColor={'#014345'}
														type={''}
														showImg={true}
														loading={loading}
														onRowClick={() =>
															onView &&
															onView({
																on: true,
																chi: chi,
															})
														}
														bottomRightText={formatDateTime(chi.created_at)}
													/>
												);
											}
										)}
									</div>
								</>
							)}
						</div>
					</div>
				</PageLayout>
			</>

			<RavenModal
				onClose={() => setAssignBankbox({ ...assignBankbox, on: false })}
				visble={assignBankbox.on}
				loading={loading}
				onBtnClick={handleAssign}
				btnColor="deep-green-light"
				btnLabel="Assign"
			>
				<div className="assign-bb-modal">
					<div className="assign-bb-modal__title">
						<h6>Assign BankBox</h6>
						<p>Allocate a bankbox to a user</p>
					</div>

					<div className="assign-bb-modal__form-body">
						<RavenInputField
							type="text"
							color="deep-green-light"
							label="BankBox Serial Number"
							onChange={(e: any) => {
								setAssignFormData({
									...assignFormData,
									serial_number: e.target.value,
								});
							}}
							placeholder="4989q3****"
							name="serial"
						/>
						<RavenInputField
							type="text"
							onChange={(e: any) => {
								setAssignFormData({
									...assignFormData,
									label: e.target.value,
								});
							}}
							color="deep-green-light"
							label="BankBox Label"
							placeholder="cp-11-finance"
							name="serial"
						/>
					</div>
				</div>
			</RavenModal>
			<DeleteModal
				onDelete={
					showDeactivate?.chi?.status === 1
						? handleDeactivate
						: handleReactivate
				}
				loading={loading}
				deleteText={
					showDeactivate.chi?.status === 1 ? 'Deactivate' : 'Activate'
				}
				buttonColor={showDeactivate.chi?.status !== 1 ? 'deep-green-light' : ''}
				visible={showDeactivate.on}
				onCancel={() =>
					setShowDeactivate({
						on: false,
						chi: {} as BankBoxData,
					})
				}
				title={
					showDeactivate.chi?.status === 1
						? 'Deactivate BankBox'
						: 'Activate BankBox'
				}
				text={
					showDeactivate.chi?.status === 1
						? 'Deactivating a BankBox suspend it from recieving or sending out money, are you sure you want to proceed?'
						: 'Activating this bankbox will activate the sending and receiving feature of the bankbox.'
				}
			/>
		</>
	);
};

export default AllBankBox;
