import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import './styles/index.css';
import {
	removeCommaAndNairaSign,
	searchParams,
} from '../../../../utils/helper/Helper';

import { useDarkMode } from '../../../../hooks/useDarkMode';

import { useDispatch, useSelector } from 'react-redux';
import { getProfile, getSettingsConfig } from '../../../../redux/settings';
import { AppDispatch } from '../../../../redux/store';
import { TeamFormData } from '../../settings/components/team';
import BasicProfile from './components/BasicProfile';
import { bankboxAPI } from '../../../../redux/bankbox';
import RootState, { BankboxMerchantProfile } from '../../../../redux/types';
import { RavenButton, toast } from '@ravenpay/raven-bank-ui';
import useScrollToLocation from '../../../../hooks/useScrolltoLocation';
import { cleanHex } from './components/colorConvert';

type activeSettings =
	| 'business-profile'
	| 'verification'
	| 'team'
	| 'keys-webhook'
	| 'preference'
	| 'security';

const BankboxSettings = () => {
	const [activeSettings, setActiveSettings] = useState<activeSettings | string>(
		'basic'
	);

	const [formData, setFormData] = useState<BankboxMerchantProfile>({
		address: '',
		app_id: '',
		app_name: '',
		bank_collection_fee: 0,
		card_fee: 0,
		card_fee_cap: 0,
		card_fee_model: '',
		color_code: '',
		created_at: '',
		device_price: 0,
		email: '',
		fee_ref: null,
		id: 0,
		key_serial: '',
		logo_url: null,
		mandatory_fee: 0,
		region: '',
		status: 0,
		support_email: '',
		updated_at: '',
		virtual_account: 0,
		virtual_account_provider: '',
		webhook_secret: null,
		webhook_url: null,
		website: null,
	});

	function isSettings(wl: string) {
		if (activeSettings === wl) return true;
		else return false;
	}

	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const [logoChanged, setIsLogoChange] = useState(false);
	const [logo, setLogo] = useState<File | undefined>();

	const { profile, loading } = useSelector((state: RootState) => state.bankbox);
	const [canUpdate, setCanUpdate] = useState<boolean>(false);
	const dispatch = useDispatch<AppDispatch>();

	// useEffect(() => {
	// 	insertUrlParam(
	// 		'active-setting',
	// 		activeSettings,
	// 		`${capitalizeFirstLetter(
	// 			`${activeSettings}`
	// 		)} Bills Payment | Raven Atlas`,
	// 		true
	// 	);
	// }, [activeSettings]);

	useEffect(() => {
		const billie: activeSettings | null | string =
			searchParams.get('active-setting');
		billie !== null ? setActiveSettings(billie) : '';
	}, []);

	async function fetchProfile() {
		const resp = await dispatch(bankboxAPI.getProfile({}));

		if (resp.payload?.status === 'success') {
			setFormData(resp.payload.data.data);
		}
	}

	useEffect(() => {
		fetchProfile();
	}, []);

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...formData, [name]: value };
		setFormData(obj);
		if (!canUpdate) {
			setCanUpdate(true);
		}
	};

	const handleUpload = async (t: boolean) => {
		const uploadData = new FormData();

		uploadData.append('business_logo', logo as Blob);
		const resp = await dispatch(bankboxAPI.uploadLogo(uploadData));
		if (resp.payload.status === 'success') {
			if (t) {
				toast.success('Logo updated successfully');
			}

			if (canUpdate) {
				dispatch(bankboxAPI.updateProfile(formData));
			}
			setCanUpdate(false);
			setIsLogoChange(false);
		}
	};

	const handleSubmit = async () => {
		if (logoChanged) {
			handleUpload(!canUpdate);
		} else {
			dispatch(
				bankboxAPI.updateProfile({
					...formData,
					color_code: cleanHex(formData.color_code),
					device_price: removeCommaAndNairaSign(formData.device_price) as any,
					mandatory_fee: removeCommaAndNairaSign(formData.mandatory_fee) as any,
					card_fee: removeCommaAndNairaSign(formData.card_fee) as any,
					card_fee_cap: removeCommaAndNairaSign(formData.card_fee_cap) as any,
				})
			);
		}
	};

	//initialize scroll to location
	useScrollToLocation();

	return (
		<>
			<DashboardLayout>
				<div className={`settings ${theme}`}>
					<div className="settings__title">
						<div className="title__main">
							<h5>BankBox Settings</h5>
							<p>Manage your account and profile settings</p>
						</div>
						<RavenButton
							loading={loading}
							disabled={!canUpdate && !logoChanged}
							onClick={handleSubmit}
							className="non-mobile"
							color="deep-green-light"
						>
							Save Changes
						</RavenButton>
						<RavenButton
							loading={loading}
							disabled={!canUpdate && !logoChanged}
							onClick={handleSubmit}
							className="non-desktop"
							color="deep-green-light"
						>
							Save
						</RavenButton>
					</div>
					<div className="settings__sidebar">
						<div className="sidebar__selector">
							<a
								href="#basic"
								onClick={() => setActiveSettings('basic')}
								className={`selector__item ${isSettings('basic') && 'active'}`}
							>
								<p>Basic Details</p>
							</a>
							<a
								href="#prices"
								onClick={() => setActiveSettings('prices')}
								className={`selector__item ${isSettings('prices') && 'active'}`}
							>
								<p>Prices, Rates and Fees</p>
							</a>
							<a
								href="#operations"
								onClick={() => setActiveSettings('operations')}
								className={`selector__item ${
									isSettings('operations') && 'active'
								}`}
							>
								<p>Operations & Support</p>
							</a>
							<a
								href="#webhook"
								onClick={() => setActiveSettings('webhook')}
								className={`selector__item ${
									isSettings('webhook') && 'active'
								}`}
							>
								<p>Webhook & Keys</p>
							</a>
						</div>

						<>
							<BasicProfile
								loading={loading}
								logoFile={(e) => {
									setLogo(e);
								}}
								logoChanged={(e) => {
									setIsLogoChange(e);
								}}
								value={formData}
								onChange={handleChange}
								activeSettings={(e) => setActiveSettings(e as string)}
							/>
						</>
					</div>
				</div>
			</DashboardLayout>
		</>
	);
};

export default BankboxSettings;
