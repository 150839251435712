import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../../../../layout/dashboard/dashboardLayout';
import ContainerLayout from '../../../../../../../layout/dashboard/container';
import { icons } from '../../../../../../../assets/icons';
import './styles/index.css';
import {
	RavenButton,
	RavenInputField,
	RavenTable,
	RavenTableRow,
	RavenToolTip,
	toast,
} from '@ravenpay/raven-bank-ui';
import * as XLSX from 'xlsx';
import temolate from './docs/bulk_transfer_format.png';
import {
	formatNumWithComma,
	symbol,
} from '../../../../../../../utils/helper/Helper';
import { generateRandomColor } from '../../../../../../../utils/helper/color';
import Pagination from '../../../../../../../components/common/pagination';
import TableActionBlock from '../../../../../../../components/common/table/TableActionBlock';
import TableInput from '../../../../../../../components/common/table/TableInput';
import ConfirmTransactionPinModal from '../../common/ConfirmPinModal';
import { useNavigate } from 'react-router-dom';

interface BULK {
	onBack?: () => void;
}
type EditMode = {
	id: number | string;
	on: boolean;
};
const ManualBulkTransfer = () => {
	const [step, setStep] = useState(1);
	const [pinModal, setPinModal] = useState(false);
	const [bulkList, setBulkList] = useState<any>([]);
	const [newData, setNewData] = useState<any>([]);

	const tableHeadList = [
		'BANK',
		'ACCOUNT NO',
		'AMOUNT',
		'ACCOUNT NAME',
		'STATUS',
		'ACTION(S)',
	];

	const [editMode, setEditMode] = useState<EditMode>({
		id: '',
		on: false,
	});

	function isEditMode(k: number | string) {
		if (k === editMode.id && editMode.on) {
			return true;
		} else return false;
	}

	function editData(key: string | number, e: any) {
		newData.map((chi: any, idx: number) => {
			if (idx === key) {
				newData[idx][e.target.name] = e.target.value;
				setNewData(newData);
			}
		});

		return newData;
	}

	// save edited data
	function onSaveEdit(data: any) {
		setBulkList(data);
	}

	// keep new data up to date
	useEffect(() => {
		setNewData(bulkList);
	}, [bulkList]);

	const navigate = useNavigate();

	function formatStatus(status: string) {
		return (
			<div className={`transaction-status ${status.toLowerCase()}`}>
				<p>{status}</p>
			</div>
		);
	}

	function onDelete(idx: any) {
		const filteredList = bulkList.filter(
			(d: any, index: number) => index !== idx
		);
		setBulkList(filteredList);
	}

	if (bulkList?.length === 0) {
		setBulkList([
			{ bank: '', account_no: '', account_name: '', status: 'pending' },
		]);
		setEditMode({
			id: 0,
			on: true,
		});
	}

	return (
		<>
			<DashboardLayout>
				{step === 1 && (
					<>
						<ContainerLayout
							pageTitle="Make a Transfer"
							pageMode
							className="table-container-manual"
						>
							<div className="content__main--top-bar">
								<div className="top-bar__title">
									<h5>Manual Bulk Transfer</h5>
									<p className={'active'}>{bulkList?.length}</p>
								</div>

								<Pagination
									className="top-bar__pagination"
									currentPage={1}
									itemsPerPage={10}
									totalItems={100}
									onPageChange={function (page: number): void {
										// setCurrentPage(page);
									}}
								/>
							</div>
							<div className="bulk-transfer__table-wrap">
								<RavenTable
									action={false}
									className="table__main"
									headerList={tableHeadList}
								>
									{bulkList.map((chi: any, idx: number) => {
										return (
											<React.Fragment key={idx}>
												<RavenTableRow
													className={`table__main ${
														isEditMode(idx) && 'editing'
													}`}
													six={
														<TableActionBlock
															tooltipName={chi.account_name}
															editMode={isEditMode(idx)}
															onEdit={() => {
																setEditMode({
																	id: idx,
																	on: !editMode.on,
																});
															}}
															onSave={() => {
																onSaveEdit(newData);
																setEditMode({
																	id: idx,
																	on: !editMode.on,
																});
															}}
															onDelete={() => onDelete(idx)}
														/>
													}
													three={
														isEditMode(idx) ? (
															<TableInput
																onChange={(e) => {
																	editData(idx, e);
																}}
																type="text"
																name="amount"
																value={chi.amount}
																id="input"
																autoFocus
															/>
														) : (
															<div className="status-type-wrap ">
																<span>
																	{symbol('ngn') +
																		formatNumWithComma(chi.amount, 'ngn')}
																</span>
															</div>
														)
													}
													five={formatStatus('pending')}
													four={
														<div className="account_name">
															{isEditMode(idx) ? (
																<p className="text">
																	{' '}
																	{chi.account_name?.length > 5 ? (
																		chi.account_name
																	) : (
																		<div className="account_name--placeholder">
																			<p></p>
																		</div>
																	)}
																</p>
															) : (
																<p className="text">{chi.account_name}</p>
															)}
														</div>
													}
													two={
														isEditMode(idx) ? (
															<TableInput
																onChange={(e) => {
																	editData(idx, e);
																}}
																type="input"
																name="account_number"
																value={chi.account_number}
																id="input"
																autoFocus
															/>
														) : (
															<div className="type-wrap">
																<span>{chi.account_number}</span>
															</div>
														)
													}
													one={
														isEditMode(idx) ? (
															<TableInput
																onChange={(e) => {
																	var d = {
																		target: {
																			value: e,
																			name: 'bank',
																		},
																	};
																	editData(idx, d);
																}}
																placeholder="Select Bank"
																type="select"
																selectOptions={[
																	{
																		value: 'Wema Bank',
																		label: 'Wema Bank',
																	},
																]}
																name="bank"
																value={chi.bank}
																id="input"
																autoFocus
															/>
														) : (
															<div className="type-wrap">
																<span>{chi.bank.label}</span>
															</div>
														)
													}
												/>
											</React.Fragment>
										);
									})}
								</RavenTable>

								<RavenButton
									onClick={() => {
										setBulkList([
											...bulkList,
											{
												account_name: '--',
												bank: {
													label: 'Select Bank',
													value: 'Select Bank',
												},
												account_number: '--',
											},
										]);
									}}
									className="table-wrap__add-row-btn"
									color="deep-green-light"
								>
									{' '}
									<p>Add New Row</p>
									<figure>{icons.plus_circle_alt}</figure>
								</RavenButton>
							</div>
							<div className="table-container-manual__buttons">
								<RavenButton
									onClick={() => setStep(2)}
									color="deep-green-light"
									label="Continue"
								/>
							</div>
						</ContainerLayout>
					</>
				)}

				{step === 2 && (
					<ContainerLayout style={{ width: '100%' }}>
						<div className="bulk-transfer-summary">
							<figure className="bulk-transfer-summary__arrow-back">
								{icons.arrow_back}
							</figure>
							<span className="bulk-transfer-summary__title">
								<h5>Make a Bulk Transfer</h5>
								<p>Send money to several people all at once</p>
							</span>
							<div className="bulk-transfer-summary__narration-form">
								<RavenInputField
									name="narration"
									label="Narration"
									color="deep-green-light"
									type="textarea"
									placeholder="E.g ‘something for dem boys’"
								/>
							</div>
							<div className="bulk-transfer-summary__table">
								<div className="table__item">
									<p>Total Amount</p>
									<p>₦1,050,000.00</p>
								</div>
								<div className="table__item">
									<p>Total Recipents</p>
									<p>₦1,050,000.00</p>
								</div>
								<div className="table__item">
									<p>Total Fee</p>
									<p>₦405</p>
								</div>
							</div>
							<div className="bulk-transfer-summary__button">
								<RavenButton
									label="Initiate Transfer"
									color="deep-green-light"
									className="act-btn"
									onClick={() => setPinModal(true)}
								/>
							</div>
						</div>
					</ContainerLayout>
				)}
			</DashboardLayout>
			<ConfirmTransactionPinModal
				visible={pinModal}
				onBtnClick={() => {
					navigate('/dashboard-payment/transfer');
					setPinModal(false);
					toast.success(
						'Transaction was successfully queued, we will send a notification once we are done.'
					);
				}}
				onClose={() => setPinModal(false)}
			/>
		</>
	);
};
export default ManualBulkTransfer;
