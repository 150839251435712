import React from 'react';
import '../styles/index.css';
import { icons } from '../../../../../assets/icons';

const SettingsSecurity = ({
	onChangePassword,
	onChangeTransactionPin,
}: {
	onChangePassword?: () => void;
	onChangeTransactionPin?: () => void;
}) => {
	return (
		<div className="verification-settings">
			<div className="verification-settings__title">
				<h5>Security</h5>
				<p>Manage your account passwords and security</p>
			</div>

			<div className="verification-settings__main">
				<div onClick={onChangePassword} className="main__items cursor-pointer">
					<div className="items__grouped ">
						<figure>{icons.settings_password}</figure>
						<span>
							<h5>Change Password</h5>
							<p>Change the password of your atlas account.</p>
						</span>
					</div>
				</div>

				<div
					onClick={onChangeTransactionPin}
					className="main__items cursor-pointer"
				>
					<div className="items__grouped">
						<figure>{icons.settings_bvnnin}</figure>
						<span>
							<h5>Change Transaction PIN</h5>
							<p>Access to receiving SMS notifications for your transactions</p>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SettingsSecurity;
