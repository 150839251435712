import ForgetPasswordIndex from '../../pages/auth/forget-password';
import LoginIndex from '../../pages/auth/login';
import RegisterIndex from '../../pages/auth/register';
import TeamInviteIndex from '../../pages/auth/team-invite';

export const auth_routes_group = [
	{
		path: '/login',
		element: LoginIndex,
	},
	{
		path: '/register',
		element: RegisterIndex,
	},
	{
		path: '/forgot-password',
		element: ForgetPasswordIndex,
	},
	{
		path: '/accept-invite/:id',
		element: TeamInviteIndex,
	},
];
