import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../../layout/dashboard/dashboardLayout';
import ContainerLayout from '../../../../../layout/dashboard/container';
import PageLayout from '../../../../../layout/dashboard/tableLayout';
import { icons } from '../../../../../assets/icons';
import '../styles/index.css';
import { generateRandomColor } from '../../../../../utils/helper/color';
import {
	capitalizeFirstLetter,
	formatDateTime,
	formatNumWithComma,
	handleCopy,
	mapHotkey,
	searchParams,
	symbol,
} from '../../../../../utils/helper/Helper';
import {
	RavenButton,
	RavenInputField,
	RavenModal,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import EmptyScreen from '../../../../../components/common/emptyScreen';
import { faker } from '@faker-js/faker';
import Pagination from '../../../../../components/common/pagination';
import { getSingleCustomer } from '../../../../../redux/wallet';
import { AppDispatch } from '../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../redux/types';
import { getCustomerTransaction } from '../../../../../redux/transaction';
import { logger } from '../../../../../services/logger';
import ExportModal from '../../../../../components/common/exportModal';
import TransactionModal from '../../../../../components/common/transactionModal';
import MobileTableCard from '../../../../../components/common/table/mobile/MobileTable';
import SmartFilter from '../../../../../components/common/smartFilter';
import { useNavigate } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';

type Tab = string;
const ViewVirtualAccount = () => {
	const [activeTab, setActiveTab] = useState<Tab>('account');
	const [email, setEmail] = useState<string | null>('');
	const [currentPage, setCurrentPage] = useState(1);
	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [activeFilter, setActiveFilter] = useState<string>('');
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});
	const [view, onView] = useState<any>({
		show: false,
		view: '',
	});

	type TableProp = {
		fee: string | number;
		email: string;
		created_at(created_at: any): string;
		updated_at(updated_at: any): string;
		bank: string;
		account_number: string;
		account_name?: string;
		type: string;
		description: string;
		amount: string;
		date: Date | string;
		session_id: string;
		source?: string;
	};

	const dispatch = useDispatch<AppDispatch>();

	const { customer, loading } = useSelector((state: RootState) => state.wallet);
	const { customer_transaction } = useSelector(
		(state: RootState) => state.transaction
	);
	const [copy, setCopy] = useState(false);
	const [showExport, setShowExport] = useState<boolean>(false);

	function isFilter(value: string) {
		if (value === activeFilter) return true;
		else return false;
	}

	useEffect(() => {
		if (
			typeof dateFilter.from === 'object' ||
			typeof dateFilter.to === 'object'
		) {
			setDateFilter({
				to: '',
				from: '',
			});
		}
	}, [activeFilter]);

	useEffect(() => {
		if (typeof activeFilter !== undefined) {
			setActiveFilter('');
		}
	}, [dateFilter.to || dateFilter.from]);

	function formatStatus(status: string) {
		return (
			<div className={`transaction-status ${status.toLowerCase()}`}>
				<p>{status}</p>
			</div>
		);
	}

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const queryParam = searchParams.get('email');
		setEmail(queryParam);
		if (email) {
			dispatch(getSingleCustomer({ email }));
			dispatch(
				getCustomerTransaction({
					email: email,
					page: currentPage,
					limit: 20,
				})
			);
		}
	}, [email, currentPage]);

	const cols = customer_transaction?.collections;

	function handleSingleView(chi: TableProp) {
		onView({
			show: true,
			content: chi,
		});
	}

	const navigate = useNavigate();
	return (
		<>
			<DashboardLayout>
				<ContainerLayout
					onBack={() => navigate('/dashboard-virtual-account')}
					loading={loading}
					pageTitle="Account Details"
					pageSubtitle="All transfers made on the Raven Atlas"
					pageMode
					style={{ maxWidth: '100%', width: '100%', flex: '1' }}
					topRightContent={
						<div className="virtual-account-setting">{/* {icons.gear} */}</div>
					}
				>
					<div className="virtual-account">
						<div className="virtual-account__tab">
							<span
								onClick={() => setActiveTab('account')}
								className={`tab__item ${
									activeTab === 'account' ? 'tab__item--active' : ''
								}`}
							>
								<p>Account Details</p>
							</span>
							<span
								onClick={() => setActiveTab('transactions')}
								className={`tab__item ${
									activeTab !== 'account' ? 'tab__item--active' : ''
								}`}
							>
								<p>Transactions</p>
							</span>
						</div>
						{activeTab === 'account' ? (
							<div className="virtual-account__account-details">
								<div className="account-details__account-number">
									<span>
										<p>Account Number</p>
										<h5>{customer?.account_number}</h5>
									</span>
									<figure
										style={{ cursor: 'pointer' }}
										onClick={() => {
											handleCopy(customer?.account_number, setCopy, copy);
										}}
									>
										{copy ? <FaCheck /> : icons.copy_black}

										<p>{copy ? 'Copied' : 'Copy'}</p>
									</figure>
								</div>
								<div className="account-details__main">
									<div className="account-details__item">
										<p>Account Name</p>
										<p>
											Raven • {customer?.first_name + ' ' + customer?.last_name}{' '}
										</p>
									</div>{' '}
									<div className="account-details__item">
										<p>Bank</p>
										<p>Wema Bank</p>
									</div>{' '}
									<div className="account-details__item">
										<p>Account Type</p>
										<span className="temporal">
											<p>Temporal</p>
										</span>
									</div>{' '}
									<div className="account-details__item">
										<p>Date Created</p>
										<p>{formatDateTime(customer?.created_at)}</p>
									</div>
									<div className="account-details__item">
										<p>Customer Name</p>
										<span>
											<p>{customer?.first_name + ' ' + customer?.last_name}</p>
										</span>
									</div>
									<div className="account-details__item">
										<p>Customer Email</p>
										<p>{customer?.cust_email}</p>
									</div>
								</div>
							</div>
						) : (
							<div className="virtual-account__transactions">
								<SmartFilter onExport={() => setShowExport(true)} />
								<div className="content__main">
									<div className="main__wrap">
										{loading || cols?.length === 0 ? (
											<EmptyScreen
												// title="Configure Settlement Balance"
												subTitle="The collection associated with this virtual account will show up here."
												withMascot
												// button
												loading={loading}
												// onClick={() => setShowConfiguration(true)}
												// buttonLabel="Start Configuration"
												btnIcon={false}
											/>
										) : (
											<>
												<div className="transaction__table non-mobile">
													<RavenTable
														action={false}
														className="table__main"
														headerList={[
															'NARRATION',
															'REFERENCE ID',
															'AMOUNT',
															'STATUS',
															'TRANSACTION DATE',
														]}
													>
														{cols?.map((chi: any, idx: any) => {
															const meta = JSON.parse(chi?.source);
															return (
																<React.Fragment key={idx}>
																	<RavenTableRow
																		onRowClick={() =>
																			onView({
																				show: true,
																				content: chi,
																			})
																		}
																		className="table__main"
																		five={formatStatus('successful')}
																		six={formatDateTime(chi?.created_at)}
																		one={
																			<div className="main__direction">
																				<figure>{icons.credit}</figure>
																				<p className="text">
																					{meta?.narration}{' '}
																				</p>
																			</div>
																		}
																		three={
																			<div className="main__amount">
																				<span>
																					{symbol('ngn') +
																						formatNumWithComma(
																							chi?.amount,
																							'ngn'
																						)}
																				</span>
																			</div>
																		}
																		two={
																			<div className={`main__reference`}>
																				<span>
																					{chi?.session_id?.slice(0, 20) +
																						'...'}
																				</span>
																				<figure
																					style={{ cursor: 'pointer' }}
																					onClick={() =>
																						handleCopy(
																							chi?.session_id?.slice(0, 20),
																							setCopy,
																							copy
																						)
																					}
																				>
																					{copy ? <FaCheck /> : icons.copy}
																				</figure>
																			</div>
																		}
																	/>
																</React.Fragment>
															);
														})}
													</RavenTable>
												</div>
												<div className="atlas-mobile-table-wrap">
													{cols?.map(
														(chi: any, idx: React.Key | null | undefined) => {
															const meta_data = chi?.source
																? JSON.parse(chi?.source)
																: chi?.source;

															const { first_name, last_name, bank } = meta_data;

															// logger.log(meta_data);
															return (
																<MobileTableCard
																	title={first_name + ' ' + last_name}
																	text={formatDateTime(chi.created_at)}
																	amount={
																		symbol('ngn') +
																		formatNumWithComma(chi.amount, 'ngn')
																	}
																	img={undefined}
																	svg={undefined}
																	avatar={''}
																	amountColor={'#014345'}
																	type={chi.direction}
																	showImg={true}
																	loading={loading}
																	onRowClick={() =>
																		handleSingleView({
																			...chi,
																			account_name:
																				first_name + ' ' + last_name,
																			bank: bank,
																		})
																	}
																	textTwo={''}
																/>
															);
														}
													)}
												</div>
											</>
										)}
									</div>
								</div>
							</div>
						)}
					</div>
				</ContainerLayout>
			</DashboardLayout>

			<TransactionModal
				onClose={() =>
					onView({
						show: false,
						content: '',
					})
				}
				visible={view.show}
				direction={'credit'}
				title={'Collection Details'}
				amount={view.content?.amount as unknown as number}
				onClick={() => {}}
				meta={view.content?.source}
				content={[
					{
						label: 'Bank',
						value: view.content?.bank || '--',
					},
					{
						label: 'Account Number',
						value: view.content?.account_number,
					},
					{
						label: 'Account Name',
						value: view.content?.account_name as string,
					},
					{
						label: 'Email',
						value: view.content?.email || '--',
					},
					{
						label: 'Session ID',
						value:
							view.content?.session_id?.length > 25
								? view.content?.session_id.slice(0, 25) + '...'
								: view.content?.session_id,
					},
					{
						label: 'Amount',
						value:
							symbol('ngn') + formatNumWithComma(view.content?.amount, 'ngn'),
					},
					{
						label: 'Fee',
						value: symbol('ngn') + formatNumWithComma(view.content?.fee, 'ngn'),
					},
					{
						label: 'Created At',
						value: formatDateTime(
							view.content?.created_at as unknown as string
						),
					},
					{
						label: 'Updated At',
						value: formatDateTime(
							view.content?.updated_at as unknown as string
						),
					},
				]}
			/>
			<ExportModal
				onClose={() => setShowExport(false)}
				page="transactions"
				visible={showExport}
			/>
		</>
	);
};

export default ViewVirtualAccount;
