import { RavenInputField } from '@ravenpay/raven-bank-ui';
import React, { useState } from 'react';
import { TeamFormData } from '../team';
import { logger } from '../../../../../services/logger';
import { capitalizeFirstLetter } from '../../../../../utils/helper/Helper';

const ManageDirector = ({
	chi,
	onChange,
}: {
	chi?: TeamFormData;
	onChange: (e: TeamFormData) => any;
}) => {
	const [formData, setFormData] = useState({
		clearance: {
			label: chi?.clearance,
			value: chi?.clearance,
		},
		merchant_email: chi?.merchant_email,
		fname: chi?.fname,
		lname: chi?.lname,
	});

	// logger.log(chi, 'form child');

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...formData, [name]: value };
		setFormData(obj);
	};

	React.useEffect(() => {
		setFormData({
			clearance: {
				value: chi?.clearance,
				label: capitalizeFirstLetter(chi?.clearance),
			},
			merchant_email: chi?.email,
			fname: chi?.fname,
			lname: chi?.lname,
		});
	}, [chi]);

	React.useEffect(() => {
		onChange && onChange({ ...formData, clearance: formData.clearance.value });
	}, [formData]);

	return (
		<div className="settings-modal-wrap">
			<div className="settings-modal-wrap__title">
				<h5>Manage Team</h5>
				<p>Manage existing team members on your account</p>
			</div>

			<div className="settings-modal-wrap__form">
				<div style={{ cursor: 'no-drop' }}>
					<RavenInputField
						color="deep-green-light"
						type="text"
						label="Email "
						style={{ opacity: 0.4, cursor: 'no-drop' }}
						// readOnly={true}
						value={formData.merchant_email}
						name="email"
						placeholder="E.g ‘johndoe@mail.com’"
						// onChange={handleChange}
					/>
				</div>

				<div className="form__grouped">
					<RavenInputField
						color="deep-green-light"
						value={formData.fname}
						type="text"
						label="Firstname"
						placeholder="E.g 'John'"
						name="fname"
						onChange={handleChange}
					/>
					<RavenInputField
						color="deep-green-light"
						type="text"
						value={formData.lname}
						placeholder="E.g 'Doe'"
						label="Lastname"
						name="lname"
						onChange={handleChange}
					/>
				</div>

				<RavenInputField
					color="deep-green-light"
					value={formData.clearance}
					type="select"
					label="Role"
					name="clearance"
					selectOption={[
						{ value: 'support', label: 'Support' },
						{ value: 'developer', label: 'Developer' },
						{ value: 'admin', label: 'Admin' },
					]}
					placeholder="Select a role"
					onChange={(r: { value: string; label: string }) =>
						setFormData({
							...formData,
							clearance: r,
						})
					}
				/>
			</div>
		</div>
	);
};

export default ManageDirector;
