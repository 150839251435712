import React, { useState } from 'react';
import ContainerLayout from '../../../../../layout/dashboard/container';
import {
	RavenButton,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import './styles/index.css';
import cardie from '../../../../../assets/images/card-previe.png';
import mobileCardie from '../../../../../assets/images/mobile-cardie.png';
import SmartFilter from '../../../../../components/common/smartFilter';
import MobileTableCard from '../../../../../components/common/table/mobile/MobileTable';
import {
	capitalizeFirstLetter,
	formatDateTime,
	formatNumWithComma,
	symbol,
} from '../../../../../utils/helper/Helper';
import Skeleton from 'react-loading-skeleton';
import EmptyScreen from '../../../../../components/common/emptyScreen';
import Pagination from '../../../../../components/common/pagination';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../redux/store';
import RootState from '../../../../../redux/types';
import { icons } from '../../../../../assets/icons';
import { cardTrx } from '../../../../../redux/issuing';

const SingleCard = (props: any) => {
	const [tab, setTab] = React.useState('details');
	const [currentPage, setCurrentPage] = useState(1);
	const { trx, loading } = useSelector((state: RootState) => state.issuing);
	const dispatch = useDispatch<AppDispatch>();
	const [view, onView] = useState<any>({
		show: false,
		view: '',
	});

	function formatStatus(status: string) {
		return (
			<div className={`transaction-status ${status?.toLowerCase()}`}>
				<p>{status}</p>
			</div>
		);
	}

	const { card } = props;

	React.useEffect(() => {
		dispatch(cardTrx({ card_id: card?.card_id }));
	}, []);

	return (
		<>
			<ContainerLayout
				pageMode
				pageTitle="Card Details"
				style={{ height: '100%' }}
				onBack={() => props.onBack()}
				pageSubtitle="All Details about this Card"
				topRightContent={
					<div>
						<RavenButton
							onClick={() => props.onFund(card)}
							style={{ width: '100%' }}
							color="deep-green-light"
						>
							Fund Card
						</RavenButton>
					</div>
				}
			>
				<div className="single-card-container">
					<div className="single-card__toggle">
						<div
							onClick={() => setTab('details')}
							className={`toggle__item ${tab === 'details' && 'active'}`}
						>
							<p>Card Details</p>
						</div>
						<div
							onClick={() => setTab('trx')}
							className={`toggle__item ${tab === 'trx' && 'active'}`}
						>
							<p>Card Transaction</p>
						</div>
					</div>
					{tab === 'details' && (
						<div className="single-card__content">
							<div className="content__left">
								<figure className="non-mobile">
									<img src={cardie} alt="" />
								</figure>
								<figure className="non-desktop mobile-card-preview">
									<img src={mobileCardie} alt="" />
								</figure>
							</div>
							<div className="content__right">
								<div className="right__title">
									<small>Card Number</small>
									<h5>{card?.card_pan}</h5>
								</div>

								<div className="right__content-table">
									<div className="content-table__row">
										<p>Card Name</p>
										<p>{card?.card_name}</p>
									</div>

									<div className="content-table__row">
										<p>Card Type</p>
										<p>{card.type}</p>
									</div>

									<div className="content-table__row">
										<p>Card Brand</p>
										<p>{card.brand}</p>
									</div>

									<div className="content-table__row">
										<p>Card CVV</p>
										<p>{card.cvv}</p>
									</div>

									<div className="content-table__row">
										<p>Currency</p>
										<p>{card.currency}</p>
									</div>

									<div className="content-table__row">
										<p>Balance</p>
										<p>
											{symbol(card.currency) +
												formatNumWithComma(card.balance, 'usd')}
										</p>
									</div>

									<div className="content-table__row">
										<p>Date Created</p>
										<p>{formatDateTime(card.created_at)}</p>
									</div>

									<div className="content-table__row">
										<p>Expiry Date</p>
										<p>{card.expiry_month + ' / ' + card.expiry_year}</p>
									</div>

									{/* <div className="content-table__row">
										<p>Customer Email</p>
										<p>{card.type}</p>
									</div> */}
								</div>
							</div>
						</div>
					)}
					{tab === 'trx' && (
						<div className="single-card-transactions">
							<SmartFilter />
							<div className="content__main">
								<div className="content__main--top-bar">
									<Pagination
										className="top-bar__pagination"
										currentPage={currentPage}
										itemsPerPage={100}
										totalItems={trx.length}
										onPageChange={function (page: number): void {
											setCurrentPage(page);
										}}
									/>
								</div>

								<div
									className={`history__content ${
										loading || !trx || trx.length === 0 ? 'flex-unset' : ''
									}`}
								>
									<div className="main__wrap">
										{loading || !trx || trx.length === 0 ? (
											<EmptyScreen
												loading={loading}
												// title="Configure Settlement Balance"
												subTitle="Your transactions will show up here"
												withMascot
												// button
												// onClick={() => setShowConfiguration(true)}
												// buttonLabel="Start Configuration"
												// btnIcon={false}
											/>
										) : (
											<>
												<div className="transaction__table non-mobile">
													<RavenTable
														action={false}
														className="table__main"
														headerList={[
															'NARRATION',
															'AMOUNT',
															'CARD ID',
															'STATUS',
															'TRANSACTION DATE',
														]}
													>
														{trx?.map(
															(chi, idx: React.Key | null | undefined) => {
																// logger.log(meta);
																return (
																	<React.Fragment key={idx}>
																		<RavenTableRow
																			onRowClick={() =>
																				onView({
																					show: true,
																					content: { ...chi },
																				})
																			}
																			className="table__main"
																			four={
																				loading ? (
																					<Skeleton
																						width={70}
																						style={{ borderRadius: '7.8rem' }}
																						height={20}
																					/>
																				) : (
																					formatStatus('successful')
																				)
																			}
																			one={
																				<div className="main__direction">
																					{loading ? (
																						<Skeleton
																							width={20}
																							height={20}
																							circle
																							enableAnimation
																						/>
																					) : (
																						<figure>
																							{icons[chi.direction]}
																						</figure>
																					)}
																					<p className="text">
																						{loading ? (
																							<Skeleton
																								width={100}
																								height={20}
																							/>
																						) : (
																							chi?.narration
																						)}
																					</p>
																				</div>
																			}
																			five={
																				<div className="type-wrap">
																					{loading ? (
																						<Skeleton width={60} height={20} />
																					) : (
																						<span>
																							{formatDateTime(chi.trx_date)}
																						</span>
																					)}
																				</div>
																			}
																			two={
																				<div className="type-wrap">
																					{loading ? (
																						<Skeleton width={60} height={20} />
																					) : (
																						<span>
																							{symbol('usd') +
																								formatNumWithComma(
																									chi.amount,
																									'ngn'
																								)}
																						</span>
																					)}
																				</div>
																			}
																			three={
																				loading ? (
																					<Skeleton width={70} height={20} />
																				) : (
																					capitalizeFirstLetter(
																						chi?.card_id
																					).replaceAll('_', ' ')
																				)
																			}
																		/>
																	</React.Fragment>
																);
															}
														)}
													</RavenTable>
												</div>

												<div className="atlas-mobile-table-wrap">
													{trx?.map(
														(chi, idx: React.Key | null | undefined) => {
															return (
																<MobileTableCard
																	title={capitalizeFirstLetter(chi?.narration)}
																	text={formatDateTime(chi.trx_date)}
																	amount={
																		symbol('usd') +
																		formatNumWithComma(chi.amount, 'ngn')
																	}
																	img={undefined}
																	svg={undefined}
																	avatar={''}
																	amountColor={'#014345'}
																	type={chi.direction}
																	showImg={true}
																	loading={loading}
																	onRowClick={() =>
																		onView({
																			show: true,
																			content: { ...chi },
																		})
																	}
																	textTwo={''}
																/>
															);
														}
													)}
												</div>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</ContainerLayout>
		</>
	);
};

export default SingleCard;
