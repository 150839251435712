import { RavenButton, toast } from '@ravenpay/raven-bank-ui';
import React from 'react';
import { icons } from '../../../../../assets/icons';
import DashboardLayout from '../../../../../layout/dashboard/dashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { bankboxAPI } from '../../../../../redux/bankbox';
import RootState from '../../../../../redux/types';
import Loader from '../../../../../components/common/loader';
import {
	formatDateTime,
	formatNumWithComma,
	symbol,
} from '../../../../../utils/helper/Helper';
import { useNavigate } from 'react-router-dom';
import { logger } from '../../../../../services/logger';

function SingleViewBankbox() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const searchParam = new URLSearchParams(window.location.search);
	const { all_bankbox, loading } = useSelector(
		(state: RootState) => state.bankbox
	);
	const sn = searchParam.get('serial');

	logger.log(sn, 'serial no');

	React.useEffect(() => {
		try {
			new Promise((resolve) => {
				dispatch(bankboxAPI.getSingleBankbox({ serial_no: sn as string }))
					.then((d) => {
						if (d?.payload?.status === 'success') {
							resolve(d);
						} else {
							console.error('API Request failed'); // Log the error message
						}
					})
					.catch((error) => {
						console.error(error); // Log any error that occurs in the Promise chain
					});
			});
		} catch (error) {
			toast.error('Could not fetch details');
		}
	}, [sn]);

	logger.log(all_bankbox, 'all_bankbox');

	const bb = all_bankbox[0];
	return (
		<>
			<DashboardLayout>
				<div className="bankbox-terminal-view">
					<div className="bankbox-terminal-view__left"></div>

					<div className="bankbox-terminal-view__right">
						{loading ? (
							<Loader />
						) : (
							<>
								<div className="bankbox-terminal-view__right--title">
									<div>
										<h6>{bb?.account_name}</h6>
										<p>Account No. • {bb?.account_number}</p>
									</div>
									<RavenButton
										className="view-term-trx-btn"
										label="View Transactions"
										onClick={() =>
											navigate(`/dashboard-bankbox/transactions?id=${bb.id}`)
										}
										color="deep-green-light"
									/>
								</div>
								<div className="bankbox-terminal-view__right--date-location">
									<div className="item">
										<figure>{icons.bankbox_serial}</figure>
										<p>Serial No. • {bb?.serial}</p>
									</div>

									<div className="item">
										<figure>{icons.calendar_rounded_grey}</figure>
										<p>Created at. • {formatDateTime(bb?.created_at)}</p>
									</div>

									{/* <div className="item">
										<figure>{icons.green_checkmark_rounded}</figure>
										<p>Activated • {bb?.updated_at}</p>
									</div> */}
								</div>
								<div className="bankbox-terminal-view__right--user-details">
									<div className="details-row">
										<div className="details">
											<span>Business Name</span>
											<p>{bb?.poseidon_business_name}</p>
										</div>
										<div className="details">
											<span>Terminal ID</span>
											<p>{bb?.tid}</p>
										</div>
									</div>
									<div className="details-row">
										<div className="details">
											<span>Owner's Name</span>
											<p>{bb?.owner_fname + ' ' + bb?.owner_lname}</p>
										</div>
										<div className="details">
											<span>Transaction Count</span>
											<p>{bb?.total_transaction}</p>
										</div>
									</div>
									<div className="details-row">
										<div className="details">
											<span>Email</span>
											<p>{bb?.email}</p>
										</div>
										<div className="details">
											<span>Transaction Volume</span>
											<p>
												{symbol('ngn') +
													formatNumWithComma(bb?.total_volume, 'ngn')}
											</p>
										</div>
									</div>
									<div className="details-row">
										<div className="details">
											<span>Business Cateogory</span>
											<p>{bb?.type}</p>
										</div>
										<div style={{ opacity: '0' }} className="details">
											<span>Transaction Volume</span>
											<p>₦1,250,000.00</p>
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</DashboardLayout>
		</>
	);
}

export default SingleViewBankbox;
