import React, { useEffect, useState } from 'react';
import PageLayout from '../../../../../layout/dashboard/tableLayout';
import {
	RavenButton,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import SmartFilter from '../../../../../components/common/smartFilter';
import { brands, icons } from '../../../../../assets/icons';
import visa from '../../../../../assets/images/visa.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../../../components/common/pagination';
import EmptyScreen from '../../../../../components/common/emptyScreen';
import Skeleton from 'react-loading-skeleton';
import {
	formatDateTime,
	formatNumWithComma,
	returnInitial,
	symbol,
} from '../../../../../utils/helper/Helper';
import { generateRandomColor } from '../../../../../utils/helper/color';
import MobileTableCard from '../../../../../components/common/table/mobile/MobileTable';
import './styles/index.css';
import { FaEye, FaRegEye } from 'react-icons/fa';
import { getAllCards } from '../../../../../redux/issuing';
import RootState from '../../../../../redux/types';

const AllCards = ({
	cardSettings,
	onView,
	onCreate,
}: {
	cardSettings?: (e?: any) => void;
	onView?: (e: any) => void;
	onCreate?: (e?: any) => void;
}) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [showExport, setShowExport] = useState<boolean>(false);

	const [showDeleteModal, setShowDeleteModal] = useState<{
		on: boolean;
		chi: any;
	}>({
		on: false,
		chi: '',
	});
	const [searchQuery, setSearchQuery] = useState('');
	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});
	const searchLoading = false;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	function formatStatus(status: string) {
		return (
			<div
				className={`transaction-status card-type ${
					status && status?.toLowerCase()
				}`}
			>
				<p>{status}</p>
			</div>
		);
	}

	function DropMore({ className, idx, role, chi }: any) {
		return (
			<div className={`more-wrap ${className}`}>
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						onView &&
							onView({
								on: true,
								chi: chi,
							});
						setShowDrop({
							show: !showDrop.show,
							id: idx,
						});
					}}
					className="more_item"
				>
					<figure>
						<FaRegEye />
					</figure>
					<p>View Card</p>
				</div>

				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						cardSettings && cardSettings(chi);
					}}
					className="more_item"
				>
					<figure>{icons.gear}</figure>
					<p>Settings</p>
				</div>
			</div>
		);
	}

	function isDropped(idx: number | string) {
		if (showDrop.id === idx && showDrop.show) return true;
		else return false;
	}

	useEffect(() => {
		dispatch(getAllCards());
	}, []);

	const { cards, loading } = useSelector((state: RootState) => state.issuing);

	// logger.log('cards: ', cards);
	return (
		<>
			{' '}
			<PageLayout
				pageSubtitle="All Cards created on the platform"
				pageTitle="Cards"
				topRightContent={
					<RavenButton
						size="small"
						onClick={() => onCreate && onCreate()}
						color="deep-green-light"
					>
						<p>Create Card</p>
						<figure className="non-mobile">{icons.plus_circle_white}</figure>
						<figure className="non-desktop">{icons.plus}</figure>
					</RavenButton>
				}
			>
				<SmartFilter
					defaultFilterBy="status"
					hideExport
					hideFilter
					filters={[
						{
							label: 'Status',
							filterBy: 'status',
							options: [
								{
									label: 'Pending',
									value: 'pending',
								},
								{
									label: 'Failed',
									value: 'failed',
								},
								{
									label: 'Successful',
									value: 'succesful',
								},
							],
						},
					]}
					onExport={() => setShowExport(true)}
					page="cards"
				/>

				<div className="content__main--top-bar">
					<div className="top-bar__title"></div>
					<Pagination
						className="top-bar__pagination"
						currentPage={currentPage}
						itemsPerPage={cards?.pagination?.perPage}
						totalItems={cards?.pagination?.total}
						onPageChange={function (page: number): void {
							setCurrentPage(page);
						}}
					/>
				</div>

				<div className="content__main issuing">
					<div className="main__wrap">
						{(!cards && loading) ||
						searchLoading ||
						cards?.cards?.length === 0 ? (
							<EmptyScreen
								loading={searchLoading || loading}
								loadingText={
									searchLoading ? 'Searching...' : 'Loading Transfers...'
								}
								title="No Cards Found"
								subTitle="You don't have any record yet, make new transfers and have them displayed here."
							/>
						) : (
							<>
								<div className="transaction__table non-mobile">
									<RavenTable
										action={false}
										className="table__main"
										headerList={[
											'CARD NAME',
											'CARD ID',
											'CURR.',
											'BALANCE',
											'EXP. DATE',
											'TYPE',
											'DATE CREATED',
											'         ',
										]}
									>
										{cards?.cards?.map((chi: any, idx: any) => {
											return (
												<React.Fragment key={idx}>
													<RavenTableRow
														onRowClick={() => {}}
														// manualActionIcon={icons.logout}
														className="table__main"
														five={
															loading ? (
																<Skeleton width={50} height={20} />
															) : (
																<span>
																	{chi.expiry_month + ' / ' + chi.expiry_year}
																</span>
															)
														}
														six={
															loading ? (
																<Skeleton
																	style={{ borderRadius: '10px' }}
																	width={80}
																	height={20}
																/>
															) : (
																formatStatus(chi?.type)
															)
														}
														eight={
															<div
																style={{ position: 'relative' }}
																onClick={() =>
																	setShowDrop({
																		id: idx,
																		show: !showDrop.show,
																	})
																}
															>
																<DropMore
																	role={chi?.clearance}
																	idx={idx}
																	className={isDropped(idx) && 'show-drop'}
																	chi={chi}
																/>
																{icons.three_dots}
															</div>
														}
														seven={
															loading ? (
																<Skeleton width={100} height={20} />
															) : (
																formatDateTime(chi?.created_at)
															)
														}
														two={
															loading ? (
																<Skeleton width={100} height={20} />
															) : (
																<div className="table__card-pan">
																	<figure>
																		<img src={visa} alt="" />
																	</figure>
																	<p>{chi?.card_pan}</p>
																</div>
															)
														}
														one={
															<div className="account_name--transfer">
																{loading ? (
																	<Skeleton circle width={35} height={35} />
																) : (
																	<span
																		className="account_name__span"
																		style={{
																			backgroundColor:
																				generateRandomColor('#e9f5ff'),
																		}}
																	>
																		<p>{returnInitial(chi?.card_name ?? '')}</p>
																	</span>
																)}

																{loading ? (
																	<Skeleton width={230} height={20} />
																) : (
																	<p className="text"> {chi?.card_name}</p>
																)}
															</div>
														}
														four={
															loading ? (
																<Skeleton width={80} height={20} />
															) : (
																<div className="type-wrap">
																	<span>
																		{symbol('ngn') +
																			formatNumWithComma(chi?.bal, 'ngn')}
																	</span>
																</div>
															)
														}
														three={
															loading ? (
																<Skeleton width={100} height={20} />
															) : (
																chi.currency
															)
														}
													/>
												</React.Fragment>
											);
										})}
									</RavenTable>
								</div>

								<div className="atlas-mobile-table-wrap">
									{cards?.cards?.map(
										(chi: any, idx: React.Key | null | undefined) => {
											// const { first_name, last_name, bank } = meta_data;

											// logger.log(meta_data);
											return (
												<MobileTableCard
													title={chi?.card_name}
													text={chi.card_pan.slice(-8)}
													amount={`Bal: 
														${symbol(chi.currency.toLowerCase()) + formatNumWithComma(chi.amount, 'ngn')}`}
													img={visa}
													svg={undefined}
													avatar={''}
													amountColor={'#014345'}
													type={''}
													showImg={true}
													loading={loading}
													onRowClick={() =>
														onView &&
														onView({
															on: true,
															chi: chi,
														})
													}
													bottomRightText={formatDateTime(chi.created_at)}
												/>
											);
										}
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</PageLayout>
		</>
	);
};

export default AllCards;
