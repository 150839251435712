import React from 'react';

import './styles/previewmodal.css';
import { formatNumWithComma, symbol } from '../../../utils/helper/Helper';
import { brands } from '../../../assets/icons';
import { logger } from '../../../services/logger';

const PreviewModal = ({
	content,
	onRemove,
}: {
	content: any;
	onRemove: (e?: any) => void;
}) => {
	const cancel = (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="8" cy="8" r="8" fill="#FFDFDD" />
			<path
				d="M10 6L6 10M6 6L10 10"
				stroke="#FF0F00"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	const [viewAll, setViewAll] = React.useState(false);
	const [details, setDetails] = React.useState<any>(content);

	const handleRemoveFromList = (obj: { username: any }) => {
		if (content?.length === 1) {
			setViewAll(false);
		}
		const newList = content?.filter(
			(chi: { username: any }) => chi?.username !== obj?.username
		);
		logger.log(newList, 'new');
		setDetails((prev: any) => {
			return {
				...prev,
				bulk_list: newList,
			};
		});
	};

	React.useEffect(() => {
		onRemove && onRemove(details);
	}, [details]);

	return (
		<div className={`preview-modal-wrap `}>
			<div className="preview-modal-wrap__contain">
				{content &&
					content?.map((chi: any, idx: string) => {
						return (
							<div key={idx} className="contain__item">
								<div className="item1">
									<figure>{brands.raven}</figure>
									<span>
										<h5>{chi?.account_name || chi.fname + ' ' + chi?.lname}</h5>
										<p>
											{chi?.account_number || chi?.identifier} •{' '}
											{symbol('ngn') +
												formatNumWithComma(chi?.amount || chi?._value, 'ngn')}
										</p>
									</span>
								</div>{' '}
								<figure
									className="remove-btn"
									onClick={() => handleRemoveFromList(chi)}
								>
									{cancel}
								</figure>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default PreviewModal;
