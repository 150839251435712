import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../../../layout/dashboard/dashboardLayout';
import ContainerLayout from '../../../../../../layout/dashboard/container';
import '../styles/index.css';
import { icons } from '../../../../../../assets/icons';
import {
	RavenButton,
	RavenInputField,
	RavenToolTip,
	toast,
} from '@ravenpay/raven-bank-ui';
import { useNavigate } from 'react-router-dom';
import { logger } from '../../../../../../services/logger';

type SearchURL = {
	get: () => void;
};
const NewPaymentLink = () => {
	const searchParams: URLSearchParams = new URLSearchParams(
		document.location.search
	);

	let paramStep = searchParams.get('step') ?? 1;
	let paramType = searchParams.get('type') ?? '';

	const [step, setStep] = useState<string | null | number>(1);
	const [type, setType] = useState<string | null>('single');

	const navigate = useNavigate();

	useEffect(() => {
		setType(paramType);
		setStep(Number(paramStep));
	}, [paramStep, paramType]);

	function insertUrlParam(key: string, value: any) {
		let searchParams = new URLSearchParams(window.location.search);
		searchParams.set(key, value);
		let newurl =
			window.location.protocol +
			'//' +
			window.location.host +
			window.location.pathname +
			'?' +
			searchParams.toString();
		window.history.pushState({ path: newurl }, '', newurl);
	}

	logger.log(paramStep, paramType);
	return (
		<DashboardLayout>
			{step === 1 && (
				<ContainerLayout style={{ width: '100%', maxWidth: '40%' }}>
					<div className="select-method">
						<h5>Select A Payment Link Type</h5>
						<div className="select-method__select-container">
							<div
								onClick={() => {
									insertUrlParam('type', 'single');
									insertUrlParam('step', 2);
									setStep(2);
								}}
								className="select-container__item"
							>
								<figure>{icons.flash}</figure>
								<span className="item__content">
									<p>Single Charge</p>
									<p>Create a payment link and collect one-time payment </p>
								</span>
							</div>

							<div
								onClick={() => {
									insertUrlParam('type', 'recurring');
									insertUrlParam('step', 2);
									setStep(2);
								}}
								className="select-container__item"
							>
								<figure>{icons.clock_refresh}</figure>
								<span className="item__content">
									<p>Multi Charge</p>
									<p>Create and collect money non-stop with same link</p>
								</span>
							</div>
						</div>
					</div>
				</ContainerLayout>
			)}
			{step === 2 && (
				<ContainerLayout style={{ width: '100%' }}>
					<div className="create-link-form">
						<h5 className="create-link-form__title">
							{type === 'single'
								? 'Single Charge Payment Link'
								: 'Recurring Charge Payment Link'}
						</h5>

						<div className="create-link-form__main">
							<RavenInputField
								label="Payment Title *"
								color="deep-green-dark"
								placeholder="e.g Sanda lili's Shoe"
							/>
							<div className="main__grouped-input">
								<RavenInputField
									color="deep-green-dark"
									type="select"
									placeholder="NGN"
									selectOption={[{ value: 'NGN', label: 'NGN' }]}
									label="Currency"
								/>
								<RavenInputField
									label="Amount *"
									type="number"
									numberPrefix={'₦'}
									thousandFormat
									placeholder="0.00"
									color="deep-green-dark"
								/>
							</div>
							<div className="main__upload-container">
								<div className="upload-container--title">
									<label htmlFor="">Link Thumbnail</label>
									<figure className=" tooltip-hover-wrap">
										{icons.question_circle}
										<RavenToolTip
											color={`black-light`}
											text={`Upload an image that will appear on your payment link page.`}
											position={`top-left`}
										/>
									</figure>
								</div>
								<RavenInputField color="deep-green-light" type="upload" />
							</div>
							<RavenButton
								className="main__act-btn"
								label="Continue"
								onClick={() => setStep(3)}
								color="deep-green-light"
							/>
						</div>
					</div>
				</ContainerLayout>
			)}

			{step === 3 && type === 'single' && (
				<ContainerLayout
					onBack={() => setStep(step - 1)}
					style={{ width: '100%', overflow: 'auto' }}
				>
					<div className="create-link-form">
						<h5 className="create-link-form__title">
							Single Charge Payment Link
						</h5>

						<div className="create-link-form__main">
							<RavenInputField
								label="Link Narration *"
								type="textarea"
								placeholder="Why are you creating the payment link..."
								color="deep-green-dark"
							/>

							<RavenInputField
								label="Custom URL (Optional)"
								type="text"
								placeholder="Why are you creating the payment link..."
								color="deep-green-dark"
							/>
							<RavenInputField
								label="Redirect URL (Optional) )"
								type="text"
								placeholder="Why are you creating the payment link..."
								color="deep-green-dark"
							/>

							<RavenButton
								style={{ marginBottom: '2rem' }}
								className="main__act-btn"
								label="Create Payment Link"
								onClick={() => {
									toast.success(
										'Your single charge payment link hasbeen created succesfully.'
									);
									navigate('/dashboard-payment/payment-links');
								}}
								color="deep-green-light"
							/>
						</div>
					</div>
				</ContainerLayout>
			)}
			{step === 3 && type === 'recurring' && (
				<ContainerLayout
					onBack={() => setStep(step - 1)}
					style={{ width: '100%', overflow: 'auto' }}
				>
					<div className="create-link-form">
						<h5 className="create-link-form__title">
							Recurring Charge Payment Link
						</h5>

						<RavenInputField
							label="Link Expiry Date"
							type="date"
							placeholder="Select a Date"
							color="deep-green-dark"
						/>

						<div className="create-link-form__main">
							<RavenInputField
								label="Link Narration *"
								type="textarea"
								placeholder="Why are you creating the payment link..."
								color="deep-green-dark"
							/>

							<RavenButton
								className="main__act-btn"
								label="Create Payment Link"
								onClick={() => {
									setStep(4);
								}}
								color="deep-green-light"
							/>
						</div>
					</div>
				</ContainerLayout>
			)}

			{step === 4 && type === 'recurring' && (
				<ContainerLayout
					onBack={() => setStep(step - 1)}
					style={{ width: '100%', overflow: 'auto' }}
				>
					<div className="create-link-form">
						<h5 className="create-link-form__title">
							Recurring Charge Payment Link
						</h5>

						<span className="create-link-form__subTitle">
							Additional Details (Optional)
						</span>

						<div className="create-link-form__main">
							<RavenInputField
								label="Custom URL (Optional)"
								type="text"
								placeholder="https://pay.raven.com/your-url"
								color="deep-green-dark"
							/>
							<RavenInputField
								label="Redirect URL (Optional) )"
								type="text"
								placeholder="E.g https://www.myurl.com"
								color="deep-green-dark"
							/>

							<RavenButton
								style={{ marginBottom: '2rem' }}
								className="main__act-btn"
								label="Create Payment Link"
								onClick={() => {
									toast.success(
										'Your single charge payment link hasbeen created succesfully.'
									);
									navigate('/dashboard-payment/payment-links');
								}}
								color="deep-green-light"
							/>
						</div>
					</div>
				</ContainerLayout>
			)}
		</DashboardLayout>
	);
};

export default NewPaymentLink;
