import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../../layout/dashboard/tableLayout';
import {
	RavenButton,
	RavenInputField,
	RavenModal,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import {
	formatDate,
	formatDateTime,
	formatNumWithComma,
	formatNumWithCommaNaira,
	mapHotkey,
	symbol,
} from '../../../../utils/helper/Helper';
import { faker } from '@faker-js/faker';
import { icons } from '../../../../assets/icons';
import Pagination from '../../../../components/common/pagination';
import EmptyScreen from '../../../../components/common/emptyScreen';
import './style/index.css';
import { generateRandomColor } from '../../../../utils/helper/color';
import { useNavigate } from 'react-router-dom';
import { getTransfers } from '../../../../redux/wallet';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import RootState from '../../../../redux/types';
import Skeleton from 'react-loading-skeleton';
import { logger } from '../../../../services/logger';
import useDebounce from '../../../../hooks/UseDebounce';
import { omniSearch } from '../../../../redux/transaction';
import Copy from '../../../../components/common/copyCheck';
import SmartFilter from '../../../../components/common/smartFilter';
import ExportModal from '../../../../components/common/exportModal';
import TransactionModal from '../../../../components/common/transactionModal';
import MobileTableCard from '../../../../components/common/table/mobile/MobileTable';
import { bankboxAPI } from '../../../../redux/bankbox';
const BankBoxTransactions = () => {
	const [demo, setDemo] = useState(true);
	const [showExport, setShowExport] = useState<boolean>(false);
	const [activeCurrency, setActiveCurrency] = useState('ngn');
	const [currentPage, setCurrentPage] = useState(1);
	const [activeFilter, setActiveFilter] = useState<string>('');
	const [showConfiguration, setShowConfiguration] = useState<boolean>(false);
	const [view, onView] = useState<any>({
		show: false,
		view: '',
	});
	const [searchQuery, setSearchQuery] = useState('');

	useState<string>('business-account');
	const [boot, onBoot] = useState(false);
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { transfers } = useSelector((state: RootState) => state.wallet);

	const { all_bankbox_trx, loading } = useSelector(
		(state: RootState) => state.bankbox
	);

	const { searchLoading } = useSelector(
		(state: RootState) => state.transaction
	);

	function isFilter(value: string) {
		if (value === activeFilter) return true;
		else return false;
	}

	useEffect(() => {
		if (
			typeof dateFilter.from === 'object' ||
			typeof dateFilter.to === 'object'
		) {
			setDateFilter({
				to: '',
				from: '',
			});
		}
	}, [activeFilter]);

	useEffect(() => {
		if (typeof activeFilter !== undefined) {
			setActiveFilter('');
		}
	}, [dateFilter.to || dateFilter.from]);

	function formatStatus(status: string | number, refunded?: number) {
		let s;

		if (status !== '00') {
			s = 'failed';
		}

		if (status === '00') {
			s = 'successful';
		}
		return (
			<div className={`transaction-status ${s && s?.toLowerCase()}`}>
				<p>{s}</p>
			</div>
		);
	}

	//fetch transfers
	useEffect(() => {
		if (!boot && transfers.transfers) {
			onBoot(true);
		}
		dispatch(
			bankboxAPI.getAllTransactions({
				page: currentPage,
				per_page: 20,
			})
		);
	}, [currentPage]);

	function returnInitial(name: string) {
		const i = name.split(' ');

		if (typeof length !== 'undefined') {
			if (i?.length === 1) {
				return i[0]?.slice(0, 1)?.toUpperCase();
			} else return i[0]?.slice(0, 1)?.toUpperCase();
		}
		{
			return '';
		}
	}

	const debounceSearchTerm = useDebounce(searchQuery, 1000);
	useEffect(() => {
		let isMount = true;
		logger.log('searching');

		if (isMount && debounceSearchTerm?.length > 2) {
			dispatch(omniSearch(searchQuery));
		}

		if (isMount && debounceSearchTerm?.length < 1) {
			dispatch(
				getTransfers({
					page: currentPage,
					limit: 20,
				})
			);
		}

		return () => {
			isMount = false;
		};
	}, [debounceSearchTerm]);
	let trx = all_bankbox_trx?.data;

	function convertToHumanReadable(inputString: string) {
		// Split the input string by underscores
		const words = inputString.split('_');

		// Capitalize the first letter of each word and join them with spaces
		const humanReadableString = words
			.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');

		return humanReadableString;
	}

	const searchParams = new URLSearchParams(window.location.search);
	const terminalId = searchParams.get('id');

	async function terminalTrx() {
		if (terminalId && terminalId?.length > 0) {
			const resp = await dispatch(
				bankboxAPI.getSingleTransaction({ id: terminalId })
			);
		}
	}
	React.useEffect(() => {
		terminalTrx();
	}, [terminalId]);

	return (
		<>
			<DashboardLayout>
				<PageLayout
					pageSubtitle="All Transaction made on the bankbox"
					pageTitle="BankBox Transaction"
					topRightContent={<></>}
				>
					<SmartFilter
						defaultFilterBy="status"
						filters={[
							{
								label: 'Status',
								filterBy: 'status',
								options: [
									{
										label: 'Pending',
										value: 'pending',
									},
									{
										label: 'Failed',
										value: 'failed',
									},
									{
										label: 'Successful',
										value: 'succesful',
									},
								],
							},
						]}
						onExport={() => setShowExport(true)}
						page="bankbox_transactions"
					/>

					<div className="content__main--top-bar">
						<div className="top-bar__title"></div>
						<Pagination
							className="top-bar__pagination"
							currentPage={currentPage}
							itemsPerPage={all_bankbox_trx?.perPage ?? 0}
							totalItems={all_bankbox_trx?.total ?? 0}
							onPageChange={function (page: number): void {
								setCurrentPage(page);
							}}
						/>
					</div>

					<div className="content__main">
						<div className="main__wrap">
							{loading || searchLoading || trx?.length === 0 ? (
								<EmptyScreen
									loading={loading || searchLoading}
									loadingText={
										searchLoading ? 'Searching...' : 'Loading Transactions...'
									}
									title="No Transaction Found"
									subTitle="You don't have any record yet,your new bankbox transactions will be displayed here."
								/>
							) : (
								<>
									<div className="transaction__table non-mobile">
										<RavenTable
											action={false}
											className="table__main"
											headerList={[
												'Email',
												'Type',
												'Amount',
												'Details',
												'Status',
												'Transaction Date',
											]}
										>
											{trx?.map((chi, idx: number) => {
												const meta = JSON.parse(chi.meta_data);
												const pdon = JSON.parse(chi.pdon_meta);

												return (
													<React.Fragment key={idx}>
														<RavenTableRow
															onRowClick={() =>
																onView({
																	show: true,
																	content: {
																		...chi,
																		meta_data: meta,
																		pdon_meta: pdon,
																	},
																})
															}
															className="table__main"
															five={
																loading ? (
																	<Skeleton
																		style={{ borderRadius: '10px' }}
																		width={80}
																		height={20}
																	/>
																) : (
																	formatStatus(meta?.response_code)
																)
															}
															six={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	formatDateTime(chi?.created_at)
																)
															}
															two={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	convertToHumanReadable(chi?.type)
																)
															}
															one={
																<div className="account_name--transfer">
																	{loading ? (
																		<Skeleton circle width={35} height={35} />
																	) : (
																		<span
																			className="account_name__span"
																			style={{
																				backgroundColor:
																					generateRandomColor('#e9f5ff'),
																			}}
																		>
																			<p>
																				{returnInitial(
																					pdon?.app_profile
																						?.poseidon_business_email || '--'
																				)}
																			</p>
																		</span>
																	)}

																	{loading ? (
																		<Skeleton width={230} height={20} />
																	) : (
																		<div className="bankbox-trx-tbl-name">
																			<p className="text">
																				{' '}
																				{pdon?.app_profile
																					?.poseidon_business_email || '--'}
																			</p>
																			{chi.type === 'transfer_in' ? (
																				<p>
																					{meta?.source?.account_number} •{' '}
																					{meta?.source?.first_name +
																						' ' +
																						meta?.source?.last_name}
																				</p>
																			) : (
																				<p>
																					{chi?.serial} •{' '}
																					{
																						pdon?.terminal_profile
																							?.poseidon_business_name
																					}
																				</p>
																			)}
																		</div>
																	)}
																</div>
															}
															four={
																loading ? (
																	<Skeleton width={80} height={20} />
																) : (
																	<div className="type-wrap">
																		<span>{meta?.card_pan ?? '--'}</span>
																	</div>
																)
															}
															three={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	symbol('ngn') +
																	formatNumWithComma(chi?.amount, 'ngn')
																)
															}
														/>
													</React.Fragment>
												);
											})}
										</RavenTable>
									</div>

									<div className="atlas-mobile-table-wrap">
										{trx?.map((chi: any, idx: React.Key | null | undefined) => {
											const meta = JSON.parse(chi.meta_data);
											const pdon = JSON.parse(chi.pdon_meta);

											// const { first_name, last_name, bank } = meta_data;

											// logger.log(meta_data);
											return (
												<MobileTableCard
													title={
														pdon?.terminal_profile?.poseidon_business_name ??
														'--'
													}
													text={formatDateTime(chi.created_at)}
													amount={
														symbol('ngn') +
														formatNumWithComma(chi.amount, 'ngn')
													}
													img={undefined}
													svg={undefined}
													avatar={''}
													amountColor={'#014345'}
													type={chi.direction}
													showImg={true}
													loading={loading}
													onRowClick={() =>
														onView({
															show: true,
															content: chi,
														})
													}
													textTwo={''}
												/>
											);
										})}
									</div>
								</>
							)}
						</div>
					</div>
				</PageLayout>
			</DashboardLayout>

			<TransactionModal
				onClose={() =>
					onView({
						show: false,
						content: {
							type: '',
							bank: '',
							description: '',
							amount: '',
							date: '',
							session_id: '',
							account_number: '',
							email: '',
							created_at: function (created_at: any): string {
								throw new Error('Function not implemented.');
							},
							updated_at: function (updated_at: any): string {
								throw new Error('Function not implemented.');
							},
						},
						on: false,
					})
				}
				visible={view.show}
				direction={view?.content?.direction}
				title={'Transactions Details'}
				amount={view?.content?.amount as unknown as number}
				onClick={() => {}}
				meta={JSON.stringify({
					...view?.content?.meta_data,
					...view?.content?.pdon_meta,
				})}
				content={
					view?.content?.type === 'transfer_in'
						? [
								{
									label: 'Narration',
									value: view?.content?.meta_data?.source?.narration || '--',
								},
								{
									label: 'Amount',
									value:
										symbol('ngn') +
										formatNumWithComma(view?.content?.amount, 'ngn'),
								},
								{
									label: 'Bank',
									value: view?.content?.meta_data?.source?.bank || '--',
								},
								{
									label: 'Account Name',
									value:
										view?.content?.meta_data?.source?.first_name +
											' ' +
											view?.content?.meta_data?.source?.last_name ||
										'--' ||
										'--',
								},
								{
									label: 'Account Number',
									value:
										view?.content?.meta_data?.source?.account_number || '--',
								},
								{
									label: 'Merchant Reference',
									value: view?.content?.reference || '--',
									copy: true,
								},
								{
									label: 'Terminal ID',
									value: view?.content?.t_id || '--',
									copy: true,
									trim: true,
								},
								{
									label: 'Session ID',
									value: view?.content?.meta_data?.session_id || '--',
									copy: true,
									trim: true,
								},
								{
									label: 'Fee',
									value:
										symbol('ngn') +
										formatNumWithComma(view?.content?.fee, 'ngn'),
								},
								{
									label: 'Created At',
									value: formatDateTime(
										view?.content?.created_at as unknown as string
									),
								},
						  ]
						: [
								{
									label: 'Serial',
									value: view?.content?.serial || '--',
								},
								{
									label: 'Amount',
									value:
										symbol('ngn') +
										formatNumWithComma(view?.content?.amount, 'ngn'),
								},
								{
									label: 'Issuing App',
									value:
										view?.content?.pdon_meta?.terminal_profile?.issuing_app ||
										'--',
								},
								{
									label: 'Business Email',
									value:
										view?.content?.pdon_meta?.app_profile
											?.poseidon_business_email || '--',
								},
								{
									label: 'Business Name',
									value:
										view?.content?.pdon_meta?.terminal_profile
											?.poseidon_business_name || '--',
								},
								{
									label: 'Serial Number',
									value:
										view?.content?.pdon_meta?.terminal_profile
											?.poseidon_serial_number || '--',
								},
								// {
								// 	label: 'Email',
								// 	value: view?.content?.email || '--',
								// },
								{
									label: 'Merchant Reference',
									value: view?.content?.reference || '--',
									copy: true,
								},
								{
									label: 'Terminal ID',
									value: view?.content?.t_id || '--',
									copy: true,
									trim: true,
								},
								{
									label: 'BankBox Label',
									value:
										view?.content?.pdon_meta?.terminal_profile
											?.poseidon_label || '--',
								},
								{
									label: 'Fee',
									value:
										symbol('ngn') +
										formatNumWithComma(view?.content?.fee, 'ngn'),
								},
								{
									label: 'Created At',
									value: formatDateTime(
										view?.content?.created_at as unknown as string
									),
								},
						  ]
				}
			/>
			<ExportModal
				onClose={() => setShowExport(false)}
				visible={showExport}
				page="bankbox_transactions"
			/>
		</>
	);
};

export default BankBoxTransactions;
