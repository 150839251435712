import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import { toast } from '@ravenpay/raven-bank-ui';
import {
	AirtimePayload,
	BetPaymentPayload,
	BillsState,
	CableSubscribePayload,
	DataPlanPayload,
	DataPlanResponse,
	ElectricityPaymentPayload,
	ValidateBetAccountPayload,
} from './types';
import { logger } from '../services/logger';
import { formatSearch } from './transaction';

// Betting Slices

export const getBetTypes = createAsyncThunk(
	'/bet-types',
	async (payload, thunkAPI) => {
		try {
			const data = await axios.get(`/web/bet_types`);

			logger.log(data, 'BET TYPES');
			if (data?.data?.status === 'fail') {
				if (typeof data.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setBetTypes(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const validateBetAccount = createAsyncThunk(
	'/web/bet_account/validate',
	async (payload: ValidateBetAccountPayload, thunkAPI) => {
		try {
			const data = await axios.post(`/web/bet_account/validate`, payload);

			// if (data?.data?.status === 'fail') {
			// 	if (typeof data?.data?.data === 'string') {
			// 		toast.error(data?.data.data, {
			// 			position: 'top-right',
			// 		});
			// 	} else {
			// 		toast.error(data?.data.message, {
			// 			position: 'top-right',
			// 		});
			// 	}

			// 	return thunkAPI.rejectWithValue(data);
			// }
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const payForBetting = createAsyncThunk(
	'/web/bet_account/pay',
	async (payload: BetPaymentPayload, thunkAPI) => {
		try {
			const data = await axios.post(`/web/bet_account/pay`, payload);

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getBettingRecords = createAsyncThunk(
	'/bet-records',
	async (
		payload: { currentPage: string | number; limit: number },
		thunkAPI
	) => {
		try {
			const data = await axios.get(
				`web/bet_records?page=${payload.currentPage}&per_page=${payload?.limit}`
			);

			if (data?.data?.status === 'fail') {
				// if (typeof data?.data?.data === 'string') {
				// 	toast.error(data?.data?.data);
				// } else {
				// 	toast.error(data?.data.message, {
				// 		position: 'top-right',
				// 	});
				// }

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setBettingRecords(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getBetRecord = createAsyncThunk(
	'/bet-record',
	async (payload: { currentPage: string; limit: string }, thunkAPI) => {
		try {
			const data = await axios.get(
				`web/bet_records?page=${payload.currentPage}&per_page=${payload?.limit}`
			);

			if (data?.data?.status === 'fail') {
				toast.error(data?.data.message, {
					position: 'top-right',
				});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setBetRecord(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

// Airtime Slices

export const getAirtimeRecords = createAsyncThunk(
	'/airtime-record',
	async (payload: { currentPage: number; limit: number }, thunkAPI) => {
		try {
			const data = await axios.get(
				`/web/airtime/records?page=${payload.currentPage}&per_page=${payload?.limit}`
			);

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					// toast.error(data?.data.data, {
					// 	position: 'top-right',
					// });
				} else {
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setAirtimeRecords(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getAirtimeRecord = createAsyncThunk(
	'/bet-types',
	async (payload: { phone: string | number }, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`airtime/record?phone_number=${payload.phone}`
			);

			if (data?.data?.status === 'fail') {
				// toast.error(data?.data.message, {
				// 	position: 'top-right',
				// });
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setAirtimeRecord(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const buyAirtime = createAsyncThunk(
	'/web/airtime/recharge',
	async (payload: AirtimePayload, thunkAPI) => {
		try {
			const { data } = await axios.post(`/web/airtime/recharge`, payload);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

// Data Plan Slices

export const getDataRecords = createAsyncThunk(
	'/airtime-record',
	async (payload: { currentPage: number; limit: number }, thunkAPI) => {
		try {
			const data = await axios.get(
				`/web/data/records?page=${payload.currentPage}&per_page=${payload?.limit}`
			);
			logger.log(data?.data, 'data recird');

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					// toast.error(data?.data.data, {
					// 	position: 'top-right',
					// });
				} else {
					// toast.error(data?.data.message, {
					// 	position: 'top-right',
					// });
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setDataRecords(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getDataRecord = createAsyncThunk(
	'/bet-types',
	async (payload: { phone: string | number }, thunkAPI) => {
		try {
			const data = await axios.get(`data/record?phone_number=${payload.phone}`);

			if (data?.data?.status === 'fail') {
				// toast.error(data?.data.message, {
				// 	position: 'top-right',
				// });
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setDataRecord(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const purchaseData = createAsyncThunk(
	'/web/data/recharge',
	async (payload: DataPlanPayload, thunkAPI) => {
		try {
			const data = await axios.post(`/web/data_plans/recharge`, payload);

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data.data?.data);
				} else {
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				}
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data.data.message, {
					position: 'top-right',
				});

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getDataPlans = createAsyncThunk(
	'/data-plans',
	async (payload, thunkAPI) => {
		try {
			const data = await axios.get(`web/data_plans`);
			logger.log(data, 'd');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data.data?.data);
				} else {
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setDataPlans(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

// Electricity Slices

export const validateMeterNumber = createAsyncThunk(
	'/web/electricity/validate',
	async (
		payload: {
			provider: string;
			meter_no: string | number;
			meter_type: string;
		},
		thunkAPI
	) => {
		try {
			const data = await axios.post(`/web/electricity/validate_meter`, payload);

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getElectricityDiscos = createAsyncThunk(
	'/airtime-record',
	async (payload, thunkAPI) => {
		try {
			const data = await axios.get(`/web/electricity/discos`);

			if (data?.data?.status === 'fail') {
				toast.error(data?.data.message, {
					position: 'top-right',
				});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setElectricityDiscos(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getElectricityRecords = createAsyncThunk(
	'/airtime-record',
	async (payload: { currentPage: number; limit: number }, thunkAPI) => {
		try {
			const data = await axios.get(
				`/web/electricity/records?page=${payload.currentPage}&per_page=${payload?.limit}`
			);

			logger.log(data);

			if (data?.data?.status === 'fail') {
				// if (typeof data?.data?.data === 'string') {
				// 	toast.error(data.data?.data);
				// } else {
				// 	toast.error(data?.data.message, {
				// 		position: 'top-right',
				// 	});
				// }
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setElectricityRecords(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const payForElectricity = createAsyncThunk(
	'/web/electricity/pay',
	async (payload: ElectricityPaymentPayload, thunkAPI) => {
		try {
			const data = await axios.post(`/web/electricity/pay`, payload);

			// logger.log(data, 'pau');
			if (data?.data?.status === 'fail') {
				if (typeof data.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

// Cable Slices

export const validateCableAccount = createAsyncThunk(
	'/web/cable/validate_account',
	async (
		payload: {
			provider: string;
			smart_card_number: string | number;
		},
		thunkAPI
	) => {
		try {
			const data = await axios.post(`/web/cable/validate_account`, payload);

			if (data?.data?.status === 'fail') {
				if (typeof data.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
					return thunkAPI.rejectWithValue(data);
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getCablePlans = createAsyncThunk(
	'web/cable/providers',
	async (payload: { provider: string }, thunkAPI) => {
		try {
			const data = await axios.get(
				`/web/cable/providers?provider=${payload.provider}`
			);
			logger.log(data, 'd');

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data?.data);
				} else {
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				}
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setCablePlans(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getCableRecords = createAsyncThunk(
	'/cable-records',
	async (payload: { currentPage: number; limit: number }, thunkAPI) => {
		try {
			const data = await axios.get(
				`/web/cable/records?page=${payload.currentPage}&per_page=${payload?.limit}`
			);

			// if (data?.data?.status === 'fail') {
			// 	if (typeof data?.data?.data === 'string') {
			// 		toast.error(data?.data.data, {
			// 			position: 'top-right',
			// 		});
			// 	} else
			// 		toast.error(data?.data.message, {
			// 			position: 'top-right',
			// 		});
			// 	return thunkAPI.rejectWithValue(data);
			// }
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setCableRecords(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const subscribeCable = createAsyncThunk(
	'/web/cable/subscribe',
	async (payload: CableSubscribePayload, thunkAPI) => {
		try {
			const data = await axios.post(`/web/cable/subscribe`, payload);

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const searchBills = createAsyncThunk(
	'/search bills/q',
	async (payload: { q: string; type: string }, thunkAPI) => {
		try {
			const data = await axios.get(
				`/web/bills/search?type=${payload?.type}&search=${payload?.q}`
			);

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });

				if (payload.type) {
					thunkAPI.dispatch(
						setCableRecords(
							formatSearch(data?.data?.data?.data, payload.type + '_records')
						)
					);
					thunkAPI.dispatch(
						setDataRecords(
							formatSearch(data?.data?.data?.data, payload.type + '_records')
						)
					);
					thunkAPI.dispatch(
						setAirtimeRecords(
							formatSearch(data?.data?.data?.data, payload.type + '_records')
						)
					);
					thunkAPI.dispatch(
						setBettingRecords(
							formatSearch(data?.data?.data?.data, payload.type + '_records')
						)
					);
					thunkAPI.dispatch(
						setElectricityRecords(
							formatSearch(data?.data?.data?.data, payload.type + '_records')
						)
					);
				}
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);
const dataRecordInitialState = {
	id: 0,
	merchant_email: '',
	phone_number: '',
	reference: '',
	status: '',
	refunded: 0,
	network: '',
	request_id: null,
	amount: 0,
	amount_charged: 0,
	currency: '',
	provider: '',
	data_plan: '',
	merchant_reference: '',
	created_at: '',
};

const airtimeRecordInitialState = {
	id: 0,
	merchant_email: '',
	phone_number: '',
	reference: '',
	status: '',
	refunded: 0,
	network: '',
	request_id: '',
	amount: 0,
	amount_charged: 0,
	currency: '',
	provider: '',
	merchant_reference: '',
	created_at: '',
};
const initialPaginationState = {
	perPage: 0,
	currentPage: 0,
	nextPage: null,
	prevPage: null,
	totalPages: 0,
	total: 0,
};
const initialState: BillsState = {
	loading: false,
	isAuth: false,
	bettingRecords: {
		bet_records: [],
		pagination: initialPaginationState,
	},
	betRecord: {},
	betTypes: {},
	airtimeRecord: airtimeRecordInitialState,
	airtimeRecords: {
		airtime_records: [],
		pagination: initialPaginationState,
	},
	dataRecord: dataRecordInitialState,
	dataRecords: {
		data_records: [],
		pagination: initialPaginationState,
	},
	cableRecords: {
		cable_records: [],
		pagination: initialPaginationState,
	},
	dataPlans: [],
	electricityDiscos: [],
	electricityRecords: {
		electricity_records: [],
		pagination: initialPaginationState,
	},
	cablePlans: [],
	// initialize other state properties
};

export const billsSlice = createSlice({
	name: 'auth',
	initialState,

	reducers: {
		setBetTypes: (state, action) => {
			state.betTypes = action.payload;
			state.isAuth = true;
		},
		setBettingRecords: (state, action) => {
			state.bettingRecords = action.payload;
			state.isAuth = true;
		},
		setBetRecord: (state, action) => {
			state.betRecord = action.payload;
			state.isAuth = true;
		},
		setAirtimeRecord: (state, action) => {
			state.airtimeRecord = action.payload;
			state.isAuth = true;
		},
		setAirtimeRecords: (state, action) => {
			state.airtimeRecords = action.payload;
			state.isAuth = true;
		},
		setDataRecord: (state, action) => {
			state.dataRecord = action.payload;
			state.isAuth = true;
		},
		setCableRecords: (state, action) => {
			state.cableRecords = action.payload;
			state.isAuth = true;
		},
		setDataRecords: (state, action) => {
			state.dataRecords = action.payload;
			state.isAuth = true;
		},
		setDataPlans: (state, action) => {
			state.dataPlans = action.payload;
			state.isAuth = true;
		},

		setCablePlans: (state, action) => {
			state.cablePlans = action.payload;
			state.isAuth = true;
		},

		setElectricityDiscos: (state, action) => {
			state.electricityDiscos = action.payload;
			state.isAuth = true;
		},
		setElectricityRecords: (state, action) => {
			state.electricityRecords = action.payload;
			state.isAuth = true;
		},
	},

	extraReducers: (builder) => {
		builder
			// Handle pending actions
			.addCase(getBetTypes.pending, (state) => {
				state.loading = true;
			})
			.addCase(buyAirtime.pending, (state) => {
				state.loading = true;
			})
			.addCase(getDataPlans.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAirtimeRecords.pending, (state) => {
				state.loading = true;
			})
			.addCase(getCableRecords.pending, (state) => {
				state.loading = true;
			})
			.addCase(payForElectricity.pending, (state) => {
				state.loading = true;
			})
			.addCase(purchaseData.pending, (state) => {
				state.loading = true;
			})
			.addCase(payForBetting.pending, (state) => {
				state.loading = true;
			})
			.addCase(subscribeCable.pending, (state) => {
				state.loading = true;
			})
			.addCase(searchBills.pending, (state) => {
				state.loading = true;
			})

			// Handle fulfilled actions
			.addCase(getBetTypes.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(buyAirtime.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getDataPlans.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getAirtimeRecords.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getCableRecords.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(payForElectricity.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(purchaseData.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(payForBetting.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(subscribeCable.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(searchBills.fulfilled, (state) => {
				state.loading = false;
			})

			// Handle rejected actions
			.addCase(getBetTypes.rejected, (state) => {
				return initialState;
			})
			.addCase(buyAirtime.rejected, (state) => {
				return initialState;
			})
			.addCase(getDataPlans.rejected, (state) => {
				return initialState;
			})
			.addCase(getAirtimeRecords.rejected, (state) => {
				return initialState;
			})
			.addCase(payForElectricity.rejected, (state) => {
				return initialState;
			})
			.addCase(payForBetting.rejected, (state) => {
				state.loading = false;
			})
			.addCase(purchaseData.rejected, (state) => {
				return initialState;
			})
			.addCase(subscribeCable.rejected, (state) => {
				return initialState;
			})
			.addCase(getCableRecords.rejected, (state) => {
				return initialState;
			})
			.addCase(searchBills.rejected, (state) => {
				return initialState;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	setBetTypes,
	setBettingRecords,
	setBetRecord,
	setDataPlans,
	setAirtimeRecord,
	setAirtimeRecords,
	setDataRecord,
	setDataRecords,
	setElectricityDiscos,
	setCablePlans,
	setElectricityRecords,
	setCableRecords,
} = billsSlice.actions;

export default billsSlice.reducer;
