import { RavenButton, RavenToggleSlide } from '@ravenpay/raven-bank-ui';
import React, { useEffect, useState } from 'react';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
// import "../../../../styles/fragments/PreviewDetailsCommonBox.css";

import './styles/index.css';
import { brands, icons } from '../../../../../../../assets/icons';
import {
	IconVault,
	formatDate,
	formatDateTime,
	formatNumWithComma,
	formatNumWithCommaNaira,
	symbol,
} from '../../../../../../../utils/helper/Helper';
import { logger } from '../../../../../../../services/logger';

interface PreviewDetails {
	onBtnClick?: (event?: any) => any;
	multi?: boolean;
	content?: any;
	loading?: boolean;
}

const PreviewDetailsBox = ({
	content,
	multi,
	loading,
	onBtnClick,
}: PreviewDetails) => {
	const [currentItem, setCurrentItem] = useState<any>({});
	const [currentItemIndex, setCurrentItemIndex] = useState(0);

	useEffect(() => {
		if (multi) {
			setCurrentItem(content[currentItemIndex]);
		} else {
			setCurrentItem(content);
		}
	}, [multi ? currentItemIndex : '']);

	function handleNext() {
		if (currentItemIndex === content.length - 1) {
			setCurrentItemIndex(0);
		} else {
			setCurrentItemIndex(currentItemIndex + 1);
		}
	}

	function handlePrev() {
		if (currentItemIndex === 0) {
			setCurrentItemIndex(content.length - 1);
		} else {
			setCurrentItemIndex(currentItemIndex - 1);
		}
	}

	return (
		<div className="preview-transfer">
			<div className="preview-transfer__logo">
				<figure className="logo">
					<img src={IconVault(brands.raven_with_arrow)} alt="" />
				</figure>
			</div>

			<div className="preview-transfer-main">
				<div className="preview-transfer__header">
					<h5>
						{symbol('ngn') + formatNumWithComma(currentItem?.amount, 'ngn')}
					</h5>
					<small>{formatDateTime(new Date())}</small>
				</div>

				{multi && (
					<div className="preview-transfer__switch">
						<figure onClick={handlePrev} className="pages__current">
							{icons.chevron_right}
						</figure>
						<div className="switch__pages">
							<p>{currentItemIndex + 1}/</p>
							<p>{content.length}</p>
						</div>
						<figure onClick={handleNext} className="pages__total">
							{icons.chevron_right}
						</figure>
					</div>
				)}

				<div className="preview-transfer__table">
					<div className="table__item">
						<p>To</p>
						<p>{content?.account_name}</p>
					</div>
					<div className="table__item">
						<p>Bank</p>
						<p>{content?.bank}</p>
					</div>

					<div className="table__item">
						<p>Account No</p>
						<p>{content?.account_number}</p>
					</div>

					<div className="table__item">
						<p>Amount</p>
						<p>{symbol('ngn') + formatNumWithComma(content?.amount, 'ngn')}</p>
					</div>

					<div className="table__item">
						<p>Narration</p>
						<p>{content?.narration}</p>
					</div>

					<div className="table__item">
						<p>Fee</p>
						<p>₦10.00</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PreviewDetailsBox;
