import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../layout/dashboard/tableLayout';
import {
	RavenButton,
	RavenInputField,
	RavenModal,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import {
	formatDateTime,
	formatNumWithComma,
	mapHotkey,
	symbol,
} from '../../../utils/helper/Helper';
import { icons } from '../../../assets/icons';
import Pagination from '../../../components/common/pagination';
import EmptyScreen from '../../../components/common/emptyScreen';
import './style/index.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RootState, {
	CreateVirtualAccountResponse,
	ResponseStructure,
} from '../../../redux/types';
import {
	createVirtualAccount,
	getVirtualAccounts,
} from '../../../redux/wallet';
import Skeleton from 'react-loading-skeleton';
import { logger } from '../../../services/logger';
import SmartFilter from '../../../components/common/smartFilter';
import ExportModal from '../../../components/common/exportModal';
import MobileTableCard from '../../../components/common/table/mobile/MobileTable';
const VirtualAccounts = () => {
	const [demo, setDemo] = useState(true);
	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [activeCurrency, setActiveCurrency] = useState('ngn');
	const [currentPage, setCurrentPage] = useState(1);
	const [activeFilter, setActiveFilter] = useState<string>('');
	const [createModal, setCreateModal] = useState(false);
	const [formData, setFormData] = useState({
		account_name: '',
		email: '',
		firstname: '',
		lastname: '',
		phone: '',
	});
	const [showExportModal, setShowExportModal] = useState<boolean>(false);

	const [boot, onBoot] = useState(false);
	useState<string>('business-account');
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { virtualAccounts, loading } = useSelector(
		(state: RootState) => state.wallet
	);

	function isFilter(value: string) {
		if (value === activeFilter) return true;
		else return false;
	}

	useEffect(() => {
		if (
			typeof dateFilter.from === 'object' ||
			typeof dateFilter.to === 'object'
		) {
			setDateFilter({
				to: '',
				from: '',
			});
		}
	}, [activeFilter]);

	useEffect(() => {
		if (typeof activeFilter !== undefined) {
			setActiveFilter('');
		}
	}, [dateFilter.to || dateFilter.from]);

	function formatStatus(status: number) {
		const s = status === 1 ? 'permanent' : 'temporal';
		return (
			<div className={`transaction-status ${s?.toLowerCase() ?? ''}`}>
				<p>{s}</p>
			</div>
		);
	}

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...formData, [name]: value };
		setFormData(obj);
	};

	useEffect(() => {
		if (!boot && virtualAccounts.virtual_accounts) {
			onBoot(true);
		}
		dispatch(
			getVirtualAccounts({
				perPage: 20,
				page: currentPage,
			})
		);
	}, [currentPage]);

	const vAccounts = virtualAccounts?.virtual_accounts;

	type GenerateAccountPayload = {
		payload: ResponseStructure<CreateVirtualAccountResponse>;
	};

	async function generateVirtualAccount() {
		const resp = (await dispatch(
			createVirtualAccount({
				first_name: formData?.firstname,
				last_name: formData?.lastname,
				phone: formData?.phone,
				email: formData?.email,
			})
		)) as GenerateAccountPayload;

		if ((await resp?.payload?.status) === 'success') {
			setCreateModal(false);
		}
	}

	interface VirtualAccounts {
		cust_email: any;
		created_at: string | Date;
		status: number;
		bank:
			| string
			| number
			| boolean
			| React.ReactElement<any, string | React.JSXElementConstructor<any>>
			| React.ReactFragment
			| React.ReactPortal
			| null
			| undefined;
		account_number:
			| string
			| number
			| boolean
			| React.ReactElement<any, string | React.JSXElementConstructor<any>>
			| React.ReactFragment
			| React.ReactPortal
			| null
			| undefined;
		account_name: any;
		first_name: string;
		last_name: string;
	}

	return (
		<>
			<DashboardLayout>
				<PageLayout
					pageSubtitle="Manage all your virtual accounts."
					pageTitle="Virtual Accounts"
					topRightContent={
						<RavenButton
							onClick={() => setCreateModal(true)}
							color="deep-green-light"
							size="small"
						>
							<p>New Account</p>
							<figure className="non-mobile">{icons.plus_circle_white}</figure>
							<figure className="non-desktop">{icons.plus}</figure>
						</RavenButton>
					}
				>
					<SmartFilter
						onExport={() => setShowExportModal(true)}
						page="accounts"
					/>
					<div className="content__main">
						<div
							className={`main__active-filters ${
								(activeFilter ||
									typeof dateFilter.to === 'object' ||
									typeof dateFilter.from === 'object') &&
								'main__active-filters--show'
							}`}
						>
							<span className="active-filters__item">
								<p>
									<b>Date:</b>
									{typeof dateFilter.to === 'object' ||
									typeof dateFilter.from === 'object'
										? formatDateTime(dateFilter.from).split(',')[0] +
										  ' - ' +
										  formatDateTime(dateFilter.to).split(',')[0]
										: activeFilter}
								</p>
								<figure>{icons.x_circle}</figure>
							</span>
							<span
								onClick={() => {
									setDateFilter({
										to: '',
										from: '',
									});
									setActiveFilter('');
								}}
								className="active-filters__clear-item"
							>
								<p>Clear All</p>
							</span>
						</div>
						<div className="content__main--top-bar">
							<div className="top-bar__title">
								{/* <h5>{activeCurrency.toUpperCase()} Settlement History</h5> */}
								{/* <p className={`${demo && 'active'}`}>{`${faker.number.bigInt({
										min: '50',
										max: '700',
									})}`}</p> */}
							</div>
							<Pagination
								className="top-bar__pagination"
								currentPage={currentPage}
								itemsPerPage={10}
								totalItems={virtualAccounts?.pagination?.total}
								onPageChange={function (page: number): void {
									setCurrentPage(page);
								}}
							/>
						</div>

						<div className="main__wrap">
							{loading || vAccounts?.length === 0 ? (
								<EmptyScreen
									loading={loading}
									title="No Virtual Account Found."
									subTitle="Once you create a new virtual account it will appear here."
									button
									buttonLabel="Start Configuration"
									btnIcon={false}
								/>
							) : (
								<>
									<div className="transaction__table non-mobile">
										<RavenTable
											action={false}
											className="table__main"
											headerList={[
												'CUSTOMER NAME',
												'ACCOUNT NAME',
												'ACCOUNT NO.',
												'BANK',
												'STATUS',
												'DATE CREATED',
												'  ',
											]}
										>
											{vAccounts?.map((chi: VirtualAccounts, idx: number) => {
												return (
													<React.Fragment key={idx}>
														<RavenTableRow
															className="table__main"
															seven={
																loading ? (
																	<Skeleton circle width={35} height={35} />
																) : (
																	<span
																		onClick={() =>
																			navigate(
																				`/dashboard-virtual-account/customer?email=${chi?.cust_email}`
																			)
																		}
																	>
																		{icons.forward}
																	</span>
																)
															}
															six={
																loading ? (
																	<Skeleton width={130} height={20} />
																) : (
																	formatDateTime(chi.created_at)
																)
															}
															five={
																loading ? (
																	<Skeleton
																		style={{ borderRadius: '7rem' }}
																		width={80}
																		height={20}
																	/>
																) : (
																	formatStatus(chi.status)
																)
															}
															four={
																loading ? (
																	<Skeleton width={70} height={20} />
																) : (
																	<div className="status-type-wrap ">
																		<span>{chi?.bank}</span>
																	</div>
																)
															}
															three={
																loading ? (
																	<Skeleton width={120} height={20} />
																) : (
																	<div className="type-wrap">
																		<span>{chi?.account_number}</span>
																	</div>
																)
															}
															two={
																loading ? (
																	<Skeleton width={180} height={20} />
																) : (
																	`${chi.account_name}`
																)
															}
															one={
																loading ? (
																	<Skeleton width={140} height={20} />
																) : (
																	<div className="account_name">
																		<p className="text">
																			{' '}
																			{chi?.first_name + ' ' + chi?.last_name}
																		</p>
																	</div>
																)
															}
														/>
													</React.Fragment>
												);
											})}
										</RavenTable>
									</div>

									<div className="atlas-mobile-table-wrap">
										{vAccounts?.map(
											(
												chi: VirtualAccounts,
												idx: React.Key | null | undefined
											) => {
												// const { first_name, last_name, bank } = meta_data;

												// logger.log(meta_data);
												return (
													<MobileTableCard
														title={chi?.account_name}
														text={formatDateTime(chi.created_at)}
														amount={
															formatStatus(chi.status) as unknown as string
														}
														img={undefined}
														svg={undefined}
														avatar={''}
														amountColor={'#014345'}
														type={''}
														showImg={true}
														loading={loading}
														onRowClick={() =>
															navigate(
																`/dashboard-virtual-account/customer?email=${chi?.cust_email}`
															)
														}
														textTwo={''}
													/>
												);
											}
										)}
									</div>
								</>
							)}
						</div>
					</div>
				</PageLayout>
			</DashboardLayout>

			<RavenModal
				btnLabel="Create Account"
				btnColor="deep-green-light"
				onBtnClick={generateVirtualAccount}
				visble={createModal}
				loading={loading}
				onClose={() => setCreateModal(false)}
			>
				<div className="create-virtual-wrap">
					<div className="create-virtual-wrap__title">
						<h5>Create Virtual Account</h5>
						<p>Quickly create virtual account</p>
					</div>

					<div className="create-virtual-wrap__form">
						{/* <RavenInputField
							color="deep-green-light"
							type="text"
							label="Account Name"
							name="account_name"
							placeholder="Enter name of Account"
							value={formData.account_name}
							onChange={handleChange}
						/> */}

						<div className="form__grouped">
							<RavenInputField
								color="deep-green-light"
								value={formData.firstname}
								type="text"
								label="Firstname"
								placeholder="E.g 'John'"
								name="firstname"
								onChange={handleChange}
							/>
							<RavenInputField
								color="deep-green-light"
								type="text"
								value={formData.lastname}
								placeholder="E.g 'Doe'"
								label="Lastname"
								name="lastname"
								onChange={handleChange}
							/>
						</div>

						<RavenInputField
							color="deep-green-light"
							type="text"
							label="Email Address"
							value={formData.email}
							name="email"
							placeholder="E.g ‘johndoe@mail.com’"
							onChange={handleChange}
						/>

						<RavenInputField
							color="deep-green-light"
							value={formData.phone}
							type="phone"
							label="Mobile Number*"
							name="phone"
							placeholder="E.g ‘johndoe@mail.com’"
							onChange={handleChange}
						/>
					</div>
				</div>
			</RavenModal>
			<ExportModal
				onClose={() => setShowExportModal(false)}
				page="virtual-accounts"
				visible={showExportModal}
			/>
		</>
	);
};

export default VirtualAccounts;
