import { RavenInputField } from '@ravenpay/raven-bank-ui';
import React, { useEffect, useState } from 'react';
import './styles/index.css';

interface TableInput {
	type?: string;
	autoFocus?: boolean;
	onChange?: (value: any) => void;
	className?: string;
	id?: string;
	name?: string;
	placeholder?: string;
	maxWidth?: number | string;
	style?: React.CSSProperties;
	value?: any;
	selectOptions?: any;
}
const TableInput = ({
	type,
	autoFocus,
	id,
	name,
	onChange,
	maxWidth,
	style,
	placeholder,
	selectOptions,
	className,
	value,
}: TableInput) => {
	const [size, setSize] = useState('');
	const [val, setValue] = useState('');

	function resizeInput() {
		setSize(String(val)?.length + 'ch');
	}

	useEffect(() => {
		setValue(value);
		resizeInput();
	}, [value]);

	return (
		<div className="table-edit-input-wrap">
			{type === 'select' ? (
				<>
					<RavenInputField
						type={type}
						style={{
							maxWidth: maxWidth,
							...style,
							// width: size,
							backgroundColor: '#f7f8f7',
							minWidth: '15rem',
						}}
						className="table-edit-input-wrap__input"
						color={`deep-green-light`}
						// autoFocus={autofocus}
						value={val}
						selectOption={selectOptions}
						placeholder={placeholder ?? ' '}
						name={name}
						id={id}
						onChange={(e: any) => {
							onChange && onChange(e);
							setValue(e?.target?.value ?? e);
							resizeInput();
						}}
						// className={className}
					/>
					{/* <p className="table-edit-input-wrap__indicator"></p> */}
				</>
			) : (
				<>
					<RavenInputField
						type={type}
						style={{ maxWidth: maxWidth, ...style }}
						className="table-edit-input-wrap__input"
						color={`deep-green-light`}
						// autoFocus={autofocus}
						value={val}
						placeholder={placeholder ?? ' '}
						name={name}
						id={id}
						onChange={(e: any) => {
							onChange && onChange(e);
							setValue(e?.target?.value ?? e);
							resizeInput();
						}}
						// className={className}
					/>
					{/* <p className="table-edit-input-wrap__indicator"></p> */}
				</>
			)}
		</div>
	);
};

export default TableInput;
