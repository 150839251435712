import React from 'react';
import '../styles/index.css';
import { icons } from '../../../../../assets/icons';
import { RavenInputField, RavenToggleSlide } from '@ravenpay/raven-bank-ui';
import { logger } from '../../../../../services/logger';

const SettingsPreferences = () => {
	return (
		<div className="verification-settings">
			<div className="verification-settings__title">
				<h5>Preferences</h5>
				<p>Manage your account passwords and security</p>
			</div>

			<div className="verification-settings__main">
				<div className="main__items">
					<div className="items__grouped">
						<figure>{icons.settings_bell}</figure>
						<span>
							<h5>Updates from Raven</h5>
							<p>Access to receiving SMS notifications for your transactions</p>
						</span>
					</div>
					<RavenToggleSlide color="deep-green-light" id="rerj" />
				</div>

				<div className="main__items">
					<div className="items__grouped">
						<figure>{icons.settings_email}</figure>
						<span>
							<h5>Email Notification</h5>
							<p>Access to receiving SMS notifications for your transactions</p>
						</span>
					</div>
					<RavenToggleSlide
						color="deep-green-light"
						id="ro"
						onChange={(e: any) => logger.log(e)}
					/>
				</div>
			</div>
		</div>
	);
};

export default SettingsPreferences;
