import {
	RavenButton,
	RavenCheckBox,
	RavenInputField,
	toast,
} from '@ravenpay/raven-bank-ui';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DashboardLayout from '../../../../../../../layout/dashboard/dashboardLayout';
import PreviewDetailsBox from './PreviewDetailsBox';
import {
	IconVault,
	formatNumWithComma,
	generateReference,
	removeCommaAndNairaSign,
} from '../../../../../../../utils/helper/Helper';

import useDebounce from '../../../../../../../hooks/UseDebounce';

import ContainerLayout from '../../../../../../../layout/dashboard/container';
import { icons } from '../../../../../../../assets/icons';

import './styles/index.css';
import ViewAll from './ViewAll';
import ConfirmTransactionPinModal from '../../common/ConfirmPinModal';
import {
	getBetTypes,
	payForBetting,
	validateBetAccount,
} from '../../../../../../../redux/bills';
import { AppDispatch } from '../../../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import RootState, {
	ResponseStructure,
	ValidatedBetAccount,
} from '../../../../../../../redux/types';
import { logger } from '../../../../../../../services/logger';

type Modal = {
	pin: boolean;
	success: boolean;
};

type Provider = 'gotv' | 'dstv' | 'consat' | 'startimes' | '';

const NewBettingPurchase = () => {
	const navigate = useNavigate();
	const [step, setStep] = useState<number | string>(1);
	const [selectedProvider, setSelectedProvider] = useState<{
		name: Provider;
		icon: any;
	}>({
		name: '',
		icon: '',
	});
	const [viewAll, setViewAll] = useState(false);
	const [validatedName, setValidatedName] = useState('');
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [showModal, setShowModal] = useState<Modal>({
		pin: false,
		success: false,
	});
	const [details, setDetails] = useState<any>({
		platform: '',
		amount: '',
		user_id: '',
		transfer_pin: '',
	});

	const { dashboard_data } = useSelector((state: RootState) => state.wallet);

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...details, [name]: value };
		setDetails(obj);
	};

	const handleBulkAdd = () => {
		const checkList = details?.bulk_list?.some(
			(chi: any) => chi?.phone === details?.phone
		);
		if (!checkList) {
			const obj = {
				phone: details?.phone,
				amount: details?.amount,
				provider: details?.provider,
			};
			const newList = [obj, ...details?.bulk_list];
			setDetails((prev: any) => {
				return {
					...prev,
					bulk_list: newList,
					phone: '',
					amount: '',
					provider: '',
				};
			});
			setValidatedName('');
			// setVerify(false);
		} else {
			toast.error('Recepient already exist in bulk list');
		}
	};

	const handleRemoveFromList = (obj: { phone: any }) => {
		if (details?.bulk_list?.length === 1) {
			setViewAll(false);
		}
		const newList = details?.bulk_list?.filter(
			(chi: { phone: any }) => chi?.phone !== obj?.phone
		);
		setDetails((prev: any) => {
			return {
				...prev,
				bulk_list: newList,
			};
		});
	};

	const [error, setError] = useState({
		platform: false,
		user_id: false,
	});
	const [loadingVery, setLoadingVerify] = useState(false);

	type ValidateBetAccountReponse = {
		payload: ResponseStructure<ValidatedBetAccount>;
	};

	async function validateUserID() {
		setLoadingVerify(true);

		const resp = (await dispatch(
			validateBetAccount({
				type: details?.platform?.label,
				customer_id: details?.user_id,
			})
		)) as ValidateBetAccountReponse;

		if (resp?.payload?.status === 'success') {
			logger.log(resp.payload, 'd');
			setLoadingVerify(false);
			setValidatedName(resp?.payload?.data?.data?.name as string);
			// Your logic here for successful response
		}
	}

	const dispatch = useDispatch<AppDispatch>();
	const { betTypes, loading } = useSelector((state: RootState) => state.bills);

	const debouncedSearchTerm = useDebounce(details?.user_id, 1000);
	useEffect(() => {
		let isMount = true;

		logger.log('debouncing ');
		if (isMount && debouncedSearchTerm?.length > 3) {
			validateUserID();
		}

		if (isMount && debouncedSearchTerm?.length < 1) {
			setValidatedName('');
		}

		return () => {
			isMount = false;
		};
	}, [debouncedSearchTerm]);

	function disableAlgorithm() {
		if (!details.platform || !details.user_id || !details.amount) {
			return true;
		} else {
			return false;
		}
	}

	function handleInitPayment() {
		if (!details.username) {
			return true;
		}
		if (!details.amount) {
			return true;
		} else return false;
	}

	useEffect(() => {
		dispatch(getBetTypes());
	}, []);

	function formatBetTypes() {
		const pl: any = [];
		betTypes?.data?.map((e: { label: string; value: string }) => {
			pl.push({
				label: e,
				value: e,
			});
		});
		return pl;
	}

	function makePayment() {
		dispatch(
			payForBetting({
				type: details?.platform?.label,
				customer_id: details?.user_id,
				name: validatedName,
				amount: removeCommaAndNairaSign(details?.amount) as unknown as number,
				merchant_reference: generateReference(),
			})
		);
	}

	const stepList = ['Transfer detail', 'Confirm Transactions'];

	return (
		<>
			<DashboardLayout>
				{step !== 'view-all' && (
					<ContainerLayout
						onBack={() =>
							navigate('/dashboard-payment/bill-payments?bill-type=betting')
						}
						isLatest
						pageTitle="Betting"
						steps={stepList}
						activeStep={step as number}
						btnLabel={step === 2 ? 'Complete Transaction' : 'Continue'}
						btnDisabled={step === 1 ? disableAlgorithm() : false}
						onBtnClick={() => {
							if (handleInitPayment()) setStep(2);
							else setStep(2), handleBulkAdd();
						}}
						onPrev={() => {
							step === 1
								? navigate(`/dashboard-payment/bill-payments?bill-type=betting`)
								: setStep(1);
							setDetails({ ...details, bulk_list: [] });
						}}
						pageSubtitle="Quickly fund your betting account"
						// style={{
						// 	width: '100%',
						// 	maxHeight: '95%',
						// 	backgroundColor: step === 2 ? 'unset' : '',
						// 	border: step === 2 ? 'unset' : '',
						// 	padding: step === 2 ? 'unset' : '',
						// }}
					>
						<div
							className={`bill-purchase atlas-payment-container animate-move-up-class ${
								step === 2 && 'step2'
							} `}
						>
							<form
								onSubmit={(e) => {
									e.preventDefault();
								}}
								action=""
								className="form"
							>
								{/* step 1 start ------------------------------ */}
								{step === 1 && (
									<>
										<RavenInputField
											type={`select`}
											style={{ zIndex: '676' }}
											color={`deep-green-light`}
											label={`Betting Platform `}
											value={details?.platform}
											onChange={(e: any) => {
												setDetails({
													...details,
													platform: e,
												});
												setError((prev) => {
													return { ...prev, platform: false };
												});
											}}
											className="betting-type-select"
											placeholder={`Select Betting Platform`}
											labelColor={`purple-light`}
											showError={error?.platform}
											name={`platform`}
											selectOption={formatBetTypes()}
										/>

										<RavenInputField
											type={`text`}
											color={`deep-green-light`}
											label={`User ID `}
											value={details?.user_id}
											labelSpanText={'Verify'}
											labelClassName={`label-class-span ${
												details?.user_id?.length >= 9 &&
												'label-class-span-active'
											}`}
											onChange={(e: any) => {
												handleChange(e);
												setError((prev) => {
													return { ...prev, user_id: false };
												});
											}}
											onActionClick={() => {
												details?.user_id.length > 3 && validateUserID();
											}}
											placeholder={`E.g ‘5676904735589467586736’`}
											labelColor={`purple-light`}
											showError={error?.user_id}
											name={`user_id`}
										/>
										{/* verify name box start */}
										<div
											className={`verify-id-box ${
												validatedName && 'verify-id-box-show'
											}`}
										>
											<RavenCheckBox
												checked={true}
												onChange={() => {}}
												id={1}
												color={`black-light`}
												className={`check`}
											/>
											<p className="text">{validatedName}</p>
										</div>
										{/* verify name box end */}

										<RavenInputField
											type={`number`}
											color={`black-light`}
											label={`Amount* `}
											name={`amount`}
											value={details?.amount}
											onChange={handleChange}
											placeholder={`E.g ‘50,000’`}
											labelColor={`black-light`}
											labelSpanText={
												typeof dashboard_data?.balances !== 'undefined'
													? `Bal: ₦ ${formatNumWithComma(
															dashboard_data?.balances[0]?.available_bal,
															'ngn'
													  )} `
													: 'Bal: ₦ ••••••'
											}
											labelClassName={`label-span-amount label-span-theme`}
											thousandFormat
											numberPrefix={`₦`}
											onActionClick={() => {}}
										/>
										{/* 
										<RavenButton
											className="bill-purchase-button"
											style={{ width: '100%' }}
											label="Proceed"
											disabled={disableAlgorithm()}
											onClick={() => {
												if (handleInitPayment()) setStep(2);
												else setStep(2), handleBulkAdd();
											}}
											color="deep-green-light"
										/> */}
										{/* recepient box end */}
									</>
								)}
								{/* step 1 end -------------------------------- */}

								{/* step 2 start ----------------------------- */}
								{step === 2 && (
									<div className="step-two-bills animate-move-up-class">
										<div className="preview-bill__logo">
											<figure>
												{
													<img
														src={IconVault(icons.electricity_purchase)}
														alt=""
													/>
												}
											</figure>
										</div>

										<PreviewDetailsBox
											currentIndex={(d: any) => setCurrentIndex(d)}
											onBtnClick={() =>
												// setShowModal({
												// 	pin: true,
												// 	success: false,
												// })
												makePayment()
											}
											loading={loading}
											content={details}
										/>
									</div>
								)}
							</form>

							{/* view all end ----------------------------- */}
						</div>
					</ContainerLayout>
				)}

				{step === 'view-all' && (
					<ViewAll
						onBack={() => {
							setStep(1);
						}}
						onChange={(e: any) => setDetails(e)}
						items={details}
					/>
				)}
			</DashboardLayout>
			<ConfirmTransactionPinModal
				onBtnClick={() => {
					toast.success(
						'Your Airtime Purchase of Airtel - 200 has been successful'
					);
					setShowModal({
						pin: false,
						success: false,
					});
					navigate('/dashboard-payment/bill-payments');
				}}
				onClose={() =>
					setShowModal({
						pin: false,
						success: false,
					})
				}
				visible={showModal.pin}
			/>
		</>
	);
};

export default NewBettingPurchase;
