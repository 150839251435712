import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import { brands, icons, illustrations } from '../../../../assets/icons';
import mtn from '../../../../assets/images/mtn.png';
import glo from '../../../../assets/images/glo.png';
import airtel from '../../../../assets/images/airtel.png';
import mobile from '../../../../assets/images/9mobile.png';
import consat from '../../../../assets/images/consat.png';
import dstv from '../../../../assets/images/dstv.png';
import gotv from '../../../../assets/images/gotv.png';
import bet9ja from '../../../../assets/images/bet9ja.png';
import betKing from '../../../../assets/images/betking.png';
import bet1960 from '../../../../assets/images/bet360.png';
import bet360 from '../../../../assets/images/1960bet.png';
import xbet from '../../../../assets/images/1xbet.png';
import danaAir from '../../../../assets/images/dana-air.png';
import discoveryAir from '../../../../assets/images/discovery-air.png';
import wakanow from '../../../../assets/images/wakanow.png';
import aeroAir from '../../../../assets/images/aero-air.png';
import hakAir from '../../../../assets/images/hak-air.png';
import betway from '../../../../assets/images/betway.png';
import startimes from '../../../../assets/images/startimes.png';
import {
	RavenTableRow,
	RavenTable,
	RavenModal,
	RavenInputField,
	RavenButton,
} from '@ravenpay/raven-bank-ui';
import './styles/index.css';
import {
	capitalizeFirstLetter,
	formatDateTime,
	formatNumWithComma,
	formatNumWithCommaNaira,
	formatStatus,
	insertUrlParam,
	searchParams,
	symbol,
} from '../../../../utils/helper/Helper';
import EmptyScreen from '../../../../components/common/emptyScreen';
import { useDarkMode } from '../../../../hooks/useDarkMode';
import Pagination from '../../../../components/common/pagination';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAirtimeRecords,
	getBettingRecords,
	getCableRecords,
	getDataRecords,
	getElectricityRecords,
} from '../../../../redux/bills';
import RootState, {
	AirtimeRecord,
	BetRecord,
	DataRecordObject,
	ElectricityRecord,
} from '../../../../redux/types';
import { logger } from '../../../../services/logger';
import Copy from '../../../../components/common/copyCheck';
import SmartFilter from '../../../../components/common/smartFilter';
import TransactionModal from '../../../../components/common/transactionModal';
import MobileBills from './components/MobileBills/MobileBills';
import PageLayout from '../../../../layout/dashboard/tableLayout';
import MobileTableCard from '../../../../components/common/table/mobile/MobileTable';
import ContainerLayout from '../../../../layout/dashboard/container';
import ExportModal from '../../../../components/common/exportModal';
import { IconVault } from '../../../../utils/helper/IconVault';

type billType =
	| 'airtime'
	| 'data'
	| 'electricity'
	| 'cable'
	| 'flight'
	| 'betting';

type TBD = {
	[x: string]: any;
	billers_name: string;
	direction: string;
	amount: number;
	date: Date | string;
	created_at: Date | string;
	reference?: string;
};
const Bills = () => {
	const [billType, setBillType] = useState<billType | string>('airtime');
	const [mobileTable, setMobileTable] = useState(false);
	const [showExport, setShowExport] = useState(false);
	const [view, onView] = useState<{ show: boolean; content: any }>({
		show: false,
		content: {},
	});

	const {
		airtimeRecords,
		electricityRecords,
		dataRecords,
		loading,
		cableRecords,
		bettingRecords,
	} = useSelector((state: RootState) => state.bills);

	// const filterLoading = (state: RootState) => state.filters

	const smartPaginate = isBillType('electricity')
		? electricityRecords?.pagination
		: isBillType('airtime')
		? airtimeRecords?.pagination
		: dataRecords?.pagination;

	function isBillType(wl: string) {
		if (billType === wl) return true;
		else return false;
	}

	document.addEventListener('keydown', function (event) {
		if (event.keyCode === 27) {
			onView({
				show: !view.show,
				content: '',
			});
		}
	});

	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));

	const navigate = useNavigate();

	useEffect(() => {
		insertUrlParam(
			'bill-type',
			billType,
			`${capitalizeFirstLetter(`${billType}`)} Bills Payment | Raven Atlas`,
			true
		);
	}, [billType]);

	useEffect(() => {
		const billie: billType | null | string = searchParams.get('bill-type');
		billie !== null ? setBillType(billie) : '';
	}, []);

	const dispatch = useDispatch();

	function triggerAllRequest(p?: number) {
		if (isBillType('airtime')) {
			dispatch(
				getAirtimeRecords({
					currentPage: p ?? 1,
					limit: 10,
				})
			);
		}

		if (isBillType('data')) {
			dispatch(
				getDataRecords({
					currentPage: p ?? 1,
					limit: 10,
				})
			);
		}

		if (isBillType('electricity')) {
			dispatch(
				getElectricityRecords({
					currentPage: p ?? 1,
					limit: 10,
				})
			);
		}

		if (isBillType('betting')) {
			dispatch(
				getBettingRecords({
					currentPage: p ?? 1,
					limit: 10,
				})
			);
		}

		if (isBillType('cable')) {
			dispatch(
				getCableRecords({
					currentPage: p ?? 1,
					limit: 10,
				})
			);
		}
	}

	useEffect(() => {
		triggerAllRequest();
	}, [billType]);

	const smartTableSort = () => {
		let tbd: TBD[] = [];

		if (
			isBillType('airtime') &&
			typeof airtimeRecords?.airtime_records !== 'undefined'
		) {
			airtimeRecords?.airtime_records?.map((e: AirtimeRecord) => {
				const pd: TBD = {
					...e,
					billers_name: e.network,
					direction: e.status,
					amount: e.amount,
					date: e.created_at,
					reference: e.reference,
				};
				tbd.push(pd);
			});
		}

		if (
			isBillType('data') &&
			typeof dataRecords?.data_records !== 'undefined'
		) {
			dataRecords?.data_records?.map((e: DataRecordObject) => {
				const pd: TBD = {
					...e,
					billers_name: e.network,
					direction: e.status,
					amount: e.amount,
					date: e.created_at,
					reference: e.reference,
				};
				tbd.push(pd);
			});
		}

		if (
			isBillType('electricity') &&
			typeof electricityRecords?.electricity_records !== 'undefined'
		) {
			logger.log(electricityRecords);
			electricityRecords?.electricity_records?.map((e: ElectricityRecord) => {
				const pd: TBD = {
					...e,
					billers_name: e.service_id,
					reference: e.electricity_token?.replaceAll('-', ''),
					direction: e.status,
					amount: e.amount,
					date: e.created_at,
				};
				tbd.push(pd);
			});
		}

		if (
			isBillType('betting') &&
			typeof bettingRecords?.bet_records !== 'undefined'
		) {
			bettingRecords?.bet_records?.map((e: BetRecord) => {
				const pd: TBD = {
					...e,
					billers_name: e.type,
					direction: e.status,
					amount: e.amount,
					date: e.created_at,
					reference: e.reference,
				};
				tbd.push(pd);
			});
		}

		if (
			isBillType('cable') &&
			typeof cableRecords?.cable_records !== 'undefined'
		) {
			cableRecords?.cable_records?.map((e: any) => {
				const pd: TBD = {
					...e,
					billers_name: e.provider,
					direction: e.status,
					amount: e.amount,
					date: e.created_at,
					reference: e.reference,
				};
				tbd.push(pd);
			});
		}
		return tbd;
	};

	const smartModalContent = (e: any) => {
		let tbd: TBD = {
			billers_name: '',
			direction: '',
			amount: 0,
			date: new Date(),
			reference: undefined,
			created_at: new Date(),
		};

		if (
			isBillType('airtime') &&
			typeof airtimeRecords?.airtime_records !== 'undefined'
		) {
			const pd: TBD = {
				...e,
				billers_name: e.network,
				direction: e.status,
				amount: e.amount,
				date: e.created_at,
				reference: e.reference,
			};
			tbd = pd;
		}

		if (
			isBillType('data') &&
			typeof dataRecords?.data_records !== 'undefined'
		) {
			const pd: TBD = {
				...e,
				billers_name: e.network,
				direction: e.status,
				amount: e.amount,
				plan: e.data_plan,
				date: e.created_at,
				reference: e.reference,
			};
			tbd = pd;
		}

		if (
			isBillType('electricity') &&
			typeof electricityRecords?.electricity_records !== 'undefined'
		) {
			const pd: TBD = {
				...e,
				billers_name: e.service_id,
				direction: e.status,
				amount: e.amount,
				date: e.created_at,
				reference: e.reference,
			};
			tbd = pd;
		}

		if (
			isBillType('betting') &&
			typeof bettingRecords?.bet_records !== 'undefined'
		) {
			const pd: TBD = {
				...e,
				billers_name: e.type,
				direction: e.status,
				amount: e.amount,
				date: e.created_at,
				reference: e.reference,
			};
			tbd = pd;
		}

		if (
			isBillType('cable') &&
			typeof bettingRecords?.bet_records !== 'undefined'
		) {
			const pd: TBD = {
				...e,
				billers_name: e.provider,
				direction: e.status,
				amount: e.amount,
				date: e.created_at,
				reference: e.reference,
			};
			tbd = pd;
		}
		return tbd;
	};
	const tableData: any = smartTableSort();

	const mdc = smartModalContent(view?.content);
	logger.log(mdc);

	function preFormatStatus(status?: string) {
		return (
			<div className={`transaction-status ${status?.toLowerCase()}`}>
				<p>{status}</p>
			</div>
		);
	}

	type BillsTableType = {
		reference: any;
		date: string | Date;
		status?: string;
		billers_name:
			| string
			| number
			| boolean
			| React.ReactElement<any, string | React.JSXElementConstructor<any>>
			| React.ReactFragment
			| null
			| undefined;
		amount: string;
	};

	return (
		<>
			<DashboardLayout>
				<ContainerLayout
					pageTitle="Bill Payment"
					onBack={() => mobileTable && setMobileTable(false)}
					topRightContent={<></>}
					style={{
						flex: 1,
						backgroundColor: 'white',
						padding: '2rem',
						borderRadius: '1.2rem',
					}}
					pageMode
					pageSubtitle="All links used for payment created"
				>
					<div className={`wallet ${theme}`}>
						<div className="wallet__bills-wrap non-mobile">
							<div className="bills-wrap__selector">
								<div
									onClick={() => setBillType('airtime')}
									className={`selector__item ${
										isBillType('airtime') && 'active'
									}`}
								>
									<p>Airtime</p>
								</div>
								<div
									onClick={() => setBillType('data')}
									className={`selector__item ${isBillType('data') && 'active'}`}
								>
									<p>Data</p>
								</div>
								<div
									onClick={() => setBillType('electricity')}
									className={`selector__item ${
										isBillType('electricity') && 'active'
									}`}
								>
									<p>Electricity</p>
								</div>
								<div
									onClick={() => setBillType('cable')}
									className={`selector__item ${
										isBillType('cable') && 'active'
									}`}
								>
									<p>Cable TV</p>
								</div>
								<div
									onClick={() => setBillType('betting')}
									className={`selector__item ${
										isBillType('betting') && 'active'
									}`}
								>
									<p>Betting</p>
								</div>
								{/* <div
								onClick={() => setBillType('flight')}
								className={`selector__item ${isBillType('flight') && 'active'}`}
							>
								<p>Flight Booking</p>
							</div> */}
							</div>
							<div className="bills-wrap__payments-wrap">
								<div className="payments-wrap__title">
									<div className="title__left">
										<h5>
											{billType.slice(0, 1).toUpperCase() +
												billType.slice(1, billType.length)}
										</h5>
										<p>{`Here is an overview of all your ${billType} transactions.`}</p>
									</div>
									<RavenButton
										onClick={() =>
											navigate(
												billType === 'airtime'
													? '/dashboard-payment/bill-payments/airtime/new'
													: billType === 'data'
													? '/dashboard-payment/bill-payments/data/new'
													: billType === 'flight'
													? '/dashboard-payment/bill-payments/flight/new'
													: billType === 'betting'
													? '/dashboard-payment/bill-payments/betting/new'
													: billType === 'cable'
													? '/dashboard-payment/bill-payments/cable/new'
													: billType === 'electricity'
													? '/dashboard-payment/bill-payments/electricity/new'
													: ''
											)
										}
										color="deep-green-light"
										label={
											billType === 'cable'
												? 'Buy a Plan'
												: billType === 'flight'
												? 'Pay for Flight'
												: billType === 'betting'
												? 'Topup'
												: `Buy ${capitalizeFirstLetter(billType)}`
										}
									/>
								</div>

								<div className="payments-wrap__main">
									<SmartFilter
										page={billType}
										onExport={() => setShowExport(true)}
										simple
									/>
									{tableData.length === 0 || loading ? (
										<EmptyScreen
											subTitle={`Your don't have any ${billType} bills transaction yet, once you do they will show up here.`}
											loading={loading}
										/>
									) : (
										<>
											<div className="main__pagination">
												<Pagination
													currentPage={smartPaginate?.currentPage}
													itemsPerPage={smartPaginate?.perPage}
													onPageChange={(s) => triggerAllRequest(s)}
													totalItems={smartPaginate?.total}
												/>
											</div>
											<div className="transaction__table">
												<RavenTable
													action={false}
													className="tabel__main"
													headerList={[
														'BILLERS NAME',
														'AMOUNT',
														billType !== 'electricity' && billType !== 'cable'
															? 'REFERENCE ID'
															: 'TOKEN',
														'STATUS',
														'TRANSACTION DATE',
													]}
												>
													{tableData.map(
														(
															chi: BillsTableType,
															idx: React.Key | null | undefined
														) => {
															return (
																<React.Fragment key={idx}>
																	<RavenTableRow
																		onRowClick={() =>
																			onView({
																				show: true,
																				content: chi,
																			})
																		}
																		className="table__main"
																		three={
																			<div className="main__reference-wrap ">
																				<span>
																					{String(chi.reference).slice(0, 15)}
																				</span>
																				<figure>{icons.copy}</figure>
																			</div>
																		}
																		four={preFormatStatus(chi?.status)}
																		five={formatDateTime(chi?.date)}
																		one={
																			<div className="main__direction">
																				{billType !== 'electricity' && (
																					<figure>
																						{typeof autoBillersLogo(chi) ===
																						'object' ? (
																							(autoBillersLogo(
																								chi
																							) as ReactElement)
																						) : (
																							<img
																								src={
																									autoBillersLogo(chi) as string
																								}
																								alt=""
																							/>
																						)}
																					</figure>
																				)}
																				<p className="text">
																					{' '}
																					{chi.billers_name}
																				</p>
																			</div>
																		}
																		two={
																			<div className="type-wrap">
																				<span>{'₦' + chi.amount}</span>
																			</div>
																		}
																	/>
																</React.Fragment>
															);
														}
													)}
												</RavenTable>
											</div>
										</>
									)}
								</div>
							</div>
						</div>

						<div className="non-desktop mobile-bills-wrap">
							{!mobileTable ? (
								<MobileBills
									onView={(e) => {
										setBillType(e);
										setMobileTable(true);
									}}
								/>
							) : tableData.length === 0 || loading ? (
								<EmptyScreen
									subTitle={`Your don't have any ${billType} bills transaction yet, once you do they will show up here.`}
									loading={loading}
								/>
							) : (
								<>
									<SmartFilter />
									{tableData.map(
										(
											chi: BillsTableType,
											idx: React.Key | null | undefined
										) => {
											return (
												<React.Fragment key={idx}>
													<MobileTableCard
														title={chi.billers_name as string}
														text={String(chi.reference).slice(0, 15)}
														amount={'₦' + chi.amount}
														img={autoBillersLogo(chi) as string}
														svg={undefined}
														avatar={''}
														amountColor={'#014345'}
														bottomRightText={formatDateTime(chi?.date)}
														type={''}
														showImg={billType !== 'electricity'}
														loading={false}
														onRowClick={function (row?: any): void | Function {
															onView({
																show: true,
																content: chi,
															});
														}}
													/>
												</React.Fragment>
											);
										}
									)}
								</>
							)}
						</div>
					</div>
				</ContainerLayout>
			</DashboardLayout>

			<TransactionModal
				onClose={() =>
					onView({
						show: false,
						content: '',
					})
				}
				directionTitle="Status"
				visible={view.show}
				direction={view?.content?.direction}
				title={'Bill Payment Details'}
				amount={view?.content?.amount as unknown as number}
				onClick={() => {}}
				meta={JSON.stringify(mdc)}
				content={[
					{
						label: 'Biller Name',
						value: mdc?.billers_name || '--',
					},
					{
						label: 'Amount',
						value: symbol('ngn') + formatNumWithComma(mdc?.amount) || '--',
					},

					{
						label:
							isBillType('data') || isBillType('airtime')
								? 'Mobile Number'
								: isBillType('electricity')
								? 'Account Name'
								: isBillType('cable')
								? 'Plan'
								: isBillType('betting')
								? 'Customer ID'
								: '',
						value:
							isBillType('data') || isBillType('airtime')
								? mdc?.phone_number
								: isBillType('electricity')
								? mdc?.customer_name
								: isBillType('cable')
								? mdc?.service_id
								: isBillType('betting')
								? mdc?.customer_id
								: '',
					},

					{
						label:
							billType !== 'airtime' && billType !== 'data'
								? 'Token'
								: 'Reference ID',
						value: String(mdc?.reference) as string,
						copy: true,
						trim: true,
					},
					{
						label: 'Merchant Ref',
						value: String(mdc?.merchant_reference) as string,
						copy: true,
						trim: true,
					},

					{
						label: 'Created At',
						value: formatDateTime(
							view?.content?.created_at as unknown as string
						),
					},
				]}
			/>
			<ExportModal
				onClose={() => setShowExport(false)}
				page={billType}
				visible={showExport}
			/>
		</>
	);
};

export default Bills;
function autoBillersLogo(chi: {
	reference: any;
	date: string | Date;
	status?: string | undefined;
	billers_name:
		| string
		| number
		| boolean
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| React.ReactFragment
		| null
		| undefined;
	amount: string;
}): string | ReactNode {
	return chi.billers_name === 'MTN'
		? mtn
		: chi.billers_name === 'GLO'
		? glo
		: chi.billers_name === '9Mobile'
		? mobile
		: chi.billers_name === 'airtel'
		? brands.raven
		: chi.billers_name === 'Gotv'
		? gotv
		: chi.billers_name === 'Dstv'
		? dstv
		: chi.billers_name === 'Startimes'
		? startimes
		: chi.billers_name === 'Comsat'
		? consat
		: chi.billers_name === 'Bet9ja'
		? bet9ja
		: chi.billers_name === '1960Bet'
		? bet1960
		: chi.billers_name === 'BetWay'
		? betway
		: chi.billers_name === 'BetKing'
		? betKing
		: chi.billers_name === '360Bet'
		? bet360
		: chi.billers_name === '1xBet'
		? xbet
		: chi.billers_name === 'Dana Airline'
		? danaAir
		: chi.billers_name === 'Wakanow'
		? wakanow
		: chi.billers_name === 'Hak Airlines'
		? hakAir
		: chi.billers_name === 'Fly Aero Airline'
		? aeroAir
		: chi.billers_name === 'Discovery Airlines'
		? discoveryAir
		: brands.airtel;
}
