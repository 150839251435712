import React, { useEffect } from 'react';
import ContainerLayout from '../../../../../layout/dashboard/container';
import PageLayout from '../../../../../layout/dashboard/tableLayout';
import '../styles/index.css';
import { icons } from '../../../../../assets/icons';
import {
	formatDateTime,
	formatNumWithComma,
	returnInitial,
	symbol,
} from '../../../../../utils/helper/Helper';
import EmptyScreen from '../../../../../components/common/emptyScreen';
import { useDarkMode } from '../../../../../hooks/useDarkMode';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../redux/types';
import { generateRandomColor } from '../../../../../utils/helper/color';
import { getDashboardData } from '../../../../../redux/wallet';
import { bankboxAPI } from '../../../../../redux/bankbox';
import WithdrawalModal from '../modals/WithdrawalModal';
import DashboardLayout from '../../../../../layout/dashboard/dashboardLayout';

function BankBoxMainOverview() {
	const [graphData, setGraphData] = React.useState<{
		index: number;
		content: string[];
	}>({
		index: 0,
		content: [],
	});
	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));

	const [truety, setTruety] = React.useState({
		collection: false,
		transfer: false,
	});

	const [showModal, setShowModal] = React.useState({
		withdraw: false,
	});

	const { dashboard_summary, loading, top_transacting, chart_data } =
		useSelector((state: RootState) => state.bankbox);
	// Get the current date
	const currentDate = new Date();

	// Create an array for the past 7 days
	const past7Days: string[] = [];
	for (let i = 0; i < 7; i++) {
		const date = new Date(currentDate.getTime() - i * 24 * 60 * 60 * 1000);
		past7Days.push(date.toISOString());
	}

	// Create an array for the past 30 days
	const past30Days: string[] = [];
	for (let i = 0; i < 30; i++) {
		const date = new Date(currentDate.getTime() - i * 24 * 60 * 60 * 1000);
		past30Days.push(date.toISOString());
	}

	function generatePastDays(lastDate: Date | string) {
		const newDays: string[] = [];
		const current = new Date(lastDate);
		if (lastDate) {
			for (let i = 0; i < 7; i++) {
				const date = new Date(current.getTime() - i * 24 * 60 * 60 * 1000);
				newDays.push(date.toISOString());
			}
		}

		return newDays;
	}

	ChartJS.register(
		CategoryScale,
		LinearScale,
		BarElement,
		Title,
		Tooltip,
		Legend
	);

	function handleGraphData(str?: string) {
		let i = graphData.index;

		if (str === 'prev') {
			if (i > 1) {
				i = i - 1;
			}
		} else {
			i = i + 1;
		}

		if (i === 1) {
			setGraphData({
				index: i,
				content: past7Days,
			});
		} else {
			setGraphData({
				index: i,
				content: generatePastDays(graphData.content[6]),
			});
		}
	}

	function isEmpty() {
		return truety.collection === true && truety.transfer === true;
	}

	useEffect(() => {
		if (past7Days) {
			setGraphData({
				index: 1,
				content: past7Days,
			});
		}
	}, []);

	function Graph() {
		const options: any = {
			plugins: {
				legend: {
					display: false,
					labels: {
						position: 'left',
						align: 'start',
						font: {
							size: 14,
							style: (ctx: any, options: any) => {
								// You can customize the logic here to determine the style dynamically
								return ctx.chart?.data?.datasets?.[options.datasetIndex]
									?.label === 'Collection'
									? 'italic'
									: 'normal';
							},
							color: 'black',
						},
					},
				},
			},
			responsive: true,
			scales: {
				x: {
					grid: {
						drawBorder: false,
						display: false,
					},
					ticks: {
						display: true,
					},
				},
				y: {
					ticks: {
						display: false,
					},
					border: { dash: [6, 6] }, // for the grid lines

					grid: {
						color: theme === 'dark' ? '#cccccc23' : '#E1E1E1', // for the grid lines
						tickColor: 'white', // for the tick mark
						tickBorderDash: [2, 3], // also for the tick, if long enough
						tickLength: 15, // just to see the dotted line
						tickWidth: 2.5,
						offset: true,
						drawTicks: false, // true is default
						drawOnChartArea: true, // true is default
					},

					beginAtZero: true,
				},
			},
		};

		const formattedDates = graphData.content.map(
			(dateString: string | number | Date, index: number) => {
				const date = new Date(dateString);
				if (graphData.index === 1) {
					if (index === 0) {
						return 'Today';
					} else if (index === 1) {
						return 'Yesterday';
					} else {
						return formatDateTime(date).split(',')[0];
					}
				} else {
					return formatDateTime(date).split(',')[0];
				}
			}
		);

		const labels = formattedDates;
		const collx =
			chart_data.length >= 1 &&
			chart_data?.slice(7 * graphData.index - 7, 7 * graphData.index);

		const data = {
			labels,
			datasets: [
				{
					label: 'BankBox Disbursed',
					data: labels?.map((chi: any, idx: string | number) => collx[idx]),
					backgroundColor: theme === 'dark' ? '#0B8376' : '#E8FFF6',
					borderColor: '#9BE5C9',
					borderWidth: 2,
					borderRadius: 10,
				},
			],
		};

		return (
			<Bar
				height={80}
				width={200}
				options={options}
				// ref={chartRef}
				data={data}
			/>
		);
	}

	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(bankboxAPI.getDashboardSummary({}));
		dispatch(bankboxAPI.getChartData({}));
		dispatch(bankboxAPI.getTopTransacting({}));
	}, []);

	return (
		<>
			<DashboardLayout>
				<ContainerLayout
					pageTitle="BankBox"
					topRightContent={<></>}
					pageMode
					style={{
						height: '100%',
						backgroundColor: loading ? 'white' : 'unset',
						borderRadius: loading ? '1rem' : 'unset',
					}}
					disableBack
					loading={loading}
					pageSubtitle="Manage BankBox devices on the platform"
				>
					<div className="bank-box-overview">
						<div className="bank-box-overview__stats-wrapper">
							<div className="bank-box-overview__stats-wrapper--stats">
								<div className="top">
									<figure>{icons.bank}</figure>
									<button
										style={{ cursor: 'pointer', visibility: 'hidden' }}
										onClick={() => {
											setShowModal((prev) => {
												return { ...prev, withdraw: true };
											});
										}}
										className="button"
									>
										<p>Withdraw</p>
									</button>
								</div>
								<div className="bottom">
									<p>Collection Balance</p>
									<h6>
										{symbol('ngn') +
											formatNumWithComma(
												dashboard_summary?.collectionBalance,
												'ngn'
											)}
									</h6>
								</div>
							</div>

							<div className="bank-box-overview__stats-wrapper--stats">
								<div className="top">
									<figure>{icons.loan_overdraft}</figure>
								</div>
								<div className="bottom">
									<p>Pending Requests</p>
									<h6>{dashboard_summary?.totalPendingRequest}</h6>
								</div>
							</div>

							<div className="bank-box-overview__stats-wrapper--stats">
								<div className="top">
									<figure>{icons.bankbox_rounded}</figure>
								</div>
								<div className="bottom">
									<p>Total BankBox Disbursed</p>
									<h6>{dashboard_summary?.totalBankBoxDisbursed}</h6>
								</div>
							</div>

							<div className="bank-box-overview__stats-wrapper--stats">
								<div className="top">
									<figure>{icons.people_rounded}</figure>
								</div>
								<div className="bottom">
									<p>Total Users</p>
									<h6>{dashboard_summary?.totalUsers}</h6>
								</div>
							</div>
						</div>

						<div className="bank-box-overview__bottom-contain">
							<div className="charts__graph">
								<div className="graph__top">
									<div className="top__title ">
										<p>BankBox collections</p>
										<div className="title__graph-indicator">
											<span>
												<p></p>
												<p>Amount of BankBox collections</p>
											</span>
										</div>
									</div>
									<div onClick={isEmpty} className="top__filter">
										<p>
											{graphData.index === 1
												? 'This Week'
												: formatDateTime(graphData.content[6])?.split(',')[0] +
												  ' - ' +
												  formatDateTime(graphData.content[0])?.split(',')[0]}
										</p>
										<span>
											<figure
												style={{
													cursor: graphData.index === 1 ? 'no-drop' : '',
												}}
												onClick={() =>
													graphData.index > 1 && handleGraphData('prev')
												}
											>
												{icons.chevron_left}
											</figure>
											<figure
												className="next_cursor"
												onClick={() => handleGraphData('next')}
											>
												{icons.chevron_right}
											</figure>
										</span>
									</div>
								</div>

								{loading ||
								// (truety.collection === true && truety.transfer === true) ||
								graphData.index === 0 ? (
									<EmptyScreen noMascot loading={loading} />
								) : (
									<div className="graph__main">
										{' '}
										<Graph />{' '}
									</div>
								)}
							</div>

							<div className="top-transacting">
								{top_transacting.length === 0 ? (
									<div className="empty_all_bb">
										<figure>{icons.top_transacting_mascot}</figure>
										<h6>No Data for this Metrics</h6>
										<p>
											Top 5 Users with most transactions show up here as time
											goes on
										</p>
									</div>
								) : (
									<>
										<div className="top-transacting__title">
											<figure>{icons.top_transacting}</figure>
											<h6>Top 5 Transacting Users</h6>
										</div>
										<div className="top-transacting__users">
											{top_transacting.map((chi, idx) => {
												return (
													<div key={idx} className="top-users">
														<span
															style={{
																backgroundColor: generateRandomColor('#e9f5ff'),
															}}
														>
															<p>
																{returnInitial(
																	chi?.lname + ' ' + chi?.fname ?? ''
																)}
															</p>
														</span>
														<div className="name-box">
															<p className="name">
																{' '}
																{chi?.lname + ' ' + chi?.fname ?? ''}
															</p>
															<p className="email"> {chi?.email}</p>
														</div>
													</div>
												);
											})}
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</ContainerLayout>
			</DashboardLayout>

			{/* withdraw modal show start */}
			<WithdrawalModal
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, withdraw: false };
					});
				}}
				visible={showModal.withdraw}
			/>
			{/* withdraw modal show end */}
		</>
	);
}

export default BankBoxMainOverview;
