import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../../layout/dashboard/tableLayout';
import {
	RavenButton,
	RavenInputField,
	RavenModal,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import {
	formatDateTime,
	formatNumWithComma,
	formatNumWithCommaNaira,
	mapHotkey,
	symbol,
} from '../../../../utils/helper/Helper';
import { faker } from '@faker-js/faker';
import { icons } from '../../../../assets/icons';
import Pagination from '../../../../components/common/pagination';
import EmptyScreen from '../../../../components/common/emptyScreen';
import './styles/index.css';
import { generateRandomColor } from '../../../../utils/helper/color';
import { useNavigate } from 'react-router-dom';

const PaymentLinks = () => {
	const [demo, setDemo] = useState(true);
	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [activeCurrency, setActiveCurrency] = useState('ngn');
	const [currentPage, setCurrentPage] = useState(1);
	const [activeFilter, setActiveFilter] = useState<string>('');
	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});
	const [view, onView] = useState<any>({
		show: false,
		view: '',
	});
	useState<string>('business-account');
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});

	const tableData: {
		title?: string;
		type?: string;
		amount: string;
		date: Date;
		disabled?: boolean;
		link: string;
	}[] = [];

	// Generate 10 objects with fake data for the table rows
	for (let i = 0; i < 10; i++) {
		const type = faker.helpers.arrayElement(['Deposit', 'Transfer']);
		const rowData = {
			title: faker.finance.transactionDescription().slice(0, 30),
			type: faker.helpers.arrayElement([
				'Single Charge',
				'Recurring Charge',
				'Donation',
			]),
			link: faker.internet.url(),
			amount: faker.finance.amount({ min: 20000, max: 10020032 }),
			date: faker.date.past(),
			disabled: faker.helpers.arrayElement([true, false]),
		};

		tableData.push(rowData);
	}

	const navigate = useNavigate();

	function isFilter(value: string) {
		if (value === activeFilter) return true;
		else return false;
	}

	useEffect(() => {
		mapHotkey('m', () => setDemo(!demo));
	}, [demo]);

	useEffect(() => {
		if (
			typeof dateFilter.from === 'object' ||
			typeof dateFilter.to === 'object'
		) {
			setDateFilter({
				to: '',
				from: '',
			});
		}
	}, [activeFilter]);

	useEffect(() => {
		if (typeof activeFilter !== undefined) {
			setActiveFilter('');
		}
	}, [dateFilter.to || dateFilter.from]);

	function DropMore({ className, idx }: any) {
		return (
			<div className={`more-wrap ${className}`}>
				<div
					onClick={() => {
						setShowDrop({
							show: !showDrop.show,
							id: idx,
						});
						navigate('/dashboard-payment/payment-links/single-preview');
					}}
					className="more_item"
				>
					<figure>{icons.arrow_block_right}</figure>
					<p>View Link Details</p>
				</div>
				<div className="more_item">
					<figure>{icons.broken_link}</figure>
					<p>Disable Payment Link</p>
				</div>
				<div className="more_item">
					<figure>{icons.delete_plain}</figure>
					<p>Delete Payment Link</p>
				</div>
			</div>
		);
	}

	function isDropped(idx: number | string) {
		if (showDrop.id === idx && showDrop.show) return true;
		else return false;
	}

	return (
		<>
			<DashboardLayout>
				<PageLayout
					pageSubtitle="All Payments Links made on the platform"
					pageTitle="Payment Links"
					topRightContent={
						<RavenButton
							onClick={() => navigate('/dashboard-payment/payment-links/new')}
							color="deep-green-light"
						>
							<p>New Payment Link</p>
							<figure>{icons.plus_circle_white}</figure>
						</RavenButton>
					}
				>
					<div className="history__content">
						<div className="content__top-bar">
							<div className="top-bar__search">
								<RavenInputField
									placeholder="Search"
									type="search"
									color="deep-green-light"
								/>
							</div>
							<div className="top-bar__filter-export">
								<div
									onClick={() => setShowFilter(!showFilter)}
									className="filter-export__filter-btn"
								>
									<p>Filter</p> <figure>{icons.filter}</figure>
								</div>

								{/* Section encompassing filter and it controls */}
								<div
									className={`filter-export__drop-down ${
										showFilter && 'filter-export__drop-down--open'
									}`}
								>
									<div className="drop-down__switch">
										<span
											onClick={() => setActiveFilter('All')}
											className={`${isFilter('All') && 'active'}`}
										>
											<p>All</p>
										</span>
										<span
											onClick={() => setActiveFilter('Today')}
											className={`${isFilter('Today') && 'active'}`}
										>
											<p>Today</p>
										</span>
										<span
											onClick={() => setActiveFilter('Last 7 Days')}
											className={`${isFilter('Last 7 Days') && 'active'}`}
										>
											<p>Last 7 Days</p>
										</span>
										<span
											onClick={() => setActiveFilter('Last 30 Days')}
											className={`${isFilter('Last 30 Days') && 'active'}`}
										>
											<p>Last 30 Days</p>
										</span>
									</div>
									<div className="drop-down__custom-date-range">
										<h5>Custom Date Range</h5>
										<div className="custom-date-range__grouped-form">
											<RavenInputField
												type="date"
												color="deep-green-light"
												label="From"
												value={dateFilter.from}
												onChange={(e: Date) => {
													setDateFilter({
														...dateFilter,
														from: e,
													});
												}}
												placeholder="DD MM YYYY"
											/>
											<RavenInputField
												type="date"
												color="deep-green-light"
												label="To"
												value={dateFilter.to}
												onChange={(e: Date) => {
													setDateFilter({
														...dateFilter,
														to: e,
													});
												}}
												placeholder="DD MM YYYY"
											/>
										</div>
										<div className="drop-down__action-btns">
											<RavenButton
												onClick={() => {
													setDateFilter({
														to: '',
														from: '',
													});
													setActiveFilter('');
												}}
												color="green-light"
												label="Clear Filter"
											/>
											<RavenButton
												color="deep-green-light"
												label="Apply Filter"
												onClick={() => setShowFilter(false)}
											/>
										</div>
									</div>
								</div>
								<div
									onClick={() => setShowFilter(false)}
									className={`filter-export__drop-down-closer ${
										showFilter && 'filter-export__drop-down-closer--opened'
									}`}
								></div>
								{/* End of Section encompassing filter and it controls */}

								<div
									// onClick={() => onView(!showFilter)}
									className="filter-export__filter-btn"
								>
									<p>Export</p> <figure>{icons.export}</figure>
								</div>
							</div>
						</div>
						<div className="content__main">
							<div
								className={`main__active-filters ${
									(activeFilter ||
										typeof dateFilter.to === 'object' ||
										typeof dateFilter.from === 'object') &&
									'main__active-filters--show'
								}`}
							>
								<span className="active-filters__item">
									<p>
										<b>Date:</b>
										{typeof dateFilter.to === 'object' ||
										typeof dateFilter.from === 'object'
											? formatDateTime(dateFilter.from).split(',')[0] +
											  ' - ' +
											  formatDateTime(dateFilter.to).split(',')[0]
											: activeFilter}
									</p>
									<figure>{icons.x_circle}</figure>
								</span>
								<span
									onClick={() => {
										setDateFilter({
											to: '',
											from: '',
										});
										setActiveFilter('');
									}}
									className="active-filters__clear-item"
								>
									<p>Clear All</p>
								</span>
							</div>
							<div className="content__main--top-bar">
								<div className="top-bar__title">
									{/* <h5>{activeCurrency.toUpperCase()} Settlement History</h5> */}
									{/* <p className={`${demo && 'active'}`}>{`${faker.number.bigInt({
										min: '50',
										max: '700',
									})}`}</p> */}
								</div>
								<Pagination
									className="top-bar__pagination"
									currentPage={currentPage}
									itemsPerPage={10}
									totalItems={100}
									onPageChange={function (page: number): void {
										setCurrentPage(page);
									}}
								/>
							</div>

							<div className="main__wrap">
								{!demo ? (
									<EmptyScreen
										title="Configure Settlement Balance"
										subTitle="Please configure your Settlement Balance to suit your preferences."
										withMascot
										button
										// onClick={() => setShowConfiguration(true)}
										buttonLabel="Start Configuration"
										btnIcon={false}
									/>
								) : (
									<div className="transaction__table">
										<RavenTable
											action={false}
											className="table__main"
											headerList={[
												'PAYMENT TITLE',
												'TYPE',
												'AMOUNT',
												'LINK',
												'DATE CREATED',
												'',
											]}
										>
											{tableData.map((chi, idx) => {
												return (
													<React.Fragment key={idx}>
														<RavenTableRow
															className="table__main"
															six={
																<div
																	className={`date-wrap__icons ${
																		chi.disabled && 'disabled'
																	}`}
																>
																	<figure>{icons.share_link}</figure>
																	<figure
																		className="more-icon"
																		onClick={() =>
																			setShowDrop({
																				show: !showDrop.show,
																				id: idx,
																			})
																		}
																	>
																		{icons.three_dots}
																	</figure>
																	<DropMore
																		idx={idx}
																		className={isDropped(idx) && 'show-drop'}
																	/>
																</div>
															}
															five={
																<div
																	className={`main__date-wrap ${
																		chi.disabled && 'disabled'
																	}`}
																>
																	<span>{formatDateTime(chi.date)}</span>
																</div>
															}
															four={
																<div
																	className={`main__link-wrap ${
																		chi.disabled && 'disabled'
																	} `}
																>
																	<span>{chi.link.slice(0, 15) + '...'}</span>
																	<figure>{icons.copy}</figure>
																</div>
															}
															three={
																<div
																	className={`status-type-wrap ${
																		chi.disabled && 'disabled'
																	}`}
																>
																	<span>
																		{symbol(activeCurrency) +
																			formatNumWithComma(chi.amount, 'ngn')}
																	</span>
																</div>
															}
															two={
																<div
																	className={`${chi.disabled && 'disabled'}`}
																>
																	{chi.type}
																</div>
															}
															one={
																<div
																	className={`account_name ${
																		chi.disabled && 'disabled'
																	}`}
																>
																	<p className="text">
																		{' '}
																		{chi.title?.slice(0, 1).toUpperCase() +
																			`${chi.title?.slice(
																				1,
																				chi.title.length
																			)}`}
																	</p>
																</div>
															}
														/>
													</React.Fragment>
												);
											})}
										</RavenTable>
									</div>
								)}
							</div>
						</div>
					</div>
				</PageLayout>
			</DashboardLayout>
			<RavenModal
				onClose={() =>
					onView({
						show: false,
						content: '',
					})
				}
				visble={view.show}
				onBtnClick={function noRefCheck() {}}
			>
				<div className="transaction-details-modal">
					<h5 className="transaction-details-modal__title">
						Payment Link Details
					</h5>
					<div className="transaction-details-modal__amount-view">
						<div className="amount-view__direction">
							<span
								className={`direction__badge direction__badge--${view?.content?.direction}`}
							>
								{/* <figure>{icons.arrow_narrow_down_left}</figure> */}
								<p>
									{view?.content?.direction?.slice(0, 1).toUpperCase() +
										view?.content?.direction?.slice(1, 10)}
								</p>
							</span>
							{/* {view?.content?.direction === 'failed' ? (
								<span className="direction__green-checkmark failed">
									<figure>{icons.info_red}</figure>
								</span>
							) : (
								<span className="direction__green-checkmark">
									<figure>{icons.green_checkmark}</figure>
								</span>
							)} */}
						</div>
						<h5 className="amount-view__amount">
							{formatNumWithCommaNaira(view?.content?.amount)}
						</h5>
						<div className="amount-view__actions">
							<figure>{icons.export}</figure>
							<figure>{icons.share}</figure>
							<figure>{icons.dots_horizontal}</figure>
							{view?.content?.direction === 'failed' && (
								<figure>{icons.refresh_orange}</figure>
							)}
						</div>
					</div>
					<div className="transaction-details-modal__content">
						<div className="content__main">
							<div className="main__account-details">
								<div className="account-details__item">
									<p>Narration</p>
									<p>{view?.content?.narration?.slice(0, 30)}</p>
								</div>{' '}
								<div className="account-details__item">
									<p>Bank</p>
									<p>{view?.content?.type}</p>
								</div>{' '}
								<div className="account-details__item">
									<p>Bal Before</p>
									<p>$6,000.00</p>
								</div>{' '}
								<div className="account-details__item">
									<p>Bal After</p>
									<p>Wema Bank</p>
								</div>
								<div className="account-details__item">
									<p>Reference ID</p>
									<span>
										<p>2345876118</p>
										<figure>{icons.copy}</figure>
									</span>
								</div>
								<div className="account-details__item">
									<p>Fee</p>
									<p>$0.00</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</RavenModal>
		</>
	);
};

export default PaymentLinks;
