import React, { useEffect, useRef, useState } from 'react';
import DashboardLayout from '../../../layout/dashboard/dashboardLayout';
import { icons, illustrations } from '../../../assets/icons';
import {
	RavenTableRow,
	RavenTable,
	RavenModal,
	RavenInputField,
	RavenToolTip,
	RavenButton,
} from '@ravenpay/raven-bank-ui';
import './styles/index.css';
import { faker } from '@faker-js/faker';
import {
	capitalizeFirstLetter,
	formatDateTime,
	formatNumWithComma,
	handleCopy,
} from '../../../utils/helper/Helper';
import EmptyScreen from '../../../components/common/emptyScreen';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import RootState from '../../../redux/types';
import {
	getDashboardData,
	getTransactions,
	getWalletBalance,
} from '../../../redux/wallet';
import { Balance, Transactions } from '../../../@types/dashboard';
import { FaCheck } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../components/common/pagination';
import WalletSlide from './components/walletSlide';
import TransactionModal from '../../../components/common/transactionModal';
import TopupModal from '../../../components/common/topupModal';
import { logger } from '../../../services/logger';
import SmartFilter from '../../../components/common/smartFilter';
import ExportModal from '../../../components/common/exportModal';
import MobileTableCard from '../../../components/common/table/mobile/MobileTable';

interface WalletTransaction {
	ref?: string;
	amount?: number;
	bank?: string;
	response?: string;
	b_after: number;
	b_before: number;
	created_at: string;
	currency: string;
	deleted_at: null | string;
	direction: string;
	email: string;
	id: number;
	meta_data: string;
	reference: string;
	type: string;
	updated_at: string;
	_fee: number;
	_value: number;
	narration?: string;
}
const typeList = [
	{ label: 'Airtime', value: 'Airtime Recharge' },
	{ label: 'Data', value: 'Data Recharge' },
	{ label: 'Electricity', value: 'Electricity Recharge' },
	{ label: 'Transfers', value: 'transfer' },
	{ label: 'Collection', value: 'collection' },
	{
		label: 'Data Recharge Commision',
		value: 'Data Recharge Commision',
	},
	{
		label: 'Betting Payment',
		value: 'Betting Payment',
	},
];

const Wallet = () => {
	const [wallet, setWallet] = useState('ngn');
	const [alive, setAlive] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [showFund, setShowFund] = useState(false);
	const [showExport, setShowExport] = useState(false);
	const [showSwap, setShowSwap] = useState(false);
	const [trxModal, setTrxModal] = useState<{
		on: boolean;
		chi: WalletTransaction;
	}>({
		chi: {
			b_after: 0,
			b_before: 0,
			created_at: '',
			currency: '',
			deleted_at: null,
			direction: '',
			email: '',
			id: 0,
			meta_data: '',
			reference: '',
			type: '',
			updated_at: '',
			_fee: 0,
			_value: 0,
		},
		on: false,
	});

	function isWallet(wl: string) {
		if (wallet === wl) return true;
		else return false;
	}

	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	const { wallet_balance, dashboard_data, transactions, loading } = useSelector(
		(state: RootState) => state.wallet
	);

	document.addEventListener('keydown', function (event) {
		if (event.keyCode === 27) {
			setShowFund(false);
			setShowSwap(false);
		}
	});

	// Generate an array to store the table data
	const tableData = [];
	const currencies = [
		{ label: 'NGN', value: 'NGN' },
		{ label: 'USD', value: 'USD' },
		{ label: 'GBP', value: 'GBP' },
		{ label: 'RWF', value: 'RWF' },
		{ label: 'KHS', value: 'KHS' },
		{ label: 'EUR', value: 'EUR' },
	];

	const virtual_account = dashboard_data?.virtual_account;
	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const [view, onView] = useState<any>({
		show: false,
		view: '',
	});
	const [activeFilter, setActiveFilter] = useState<any>({
		type: [],
		days: '',
	});
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});
	function symbol(wallet: string) {
		if (wallet === 'ngn') {
			return '₦';
		} else if (wallet === 'usd') {
			return '$';
		} else if (wallet === 'gbp') {
			return '£';
		} else if (wallet === 'rf') {
			return 'Fr';
		} else if (wallet === 'ksh') {
			return 'KSh';
		} else if (wallet === 'eur') {
			return '€';
		} else {
			return ''; // Default case if wallet is not recognized
		}
	}

	const [activeTab, setActiveTab] = useState(1);

	const switchTab = (tabIndex: any) => {
		setActiveTab(tabIndex);
	};

	function isFilter(value: string) {
		if (value === activeFilter.days) return true;
		else return false;
	}

	function isModalTab(tab: number) {
		if (activeTab === tab) return true;
		else return false;
	}

	useEffect(() => {
		dispatch(getWalletBalance());
		dispatch(getDashboardData());
		dispatch(
			getTransactions({
				perPage: 20,
			})
		);
	}, []);

	useEffect(() => {
		dispatch(
			getTransactions({
				perPage: 20,
				page: currentPage,
			})
		);
	}, [currentPage]);

	function filterBalance() {
		if (!loading && wallet_balance.length > 0) {
			const d: Balance = wallet_balance?.filter(
				(d: { currency: string }) => d.currency === wallet?.toUpperCase()
			);
			return d[0];
		} else {
			return {
				available_bal: 0,
				ledger_bal: 0,
			};
		}
	}

	useEffect(() => {
		if (!loading && transactions.transactions) {
			setAlive(true);
		} else setAlive(false);
	}, [loading]);

	const tableRef: any = useRef(null);
	const mobileTableRef: any = useRef(null);
	const [scrollDirection, setScrollDirection] = useState<any>('top');

	const handleScroll = () => {
		const scrollTop =
			tableRef.current.scrollTop || mobileTableRef.current.scrollTop;
		const isScrollingUp =
			scrollTop < tableRef.current.lastScrollTop ||
			scrollTop < mobileTableRef.current.lastScrollTop;
		if (scrollTop === 0) {
			// User has scrolled up and reached the top of the table
			setScrollDirection('top');
			// setTimeout(() => {

			// }, 1000);
		} else if (isScrollingUp) {
			// User is scrolling up
			setScrollDirection('up');
		} else {
			// User is scrolling down
			setScrollDirection('down');
		}

		tableRef.current.lastScrollTop = scrollTop;
		mobileTableRef.current.lastScrollTop = scrollTop;
	};

	return (
		<>
			<DashboardLayout>
				<div style={{ overflow: 'hidden' }} className={`wallet ${theme}`}>
					<div className="wallet__title">
						<div className="title__main">
							<h5>Balance</h5>
							<p>Welcome back to your dashboard. here’s an overview</p>
						</div>
						{/* <div onClick={() => setShowSwap(true)} className="title__swap-btn">
							<p>Swap</p>
							<figure>{icons.refresh}</figure>
						</div> */}
					</div>
					<div style={{ zIndex: '10000' }} className="wallet__wallets-wrap">
						<div className="wallets-wrap__balances-wrap">
							{/* Add  Wallet Slide*/}

							<div
								className={`wallet_slide-wrap ${
									scrollDirection === 'top' && 'show'
								}`}
							>
								<WalletSlide
									onTopup={() => setShowFund(!showFund)}
									onWithdraw={() => navigate('/dashboard-payment/transfer/new')}
									availableBalance={{
										usd: 0,
										ngn: filterBalance()?.available_bal as number,
										gbp: 0,
										eur: 0,
									}}
									pendingBalance={{
										usd: 0,
										ngn: filterBalance()?.ledger_bal as number,
										gbp: 0,
										eur: 0,
									}}
								/>
							</div>

							{/* End  Wallet Slide*/}
							{transactions?.transactions?.length > 0 ? (
								<>
									{/* Add Filters Here */}
									<SmartFilter
										page="wallet"
										searchTitle="Search ref, sessions..."
										// onFilter={(e: string) => setActiveFilter(e)}
										onExport={() => setShowExport(!showExport)}
										value={activeFilter}
										filters={[
											{
												label: 'Type',
												options: typeList,
											},
										]}
									/>
									{/* End Filters */}
									<div className="content__main--top-bar">
										<div></div>
										<Pagination
											className="top-bar__pagination"
											currentPage={transactions?.pagination?.currentPage ?? 1}
											itemsPerPage={transactions?.pagination?.perPage ?? 0}
											totalItems={transactions?.pagination?.total ?? 0}
											onPageChange={(d) => setCurrentPage(d)}
										/>
									</div>
								</>
							) : (
								''
							)}

							{loading ||
							wallet.toUpperCase() !== 'NGN' ||
							transactions?.transactions?.length === 0 ? (
								<EmptyScreen
									subTitle="Your wallet transaction will appear here , make new transactions and have it appear"
									noMascot={scrollDirection === 'top'}
									loading={loading}
								/>
							) : (
								<>
									<div className="balances-wrap__transactions non-mobile">
										<div
											ref={tableRef}
											onScroll={handleScroll}
											className="transaction__table"
										>
											<RavenTable
												action={false}
												className="tabel__main"
												headerList={[
													'DESCRIPTION',
													'TYPE',
													'AMOUNT',
													'BALANCE BEFORE',
													'BALANCE AFTER',
													'TRANSACTION DATE',
												]}
											>
												{transactions?.transactions?.map(
													(
														chi: WalletTransaction,
														idx: React.Key | null | undefined
													) => {
														const meta_data = chi?.meta_data
															? JSON?.parse(chi?.meta_data)
															: {};

														const { narration, createdAt } = meta_data;

														return (
															<React.Fragment key={idx}>
																<RavenTableRow
																	className="table__main"
																	five={
																		<div className="status-type-wrap ">
																			<span>{`₦${formatNumWithComma(
																				chi?.b_after?.toFixed(2),
																				'ngn'
																			)}`}</span>
																		</div>
																	}
																	six={formatDateTime(chi?.created_at)}
																	onRowClick={() => {
																		if (typeof chi?.response === 'string') {
																			setTrxModal({
																				chi: {
																					...chi,
																					reference: meta_data?.ref,
																				},
																				on: true,
																			});
																		} else {
																			setTrxModal({
																				chi: chi,
																				on: true,
																			});
																		}
																	}}
																	three={
																		<div style={{ minWidth: '10rem' }}>
																			{symbol('ngn') +
																				formatNumWithComma(
																					chi?._value?.toFixed(2) ||
																						(chi?.amount
																							? chi?.amount.toFixed(2)
																							: '--'),
																					'ngn'
																				)}
																		</div>
																	}
																	one={
																		<div className="main__direction">
																			<figure>{icons[chi.direction]}</figure>
																			<p className="text">
																				{formatNarrations(
																					narration || meta_data?.ref,
																					chi,
																					meta_data,
																					symbol
																				)}
																			</p>
																		</div>
																	}
																	four={
																		<div className="type-wrap">
																			<span>
																				{'₦' +
																					formatNumWithComma(
																						chi?._value,
																						'ngn'
																					)}
																			</span>
																		</div>
																	}
																	two={`${
																		capitalizeFirstLetter(chi?.type).replaceAll(
																			'_',
																			' '
																		) || chi?.bank
																	}`}
																/>
															</React.Fragment>
														);
													}
												)}
											</RavenTable>
										</div>
									</div>

									<div
										ref={mobileTableRef}
										onScroll={handleScroll}
										className="atlas-mobile-table-wrap"
									>
										{transactions?.transactions?.map(
											(
												chi: WalletTransaction,
												idx: React.Key | null | undefined
											) => {
												let meta_data;

												if (chi?.response) {
													meta_data = chi?.response
														? JSON.parse(chi?.response)
														: {};
												} else {
													meta_data = chi?.meta_data
														? JSON.parse(chi?.meta_data)
														: {};
												}

												const { narration, createdAt } = meta_data;

												// logger.log(meta);
												return (
													<MobileTableCard
														title={
															formatNarrations(
																narration,
																chi,
																meta_data,
																symbol
															) as string
														}
														text={formatDateTime(chi.created_at)}
														amount={
															symbol('ngn') +
															formatNumWithComma(chi._value, 'ngn')
														}
														img={undefined}
														svg={undefined}
														avatar={''}
														amountColor={'#014345'}
														type={chi.direction}
														showImg={true}
														loading={loading}
														onRowClick={() =>
															setTrxModal({
																chi: chi,
																on: true,
															})
														}
														textTwo={''}
													/>
												);
											}
										)}
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</DashboardLayout>
			<TopupModal
				accountName={virtual_account?.account_name}
				bank={virtual_account?.bank}
				accountNumber={virtual_account?.account_number}
				visible={showFund}
				onClose={() => setShowFund(false)}
			></TopupModal>
			<RavenModal
				className={`swap-modal-wrap `}
				visble={showSwap}
				onClose={() => setShowSwap(false)}
				btnLabel="Fund with RavenPay"
				btnColor="deep-green-light"
				onBtnClick={() => {}}
				closeColor
			>
				<div className="swap-modal-wrap__content">
					<div className="content__title">
						<span>
							<h5>Swap Currency </h5>
							<p>Swap funds from your different wallets</p>
						</span>
					</div>
					<div className="content__form">
						<div className="form__grouped">
							<RavenInputField
								type="select"
								label="from"
								selectOption={currencies}
								placeholder="USD"
								color="deep-green-light"
							/>
							<div className="grouped__swap-icon">
								<figure>{icons.refresh}</figure>
							</div>
							<RavenInputField
								type="select"
								selectOption={currencies}
								label="to"
								placeholder="NGN"
								color="deep-green-light"
							/>
						</div>
						<RavenInputField
							color="deep-green-light"
							label="Amount*"
							type="amount"
							placeholder="5,000"
							labelClassName={'form__balance'}
							labelSpanText={'Bal: ₦1,850,000.00'}
						/>
					</div>
					<div className="content__output">
						<span className="output__rate">£1 = ₦900</span>
						<div className="output__value">
							<p>
								You are Swapping <b style={{ color: '#755AE2' }}>₦£1000</b> to
								get
							</p>
							<h5>₦900,000.00</h5>
						</div>
					</div>
				</div>
			</RavenModal>
			<TransactionModal
				onClose={() =>
					setTrxModal({
						on: false,
						chi: {
							b_after: 0,
							b_before: 0,
							created_at: '',
							currency: '',
							deleted_at: null,
							direction: '',
							email: '',
							id: 0,
							meta_data: '',
							reference: '',
							type: '',
							updated_at: '',
							_fee: 0,
							_value: 0,
						},
					})
				}
				visible={trxModal.on}
				direction={trxModal.chi.direction}
				title={'Transaction Details'}
				meta={trxModal.chi.meta_data}
				amount={trxModal.chi._value}
				onClick={() => {}}
				content={[
					// {
					// 	label: 'Narration',
					// 	value: trxModal?.chi?.narration ?? '',
					// },
					{
						label: 'Amount',
						value:
							symbol('ngn') + formatNumWithComma(trxModal.chi._value, 'ngn'),
					},
					// {
					// 	label: 'Balance Before',
					// 	value:
					// 		symbol('ngn') + formatNumWithComma(trxModal.chi.b_before, 'ngn'),
					// },
					// {
					// 	label: 'Balance Before',
					// 	value:
					// 		symbol('ngn') + formatNumWithComma(trxModal.chi.b_after, 'ngn'),
					// },
					{
						label: 'Type',
						value: capitalizeFirstLetter(trxModal.chi.type),
					},
					{
						label: 'Currency',
						value: trxModal.chi.currency,
					},
					{
						label: 'Reference',
						value: trxModal.chi.reference,
						copy: true,
					},

					{
						label: 'Created At',
						value: formatDateTime(trxModal.chi.created_at),
					},
					{
						label: 'Updated At',
						value: formatDateTime(trxModal.chi.updated_at),
					},
				]}
			/>
			<ExportModal
				visible={showExport}
				page="wallet"
				onClose={() => setShowExport(false)}
				onClick={function (): void {
					throw new Error('Function not implemented.');
				}}
				loading={false}
			/>
		</>
	);
};

export default Wallet;

function formatNarrations(
	narration: any,
	chi: WalletTransaction,
	meta_data: any,
	symbol: (wallet: string) => '₦' | '$' | '£' | 'Fr' | 'KSh' | '€' | ''
): React.ReactNode {
	return narration
		? narration?.length > 35
			? capitalizeFirstLetter(narration).slice(0, 35) + '...'
			: narration
		: chi.type === 'Airtime Recharge'
		? `${meta_data?.network} Airtime of ${
				symbol('ngn') + formatNumWithComma(meta_data?.amount, 'ngn')
		  } for ${meta_data?.phone_number}`
		: chi?.type === 'pay_wth_raven'
		? `Payment of ${
				symbol('ngn') + formatNumWithComma(meta_data?.amount, 'ngn')
		  } via Pay with Raven`
		: chi?.type === 'Bvn verification fee'
		? `${
				symbol('ngn') + formatNumWithComma(meta_data?.fee, 'ngn')
		  } deduction for bvn verification`
		: chi?.type === 'Nin verification fee'
		? `${
				symbol('ngn') + formatNumWithComma(meta_data?.fee, 'ngn')
		  } deduction for nin verification`
		: chi?.type === 'Data Recharge'
		? `${meta_data?.network} Data Subscription of ${
				symbol('ngn') + formatNumWithComma(meta_data?.amount, 'ngn')
		  } for ${meta_data?.phone_number}`
		: chi?.type === 'Electricity Recharge'
		? `${
				meta_data?.service_id +
				' Electricity Recharge of ' +
				symbol('ngn') +
				formatNumWithComma(meta_data?.amount, 'ngn')
		  } for ${meta_data?.meter_number}`
		: chi?.type === 'Cable Recharge'
		? `${meta_data?.service_id}
																				   Subscription for ${meta_data?.customer_name}`
		: chi?.type === 'Betting Payment'
		? `${symbol('ngn') + formatNumWithComma(meta_data?.amount, 'ngn')}
																				  Betting Payment`
		: narration
		? narration
		: '--';
}
