import { RavenCheckBox } from '@ravenpay/raven-bank-ui';
import React from 'react';
import './index.css';

const VerifiedName = ({ name }: { name: string }) => {
	return (
		<div
			className={`verify-identity-box ${name && 'verify-identity-box-show'}`}
		>
			<RavenCheckBox
				checked={true}
				onChange={() => {}}
				id={1}
				color={`black-light`}
				className={`check`}
			/>
			<p className="text">{name}</p>
		</div>
	);
};

export default VerifiedName;
