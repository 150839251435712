import React from 'react';
import './style/index.css';
import { IconVault } from '../../utils/helper/Helper';
import { icons } from '../../assets/icons';
const ComingSoon = ({
	className,
	text,
	position = 'top-right',
}: {
	className?: string;
	text?: string;
	position?: string;
}) => {
	return (
		<div className={`security-lock-wrap-box ${position} ${className}`}>
			{/* <figure className="img-box">
				<img
					src={IconVault(icons.lock)}
					alt=""
					className="img dull-bright-filter"
				/>
			</figure> */}
			<p className="text grey-white-color dull-bright-filter">
				{text || 'COMING SOON'}
			</p>
		</div>
	);
};

export default ComingSoon;
