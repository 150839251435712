import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import { toast } from '@ravenpay/raven-bank-ui';
import { ExportPayload } from './types';
import { logger } from '../services/logger';

export const exportTransfers = createAsyncThunk(
	'/transfers-export',
	async (payload: ExportPayload, thunkAPI) => {
		try {
			const data = await axios.get(
				`web/transfers_for_export_download?${
					payload.start_date ? 'start_date=' + payload.start_date : ''
				}${payload.end_date ? '&end_date=' + payload.end_date : ''}${
					payload.status ? '&filter_by=' + payload.status : ''
				}${payload._value ? '&filter_value=' + payload._value : ''}${
					payload.file_type ? '&file_type=' + payload.file_type : ''
				}`
			);

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setBvn(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const exportCollections = createAsyncThunk(
	'/collections-export',
	async (payload: ExportPayload, thunkAPI) => {
		try {
			const data = await axios.get(
				`web/collections_for_export_download?${
					payload.start_date ? 'start_date=' + payload.start_date : ''
				}${payload.end_date ? '&end_date=' + payload.end_date : ''}${
					payload.status ? '&filter_by=' + payload.status : ''
				}${payload._value ? '&filter_value=' + payload._value : ''}${
					payload.file_type ? '&file_type=' + payload.file_type : ''
				}`
			);

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setBvn(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const exportTransactions = createAsyncThunk(
	'/transaction-export',
	async (payload: ExportPayload, thunkAPI) => {
		logger.log(payload, 'dnin');
		try {
			const data = await axios.get(
				`web/transactions_for_export_download?${
					payload.start_date ? 'start_date=' + payload.start_date : ''
				}${payload.end_date ? '&end_date=' + payload.end_date : ''}${
					payload.status ? '&filter_by=' + payload.status : ''
				}${payload._value ? '&filter_value=' + payload._value : ''}${
					payload.file_type ? '&file_type=' + payload.file_type : ''
				}`
			);

			logger.log(data, 'dare');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setBvn(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const exportCustomers = createAsyncThunk(
	'/customers-export',
	async (payload: ExportPayload, thunkAPI) => {
		try {
			const data = await axios.get(
				`web/all_customers_export_download?${
					payload.start_date ? 'start_date=' + payload.start_date : ''
				}${payload.end_date ? '&end_date=' + payload.end_date : ''}${
					payload.status ? '&filter_by=' + payload.status : ''
				}${payload._value ? '&filter_value=' + payload._value : ''}${
					payload.file_type ? '&file_type=' + payload.file_type : ''
				}`
			);

			logger.log(payload, 'dareed');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setBvn(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const exportCable = createAsyncThunk(
	'/cable-export',
	async (payload: ExportPayload, thunkAPI) => {
		try {
			const data = await axios.get(
				`/web/cable/records/export?${
					payload.start_date ? 'start_date=' + payload.start_date : ''
				}${payload.end_date ? '&end_date=' + payload.end_date : ''}${
					payload.status ? '&filter_by=' + payload.status : ''
				}${payload._value ? '&filter_value=' + payload._value : ''}${
					payload.file_type ? '&file_type=' + payload.file_type : ''
				}`
			);

			logger.log(payload, 'dareed');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setBvn(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const exportAirtime = createAsyncThunk(
	'/airtime-export',
	async (payload: ExportPayload, thunkAPI) => {
		try {
			const data = await axios.get(
				`/web/airtime/records/export?${
					payload.start_date ? 'start_date=' + payload.start_date : ''
				}${payload.end_date ? '&end_date=' + payload.end_date : ''}${
					payload.status ? '&filter_by=' + payload.status : ''
				}${payload._value ? '&filter_value=' + payload._value : ''}${
					payload.file_type ? '&file_type=' + payload.file_type : ''
				}`
			);

			logger.log(payload, 'dareed');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setBvn(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const exportData = createAsyncThunk(
	'/data-export',
	async (payload: ExportPayload, thunkAPI) => {
		try {
			const data = await axios.get(
				`/web/data/records/export?start_date=${
					payload.start_date ? 'start_date=' + payload.start_date : ''
				}${payload.end_date ? '&end_date=' + payload.end_date : ''}${
					payload.status ? '&filter_by=' + payload.status : ''
				}${payload._value ? '&filter_value=' + payload._value : ''}${
					payload.file_type !== 'undefined'
						? '&file_type=' + payload.file_type
						: ''
				}`
			);

			logger.log(payload, 'dareed');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setBvn(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const exportElectricity = createAsyncThunk(
	'/electricity-export',
	async (payload: ExportPayload, thunkAPI) => {
		try {
			const data = await axios.get(
				`/web/electricity/records/export?${
					payload.start_date ? 'start_date=' + payload.start_date : ''
				}${payload.end_date ? '&end_date=' + payload.end_date : ''}${
					payload.status ? '&filter_by=' + payload.status : ''
				}${payload._value ? '&filter_value=' + payload._value : ''}${
					payload.file_type ? '&file_type=' + payload.file_type : ''
				}`
			);

			logger.log(payload, 'dareed');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setBvn(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const exportBetting = createAsyncThunk(
	'/betting-export',
	async (payload: ExportPayload, thunkAPI) => {
		try {
			const data = await axios.get(
				`/web/bet_records/export?${
					payload.start_date ? 'start_date=' + payload.start_date : ''
				}${payload.end_date ? '&end_date=' + payload.end_date : ''}${
					payload.status ? '&filter_by=' + payload.status : ''
				}${payload._value ? '&filter_value=' + payload._value : ''}${
					payload.file_type ? '&file_type=' + payload.file_type : ''
				}`
			);

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setBvn(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const exportVirtualAccount = createAsyncThunk(
	'/virtual-account-export',
	async (payload: ExportPayload, thunkAPI) => {
		try {
			const data = await axios.get(
				`/web/virtual_account_for_export_download?${
					payload.start_date ? 'start_date=' + payload.start_date : ''
				}${payload.end_date ? '&end_date=' + payload.end_date : ''}${
					payload.status ? '&filter_by=' + payload.status : ''
				}${payload._value ? '&filter_value=' + payload._value : ''}${
					payload.file_type ? '&file_type=' + payload.file_type : ''
				}`
			);

			logger.log(data, 'vAccouts export');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				// thunkAPI.dispatch(setBvn(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const initialState: any = {
	loading: false,
};

export const exportsSlice = createSlice({
	name: 'exports',
	initialState,

	reducers: {
		setProfile: (state, action) => {
			state.profile = action.payload;
			state.isAuth = true;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(exportTransfers.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(exportTransfers.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(exportTransfers.rejected, () => {
			return initialState;
		});

		builder.addCase(exportCollections.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(exportCollections.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(exportCollections.rejected, () => {
			return initialState;
		});

		builder.addCase(exportCustomers.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(exportCustomers.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(exportCustomers.rejected, () => {
			return initialState;
		});

		builder.addCase(exportTransactions.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(exportTransactions.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(exportTransactions.rejected, () => {
			return initialState;
		});

		builder.addCase(exportAirtime.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(exportAirtime.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(exportAirtime.rejected, () => {
			return initialState;
		});

		builder.addCase(exportBetting.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(exportBetting.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(exportBetting.rejected, () => {
			return initialState;
		});

		builder.addCase(exportCable.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(exportCable.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(exportCable.rejected, () => {
			return initialState;
		});

		builder.addCase(exportElectricity.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(exportElectricity.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(exportElectricity.rejected, () => {
			return initialState;
		});

		builder.addCase(exportData.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(exportData.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(exportData.rejected, () => {
			return initialState;
		});

		builder.addCase(exportVirtualAccount.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(exportVirtualAccount.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(exportVirtualAccount.rejected, () => {
			return initialState;
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = exportsSlice.actions;

export default exportsSlice.reducer;
