import React, { MouseEventHandler } from 'react';
import { icons } from '../../../../../assets/icons';
import './style/index.css';
import { DetectNetwork } from '../../../../../utils/helper/DetectNetwork';
import { formatNumWithComma, symbol } from '../../../../../utils/helper/Helper';
import ComingSoon from '../../../../../components/comingSoon';

let currencies = [
	{
		item: 'ngn',
		active: true,
	},
	{
		item: 'usd',
		active: false,
	},
	{
		item: 'gbp',
		active: false,
	},
	{
		item: 'eur',
		active: false,
	},
];
type Balance = {
	[key: string]: number;
	usd: number;
	ngn: number;
	gbp: number;
	eur: number;
};
const WalletSlide = ({
	onTopup,
	onWithdraw,
	availableBalance,
	pendingBalance,
}: {
	onTopup?: MouseEventHandler<HTMLButtonElement> | undefined;
	onWithdraw?: MouseEventHandler<HTMLButtonElement> | undefined;
	availableBalance: Balance;
	pendingBalance: Balance;
}) => {
	return (
		<div className="walletslide">
			{currencies.map((chi: { item: string; active: boolean }, idx) => {
				return (
					<div
						style={{ cursor: chi.item !== 'ngn' ? 'no-drop' : 'pointer' }}
						key={idx}
						className={`walletslide__wallet ${chi.item === 'ngn' && 'active'}`}
					>
						<figure className="walletslide__wallet--flag">
							{icons[chi.item]}
							{chi.item !== 'ngn' && <ComingSoon />}
						</figure>
						<div className="walletslide__wallet--available-balance">
							<p>Available Balance</p>
							<span>
								{symbol(chi.item) +
									formatNumWithComma(
										typeof availableBalance[chi.item]
											? availableBalance[chi.item]
											: 0,
										'ngn'
									)}
							</span>
						</div>

						<div
							style={{ opacity: !chi.active ? '0.5' : '1' }}
							className="walletslide__wallet--buttons"
						>
							<button
								style={{ cursor: !chi.active ? 'no-drop' : 'cursor' }}
								onClick={chi.active ? onTopup : () => {}}
								className="buttons__topup"
							>
								Top Up
							</button>
							<button
								style={{ cursor: !chi.active ? 'no-drop' : 'cursor' }}
								onClick={chi.active ? onWithdraw : () => {}}
								className="buttons__withdraw"
							>
								Transfer
							</button>
						</div>

						<p className="walletslide__wallet--pending-balance">
							Pending Balance:{' '}
							<b>
								{symbol(chi.item) +
									formatNumWithComma(
										typeof availableBalance[chi.item]
											? pendingBalance[chi.item]
											: 0
									)}
							</b>
						</p>
					</div>
				);
			})}
		</div>
	);
};

DetectNetwork;
export default WalletSlide;
