import {
	RavenButton,
	RavenCheckBox,
	RavenInputField,
	toast,
} from '@ravenpay/raven-bank-ui';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DashboardLayout from '../../../../../../../layout/dashboard/dashboardLayout';
import { FaTimes } from 'react-icons/fa';
import PreviewDetailsBox from './PreviewDetailsBox';
// import ConfirmTransactionPinModal from "../ConfirmPinModal";

import { useDispatch } from 'react-redux';
import {
	IconVault,
	formatNumWithComma,
	generateReference,
	removeCommaAndNairaSign,
} from '../../../../../../../utils/helper/Helper';
import ActionContext from '../../../../../../../context/ActionContext';
import { useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';

import useDebounce from '../../../../../../../hooks/UseDebounce';
import useWindowSize from '../../../../../../../hooks/UseWindowSize';
import ContainerLayout from '../../../../../../../layout/dashboard/container';
import { brands, icons } from '../../../../../../../assets/icons';
import './styles/index.css';
import ViewAll from './ViewAll';
import ConfirmTransactionPinModal from '../../common/ConfirmPinModal';
import {
	initiateMultipleTransfer,
	initiateSingleTransfer,
	resolveRavenUsername,
} from '../../../../../../../redux/transaction';
import RootState, { TransferPayload } from '../../../../../../../redux/types';
import { verificationLoader } from '../../../../../../../components/common/verificationLoader';
import { logger } from '../../../../../../../services/logger';
import PreviewModal from './PreviewModal';

type Modal = {
	pin: boolean;
	success: boolean;
};
const TransferRaven = () => {
	const [showPreview, setShowPreview] = useState(false);
	const [hovered, setHovered] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [step, setStep] = useState<number | string>(1);

	const stepList = ['Transfer detail', 'Confirm Transactions'];
	const [viewAll, setViewAll] = useState(false);
	const [ravenName, setRavenName] = useState('');
	const [showModal, setShowModal] = useState<Modal>({
		pin: false,
		success: false,
	});
	const [details, setDetails] = useState<any>({
		username: '',
		amount: '',
		narration: 'Sent from raven',
		bulk_list: [],
		currency: 'ngn',
		validatedName: '',
		beneficiary: false,
		schedule: '',
		transfer_pin: '',
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...details, [name]: value };
		setDetails(obj);
	};

	const { loading } = useSelector((state: RootState) => state.transaction);
	const { dashboard_data } = useSelector((state: RootState) => state.wallet);

	const handleBulkTransfer = () => {
		const checkList = details?.bulk_list?.some(
			(chi: any) => chi?.username === details?.username
		);
		if (!checkList) {
			const obj = {
				account_name: ravenName,
				username: details?.username,
				amount: removeCommaAndNairaSign(details?.amount),
				narration: details?.narration,
				beneficiary: false,
				identifier: details?.username,
				reference: details?.reference,
				currency: details?.currency,
			};
			const newList = [obj, ...details?.bulk_list];
			setDetails((prev: any) => {
				return {
					...prev,
					bulk_list: newList,
					username: '',
					amount: '',
					narration: 'Sent from raven',
				};
			});
			setRavenName('');
			// setVerify(false);
		} else {
			toast.error('Recepient already exist in bulk list');
		}
	};

	const handleRemoveFromList = (obj: { username: any }) => {
		if (details?.bulk_list?.length === 1) {
			setViewAll(false);
		}
		const newList = details?.bulk_list?.filter(
			(chi: { username: any }) => chi?.username !== obj?.username
		);
		setDetails((prev: any) => {
			return {
				...prev,
				bulk_list: newList,
			};
		});
	};

	const [error, setError] = useState({
		username: false,
	});
	const [loadingVery, setLoadingVerify] = useState(false);
	const validateUsername = async (username: string) => {
		setLoadingVerify(true);
		const name = await dispatch(resolveRavenUsername({ identifier: username }));
		if (name?.payload?.status === 'success') {
			setLoadingVerify(false);

			setRavenName(name?.payload?.data?.data?.account_name);
			setDetails((e: any) => {
				return {
					...e,
					validated_name: name?.payload?.data?.data,
				};
			});
		}
	};

	const debouncedSearchTerm = useDebounce(details?.username, 1000);
	useEffect(() => {
		let isMount = true;

		if (isMount && debouncedSearchTerm?.length > 3) {
			validateUsername(details?.username);
		}

		if (isMount && debouncedSearchTerm?.length < 1) {
			setRavenName('');
		}

		return () => {
			isMount = false;
		};
	}, [debouncedSearchTerm]);

	function disableAlgorithm() {
		if (details.amount && details.username) {
			return false;
		} else if ((details.username && !details.amount) || !details.narration) {
			return true;
		} else if (details.amount && (!details.narration || !details.username)) {
			return true;
		} else if (details.bulk_list.length === 0) {
			return true;
		} else {
			return false;
		}
	}

	function handleInitPayment() {
		if (!details.username) {
			return true;
		}
		if (!details.amount) {
			return true;
		} else return false;
	}

	// logger.log(details);

	async function initiateTransfer() {
		const payloa: TransferPayload = {
			amount: removeCommaAndNairaSign(
				details?.bulk_list[0]?.amount
			) as unknown as number,
			bank_code: '615' as string,
			bank: 'Raven Bank' as string,
			account_number: '0000000000',
			account_name: details?.bulk_list[0]?.account_name as string,
			narration: details?.bulk_list[0]?.narration,
			currency: 'NGN',
			identifier: details?.bulk_list[0]?.identifier,
			reference: generateReference(),
		};
		const payload: { recipients: TransferPayload[] } = {
			recipients: details?.bulk_list,
		};
		logger.log({ recipients: details?.bulk_list });

		const response = await dispatch(initiateMultipleTransfer(payload));

		if (response?.payload?.status === 'success') {
			setShowModal({
				pin: false,
				success: false,
			});
			navigate('/dashboard-payment/transfer');
		}
	}

	// logger.log(details);

	// useEffect(() => {
	// 	let triggered = false;
	// 	if (showPreview && !hovered && !triggered) {
	// 		const t = setTimeout(() => {
	// 			setShowPreview(false);
	// 			triggered = true;
	// 		}, 2000);

	// 		if (hovered) {
	// 			clearTimeout(t);
	// 			logger.log('hovered', t);
	// 			triggered = false;
	// 		}
	// 	}
	// }, [showPreview, hovered]);
	return (
		<>
			<DashboardLayout>
				{step !== 'view-all' && (
					<ContainerLayout
						isLatest
						activeStep={step as number}
						steps={stepList}
						btnLoading={loading}
						btnDisabled={disableAlgorithm()}
						onBtnClick={() => {
							if (step !== 2) {
								if (handleInitPayment()) setStep(2);
								else setStep(2), handleBulkTransfer();
							} else {
								initiateTransfer();
							}
						}}
						btnColor="deep-green-light"
						onBack={() => {
							navigate('/dashboard-payment/transfer');
						}}
						onPrev={() => {
							step === 1
								? navigate('/dashboard-payment/transfer/new')
								: setStep(1);
						}}
						pageTitle="Transfer to Raven User"
						pageSubtitle="Send money to a raven user via their username."
						style={{
							width: '100%',
							backgroundColor: step === 2 ? 'unset' : '',
							border: step === 2 ? 'unset' : '',
							padding: step === 2 ? 'unset' : '',
						}}
					>
						<div
							className={`atlas-payment-container animate-move-up-class ${
								step === 2 && 'expanded'
							} `}
						>
							<form
								onSubmit={(e) => {
									e.preventDefault();
								}}
								action=""
								className="form"
							>
								{/* step 1 start ------------------------------ */}
								{step === 1 && (
									<>
										<div className="raven__form-global">
											{/* end of bg box */}
											<RavenInputField
												type={`text`}
												color={`black-light`}
												label={`Raven Username* `}
												value={details?.username}
												onChange={(e: any) => {
													handleChange(e);
													setError((prev) => {
														return { ...prev, username: false };
													});
												}}
												placeholder={`E.g mona`}
												labelColor={`purple-light`}
												showError={error?.username}
												labelSpanText={verificationLoader(loadingVery)}
												labelClassName={`label-class-span ${
													details?.username?.length > 3 &&
													'label-class-span-active'
												}`}
												name={`username`}
												onActionClick={() => {
													details?.username.length > 3 &&
														validateUsername(details?.username);
												}}
											/>
											{/* verify name box start */}
											<div
												className={`verify-name-box ${
													ravenName && 'verify-name-box-show'
												}`}
											>
												<RavenCheckBox
													checked={true}
													onChange={() => {}}
													id={1}
													color={`black-light`}
													className={`check`}
												/>
												<p className="text">{ravenName}</p>
											</div>
											{/* verify name box end */}
											<RavenInputField
												type={`number`}
												color={`black-light`}
												label={`Amount* `}
												name={`amount`}
												value={details?.amount}
												onChange={handleChange}
												placeholder={`How much are you sending`}
												labelColor={`black-light`}
												labelSpanText={
													typeof dashboard_data?.balances !== 'undefined'
														? `Bal: ₦ ${formatNumWithComma(
																dashboard_data?.balances[0]?.available_bal,
																'ngn'
														  )} `
														: 'Bal: ₦ ••••••'
												}
												labelClassName={`label-span-amount label-span-theme`}
												thousandFormat
												numberPrefix={`₦`}
												onActionClick={() => {}}
											/>
											<RavenInputField
												type={`text`}
												color={`black-light`}
												label={`Narration* `}
												value={details?.narration}
												onChange={handleChange}
												placeholder={`E.g From timi`}
												name={`narration`}
											/>
											{/* add another text start */}
											<div
												onClick={() => {
													details?.amount &&
														details?.username &&
														ravenName &&
														handleBulkTransfer();
												}}
												className={`add-another-box ${
													details?.amount &&
													details?.username &&
													ravenName &&
													'add-another-box-active'
												}`}
											>
												<figure className="img-box">
													<img
														src={IconVault(icons.plus_circle_white)}
														alt=""
														className="img"
													/>
													{}
												</figure>
												<p className="text">Add New Recipient</p>
											</div>
											{/* add another text end */}
											{/* recepient box start */}
											<div
												className={`recepient-box ${
													details?.bulk_list?.length > 0 && 'recepient-box-show'
												}`}
											>
												{/* <div className="text-line">
													<span></span>
													<p className="text">Recipients</p>
													<span></span>
												</div> */}
												<div className="view-total-box">
													<div className="view-wrap">
														{viewAll ? (
															<p
																onClick={() => {
																	setViewAll(false);
																	details?.bulk_list?.length === 1 &&
																		setViewAll(false);
																}}
																className="text"
															>
																Close
															</p>
														) : (
															<div className="view-wrap__container">
																{details?.bulk_list?.length > 2 && (
																	<span>
																		<p>+{details?.bulk_list?.length - 2}</p>
																	</span>
																)}
																<p
																	// onClick={() => {
																	// 	setStep('view-all');
																	// }}

																	onMouseEnter={() => setShowPreview(true)}
																	className="text"
																>
																	View all
																	<figure>{icons.arrow_right_purple}</figure>
																</p>
															</div>
														)}
													</div>

													<div
														onMouseLeave={() => {
															setShowPreview(false);
															setHovered(false);
														}}
														onMouseEnter={() => setHovered(true)}
														className={`preview-modal ${showPreview && 'show'}`}
													>
														<PreviewModal
															onRemove={(e) =>
																setDetails({
																	...details,
																	bulk_list: e.length > 0 ? e : [],
																})
															}
															content={details?.bulk_list}
														/>
													</div>
												</div>
												{/* end of view total box */}

												{/* preview list start */}
												{!viewAll && (
													<div className="preview-box">
														{details?.bulk_list?.slice(0, 2).map(
															(
																chi: {
																	username: string;
																	account_name: string;
																	amount: any;
																},
																idx: React.Key | null | undefined
															) => {
																const { username, amount } = chi;
																return (
																	<div
																		className="box box-card grey-bg"
																		key={idx}
																	>
																		<div
																			onClick={() => {
																				handleRemoveFromList(chi);
																			}}
																			className="cancel-box"
																		>
																			<FaTimes className="icon" />
																		</div>
																		<figure className="img-box">
																			<img
																				src={IconVault(brands.raven)}
																				alt=""
																				className="img"
																			/>
																		</figure>
																		<p className="name  raven-name">
																			{chi.account_name}
																		</p>
																		<p className="text grey-white-color">{`${
																			username || '---'
																		} • ${amount}`}</p>
																	</div>
																);
															}
														)}
													</div>
												)}

												{/* preview list end */}
											</div>
										</div>

										{/* recepient box end */}
									</>
								)}
								{/* step 1 end -------------------------------- */}

								{/* step 2 start ----------------------------- */}
								{step === 2 && (
									<div className="step-two-transfer-raven animate-move-up-class">
										{details?.bulk_list.length === 1 ? (
											<>
												{details?.bulk_list?.map(
													(chi: any, idx: React.Key | null | undefined) => {
														return (
															<PreviewDetailsBox
																key={idx}
																loading={loading}
																onBtnClick={() =>
																	// setShowModal({
																	// 	pin: true,
																	// 	success: false,
																	// })
																	initiateTransfer()
																}
																content={chi}
															/>
														);
													}
												)}
											</>
										) : (
											<>
												<PreviewDetailsBox
													multi
													onBtnClick={() =>
														// setShowModal({
														// 	pin: true,
														// 	success: false,
														// })
														initiateTransfer()
													}
													content={details?.bulk_list}
												/>
											</>
										)}
									</div>
								)}
							</form>

							{/* view all end ----------------------------- */}
						</div>
					</ContainerLayout>
				)}

				{step === 'view-all' && (
					<ViewAll
						onBack={() => {
							setStep(1);
						}}
						onChange={(e: any) => setDetails(e)}
						items={details}
					/>
				)}
			</DashboardLayout>
			<ConfirmTransactionPinModal
				onBtnClick={() => {
					toast.success(
						'Transfer of ₦20,000 to 2135876118  UBA is on it’s way.'
					);
					setShowModal({
						pin: false,
						success: false,
					});
					navigate('/dashboard-payment/transfer');
				}}
				onClose={() =>
					setShowModal({
						pin: false,
						success: false,
					})
				}
				visible={showModal.pin}
			/>
		</>
	);
};

export default TransferRaven;
