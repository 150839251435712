import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../layout/dashboard/dashboardLayout';
import { icons } from '../../../assets/icons';

import {
	RavenTableRow,
	RavenTable,
	RavenModal,
	RavenInputField,
	RavenButton,
} from '@ravenpay/raven-bank-ui';
import './styles/index.css';
import { faker } from '@faker-js/faker';
import {
	capitalizeFirstLetter,
	formatDateTime,
	formatNumWithComma,
	formatNumWithCommaNaira,
	formatStatus,
	insertUrlParam,
	returnInitial,
	searchParams,
	symbol,
} from '../../../utils/helper/Helper';
import EmptyScreen from '../../../components/common/emptyScreen';
import { useDarkMode } from '../../../hooks/useDarkMode';
import Pagination from '../../../components/common/pagination';
import { useNavigate } from 'react-router-dom';
import BusinessProfile from './components/businessProfile';
import VerificationSettings from './components/verification';
import { generateRandomColor } from '../../../utils/helper/color';
import SettingsTeam, { TeamFormData } from './components/team';
import SettingsDirector from './components/directors';
import SettingsWebhooksKeys from './components/webhooksKeys';
import SettingsSecurity from './components/security';
import SettingsPreferences from './components/preferences';
import SettingsPayments from './components/payments';
import AddTeamMember from './components/modalContents/AddTeamMember';
import ManageDirector from './components/modalContents/ManageDirector';
import { useDispatch, useSelector } from 'react-redux';
import {
	changePassword,
	getProfile,
	getSettingsConfig,
	getTeams,
	inviteTeamMember,
} from '../../../redux/settings';
import RootState, { InviteTeamPayload } from '../../../redux/types';
import { AppDispatch } from '../../../redux/store';
import { logger } from '../../../services/logger';
import { updateTeamMember } from '../../../redux/auth';
import ChangePassword from './components/modalContents/ChangePassword';

type activeSettings =
	| 'business-profile'
	| 'verification'
	| 'team'
	| 'keys-webhook'
	| 'preference'
	| 'security';

type Modal = {
	add_director?: boolean;
	manage_director?: boolean;
	add_team_member?: boolean;
	manage_team_member?: boolean;
	chi?: TeamFormData;
};

export interface InviteTeamState {
	email: string;
	fname: string;
	lname: string;
	clearance: {
		value: string;
		label: string;
	};
}
const Settings = () => {
	const [activeSettings, setActiveSettings] = useState<activeSettings | string>(
		'business-profile'
	);
	const [demo, setDemo] = useState(false);
	const [inviteData, setInviteData] = useState<InviteTeamState>({
		email: '',
		fname: '',
		lname: '',
		clearance: {
			value: '',
			label: '',
		},
	});

	const [updateData, setUpdateData] = useState<TeamFormData>({
		merchant_email: '',
		fname: '',
		lname: '',
		clearance: '',
	});

	const [changePasswordForm, setChangePasswordForm] = useState<{
		old_password: string;
		new_password: string;
		confirm_password: string;
	}>({
		old_password: '',
		new_password: '',
		confirm_password: '',
	});

	const [changePwdStep, setChangePwdStep] = useState(1);
	const [showChangePwdModal, setShowChangePwdModal] = useState(false);

	const [showModal, setShowModal] = useState<Modal>({
		add_team_member: false,
		manage_team_member: false,
		manage_director: false,
		add_director: false,
		chi: {
			fname: '',
			lname: '',
			merchant_email: '',
			clearance: '',
		},
	});
	const [view, onView] = useState<{ show: boolean; content: any }>({
		show: false,
		content: {},
	});

	function isSettings(wl: string) {
		if (activeSettings === wl) return true;
		else return false;
	}

	document.addEventListener('keydown', function (event) {
		if (event.keyCode === 27) {
			onView({
				show: !view.show,
				content: '',
			});
		}
	});

	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const { config, loading, profile } = useSelector(
		(state: RootState) => state.settings
	);

	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		const handleKeyDown = (event: { metaKey: unknown; key: string }) => {
			// Check if Cmd + D is pressed
			if (event.metaKey && event.key === 'm') {
				setDemo(!demo);
			}
		};

		// Add event listener when the component mounts
		window.addEventListener('keydown', handleKeyDown);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [demo]);

	useEffect(() => {
		insertUrlParam(
			'active-setting',
			activeSettings,
			`${capitalizeFirstLetter(
				`${activeSettings}`
			)} Bills Payment | Raven Atlas`,
			true
		);
	}, [activeSettings]);

	useEffect(() => {
		const billie: activeSettings | null | string =
			searchParams.get('active-setting');
		billie !== null ? setActiveSettings(billie) : '';
	}, []);

	useEffect(() => {
		dispatch(getSettingsConfig());
		dispatch(getProfile());
	}, []);

	async function handleInviteTeam() {
		const resp = await dispatch(
			inviteTeamMember({
				...inviteData,
				clearance: inviteData.clearance?.value,
			})
		);

		if (resp?.payload?.status === 'success') {
			dispatch(getTeams());
			setShowModal({
				add_team_member: false,
				manage_team_member: false,
			});
		}
	}

	let updateLoading = false;

	async function updateTeam() {
		updateLoading = true;
		const resp = await dispatch(
			updateTeamMember({ ...updateData, email: updateData?.merchant_email })
		);

		if (resp?.payload?.status === 'success') {
			updateLoading = false;
			setShowModal({
				manage_director: false,
				add_director: false,
			});
		} else {
			updateLoading = false;
		}
	}

	async function handleChangePassword() {
		const resp = await dispatch(changePassword(changePasswordForm));

		logger.log(changePasswordForm);
		if (resp?.payload?.status === 'success') {
			setShowChangePwdModal(false);
		}
	}

	return (
		<>
			<DashboardLayout>
				<div className={`settings ${theme}`}>
					<div className="settings__title">
						<div className="title__main">
							<h5>Settings</h5>
							<p>Manage your account and profile settings</p>
						</div>
					</div>
					<div className="settings__sidebar">
						<div className="sidebar__selector">
							<div
								onClick={() => setActiveSettings('business-profile')}
								className={`selector__item ${
									isSettings('business-profile') && 'active'
								}`}
							>
								<p>Business Profile</p>
							</div>
							<div
								onClick={() => setActiveSettings('verification')}
								className={`selector__item ${
									isSettings('verification') && 'active'
								}`}
							>
								<p>Verification</p>
							</div>
							<div
								onClick={() => setActiveSettings('team')}
								className={`selector__item ${isSettings('team') && 'active'}`}
							>
								<p>Team</p>
							</div>
							<div
								onClick={() => setActiveSettings('directors')}
								className={`selector__item ${
									isSettings('directors') && 'active'
								}`}
							>
								<p>Directors</p>
							</div>
							<div
								onClick={() => setActiveSettings('keys-webhook')}
								className={`selector__item ${
									isSettings('keys-webhook') && 'active'
								}`}
							>
								<p>Keys and Webhooks</p>
							</div>
							<div
								onClick={() => setActiveSettings('security')}
								className={`selector__item ${
									isSettings('security') && 'active'
								}`}
							>
								<p>Security</p>
							</div>
							{/* <div
								onClick={() => setActiveSettings('preference')}
								className={`selector__item ${
									isSettings('preference') && 'active'
								}`}
							>
								<p>Preference</p>
							</div> */}
							<div
								onClick={() => setActiveSettings('payment')}
								className={`selector__item ${
									isSettings('payment') && 'active'
								}`}
							>
								<p>Payment</p>
							</div>{' '}
						</div>

						{isSettings('business-profile') && (
							<BusinessProfile profile={profile} />
						)}

						{isSettings('verification') && <VerificationSettings />}

						{/* Teams Settings Page */}
						{isSettings('team') && (
							<SettingsTeam
								addMember={() => {
									setShowModal({
										add_team_member: true,
										manage_team_member: false,
									});
								}}
								manageMember={(chi) =>
									setShowModal({
										chi: chi,
										manage_team_member: true,
										add_team_member: false,
									})
								}
								demo={demo}
							/>
						)}
						{/* End of Teams Settings Page */}

						{/* Teams Settings Page */}
						{isSettings('directors') && (
							<SettingsDirector
								addDirector={() => {
									setShowModal({
										add_director: true,
										manage_director: false,
									});
								}}
								manageDirector={() =>
									setShowModal({
										manage_director: true,
										add_director: false,
									})
								}
								demo={demo}
							/>
						)}
						{/* End of Teams Settings Page */}

						{/* Teams Settings Page */}
						{isSettings('keys-webhook') && (
							<SettingsWebhooksKeys keys={config} />
						)}
						{/* End of Teams Settings Page */}

						{/* Teams Settings Page */}
						{isSettings('security') && (
							<SettingsSecurity
								onChangePassword={() =>
									setShowChangePwdModal(!showChangePwdModal)
								}
							/>
						)}
						{/* End of Teams Settings Page */}

						{/* Teams Settings Page */}
						{isSettings('preference') && <SettingsPreferences />}
						{/* End of Teams Settings Page */}

						{/* Teams Settings Page */}
						{isSettings('payment') && <SettingsPayments />}
						{/* End of Teams Settings Page */}
					</div>
				</div>
			</DashboardLayout>
			<RavenModal
				onClose={() =>
					onView({
						show: false,
						content: '',
					})
				}
				visble={view.show}
				onBtnClick={function noRefCheck() {}}
			>
				<div className="transaction-details-modal">
					<h5 className="transaction-details-modal__title">Transfer Details</h5>
					<div className="transaction-details-modal__amount-view">
						<div className="amount-view__direction">
							<span
								className={`direction__badge direction__badge--${view?.content?.direction}`}
							>
								{/* <figure>{icons.arrow_narrow_down_left}</figure> */}
								<p>
									{view?.content?.direction?.slice(0, 1).toUpperCase() +
										view?.content?.direction?.slice(1, 10)}
								</p>
							</span>
							{/* {view?.content?.direction === 'failed' ? (
								<span className="direction__green-checkmark failed">
									<figure>{icons.info_red}</figure>
								</span>
							) : (
								<span className="direction__green-checkmark">
									<figure>{icons.green_checkmark}</figure>
								</span>
							)} */}
						</div>
						<h5 className="amount-view__amount">
							{formatNumWithCommaNaira(view?.content?.amount)}
						</h5>
						<div className="amount-view__actions">
							<figure>{icons.export}</figure>
							<figure>{icons.share}</figure>
							<figure>{icons.dots_horizontal}</figure>
							{view?.content?.direction === 'failed' && (
								<figure>{icons.refresh_orange}</figure>
							)}
						</div>
					</div>
					<div className="transaction-details-modal__content">
						<div className="content__main">
							<div className="main__account-details">
								<div className="account-details__item">
									<p>Narration</p>
									<p>{view?.content?.narration?.slice(0, 30)}</p>
								</div>{' '}
								<div className="account-details__item">
									<p>Bank</p>
									<p>{view?.content?.type}</p>
								</div>{' '}
								<div className="account-details__item">
									<p>Bal Before</p>
									<p>$6,000.00</p>
								</div>{' '}
								<div className="account-details__item">
									<p>Bal After</p>
									<p>Wema Bank</p>
								</div>
								<div className="account-details__item">
									<p>
										{activeSettings !== 'business-profile' &&
										activeSettings !== 'verification'
											? 'Token'
											: 'Reference ID'}
									</p>
									<span>
										<p>2345876118</p>
										<figure>{icons.copy}</figure>
									</span>
								</div>
								<div className="account-details__item">
									<p>Fee</p>
									<p>$0.00</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</RavenModal>

			<RavenModal
				btnLabel="Add Member"
				btnColor="deep-green-light"
				onBtnClick={handleInviteTeam}
				loading={loading}
				disabled={
					!inviteData?.clearance?.value ||
					!inviteData.fname ||
					!inviteData.email ||
					!inviteData.lname
				}
				visble={showModal.add_team_member}
				onClose={() => {
					setInviteData({
						email: '',
						fname: '',
						lname: '',
						clearance: {
							value: '',
							label: '',
						},
					});
					setShowModal({
						add_team_member: false,
						manage_team_member: false,
					});
				}}
			>
				<AddTeamMember
					formData={inviteData}
					onChange={(e) => setInviteData(e)}
				/>
			</RavenModal>

			<RavenModal
				btnLabel="Add Member"
				btnColor="deep-green-light"
				onBtnClick={() => {}}
				visble={showModal.add_director}
				onClose={() =>
					setShowModal({
						add_director: false,
					})
				}
			>
				{/* <AddTeamMember /> */}
			</RavenModal>
			<RavenModal
				btnLabel="Save Changes"
				btnColor="deep-green-light"
				onBtnClick={updateTeam}
				loading={updateLoading as unknown as boolean}
				visble={showModal.manage_team_member}
				onClose={() =>
					setShowModal({
						manage_team_member: false,
						add_team_member: false,
					})
				}
			>
				<ManageDirector
					onChange={(e) => setUpdateData(e)}
					chi={showModal.chi}
				/>
			</RavenModal>

			{/* Change Transaction Pin Starts here */}
			<RavenModal
				visble={showChangePwdModal}
				className={'change-password-modal'}
				btnColor="deep-green-light"
				loading={loading}
				btnLabel={changePwdStep === 1 ? 'Continue' : 'Save Password'}
				onBtnClick={() => {
					changePwdStep === 1 ? setChangePwdStep(2) : handleChangePassword();
				}}
				onClose={() => setShowChangePwdModal(false)}
			>
				<ChangePassword
					onStepChange={(e) => setChangePwdStep(e)}
					formData={changePasswordForm}
					step={changePwdStep}
					onChange={function (e) {
						setChangePasswordForm(e);
					}}
				/>
			</RavenModal>
		</>
	);
};

export default Settings;
