import React, { Fragment } from 'react';
import {
	darkTheme,
	GlobalStyles,
	lightTheme,
} from '../../../utils/themes/theme';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { ThemeProvider } from 'styled-components';
import './style/index.css';
import ravenLoader from '../../../assets/images/raven-loader.gif';
import HeaderNavComponent from '../headerNav';
import SideMenuComponent from '../sideMenu';
import GetUser from '../../../components/common/getUser/index';
import { RavenActionBar } from '@ravenpay/raven-bank-ui';
import RootState from '../../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import { logger } from '../../../services/logger';
import { useNavigate } from 'react-router-dom';
import { toggleMenu } from '../../../redux/actions/actions';

interface MyComponentProps {
	children?: React.ReactNode;
	loading?: boolean;
}

type ToastType = 'none' | 'warning' | 'info' | 'error' | undefined;

const DashboardLayout: React.FC<MyComponentProps> = ({
	children,
	loading,
}: MyComponentProps) => {
	const { isMenuOpen } = useSelector((state: RootState) => state.general);
	const { profile } = useSelector((state: RootState) => state.wallet);
	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const themeMode = theme === 'light' ? lightTheme : darkTheme;

	const { bvn, verified } = profile?.merchantInfo ?? { bvn: 0, verified: 0 };
	const {
		address_status,
		document_verification_status,
		cac_verified,
		registration_number,
		transaction_range,
		industry,
		business_address,
	} = profile?.businessInfo ?? {
		address_status: 0,
		document_verification_status: 0,
		cac_verified: 0,
		registration_number: null,
		transaction_range: null,
		industry: null,
		business_address: null,
	};

	const navigate = useNavigate();
	const dispatch = useDispatch();

	function toggleNav() {
		dispatch(toggleMenu(false));
	}

	function toastMsg() {
		if (bvn !== 3) {
			return {
				actionText: 'Goto BVN Verification',
				action: () => navigate('/verification?kyc-stage=bvn'),
				type: 'warning',
				msg: 'You are yet to verify your BVN, kindly complete it to remove restrictions on your account.',
			};
		} else if (document_verification_status !== 1) {
			return {
				actionText: 'Goto ID Verification',
				type: 'warning',
				action: () => navigate('/verification?kyc-stage=id'),
				msg: 'You are yet to verify your ID, kindly complete it to remove restrictions on your account.',
			};
		} else if (cac_verified !== 1) {
			if (registration_number?.length > 1) {
				return {
					// actionText: 'Goto CAC Verification',
					type: 'info',
					// action: () => navigate('/verification?kyc-stage=cac'),
					msg: 'We are currently reviewing your submitted CAC details. we will update you once the review has been completed. ',
				};
			} else
				return {
					actionText: 'Goto CAC Verification',
					type: 'warning',
					action: () => navigate('/verification?kyc-stage=cac'),
					msg: 'You are yet to verify your Business CAC Details, kindly complete it to remove restrictions on your account.',
				};
		} else if (industry?.length > 1 && transaction_range?.length > 1) {
			return {
				actionText: 'Goto Business Verification',
				type: 'warning',
				action: () => navigate('/verification?kyc-stage=business-info'),
				msg: 'You are yet to verify your Business Informations, kindly complete it to remove restrictions on your account.',
			};
		} else if (address_status !== 1) {
			if (business_address?.length > 1) {
				return {
					actionText: 'Goto Address Verification',
					type: 'info',
					action: () => navigate('/verification?kyc-stage=address'),
					msg: 'We are currently reviewing your submitted Address Information. we will update you once the review has been completed. ',
				};
			} else
				return {
					actionText: 'Goto Address Verification',
					type: 'warning',
					action: () => navigate('/verification?kyc-stage=address'),
					msg: 'You are yet to verify your Address Information, kindly complete it to remove restrictions on your account.',
				};
		}
	}

	const showToast = bvn !== 3 || verified !== 1 || address_status !== 1;
	// logger.log(showToast, 'profile');
	const [showBar, setShowBar] = React.useState(showToast);

	return (
		<Fragment>
			<ThemeProvider theme={themeMode}>
				<GlobalStyles />

				<div className="dashboard-layout-wrap">
					{/* sidenav wrap start */}

					<div
						className={`side-nav-wrap mobile-nav-thread  ${
							isMenuOpen && 'tablet-collapsed  show-mobile-nav '
						}`}
					>
						<SideMenuComponent theme={theme} />
					</div>
					{loading && (
						<figure className="loader-animation">
							<img src={ravenLoader} alt="" />
						</figure>
					)}
					{/* sidenav wrap end */}
					{/* main body wrap start */}
					<div className={`main-body-wrap ${!isMenuOpen ? 'expanded' : ''} `}>
						{/* header wrap start */}

						<div
							className={`header-wrap white-base-black-bg ${
								location.pathname.includes('dashboard-onboarding-') &&
								'onboarding-header'
							}`}
						>
							<HeaderNavComponent theme={theme} toggleTheme={toggleTheme} />
						</div>

						{isMenuOpen && (
							<div onClick={toggleNav} className="mobile-invisible-close"></div>
						)}

						<RavenActionBar
							actionText={toastMsg()?.actionText}
							className="overview__actionbar non-mobile"
							msg={toastMsg()?.msg}
							onAction={toastMsg()?.action}
							onCancel={() => setShowBar(false)}
							type={toastMsg()?.type as ToastType}
							visible={profile?.clearance === 'admin' ? showBar : false}
						/>
						{/* header wrap end */}
						{/* main wrap start */}

						<div className="main-wrap main-wrap-bg">{children}</div>
						{/* main wrap end */}
					</div>
					{/* main body wrap end */}
				</div>
			</ThemeProvider>
		</Fragment>
	);
};

export default DashboardLayout;
