import React from 'react';
import { icons, illustrations } from '../../../../../../assets/icons';
import './style/index.css';
import MobileBillsTable from './MobileBillsTable';
import SmartFilter from '../../../../../../components/common/smartFilter';

const MobileBills = ({ onView }: { onView: (e: string) => void }) => {
	return (
		<div className="mobile-bills-wrap">
			<div
				onClick={() => onView('airtime')}
				className="mobile-bills-wrap__item"
			>
				<figure>{illustrations.airtime_bills}</figure>

				<div className="item__content">
					<h5>Airtime</h5>
					<p>Buy airtime and stay connected with ease.</p>
				</div>
			</div>

			<div onClick={() => onView('data')} className="mobile-bills-wrap__item">
				<figure>{illustrations.data_bills}</figure>

				<div className="item__content">
					<h5>Data</h5>
					<p>Buy airtime and stay connected with ease.</p>
				</div>
			</div>

			<div
				onClick={() => onView('electricity')}
				className="mobile-bills-wrap__item"
			>
				<figure>{illustrations.electricity_bills}</figure>

				<div className="item__content">
					<h5>Electricity</h5>
					<p>Buy airtime and stay connected with ease.</p>
				</div>
			</div>

			<div onClick={() => onView('cable')} className="mobile-bills-wrap__item">
				<figure>{illustrations.airtime_bills}</figure>

				<div className="item__content">
					<h5>Cable TV</h5>
					<p>Buy airtime and stay connected with ease.</p>
				</div>
			</div>
			<div
				onClick={() => onView('betting')}
				className="mobile-bills-wrap__item"
			>
				<figure>{illustrations.betting_bills}</figure>

				<div className="item__content">
					<h5>Betting</h5>
					<p>Buy airtime and stay connected with ease.</p>
				</div>
			</div>
		</div>
	);
};

export default MobileBills;
