import overviewIcon from '../../../assets/sidenav-icon-overview.svg';
import walletIcon from '../../../assets/sidenav-icon-wallet.svg';
import transactionIcon from '../../../assets/sidenav-icon-transaction.svg';
import collectionIcon from '../../../assets/sidenav-icon-collection.svg';
import paymentIcon from '../../../assets/sidenav-icon-payment.svg';
import virtual_accountIcon from '../../../assets/sidenav-icon-virtual-account.svg';
import card_issuingIcon from '../../../assets/sidenav-icon-card.svg';
import customerIcon from '../../../assets/sidenav-icon-customers.svg';
import settingIcon from '../../../assets/sidenav-icon-setting.svg';
import user_kycIcon from '../../../assets/sidenav-icon-user-kyc.svg';
import walletBalanceIcon from '../../../assets/wallet-icon-balance.svg';
import walletFundingeIcon from '../../../assets/wallet-icon-history.svg';
import { icons } from '../../../assets/icons';
import { IconVault } from '../../../utils/helper/Helper';

export const sideMenuList = [
	{ name: 'Overview', icon: overviewIcon, link: '/dashboard-overview' },
	{
		name: 'Wallet',
		icon: walletIcon,
		link: '/dashboard-wallet',
		numb: 'two',
		children: [
			{
				name: 'Balances',
				link: '/dashboard-wallet/balances',
				icon: walletBalanceIcon,
			},
			// {
			// 	name: 'Balance History',
			// 	link: '/dashboard-wallet/balance-history',
			// 	icon: transactionIcon,
			// },
			{
				name: 'Funding History',
				link: '/dashboard-wallet/funding-history',
				icon: walletFundingeIcon,
			},
			// {
			// 	name: 'Settlement History',
			// 	link: '/dashboard-wallet/settlement-history',
			// 	icon: collectionIcon,
			// },
		],
	},
	{
		name: 'Transactions',
		icon: transactionIcon,
		link: '/dashboard-transactions',
	},
	{
		name: 'Collection',
		icon: collectionIcon,
		link: '/dashboard-collections',
	},
	{
		name: 'Payments',
		icon: paymentIcon,
		link: '/dashboard-payment/transfer',
		numb: 'three',
		children: [
			{
				name: 'Transfers',
				link: '/dashboard-payment/transfer',
				icon: IconVault(icons.paper_plane),
			},
			// {
			// 	name: 'Payment Links',
			// 	link: '/dashboard-payment/payment-links',
			// 	icon: walletFundingeIcon,
			// },
			{
				name: 'Bill Payments',
				link: '/dashboard-payment/bill-payments',
				icon: IconVault(icons.bills),
			},
		],
	},
	{
		name: 'BankBox',
		icon: IconVault(icons.bank_box),
		link: '/dashboard-bankbox',
		numb: 'four',
		children: [
			{
				name: 'Overview',
				link: '/dashboard-bankbox/overview',
				icon: IconVault(icons.bankbox_chevron),
			},

			{
				name: 'Users',
				link: '/dashboard-bankbox/users',
				icon: IconVault(icons.bankbox_chevron),
			},

			{
				name: 'Terminals',
				link: '/dashboard-bankbox/terminals',
				icon: IconVault(icons.bankbox_chevron),
			},

			{
				name: 'Transactions',
				link: '/dashboard-bankbox/transactions',
				icon: IconVault(icons.bankbox_chevron),
			},
			{
				name: 'Requests',
				link: '/dashboard-bankbox/requests',
				icon: IconVault(icons.bankbox_chevron),
			},
			{
				name: 'Settings',
				link: '/dashboard-bankbox/settings',
				icon: IconVault(icons.bankbox_chevron),
			},
		],
	},
	{
		name: 'Virtual Accounts',
		icon: virtual_accountIcon,
		link: '/dashboard-virtual-account',
	},
	{
		name: 'Card Issuing',
		icon: card_issuingIcon,
		link: '/dashboard-card-issuing',
	},
	{
		name: 'Customers',
		icon: customerIcon,
		link: '/dashboard-customer',
	},
	{
		name: 'User KYC',
		icon: user_kycIcon,
		link: '/dashboard-user-kyc',
		children: [],
	},
	{
		name: 'Settings',
		icon: settingIcon,
		link: '/dashboard-setting',
	},
];
