import React, { useState } from 'react';
import DashboardLayout from '../../../../../../layout/dashboard/dashboardLayout';
import './styles/index.css';
import {
	RavenActionBar,
	RavenButton,
	RavenInputField,
} from '@ravenpay/raven-bank-ui';
import { IconVault } from '../../../../../../utils/helper/Helper';
import { brands, icons, illustrations } from '../../../../../../assets/icons';
import bankBorderless from '../../../../../../assets/images/bank_without_borders.png';
import playStore from '../../../../../../assets/images/playstore.png';
import appStore from '../../../../../../assets/images/appstore.png';
import dangote from '../../../../../../assets/images/dangote.png';

const PaymentLinkPay = () => {
	const [showBar, setShowBar] = useState(true);
	const [status, setStatus] = useState<
		'failed' | 'successful' | 'expired' | 'active'
	>('active');

	function isStatus(s: string) {
		if (s === status) return true;
		else return false;
	}

	return (
		<div className="payment-page-wrap">
			<div className="payment-page-wrap__header">
				<RavenActionBar
					// actionText="Click me"
					className=""
					msg="Hey! You are viewing this payment link ON preview Mode"
					// onAction={function noRefCheck() {}}
					onCancel={() => setShowBar(false)}
					type="info"
					visible={showBar}
				/>
			</div>
			<div
				style={{
					backgroundImage: `url(${IconVault(brands.big_wing)})`,
					backgroundSize: 'contain',
				}}
				className="payment-page-wrap__body"
			>
				<div className="body__left">
					<div className="left__preview-toggler">
						<div className="top__wallet-select">
							<span
								onClick={() => setStatus('active')}
								className={`wallet-select__item ${
									isStatus('active') && 'active'
								} `}
							>
								Active
							</span>
							<span
								onClick={() => setStatus('successful')}
								className={`wallet-select__item ${
									isStatus('successful') && 'active'
								} `}
							>
								Successful
							</span>
							<span
								onClick={() => setStatus('expired')}
								className={`wallet-select__item ${
									isStatus('expired') && 'active'
								} `}
							>
								Expired
							</span>
						</div>
					</div>
					<div className="left__you-are-the-reason">
						{illustrations.you_are_the_reason}
					</div>
				</div>
				<div className="body__right">
					{status !== 'active' ? (
						<div className="right__content-container">
							<span className="content-container__top-blue-shade" />
							<figure
								className={`content-container__illustration ${
									status !== 'successful' && 'error'
								}`}
							>
								{status === 'successful' ? (
									<img src={bankBorderless} alt="" />
								) : (
									icons.error
								)}
							</figure>
							<div className="content-container__main">
								<h5 className="main__title">{`Payment ${
									status.slice(0, 1).toUpperCase() +
									status.slice(1, status.length)
								}`}</h5>

								{status === 'successful' ? (
									<div className="main__link-details">
										<div className="link-details__item">
											<p>Merchant Name</p>
											<p>Ezeani Emmanuel</p>
										</div>{' '}
										<div className="link-details__item">
											<p>Amount Paid</p>
											<p>₦500,000.00</p>
										</div>
										<div className="link-details__item">
											<p>Payment Method</p>
											<p>Card</p>
										</div>
										<div className="link-details__item">
											<p>Reference ID</p>
											<span>
												<p>234587611861186114F8J</p>
												<figure>{icons.copy}</figure>
											</span>
										</div>
									</div>
								) : (
									<div className="main__not-success">
										{status === 'failed' ? (
											<p>
												Unable to carry out your transaction, kindly retry this
												transaction or use a different payment method to carry
												out your transaction.
											</p>
										) : (
											<p>
												You cannot make payment to this link anymore, request a
												different link from the recipient or contact our support
											</p>
										)}
									</div>
								)}

								<div className="main__button-wrap">
									<RavenButton
										className="main__button"
										color="deep-green-light"
										label="Close Window"
									/>
								</div>
							</div>
						</div>
					) : (
						<div className="right__content-container link-status-active">
							<div className="link-status-active__business-info">
								<figure className="business-info__logo">
									<img src={dangote} alt="" />
								</figure>
								<h5>B.D Game Store</h5>
							</div>

							<div className="link-status-active__payment-details">
								<h5>Payment for PS5</h5>
								<p>
									This payment is for the PS5 Purchase in our store earlier
									today
								</p>
							</div>

							<div className="link-status-active__form">
								<div className="link-status-active__form--grouped">
									<RavenInputField
										color="deep-green-light"
										type="text"
										className="grouped-input"
										placeholder="E.g 'Emmanuel'"
										label="Firstname"
									/>
									<RavenInputField
										color="deep-green-light"
										placeholder="E.g 'Ezeani'"
										className="grouped-input"
										type="text"
										label="Lastname"
									/>
								</div>
								<RavenInputField
									color="deep-green-light"
									type="email"
									placeholder="E.g 'monali@gmail.com'"
									label="Email Address"
								/>{' '}
								<RavenInputField
									color="deep-green-light"
									type="number"
									placeholder="E.g '50000'"
									label="Amount"
								/>
							</div>

							<div className="link-status-active__btn">
								<RavenButton
									color="deep-green-light"
									label="Pay NGN 500,000.00"
								/>
							</div>
						</div>
					)}
				</div>

				<div className="body__footer">
					<p>Raven Bank: The people bank of Africa</p>
					<figure>
						<img src={playStore} alt={'Play Store'} />
					</figure>
					<figure>
						<img src={appStore} alt="" />
					</figure>
				</div>
			</div>
		</div>
	);
};

export default PaymentLinkPay;
