import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../../../../layout/dashboard/dashboardLayout';
import ContainerLayout from '../../../../../../../layout/dashboard/container';
import { icons } from '../../../../../../../assets/icons';
import './styles/index.css';
import {
	RavenButton,
	RavenInputField,
	RavenTable,
	RavenTableRow,
	RavenToolTip,
	toast,
} from '@ravenpay/raven-bank-ui';
import * as XLSX from 'xlsx';
import temolate from './docs/bulk_transfer_format.png';
import {
	formatNumWithComma,
	symbol,
} from '../../../../../../../utils/helper/Helper';
import { generateRandomColor } from '../../../../../../../utils/helper/color';
import Pagination from '../../../../../../../components/common/pagination';
import TableActionBlock from '../../../../../../../components/common/table/TableActionBlock';
import TableInput from '../../../../../../../components/common/table/TableInput';
import ConfirmTransactionPinModal from '../../common/ConfirmPinModal';
import { useNavigate } from 'react-router-dom';

interface BULK {
	onBack?: () => void;
}
type EditMode = {
	id: number | string;
	on: boolean;
};
const BulkTransfer = () => {
	const [parsedJson, setParsedJson] = useState<any>(0);
	const [step, setStep] = useState(1);
	const [pinModal, setPinModal] = useState(false);
	const [bulkList, setBulkList] = useState<any>([]);
	const [newData, setNewData] = useState<any>([]);

	let expectedFormat = {
		0: 'Account Name',
		1: 'Account Number',
		2: 'Bank',
		3: 'Bank Code',
		4: 'Amount',
		5: 'Narration',
	};

	function handleFileUpload(file: any) {
		// function handleFile(file /*:File*/) {
		/* Boilerplate to set up FileReader */
		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = (e: any) => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX?.read(bstr, { type: rABS ? 'binary' : 'array' });
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];

			/* Convert array of arrays */
			const data: any = XLSX?.utils.sheet_to_json(ws, { header: 1 });
			/* Update state */
			setParsedJson(data);
		};
		if (rABS) reader?.readAsBinaryString(file);
		else reader?.readAsArrayBuffer(file);
	}

	useEffect(() => {
		if (parsedJson?.length > 2) {
			if (
				expectedFormat[0] !== parsedJson[0][0] &&
				expectedFormat[1] !== parsedJson[1][1] &&
				expectedFormat[2] !== parsedJson[2][2] &&
				expectedFormat[3] !== parsedJson[3][3] &&
				expectedFormat[4] !== parsedJson[4][4]
			) {
				toast.error(
					'Your bulk file is not a valid excel format, Download and use the template below.'
				);
			} else {
				//  filter the array for empty objects
				// logger.log("333");
				if (parsedJson.length > 1) {
					const filteredJson = parsedJson?.filter((element: {}) => {
						// logger.log(element, "elementi");
						if (Object.keys(element)?.length > 4) {
							return true;
						}

						return false;
					});

					// structure data for consumption
					const structuredData = filteredJson?.map(
						({
							0: account_name,
							1: account_number,
							2: bank,
							3: bank_code,
							4: amount,
						}: any) => ({
							account_name,
							account_number,
							bank,
							bank_code,
							amount,
						})
					);

					// logger.log(structuredData);
					const removeIndexOne = structuredData?.filter(
						(chi: any, index: number) => index !== 0
					);
					if (removeIndexOne?.length > 0) {
						setStep(2);
						setBulkList(removeIndexOne);
						// paginateFunc(removeIndexOne, perPageVal, 1);
					} else {
						toast.error('No data found in uploaded file');
						// logger.log(structuredData);
					}
				}
			}
		}
	}, [parsedJson]);

	function getExtension(filename: string) {
		return filename.split('.').pop();
	}
	const [paginatedList, setPaginatedList] = useState<any>({
		current_page: '',
		per_page: '',
		total: '',
		total_pages: '',
		dataList: [],
	});

	function paginateFunc(
		array: string | any[],
		page_size: number,
		current_page: number
	) {
		const start_index = (current_page - 1) * page_size;
		const end_index = start_index + page_size;
		const paginatedData = array.slice(start_index, end_index);
		const info = {
			current_page: current_page,
			per_page: page_size,
			total: array.length,
			total_pages: Math.ceil(array.length / page_size),
			dataList: paginatedData,
		};
		// logger.log(info);
		setPaginatedList((prev: any) => {
			return {
				...prev,
				current_page: info?.current_page,
				per_page: info?.per_page,
				total: info?.total,
				total_pages: info?.total_pages,
				dataList: info?.dataList,
			};
		});
	}

	function returnInitial(name: string) {
		const i = name?.split(' ');
		return i[0]?.slice(0, 1)?.toUpperCase() + i[1]?.slice(0, 1)?.toUpperCase();
	}

	const tableHeadList = [
		'ACCOUNT NAME',
		'BANK',
		'ACCOUNT NO',
		'CURR',
		'AMOUNT',
		'ACTION(S)',
	];

	const [editMode, setEditMode] = useState<EditMode>({
		id: '',
		on: false,
	});

	function isEditMode(k: number | string) {
		if (k === editMode.id && editMode.on) {
			return true;
		} else return false;
	}

	function editData(key: string | number, e: any) {
		newData.map((chi: any, idx: number) => {
			if (idx === key) {
				newData[idx][e.target.name] = e.target.value;
				setNewData(newData);
			}
		});

		return newData;
	}

	function onDelete(idx: any) {
		const filteredList = bulkList.filter(
			(d: any, index: number) => index !== idx
		);
		setBulkList(filteredList);
	}

	// save edited data
	function onSaveEdit(data: any) {
		setBulkList(data);
	}

	// keep new data up to date
	useEffect(() => {
		setNewData(bulkList);
	}, [bulkList]);

	const navigate = useNavigate();
	return (
		<>
			<DashboardLayout>
				{step === 1 && (
					<ContainerLayout style={{ width: '100%' }}>
						<div className="bulk_transfer">
							<figure className="bulk_transfer__arrow-back">
								{icons.arrow_back}
							</figure>
							<div className="bulk_transfer__title-contain">
								<h5>Upload Transfer Document</h5>
								<small>Here is a list of all your recipients</small>
							</div>
							<div className="bulk_transfer__upload-container ">
								<div className="upload-container-select__upload">
									<RavenInputField
										upploadText="or drag and drop document file here"
										type="upload-two"
										onChange={(e: any) => {
											getExtension(e['name']) ===
											('xlsx' || 'numbers' || 'number' || 'csv')
												? handleFileUpload(e)
												: handleFileUpload(e);
											//  toast.error(
											// 		'File format not supported, Kindly download supportted format above'
											//   );
										}}
										value=""
										color={`deep-green-light`}
										labelColor="green-light"
										className="upload-container-select__upload"
									/>
									<span className="upload__file-size">
										Maximum file size acceptable for upload is 2MB
									</span>
								</div>
							</div>
							<div className="bulk_transfer__hint-wrap">
								<p className="title">Bulk Transfer Hint</p>
								<span>
									<p>Transfers are only in NGN for now</p>
									<p>You can upload .xlsx or .csv file.</p>
									<p>
										Make sure your file has the correct column header such as{' '}
										<b>Bank Name, Account No., Currency, Amount</b>
									</p>
									<p>Numbers of rows should not exceed 1000.</p>
								</span>
							</div>

							<div className="bulk_transfer__button-group">
								<a
									href={temolate}
									download="Atlas Bulk Transfer Template.xlsx"
									style={{
										textDecoration: 'none',
									}}
								>
									<RavenButton
										color="deep-green-light"
										className="button-group__buttons"
										style={{ backgroundColor: '#F0EEFF', color: 'black' }}
										label="Download our Template"
									/>
								</a>
								<RavenButton
									color="deep-green-dark"
									className="button-group__buttons"
									label="Preview Data"
								/>
							</div>
						</div>
					</ContainerLayout>
				)}

				{step === 2 && (
					<>
						<ContainerLayout
							className="table-container"
							style={{ maxWidth: '90%', width: '100%' }}
						>
							<figure
								onClick={() => setStep(1)}
								className="table-container__arrow-back"
							>
								{icons.arrow_back}
							</figure>
							<div className="content__main--top-bar">
								<div className="top-bar__title">
									<h5>Bulk Transfer Preview</h5>
									<p className={'active'}>{bulkList.length}</p>
								</div>

								<Pagination
									className="top-bar__pagination"
									currentPage={1}
									itemsPerPage={10}
									totalItems={100}
									onPageChange={function (page: number): void {
										// setCurrentPage(page);
									}}
								/>
							</div>
							<div className="bulk-transfer__table-wrap">
								<RavenTable
									action={false}
									className="table__main"
									headerList={tableHeadList}
								>
									{bulkList.map((chi: any, idx: number) => {
										return (
											<React.Fragment key={idx}>
												<RavenTableRow
													className={`table__main ${
														isEditMode(idx) && 'editing'
													}`}
													six={
														<TableActionBlock
															tooltipName={chi.account_name}
															editMode={isEditMode(idx)}
															onEdit={() => {
																setEditMode({
																	id: idx,
																	on: !editMode.on,
																});
															}}
															onSave={() => {
																onSaveEdit(newData);
																setEditMode({
																	id: idx,
																	on: !editMode.on,
																});
															}}
															onDelete={() => onDelete(idx)}
														/>
													}
													four={<div className="status-type-wrap ">NGN</div>}
													five={
														isEditMode(idx) ? (
															<TableInput
																onChange={(e) => {
																	editData(idx, e);
																}}
																type="text"
																name="amount"
																value={chi.amount}
																id="input"
																autoFocus
															/>
														) : (
															<div className="status-type-wrap ">
																<span>
																	{symbol('ngn') +
																		formatNumWithComma(chi.amount, 'ngn')}
																</span>
															</div>
														)
													}
													one={
														<div className="account_name">
															<span
																style={{
																	backgroundColor:
																		generateRandomColor('#e9f5ff'),
																}}
															>
																<p>{returnInitial(chi.account_name ?? '')}</p>
															</span>

															{isEditMode(idx) ? (
																<TableInput
																	onChange={(e) => {
																		editData(idx, e);
																	}}
																	type="text"
																	name="account_name"
																	value={chi.account_name}
																	id="input"
																	autoFocus
																/>
															) : (
																<p className="text"> {chi.account_name}</p>
															)}
														</div>
													}
													three={
														isEditMode(idx) ? (
															<TableInput
																onChange={(e) => {
																	editData(idx, e);
																}}
																type="input"
																name="account_number"
																value={chi.account_number}
																id="input"
																autoFocus
															/>
														) : (
															<div className="type-wrap">
																<span>{chi.account_number}</span>
															</div>
														)
													}
													two={`${chi.bank}`}
												/>
											</React.Fragment>
										);
									})}
								</RavenTable>
							</div>
							<div className="table-container__buttons">
								<RavenButton
									className="reupload"
									//
									color="deep-green-light"
									label="Re-upload File"
								/>
								<RavenButton
									//
									onClick={() => setStep(3)}
									color="deep-green-light"
									label="Proceed"
								/>
							</div>
						</ContainerLayout>
					</>
				)}

				{step === 3 && (
					<ContainerLayout style={{ width: '100%' }}>
						<div className="bulk-transfer-summary">
							<figure className="bulk-transfer-summary__arrow-back">
								{icons.arrow_back}
							</figure>
							<span className="bulk-transfer-summary__title">
								<h5>Make a Bulk Transfer</h5>
								<p>Send money to several people all at once</p>
							</span>
							<div className="bulk-transfer-summary__narration-form">
								<RavenInputField
									name="narration"
									label="Narration"
									color="deep-green-light"
									type="textarea"
									placeholder="E.g ‘something for dem boys’"
								/>
							</div>
							<div className="bulk-transfer-summary__table">
								<div className="table__item">
									<p>Total Amount</p>
									<p>₦1,050,000.00</p>
								</div>
								<div className="table__item">
									<p>Total Recipents</p>
									<p>₦1,050,000.00</p>
								</div>
								<div className="table__item">
									<p>Total Fee</p>
									<p>₦405</p>
								</div>
							</div>
							<div className="bulk-transfer-summary__button">
								<RavenButton
									label="Initiate Transfer"
									color="deep-green-light"
									className="act-btn"
									onClick={() => setPinModal(true)}
								/>
							</div>
						</div>
					</ContainerLayout>
				)}
			</DashboardLayout>
			<ConfirmTransactionPinModal
				visible={pinModal}
				onBtnClick={() => {
					navigate('/dashboard-payment/transfer');
					setPinModal(false);
					toast.success(
						'Transaction was successfully queued, we will send a notification once we are done.'
					);
				}}
				onClose={() => setPinModal(false)}
			/>
		</>
	);
};
export default BulkTransfer;
