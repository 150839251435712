import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import {
	ActivateBankBoxPayload,
	AssignBankBoxPayload,
	BankBoxCreateUserPayload,
	BankBoxState,
	BankboxMerchantProfile,
	GetAllBankBoxQueryParams,
} from './types';
import { logger } from '../services/logger';
import { toast } from '@ravenpay/raven-bank-ui';
import axios from '../utils/banbox_axios';
import mainAxios from '../utils/axios';
import defaultAxios from 'axios';
import { getCookie } from '../utils/helper/Helper';
import env from '../env';

export interface BankBoxRequests {
	createUser: AsyncThunk<any, BankBoxCreateUserPayload, any>;
	getDashboardSummary: any;
	uploadLogo: any;
	getTopTransacting: AsyncThunk<any, any, any>;
	activateBankbox: AsyncThunk<any, ActivateBankBoxPayload, any>;
	getAllBankBox: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	getAllBankBoxRequest: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	exportUsers: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	getProfile: AsyncThunk<any, any, any>;
	exportBankboxes: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	assignBankbox: AsyncThunk<any, AssignBankBoxPayload, any>;
	updateProfile: AsyncThunk<any, BankboxMerchantProfile, any>;
	deactivateBankbox: AsyncThunk<any, { serial_no: string }, any>;
	reactivateBankbox: AsyncThunk<any, { serial_no: string }, any>;
	setBankboxKey: AsyncThunk<any, { bankbox_key: string }, any>;
	getSingleUser: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	getSingleBankbox: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	getAllUsers: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	getAllTransactions: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	getChartData: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
	getSingleTransaction: AsyncThunk<any, { id: string }, any>;
	exportBankboxTransaction: AsyncThunk<any, GetAllBankBoxQueryParams, any>;
}

const getDashboardSummary = createAsyncThunk(
	'/bankbox/dashboardData',
	async (payload, thunkAPI) => {
		try {
			const { data } = await axios.get(`/atlas/pdon/dashboard`);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setSummary(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getProfile = createAsyncThunk(
	'/bankbox/profile',
	async (payload, thunkAPI) => {
		try {
			const { data } = await axios.get(`/atlas/pdon/get_merchant`);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setProfile(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);
const createUser = createAsyncThunk(
	'/bankbox/createUser',
	async (payload: BankBoxCreateUserPayload, thunkAPI) => {
		try {
			const { data } = await axios.post(`/atlas/pdon/create_new_user`, payload);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const activateBankbox = createAsyncThunk(
	'/bankbox/onboard',
	async (payload: ActivateBankBoxPayload, thunkAPI) => {
		try {
			const { data } = await axios.post(`/atlas/pdon/onboard`, payload);
			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return data;
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const uploadLogo = createAsyncThunk(
	'/bankbox/upload',
	async (payload: FormData, thunkAPI) => {
		try {
			const { data } = await defaultAxios.post(
				`${env.bankbox_api}/atlas/pdon/upload`,
				payload,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: 'Bearer ' + getCookie('token'),
						'x-pos-key': getCookie('x-pos-key'),
					},
				}
			);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return data;
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const setBankboxKey = createAsyncThunk(
	'/bankbox/key_update',
	async (payload: { bankbox_key: string }, thunkAPI) => {
		try {
			const { data } = await mainAxios.post(`/web/set_bankbox_key`, payload);
			3;
			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return data;
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getAllBankBox = createAsyncThunk(
	'/bankbox/get_all_bankbox',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_all_bankbox?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setAllBankBox(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getAllBankBoxRequest = createAsyncThunk(
	'/bankbox/get_all_bankbox_request',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_all_bankbox_request?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setAllBankBoxRequest(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const exportUsers = createAsyncThunk(
	'/bankbox/export_bankbox_users',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/export_bankbox_users?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const exportBankboxes = createAsyncThunk(
	'/bankbox/export_bankboxs',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/export_bankboxs?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const assignBankbox = createAsyncThunk(
	'/bankbox/assign',
	async (payload: AssignBankBoxPayload, thunkAPI) => {
		try {
			const { data } = await axios.post(`/atlas/pdon/assign_bank_box`, payload);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const updateProfile = createAsyncThunk(
	'/bankbox/update',
	async (payload: BankboxMerchantProfile, thunkAPI) => {
		try {
			const { data } = await axios.post(`/atlas/pdon/settings`, payload);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const deactivateBankbox = createAsyncThunk(
	'/bankbox/deactivate_bank_box',
	async (payload: { serial_no: string }, thunkAPI) => {
		try {
			const { data } = await axios.post(
				`/atlas/pdon/deactivate_bank_box`,
				payload
			);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const reactivateBankbox = createAsyncThunk(
	'/bankbox/activate_bank_box',
	async (payload: { serial_no: string }, thunkAPI) => {
		try {
			const { data } = await axios.post(
				`/atlas/pdon/activate_bank_box`,
				payload
			);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getAllTransactions = createAsyncThunk(
	'/bankbox/get_all_bankbox_transactions',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_bankbox_transactions?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial=' + payload.serial_no : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setAllBankBoxTrx(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getSingleUser = createAsyncThunk(
	'/bankbox/get_single_user',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_single_user?
        ${payload.email ? '&email=' + payload.email : ''}
        `
			);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setUser(data?.data?.data?.user));
				thunkAPI.dispatch(setSingleUserBankBoxes(data?.data?.data?.bankboxes));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getTopTransacting = createAsyncThunk(
	'/bankbox/get_top_transacting',
	async (payload, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_top_transacting_users
        `
			);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });

				thunkAPI.dispatch(setTopTransacting(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getSingleBankbox = createAsyncThunk(
	'/bankbox/get_single_bankbox',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_single_bankbox?${
					payload.serial_no ? 'serial_no=' + payload.serial_no : ''
				}
        `
			);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					// toast.error(data?.data);
				} else {
					// toast.error(data?.message, {
					// 	position: 'top-right',
					// });
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });

				thunkAPI.dispatch(setAllBankBox(data.data.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				// toast.error(error.message, {
				// 	position: 'top-right',
				// });
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getAllUsers = createAsyncThunk(
	'/bankbox/get_all_users',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_all_users?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}${payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.region ? '&region=' + payload.region : ''
				}${payload.search ? '&search=' + payload.search : ''}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);
			// logger.log(data?.data, 'log');

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setAllUsers(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getChartData = createAsyncThunk(
	'/bankbox/get_chart_data',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_chart_data?
        ${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}
        ${payload.date ? '&date=' + payload.date : ''}
        `
			);
			logger.log(data?.data, 'dataun');

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}

			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setChartData(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const getSingleTransaction = createAsyncThunk(
	'/bankbox/get_bankbox_single_transaction',
	async (payload: { id: string }, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/get_bankbox_single_transaction?${
					payload.id ? 'id=' + payload.id : ''
				}

        `
			);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setAllBankBoxTrx(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);
const exportBankboxTransaction = createAsyncThunk(
	'/bankbox/export_bankbox_transactions',
	async (payload: GetAllBankBoxQueryParams, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`/atlas/pdon/export_bankbox_transactions?${
					payload.affiliate_app_id
						? '?affiliate_app_id=' + payload.affiliate_app_id
						: ''
				}${payload.per_page ? '&per_page=' + payload.per_page : ''}${
					payload.page ? '&page=' + payload.page : ''
				}{payload.start_date ? '&start_date=' + payload.start_date : ''}${
					payload.stop_date ? '&stop_date=' + payload.stop_date : ''
				}${payload.serial_no ? '&serial_no=' + payload.serial_no : ''}${
					payload.search ? '&search=' + payload.search : ''
				}${
					payload.business_name ? '&business_name=' + payload.business_name : ''
				}${payload.email ? '&email=' + payload.email : ''}
        `
			);
			// logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const initialState: BankBoxState = {
	loading: false,
	isAuth: false,
	top_transacting: [],
	profile: {
		address: '',
		app_id: '',
		app_name: '',
		bank_collection_fee: 0,
		card_fee: 0,
		card_fee_cap: 0,
		card_fee_model: '',
		color_code: '',
		created_at: '',
		device_price: 0,
		email: '',
		fee_ref: null,
		id: 0,
		key_serial: '',
		logo_url: null,
		mandatory_fee: 0,
		region: '',
		status: 0,
		support_email: '',
		updated_at: '',
		virtual_account: 0,
		virtual_account_provider: '',
		webhook_secret: null,
		webhook_url: null,
		website: null,
	},
	single_user_bankboxes: [],
	users: {
		data: [],
		pagination: {
			perPage: 0,
			currentPage: 0,
			nextPage: null,
			prevPage: null,
			totalPages: 0,
			total: 0,
		},
	},
	all_bankbox_trx: {
		data: [],
		total: 0,
		perPage: 0,
		pagination: {
			perPage: 0,
			currentPage: 0,
			nextPage: null,
			prevPage: null,
			totalPages: 0,
			total: 0,
		},
	},
	dashboard_summary: {
		collectionBalance: 0,
		totalPendingRequest: 0,
		totalBankBoxDisbursed: 0,
		totalUsers: 0,
	},
	chart_data: {},
	all_bankbox: [],
	all_bankbox_request: {
		data: [],
		pagination: {
			perPage: 0,
			currentPage: 0,
			nextPage: null,
			prevPage: null,
			totalPages: 0,
			total: 0,
		},
	},
	user: {
		id: 0,
		fname: '',
		lname: '',
		poseidon_email: '',
		bvn: '',
		nin: '',
		phone: '',
		affiliate_app: '',
		affiliate_app_id: '',
		poseidon_business_name: '',
		poseidon_business_address: '',
		poseidon_business_type: null,
		poseidon_business_description: '',
		poseidon_profile_reference: '',
		business_category: '',
		region: '',
		created_at: '',
		updated_at: '',
	},

	// initialize other state properties
};

export const bankboxSlice = createSlice({
	name: 'bankbox',
	initialState,

	reducers: {
		setBankBoxUser: (state, action) => {
			state.user = action.payload;
			state.isAuth = true;
		},
		setAllUsers: (state, action) => {
			state.users = action.payload;
			state.isAuth = true;
		},
		setUser: (state, action) => {
			state.user = action.payload;
			state.isAuth = true;
		},
		setSummary: (state, action) => {
			state.dashboard_summary = action.payload;
			state.isAuth = true;
		},
		setAllBankBox: (state, action) => {
			state.all_bankbox = action.payload;
			state.isAuth = true;
		},
		setChartData: (state, action) => {
			state.chart_data = action.payload;
			state.isAuth = true;
		},

		setSingleUserBankBoxes: (state, action) => {
			state.single_user_bankboxes = action.payload;
			state.isAuth = true;
		},
		setAllBankBoxRequest: (state, action) => {
			state.all_bankbox_request = action.payload;
			state.isAuth = true;
		},
		setTopTransacting: (state, action) => {
			state.top_transacting = action.payload;
			state.isAuth = true;
		},
		setAllBankBoxTrx: (state, action) => {
			state.all_bankbox_trx = action.payload;
			state.isAuth = true;
		},
		setProfile: (state, action) => {
			state.profile = action.payload;
			state.isAuth = true;
		},
	},

	extraReducers: (builder) => {
		// Pending state
		builder
			.addCase(createUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(getDashboardSummary.pending, (state) => {
				state.loading = true;
			})
			.addCase(activateBankbox.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllBankBox.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllBankBoxRequest.pending, (state) => {
				state.loading = true;
			})
			.addCase(exportUsers.pending, (state) => {
				state.loading = true;
			})
			.addCase(exportBankboxes.pending, (state) => {
				state.loading = true;
			})
			.addCase(assignBankbox.pending, (state) => {
				state.loading = true;
			})
			.addCase(deactivateBankbox.pending, (state) => {
				state.loading = true;
			})
			.addCase(getSingleUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(getSingleBankbox.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllUsers.pending, (state) => {
				state.loading = true;
			})
			.addCase(getChartData.pending, (state) => {
				state.loading = true;
			})
			.addCase(getSingleTransaction.pending, (state) => {
				state.loading = true;
			})
			.addCase(exportBankboxTransaction.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllTransactions.pending, (state) => {
				state.loading = true;
			})
			.addCase(getProfile.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateProfile.pending, (state) => {
				state.loading = true;
			})
			.addCase(uploadLogo.pending, (state) => {
				state.loading = true;
			})
			.addCase(reactivateBankbox.pending, (state) => {
				state.loading = true;
			})
			.addCase(setBankboxKey.pending, (state) => {
				state.loading = true;
			});
		// Fulfilled state
		builder
			.addCase(createUser.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getDashboardSummary.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(activateBankbox.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getAllBankBox.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getAllBankBoxRequest.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(exportUsers.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(exportBankboxes.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(assignBankbox.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(deactivateBankbox.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getSingleUser.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getSingleBankbox.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getAllUsers.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getChartData.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getSingleTransaction.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(exportBankboxTransaction.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getAllTransactions.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getProfile.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(updateProfile.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(uploadLogo.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(reactivateBankbox.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(setBankboxKey.fulfilled, (state) => {
				state.loading = false;
			});

		// Rejected state
		builder
			.addCase(createUser.rejected, (state) => {
				return initialState;
			})
			.addCase(getDashboardSummary.rejected, (state) => {
				return initialState;
			})
			.addCase(activateBankbox.rejected, (state) => {
				return initialState;
			})
			.addCase(getAllBankBox.rejected, (state) => {
				return initialState;
			})
			.addCase(getAllBankBoxRequest.rejected, (state) => {
				return initialState;
			})
			.addCase(exportUsers.rejected, (state) => {
				return initialState;
			})
			.addCase(exportBankboxes.rejected, (state) => {
				return initialState;
			})
			.addCase(assignBankbox.rejected, (state) => {
				return initialState;
			})
			.addCase(deactivateBankbox.rejected, (state) => {
				return initialState;
			})
			.addCase(getSingleUser.rejected, (state) => {
				return initialState;
			})
			.addCase(getSingleBankbox.rejected, (state) => {
				return initialState;
			})
			.addCase(getAllUsers.rejected, (state) => {
				return initialState;
			})
			.addCase(getChartData.rejected, (state) => {
				return initialState;
			})
			.addCase(getSingleTransaction.rejected, (state) => {
				return initialState;
			})
			.addCase(exportBankboxTransaction.rejected, (state) => {
				return initialState;
			})
			.addCase(getAllTransactions.rejected, (state) => {
				return initialState;
			})
			.addCase(getProfile.rejected, (state) => {
				return initialState;
			})
			.addCase(updateProfile.rejected, (state) => {
				return initialState;
			})
			.addCase(uploadLogo.rejected, (state) => {
				return initialState;
			})
			.addCase(reactivateBankbox.rejected, (state) => {
				return initialState;
			})
			.addCase(setBankboxKey.rejected, (state) => {
				return initialState;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	setBankBoxUser,
	setSummary,
	setAllBankBox,
	setAllBankBoxRequest,
	setAllBankBoxTrx,
	setAllUsers,
	setChartData,
	setTopTransacting,
	setUser,
	setProfile,
	setSingleUserBankBoxes,
} = bankboxSlice.actions;

export const bankboxAPI: BankBoxRequests = {
	createUser,
	getDashboardSummary,
	activateBankbox,
	getAllBankBox,
	getAllBankBoxRequest,
	exportUsers,
	exportBankboxes,
	getTopTransacting,
	assignBankbox,
	deactivateBankbox,
	reactivateBankbox,
	getSingleUser,
	getSingleBankbox,
	getProfile,
	getAllUsers,
	getChartData,
	getSingleTransaction,
	exportBankboxTransaction,
	updateProfile,
	getAllTransactions,
	uploadLogo,
	setBankboxKey,
};

export default bankboxSlice.reducer;

// smiles i used a different approach here
