import React, { useEffect, useRef, useState } from 'react';
import DashboardLayout from '../../../layout/dashboard/dashboardLayout';
import { useNavigate } from 'react-router-dom';
import {
	RavenActionBar,
	RavenButton,
	RavenDropDown,
	toast,
} from '@ravenpay/raven-bank-ui';
import './styles/index.css';
import { icons } from '../../../assets/icons';
import naira from '../../../assets/images/naira.png';
import GaugeChart from 'react-gauge-chart';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
	formatDateTime,
	formatNumWithComma,
	symbol,
} from '../../../utils/helper/Helper';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { darkTheme, lightTheme } from '../../../utils/themes/theme';
import { getDashboardData } from '../../../redux/wallet';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import RootState, {
	PeriodSummary,
	ServiceSummary,
	SummaryData,
} from '../../../redux/types';
import { logger } from '../../../services/logger';
import { Balance } from '../../../@types/dashboard';
import Skeleton from 'react-loading-skeleton';
import EmptyScreen from '../../../components/common/emptyScreen';
import { getProfile } from '../../../redux/settings';
import moment from 'moment';
import TopupModal from '../../../components/common/topupModal';
import { AtlasDropDown } from '../../../components/common/DropDown/AtlasDropdown';
import {
	getPeriodicSummary,
	initialPeriodicSummary,
} from '../../../redux/transaction';

const OverviewIndex = () => {
	// const navigate = useNavigate();
	const [currency, setCurrency] = useState('ngn');
	const [guageFilter, setGuageFilter] = useState('today');
	const [selectedGuageFilter, setSelectedGuageFilter] = useState({
		label: 'Transfers',
		value: 'transfers',
		action: () => {},
	});
	const [alive, setAlive] = useState(false);
	const [guageColors, setGuageColors] = useState<string[]>([]);
	const [graphData, setGraphData] = useState<{
		index: number;
		content: string[];
	}>({
		index: 0,
		content: [],
	});
	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const [showTopup, setShowTopup] = useState(false);
	const [showGuageDrop, setShowGuageDrop] = useState(false);
	const [truety, setTruety] = useState({
		collection: true,
		transfer: true,
	});
	const { dashboard_data, loading, profile } = useSelector(
		(state: RootState) => state.wallet
	);

	const { periodic_summary } = useSelector(
		(state: RootState) => state.transaction
	);

	const dispatch = useDispatch<AppDispatch>();
	const chartRef: any = useRef(null);

	ChartJS.register(
		CategoryScale,
		LinearScale,
		BarElement,
		Title,
		Tooltip,
		Legend
	);

	const handleLegendItemClick = (index: number) => {
		const chartInstance = chartRef.current?.chartInstance;

		if (chartInstance) {
			const meta = chartInstance.getDatasetMeta(0);
			const legendItem = meta.data[index];

			if (legendItem) {
				// Programmatically simulate a legend click event
				legendItem.hidden = !legendItem.hidden;
				chartInstance.update();
			}
		}
	};

	// Get the current date
	const currentDate = new Date();

	// Create an array for the past 7 days
	const past7Days: string[] = [];
	for (let i = 0; i < 7; i++) {
		const date = new Date(currentDate.getTime() - i * 24 * 60 * 60 * 1000);
		past7Days.push(date.toISOString());
	}

	// Create an array for the past 30 days
	const past30Days: string[] = [];
	for (let i = 0; i < 30; i++) {
		const date = new Date(currentDate.getTime() - i * 24 * 60 * 60 * 1000);
		past30Days.push(date.toISOString());
	}

	function generatePastDays(lastDate: Date | string) {
		const newDays: string[] = [];
		const current = new Date(lastDate);
		if (lastDate) {
			for (let i = 0; i < 7; i++) {
				const date = new Date(current.getTime() - i * 24 * 60 * 60 * 1000);
				newDays.push(date.toISOString());
			}
		}

		return newDays;
	}

	function Graph() {
		const options: any = {
			plugins: {
				legend: {
					display: false,
					labels: {
						position: 'left',
						align: 'start',
						font: {
							size: 14,
							style: (ctx: any, options: any) => {
								// You can customize the logic here to determine the style dynamically
								return ctx.chart?.data?.datasets?.[options.datasetIndex]
									?.label === 'Collection'
									? 'italic'
									: 'normal';
							},
							color: 'black',
						},
					},
				},
			},
			responsive: true,
			scales: {
				x: {
					grid: {
						drawBorder: false,
						display: false,
					},
					ticks: {
						display: false,
					},
				},
				y: {
					ticks: {
						callback: function (value: number, index: number, values: number) {
							if (value >= 1000000) {
								return (value / 1000000).toFixed(1) + 'M'; // Convert to millions
							} else if (value >= 1000) {
								return (value / 1000).toFixed(1) + 'k'; // Convert to thousands
							} else {
								return value; // Use the original value
							}
						},
					},
					border: { dash: [6, 6] }, // for the grid lines

					grid: {
						color: theme === 'dark' ? '#cccccc23' : '#E1E1E1', // for the grid lines
						tickColor: 'white', // for the tick mark
						tickBorderDash: [2, 3], // also for the tick, if long enough
						tickLength: 15, // just to see the dotted line
						tickWidth: 2.5,
						offset: true,
						drawTicks: theme === 'light', // true is default
						drawOnChartArea: true, // true is default
					},

					beginAtZero: true,
				},
			},
		};

		const formattedDates = graphData.content.map(
			(dateString: string | number | Date, index: number) => {
				const date = new Date(dateString);
				if (graphData.index === 1) {
					if (index === 0) {
						return 'Today';
					} else if (index === 1) {
						return 'Yesterday';
					} else {
						return formatDateTime(date).split(',')[0];
					}
				} else {
					return formatDateTime(date).split(',')[0];
				}
			}
		);

		const labels = formattedDates;
		const collx = dashboard_data?.graph_data?.collections.slice(
			7 * graphData.index - 7,
			7 * graphData.index
		);
		const trxx = dashboard_data?.graph_data?.transfers.slice(
			7 * graphData.index - 7,
			7 * graphData.index
		);

		const data = {
			labels,
			datasets: [
				{
					label: 'Collection',
					data: labels?.map((chi: any, idx: string | number) => collx[idx]),
					backgroundColor: theme === 'dark' ? '#0B8376' : '#014345',
				},
				{
					label: 'Transfers',
					data: labels?.map((chi: any, idx: string | number) => trxx[idx]),
					backgroundColor: theme === 'dark' ? '#515151' : '#9BE5C9',
				},
			],
		};

		return (
			<Bar
				height={80}
				width={200}
				options={options}
				// ref={chartRef}
				data={data}
			/>
		);
	}

	async function fetchBalances() {
		if (!dashboard_data.graph_data?.collections) {
			dispatch(getDashboardData());
		}
	}

	useEffect(() => {
		fetchBalances();
	}, []);

	useEffect(() => {
		if (loading) {
			setAlive(false);
		} else {
			setAlive(true);
		}
	}, [loading]);

	function filterBalance() {
		if (dashboard_data.balances) {
			const d: Balance = dashboard_data?.balances?.filter(
				(d: { currency: string }) => d.currency === currency.toUpperCase()
			);
			logger.log(d);
			return d[0].available_bal;
		}
		return 0;
	}

	function handleGraphData(str?: string) {
		let i = graphData.index;

		if (str === 'prev') {
			if (graphData.index !== 1) {
				i = i - 1;
			} else {
				i = 1;
			}
		} else {
			i = i + 1;
		}

		if (i === 1) {
			setGraphData({
				index: i,
				content: past7Days,
			});
		} else {
			setGraphData({
				index: i,
				content: generatePastDays(graphData?.content[6]),
			});
		}
	}

	useEffect(() => {
		handleGraphData();
		dispatch(getProfile());
	}, []);

	const [greetingVal, setGreetingVal] = useState('');
	useEffect(() => {
		var CurrentDate = moment().format('HH');
		const numVal = Number(CurrentDate);
		const value =
			numVal >= 0 && numVal <= 11
				? '😇 Good Morning,'
				: numVal >= 12 && numVal < 16
				? '🌞 Good Afternoon,'
				: '😴 Good Evening,';
		setGreetingVal(value);
	}, []);

	function isEmpty() {
		return truety.collection === true && truety.transfer === true;
	}

	const colls = dashboard_data?.graph_data?.collections?.slice(0, 7);
	const trxx = dashboard_data?.graph_data?.transfers?.slice(0, 7);

	useEffect(() => {
		if (colls && trxx) {
			colls.forEach((element: any) => {
				if (element !== 0) {
					setTruety({
						...truety,
						collection: false,
					});
				}
			});

			trxx.forEach((element: any) => {
				if (element !== 0) {
					setTruety({
						...truety,
						transfer: false,
					});
				}
			});
		}
	}, [dashboard_data]);

	const navigate = useNavigate();
	const virtual_account = dashboard_data?.virtual_account;

	useEffect(() => {
		dispatch(getPeriodicSummary());
	}, []);

	interface PeriodIndex extends PeriodSummary {
		[key: string]: ServiceSummary;
	}

	function combineServices(data: PeriodIndex): ServiceSummary {
		const combinedSummary: ServiceSummary = {
			successful: 0,
			pending: 0,
			failed: 0,
		};

		const servicesToCombine = ['cable', 'betting', 'electricity', 'airtime'];
		servicesToCombine.forEach((service) => {
			combinedSummary.successful += data[service]?.successful;
			combinedSummary.pending += data[service]?.pending;
			combinedSummary.failed += data[service]?.failed;
		});

		return combinedSummary;
	}

	const combinedServicesToday = combineServices(
		periodic_summary.today as unknown as any
	);

	const combinedServicesSevenDays = combineServices(
		periodic_summary.seven_days as unknown as any
	);
	// logger.log('Combined Services Seven Days:', combinedServicesSevenDays);

	const combinedServicesThirtyDays = combineServices(
		periodic_summary.thirty_days as unknown as any
	);
	// logger.log('Combined Services Thirty Days:', combinedServicesThirtyDays);

	// logger.log('Periodic Summary:', periodic_summary);

	function formatPeriodicSummary() {
		let today;
		let seven;
		let thirty;

		today = { ...periodic_summary.today, bill_payments: combinedServicesToday };
		seven = {
			...periodic_summary.seven_days,
			bill_payments: combinedServicesSevenDays,
		};
		thirty = {
			...periodic_summary.thirty_days,
			bill_payments: combinedServicesThirtyDays,
		};

		return { today, seven, thirty };
	}

	const summary_data: any = formatPeriodicSummary();

	// function to handle gauge chart if it all zeros
	function handleGuageChart(state: 'pending' | 'failed' | 'successful') {
		if (
			summary_data[guageFilter][selectedGuageFilter.value]?.successful +
				summary_data[guageFilter][selectedGuageFilter.value]?.pending +
				summary_data[guageFilter][selectedGuageFilter.value]?.failed ===
			0
		) {
			return summary_data[guageFilter][selectedGuageFilter.value][state] === 0
				? 1
				: summary_data[guageFilter][selectedGuageFilter.value][state];
		} else {
			return summary_data[guageFilter][selectedGuageFilter.value][state];
		}
	}

	const detectZero =
		summary_data[guageFilter][selectedGuageFilter.value]?.successful +
			summary_data[guageFilter][selectedGuageFilter.value]?.pending +
			summary_data[guageFilter][selectedGuageFilter.value]?.failed ===
		0;

	function chartColor() {
		if (theme === 'dark') {
			return loading || detectZero
				? ['#333333', '#333333', '#333333']
				: ['#1ACE37', '#EA872D', '#FF0F00'];
		} else {
			return loading || detectZero
				? ['#EEEEEE', '#EEEEEE', '#EEEEEE']
				: ['#1ACE37', '#EA872D', '#FF0F00'];
		}
	}
	useEffect(() => {
		setGuageColors(chartColor());
	}, [loading, theme, detectZero]);

	return (
		<>
			<DashboardLayout>
				<div className={`overview ${theme}`}>
					<div className="overview__top-bar">
						<div className="top-bar__greetings">
							<h5>{`${greetingVal} ${profile?.fname || '---'}`}</h5>{' '}
							<p>Welcome back to your dashboard, here is an overview</p>
						</div>
						<div className="top-bar__topup-btn">
							<RavenButton
								onClick={() => setShowTopup(true)}
								label="Top Up Wallet"
								color="deep-green-light"
							/>
						</div>
					</div>
					<div className="overview__wallets-wrap">
						<div className="overview__wallets">
							<div className="wallets__balance">
								<div className="balance__currency">
									<span
										onClick={() => setCurrency('ngn')}
										className={`${currency === 'ngn' && 'active'}`}
									>
										<figure>{icons[currency]}</figure>

										<p>NGN</p>
									</span>
									<span
										style={{ opacity: 0.5, cursor: 'no-drop' }}
										// onClick={() => setCurrency('usd')}
										onClick={() =>
											toast.info('Sorry, USD Wallet is currently unavailable')
										}
										className={`${currency == 'usd' && 'active'}`}
									>
										<p>USD</p>
									</span>
									<span
										style={{ opacity: 0.5, cursor: 'no-drop' }}
										onClick={() =>
											toast.info('Sorry, GBP Wallet is currently unavailable')
										}
										// onClick={() => setCurrency('gbp')}
										className={`${currency == 'gbp' && 'active'}`}
									>
										<p>GBP</p>
									</span>
								</div>
								<div className="balance__description">
									<p>
										{/* {currency == 'ngn'
											? 'Naira'
											: currency == 'usd'
											? 'Dollar'
											: 'British Pounds'}{' '} */}
										Available Balance
									</p>
								</div>
								<div className="balance__amount">
									<h5>
										{!loading ? (
											symbol(currency) +
											formatNumWithComma(filterBalance(), currency)
										) : (
											<Skeleton width={100} height={20} />
										)}
									</h5>
								</div>
								<div className="balance__view-all-wallet">
									<span onClick={() => navigate('/dashboard-wallet/balances')}>
										{/* View All Wallets
										<figure>{icons.arrow_right_purple}</figure> */}
									</span>
									<div onClick={() => navigate('/dashboard-wallet/balances')}>
										<p>View All Wallets</p>
										<figure>{icons.arrow_right_purple}</figure>
									</div>
								</div>
							</div>
							<div className="wallets__statistics">
								<div className="statistics__item">
									<figure>{icons.total_transactions}</figure>
									<div className="item__stats"></div>
									<h5>
										{!loading ? (
											formatNumWithComma(
												dashboard_data?.total_transaction_volume,
												undefined,
												3
											)
										) : (
											<Skeleton width={70} height={20} />
										)}
									</h5>
									<p>Total Transactions</p>
								</div>

								<div className="statistics__item">
									<figure>{icons.total_customers}</figure>
									<h5>
										{!loading ? (
											formatNumWithComma(dashboard_data?.virtual_accounts_count)
										) : (
											<Skeleton width={70} height={20} />
										)}
									</h5>
									<p>Total Customers</p>
								</div>

								<div className="statistics__item">
									<figure>{icons.total_virtual_accounts}</figure>
									<h5>
										{!loading ? (
											formatNumWithComma(dashboard_data?.virtual_accounts_count)
										) : (
											<Skeleton width={70} height={20} />
										)}
									</h5>
									<p>Total Virtual Accounts</p>
								</div>
							</div>
						</div>
					</div>

					<div className="overview__charts">
						<div className="charts__guage">
							<div className="guage__wrap">
								<div className="wrap__filter">
									<span
										onClick={() => setGuageFilter('today')}
										className={`${guageFilter == 'today' && 'active'}`}
									>
										<p>Today</p>
									</span>
									<span
										onClick={() => setGuageFilter('seven')}
										className={`${guageFilter == 'seven' && 'active'}`}
									>
										<p>7 Days Ago</p>
									</span>
									<span
										onClick={() => setGuageFilter('thirty')}
										className={`${guageFilter == 'thirty' && 'active'}`}
									>
										<p>30 Days Ago</p>
									</span>
								</div>
								<div className="wrap__guage">
									<GaugeChart
										id="gauge"
										nrOfLevels={420}
										arcsLength={[
											handleGuageChart('successful'),
											handleGuageChart('pending'),
											handleGuageChart('failed'),
										]}
										arcWidth={0.4}
										needleColor={theme === 'dark' ? '#1B1B1B' : '#f9f9f9'}
										needleBaseColor={theme === 'dark' ? '#1B1B1B' : '#f9f9f9'}
										colors={guageColors}
										percent={0}
										cornerRadius={0}
										hideText={true}
										arcPadding={0.02}
									/>
									<div
										onClick={() => setShowGuageDrop(!showGuageDrop)}
										className="guage-drop-down-wrap"
									>
										<p>{selectedGuageFilter.label}</p>
										<figure>{icons.chevron_down}</figure>
									</div>
								</div>
								<AtlasDropDown
									onClick={(e: any) => {
										setShowGuageDrop(false);
										setSelectedGuageFilter(e);
									}}
									value={selectedGuageFilter}
									className={
										showGuageDrop
											? 'show-drop guage-drop-down-wrap__main'
											: 'guage-drop-down-wrap__main'
									}
									activeClassName="guage-drop-active"
									options={[
										{
											action: () => setShowGuageDrop(false),
											label: 'Transactions',
											value: 'transactions',
										},
										{
											action: () => setShowGuageDrop(false),
											label: 'Transfers',
											value: 'transfers',
										},
										{
											action: () => setShowGuageDrop(false),
											label: 'Bill payments',
											value: 'bill_payments',
										},
									]}
								/>
							</div>
							<div className="guage__stats">
								<div className="stats__item green">
									<div className="item__name">
										<p></p>
										<p>Successful {selectedGuageFilter.label}</p>
									</div>
									<div className="item__count">
										<p>
											{
												summary_data[guageFilter][selectedGuageFilter.value]
													?.successful
											}
										</p>
									</div>
								</div>

								<div className="stats__item orange">
									<div className="item__name">
										<p></p>
										<p>Pending {selectedGuageFilter.label}</p>
									</div>
									<div className="item__count">
										<p>
											{
												summary_data[guageFilter][selectedGuageFilter.value]
													?.pending
											}
										</p>
									</div>
								</div>

								<div className="stats__item red">
									<div className="item__name">
										<p></p>
										<p>Failed {selectedGuageFilter.label}</p>
									</div>
									<div className="item__count">
										<p>
											{
												summary_data[guageFilter][selectedGuageFilter.value]
													?.failed
											}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="charts__graph">
							<div className="graph__top">
								<div className="top__title ">
									<p>Transactions Graph</p>
									<div className="title__graph-indicator">
										<span onClick={() => handleLegendItemClick(0)}>
											<p></p>
											<p>Collections</p>
										</span>
										<span onClick={() => handleLegendItemClick(1)}>
											<p></p>
											<p>Transfers</p>
										</span>
									</div>
								</div>
								<div onClick={isEmpty} className="top__filter">
									<p>
										{graphData.index === 1
											? 'This Week'
											: formatDateTime(graphData.content[6]).split(',')[0] +
											  ' - ' +
											  formatDateTime(graphData.content[0]).split(',')[0]}
									</p>
									<span>
										<figure
											style={{ cursor: graphData.index === 1 ? 'no-drop' : '' }}
											onClick={() =>
												graphData.index > 1 && handleGraphData('prev')
											}
										>
											{icons.chevron_left}
										</figure>
										<figure
											className="next_cursor"
											onClick={() => handleGraphData('next')}
										>
											{icons.chevron_right}
										</figure>
									</span>
								</div>
							</div>

							{loading ||
							(truety.collection === true && truety.transfer === true) ||
							graphData.index === 0 ? (
								<EmptyScreen noMascot loading={loading} />
							) : (
								<div className="graph__main">
									<Graph />
								</div>
							)}
						</div>
					</div>
				</div>
			</DashboardLayout>
			<TopupModal
				visible={showTopup}
				bank={virtual_account?.bank}
				onClose={() => setShowTopup(false)}
				accountName={virtual_account?.account_name?.split('-')[1]}
				accountNumber={virtual_account?.account_number}
			/>
		</>
	);
};

export default OverviewIndex;
