import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../layout/dashboard/dashboardLayout';
import './styles/index.css';
import {
	RavenInputField,
	RavenTableRow,
	RavenTable,
	RavenButton,
} from '@ravenpay/raven-bank-ui';
import { icons } from '../../../assets/icons';
import EmptyScreen from '../../../components/common/emptyScreen';
import {
	formatDateTime,
	formatNumWithComma,
	handleCopy,
	mapHotkey,
	symbol,
} from '../../../utils/helper/Helper';
import { faker } from '@faker-js/faker';
import Pagination from '../../../components/common/pagination';
import { getCollections } from '../../../redux/wallet';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../redux/types';
import Skeleton from 'react-loading-skeleton';
import { logger } from '../../../services/logger';
import TransactionModal from '../../../components/common/transactionModal';
import SmartFilter from '../../../components/common/smartFilter';
import ExportModal from '../../../components/common/exportModal';
import MobileTableCard from '../../../components/common/table/mobile/MobileTable';

// Generate an array to store the table data

const Collections = () => {
	const [demo, setDemo] = useState(true);
	const [boot, onBoot] = useState(false);
	const [showExport, setShowExport] = useState<boolean>(false);
	const [modal, setModal] = useState<{ on: boolean; chi: TableProp }>({
		on: false,
		chi: {
			type: '',
			description: '',
			account_name: '',
			fee: '',
			amount: '',
			date: '',
			session_id: '',
			bank: '',
			account_number: '',
			email: '',
			created_at: function (created_at: any): string {
				throw new Error('Function not implemented.');
			},
			updated_at: function (updated_at: any): string {
				throw new Error('Function not implemented.');
			},
		},
	});
	const [copied, setCopied] = useState<any>();
	const [currentPage, setCurrentPage] = useState(1);
	const [activeFilter, setActiveFilter] = useState<any>({
		type: [],
		days: '',
	});
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});

	type TableProp = {
		meta_data?: any;
		fee: string | number;
		email: string;
		created_at(created_at: any): string;
		updated_at(updated_at: any): string;
		bank: string;
		account_number: string;
		account_name?: string;
		type: string;
		description: string;
		amount: string;
		date: Date | string;
		session_id: string;
		source?: string;
	};

	const tableData: {
		type: string;
		description: string;
		amount: string;
		date: Date;
		session_id: string;
		account_no: string;
		source?: string;
	}[] = [];

	// Generate 10 objects with fake data for the table rows
	for (let i = 0; i < 10; i++) {
		const type = faker.helpers.arrayElement(['USSD', 'Transfer']);
		const rowData = {
			type: type,
			description: '__',
			amount: faker.finance.amount({ min: 10000, max: 10000938437 }),
			date: faker.date.past(),
			session_id: faker.finance.bitcoinAddress(),
			account_no: faker.finance.accountNumber(11),
		};

		tableData.push(rowData);
	}

	const dispatch = useDispatch();
	const { collections, loading } = useSelector(
		(state: RootState) => state.wallet
	);

	function isFilter(value: string) {
		if (value === activeFilter.days) return true;
		else return false;
	}

	useEffect(() => {
		mapHotkey('m', () => setDemo(!demo));
	}, [demo]);

	useEffect(() => {
		if (
			typeof dateFilter.from === 'object' ||
			typeof dateFilter.to === 'object'
		) {
			setDateFilter({
				to: '',
				from: '',
			});
		}
	}, [activeFilter.days || activeFilter.type]);

	useEffect(() => {
		if (activeFilter.length > 0) {
			setActiveFilter({
				days: '',
				type: [],
			});
		}
	}, [dateFilter.to || dateFilter.from]);

	useEffect(() => {
		if (!boot && collections?.collections) {
			onBoot(true);
		}
		dispatch(
			getCollections({
				perPage: 20,
				page: currentPage,
			})
		);
	}, [currentPage]);

	const cols = collections?.collections;

	function handleSingleView(chi: TableProp) {
		setModal({
			on: true,
			chi: chi,
		});
	}

	return (
		<React.Fragment>
			<DashboardLayout>
				<div className="collections-wrap">
					<div className="collections-wrap__top">
						<div className="top__title ">
							<h5>Collections</h5>
							<p>All Collections on the Raven Atlas</p>
						</div>
						{/* <button className="request-money">
							<p>Request Money</p>
							<figure>{icons.plus_circle_white}</figure>
						</button> */}
					</div>

					<div className="collections__content">
						{loading || cols?.length > 0 ? (
							<>
								<SmartFilter
									onExport={() => setShowExport(!showExport)}
									page="collections"
									defaultFilterBy="status"
									// filters={[
									// 	{
									// 		label: 'Status',
									// 		filterBy: 'status',
									// 		options: [
									// 			{ label: 'Pending', value: 'pending' },
									// 			{ label: 'Failed', value: 'failed' },
									// 			{ label: 'Succesful', value: 'succesful' },
									// 		],
									// 	},
									// ]}
								/>
							</>
						) : (
							''
						)}

						<div className="content__main">
							<div className="content__main--top-bar">
								{loading || cols?.length > 0 ? (
									<>
										<div className="top-bar__filter">
											<div
												className={`main__active-filters ${
													(activeFilter.days ||
														activeFilter?.type?.length > 0 ||
														(typeof dateFilter.to === 'object' &&
															typeof dateFilter.from === 'object')) &&
													'main__active-filters--show'
												}`}
											>
												<div className="active-filters__collections">
													{(typeof dateFilter.to === 'object' &&
														typeof dateFilter.from === 'object') ||
														(activeFilter.days && (
															<span className="active-filters__item">
																<p>
																	<b>Date:</b>
																	{typeof dateFilter.from === 'object'
																		? formatDateTime(dateFilter.from).split(
																				','
																		  )[0] +
																		  ' - ' +
																		  formatDateTime(dateFilter.to).split(
																				','
																		  )[0]
																		: activeFilter.days}
																</p>

																<figure>{icons.x_circle}</figure>
															</span>
														))}

													{activeFilter.type.length > 0 && (
														<span className="active-filters__item">
															<p>
																<b>Type:</b>
																{activeFilter.type.map(
																	(
																		chi: {
																			label:
																				| string
																				| number
																				| boolean
																				| React.ReactElement<
																						any,
																						| string
																						| React.JSXElementConstructor<any>
																				  >
																				| React.ReactFragment
																				| React.ReactPortal
																				| null
																				| undefined;
																		},
																		idx: React.Key | null | undefined
																	) => {
																		return (
																			<React.Fragment key={idx}>
																				{chi.label},{' '}
																			</React.Fragment>
																		);
																	}
																)}
															</p>

															<figure>{icons.x_circle}</figure>
														</span>
													)}
												</div>

												<span
													onClick={() => {
														setDateFilter({
															to: '',
															from: '',
														});
														setActiveFilter({
															days: '',
															type: [],
														});
													}}
													className="active-filters__clear-item"
												>
													<p>Clear All</p>
												</span>
											</div>
										</div>
										<Pagination
											className="top-bar__pagination"
											currentPage={currentPage}
											itemsPerPage={collections?.pagination?.perPage}
											totalItems={collections?.pagination?.total}
											onPageChange={function (page: number): void {
												setCurrentPage(page);
											}}
										/>
									</>
								) : (
									''
								)}
							</div>
							<div className="main__wrap">
								{loading || cols?.length === 0 ? (
									<EmptyScreen
										subTitle="Your collections will appear here, recieve money into your account to see an overview of your collections."
										withMascot
										loadingText="Loading collections..."
										loading={loading}
									/>
								) : (
									<>
										<div className="transaction__table non-mobile">
											<RavenTable
												action={false}
												className="table__main"
												headerList={[
													'AMOUNT',
													'SOURCE',
													'SESSION ID',
													'ACCOUNT NO.',
													'ACCOUNT NAME',
													'DATE RECIEVED',
												]}
											>
												{cols?.map((chi: any, idx: number | string) => {
													const meta = chi?.source
														? JSON?.parse(chi?.source)
														: {};
													return (
														<React.Fragment key={idx}>
															<RavenTableRow
																className="table__main"
																onRowClick={() =>
																	handleSingleView({
																		...chi,
																		meta_data: meta,
																		account_name:
																			meta?.first_name + ' ' + meta?.last_name,
																	})
																}
																six={
																	loading ? (
																		<Skeleton width={120} height={20} />
																	) : (
																		formatDateTime(chi.created_at)
																	)
																}
																// five={
																// 	loading ? (
																// 		<Skeleton width={200} height={20} />
																// 	) : (
																// 		<div>
																// 			<p>
																// 				{' '}
																// 				{meta?.narration?.length > 25
																// 					? meta?.narration?.slice(0, 25) +
																// 					  '...'
																// 					: meta?.narration}
																// 			</p>
																// 		</div>
																// 	)
																// }
																five={
																	loading ? (
																		<Skeleton width={100} height={20} />
																	) : (
																		<div className="status-type-wrap ">
																			<span>
																				{meta?.first_name +
																					' ' +
																					meta?.last_name}
																			</span>
																		</div>
																	)
																}
																four={
																	loading ? (
																		<Skeleton width={100} height={20} />
																	) : (
																		<div className="status-type-wrap ">
																			<span>{meta?.account_number}</span>
																		</div>
																	)
																}
																three={
																	loading ? (
																		<Skeleton width={130} height={20} />
																	) : (
																		<div
																			onClick={() =>
																				handleCopy(chi?.session_id, setCopied, {
																					idx,
																					on: true,
																				})
																			}
																			className="session-wrap"
																		>
																			{copied?.on && copied?.idx === idx && (
																				<span className="copy-animation">
																					Copied!
																				</span>
																			)}
																			<span>
																				{chi?.session_id?.substring(1, 18) +
																					'...'}
																			</span>
																		</div>
																	)
																}
																two={
																	loading ? (
																		<Skeleton width={150} height={20} />
																	) : (
																		`${meta?.bank}`
																	)
																}
																one={
																	loading ? (
																		<Skeleton width={100} height={20} />
																	) : (
																		<div className="main__direction">
																			<p className="text">
																				{' '}
																				{symbol('ngn') +
																					formatNumWithComma(chi.amount, 'ngn')}
																			</p>
																		</div>
																	)
																}
															/>
														</React.Fragment>
													);
												})}
											</RavenTable>
										</div>

										<div className="atlas-mobile-table-wrap">
											{cols?.map(
												(chi: any, idx: React.Key | null | undefined) => {
													const meta_data = chi?.source
														? JSON.parse(chi?.source)
														: chi?.source;

													const { first_name, last_name, bank } = meta_data
														? meta_data
														: [{}];

													// logger.log(meta_data);
													return (
														<MobileTableCard
															title={first_name + ' ' + last_name}
															text={formatDateTime(chi.created_at)}
															amount={
																symbol('ngn') +
																formatNumWithComma(chi.amount, 'ngn')
															}
															img={undefined}
															svg={undefined}
															avatar={''}
															amountColor={'#014345'}
															type={chi.direction}
															showImg={true}
															loading={loading}
															onRowClick={() =>
																handleSingleView({
																	...chi,
																	account_name: first_name + ' ' + last_name,
																	bank: bank,
																	meta_data: meta_data,
																})
															}
															textTwo={''}
														/>
													);
												}
											)}
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</DashboardLayout>
			<TransactionModal
				onClose={() =>
					setModal({
						chi: {
							type: '',
							bank: '',
							description: '',
							amount: '',
							date: '',
							fee: '',
							session_id: '',
							account_number: '',
							email: '',
							created_at: function (created_at: any): string {
								throw new Error('Function not implemented.');
							},
							updated_at: function (updated_at: any): string {
								throw new Error('Function not implemented.');
							},
						},
						on: false,
					})
				}
				visible={modal.on}
				direction={'credit'}
				title={'Collection Details'}
				amount={modal.chi.amount as unknown as number}
				onClick={() => {}}
				meta={modal.chi.source}
				content={[
					{
						label: 'Narration',
						value: modal.chi?.meta_data?.narration || '--',
					},
					{
						label: 'Bank',
						value: modal.chi?.meta_data?.bank || '--',
					},
					{
						label: 'Account Number',
						value: modal.chi.account_number,
					},
					{
						label: 'Account Name',
						value: modal.chi.account_name as string,
					},
					{
						label: 'Email',
						value: modal.chi.email || '--',
					},
					{
						label: 'Session ID',
						copy: true,
						trim: true,
						value: modal.chi.session_id,
					},
					{
						label: 'Amount',
						value: symbol('ngn') + formatNumWithComma(modal.chi.amount, 'ngn'),
					},
					{
						label: 'Fee',
						value: symbol('ngn') + formatNumWithComma(modal.chi.fee, 'ngn'),
					},
					{
						label: 'Date',
						value: formatDateTime(modal.chi.created_at as unknown as string),
					},
				]}
			/>
			<ExportModal
				onClose={() => setShowExport(false)}
				page="collections"
				visible={showExport}
			/>
		</React.Fragment>
	);
};

export default Collections;
