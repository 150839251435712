import { faker } from '@faker-js/faker';
import {
	RavenButton,
	RavenInputField,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import React, { useEffect, useState } from 'react';
import { icons } from '../../../../../assets/icons';
import EmptyScreen from '../../../../../components/common/emptyScreen';
import {
	formatDateTime,
	returnInitial,
} from '../../../../../utils/helper/Helper';
import Pagination from '../../../../../components/common/pagination';
import { generateRandomColor } from '../../../../../utils/helper/color';
import { getDirectors } from '../../../../../redux/settings';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../redux/types';
import { logger } from '../../../../../services/logger';
import SmartFilter from '../../../../../components/common/smartFilter';

const SettingsDirector = ({
	demo,
	manageDirector,
	addDirector,
}: {
	demo?: boolean;
	addDirector?: () => any;
	manageDirector: () => any;
}) => {
	// Generate an array to store the table verification
	const tableData: {
		account_name?: string;
		email?: string;
		date: Date;
	}[] = [];

	const [currentPage, setCurrentPage] = useState(1);
	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});

	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [activeFilter, setActiveFilter] = useState<string>('');

	function isFilter(value: string) {
		if (value === activeFilter) return true;
		else return false;
	}
	const dispatch = useDispatch();
	const { directors, loading } = useSelector(
		(state: RootState) => state.settings
	);

	function DropMore({ className, idx }: any) {
		return (
			<div className={`more-wrap ${className}`}>
				<div
					onClick={() => {
						setShowDrop({
							show: !showDrop.show,
							id: idx,
						});
						manageDirector();
					}}
					className="more_item"
				>
					<figure>{icons.edit_pen}</figure>
					<p>Manage Roles</p>
				</div>

				<div className="more_item">
					<figure>{icons.delete_plain}</figure>
					<p>Delete Member</p>
				</div>
			</div>
		);
	}

	function isDropped(idx: number | string) {
		if (showDrop.id === idx && showDrop.show) return true;
		else return false;
	}

	useEffect(() => {
		dispatch(getDirectors());
	}, []);

	logger.log(directors, 'directors');
	return (
		<div className="settings__main-wrap">
			<div className="main-wrap__title">
				<div className="title__left">
					<h5>Directors</h5>
					<p>Manage all your directors in one place.</p>
				</div>
				{/* <RavenButton onClick={() => {}} color="deep-green-light">
					<div onClick={addDirector} className="title__button">
						<p>Add Directors</p>
						{icons.plus_circle_alt}
					</div>
				</RavenButton> */}
			</div>
			{loading || directors.length === 0 ? (
				<EmptyScreen
					subTitle="Your directors will show up here after completing your CAC verification."
					loading={loading}
				/>
			) : (
				<div className="history__content">
					<SmartFilter />
					<div className="content__main">
						<div className="main__wrap">
							<div className="transaction__table">
								<RavenTable
									action={false}
									className="table__main"
									headerList={['ACCOUNT NAME', 'EMAIL ADDRESS', 'ROLE', '']}
								>
									{directors.map((chi: any, idx: number) => {
										return (
											<React.Fragment key={idx}>
												<RavenTableRow
													className="table__main"
													four={icons.three_dots}
													one={
														<div className="account_name">
															<span
																style={{
																	backgroundColor:
																		generateRandomColor('#e9f5ff'),
																}}
															>
																<p>{returnInitial(chi.account_name ?? '')}</p>
															</span>
															<p className="text"> {chi.account_name}</p>
														</div>
													}
													three={
														<div className="type-wrap">
															<span>{formatDateTime(chi.date)}</span>
														</div>
													}
													two={`${chi.email}`}
												/>
											</React.Fragment>
										);
									})}
								</RavenTable>
							</div>
						</div>
						S
					</div>
				</div>
			)}
		</div>
	);
};

export default SettingsDirector;
