import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import { toast } from '@ravenpay/raven-bank-ui';
import { AuthState, TransactionState, TransferPayload } from './types';
import { logger } from '../services/logger';
import {
	setCollections,
	setTransactions,
	setTransfers,
	setVirtualAccounts,
	setWalletLoader,
} from './wallet';
import { capitalizeFirstLetter } from '../utils/helper/Helper';
import { useDispatch } from 'react-redux';

interface LoginPayload {
	// Add appropriate types for the payload
}

export const initialPeriodicSummary = {
	thirty_days: {
		transfers: {
			count: 0,
			failed: 0,
			successful: 0,
			pending: 0,
		},
		bill_payments: {
			count: 0,
			failed: 0,
			successful: 0,
			pending: 0,
		},
		airtime: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
		electricity: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
		data: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
		cable: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
		betting: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
		transactions: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
	},
	seven_days: {
		transfers: {
			count: 0,
			failed: 0,
			successful: 0,
			pending: 0,
		},
		bill_payments: {
			count: 0,
			failed: 0,
			successful: 0,
			pending: 0,
		},
		airtime: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
		electricity: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
		data: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
		cable: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
		betting: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
		transactions: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
	},
	today: {
		transfers: {
			count: 0,
			failed: 0,
			successful: 0,
			pending: 0,
		},
		bill_payments: {
			count: 0,
			failed: 0,
			successful: 0,
			pending: 0,
		},
		airtime: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
		electricity: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
		data: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
		cable: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
		betting: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
		transactions: {
			successful: 0,
			pending: 0,
			failed: 0,
		},
	},
};
/**
 *
 * @param data
 * @param slice
 * @returns
 */
export function formatSearch(data: any, slice: string) {
	return {
		pagination: {
			perPage: 0,
			currentPage: 0,
			nextPage: 0,
			prevPage: null,
			totalPages: 0,
			total: 0,
		},
		[slice]: data?.map((chi: any) => chi),
	};
}

export const getCustomerTransaction = createAsyncThunk(
	'/get_customer_transaction',
	async (
		payload: { email: string; page: string | number; limit: string | number },
		thunkAPI
	) => {
		try {
			const data = await axios.get(
				`web/customer_transactions?customer_email=${payload.email}&page=${payload.page}&perPage=${payload.limit}`
			);

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else {
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				}
				return thunkAPI.rejectWithValue(data);
			}

			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setCustomerTransaction(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getPeriodicSummary = createAsyncThunk(
	'/get_periodic_summary',
	async (payload, thunkAPI) => {
		try {
			const data = await axios.get(`/web/periodic_summary`);

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else {
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				}
				return thunkAPI.rejectWithValue(data);
			}

			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setPeriodicSummary(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getBankList = createAsyncThunk(
	'/get_banks',
	async (payload, thunkAPI) => {
		try {
			const data = await axios.get(`web/banks`);

			if (data?.data?.status === 'fail') {
				toast.error(data?.data.message, {
					position: 'top-right',
				});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setBanks(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);
export const initiateSingleTransfer = createAsyncThunk(
	'/create_transfer',
	async (payload: TransferPayload, thunkAPI) => {
		try {
			const { data } = await axios.post(`web/transfer`, payload);
			logger.log(data?.data);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const initiateMultipleTransfer = createAsyncThunk(
	'/web/transfer_multiple',
	async (payload: { recipients: TransferPayload[] }, thunkAPI) => {
		try {
			const { data } = await axios.post(`web/transfer_multiple`, payload);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const omniSearch = createAsyncThunk(
	'/omni_search',
	async (payload: string, thunkAPI) => {
		try {
			const { data } = await axios.get(`web/search?searchQuery=${payload}`);
			logger.log(data?.data, 'search query');
			if (data?.status === 'fail') {
				thunkAPI.dispatch(setWalletLoader(false));
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setWalletLoader(false));

				thunkAPI.dispatch(
					setTransfers(
						formatSearch(
							data?.data?.data?.transfers?.transfers_data,
							'transfers'
						)
					)
				);
				thunkAPI.dispatch(
					setTransactions(
						formatSearch(
							data?.data?.data?.transfers?.transfers_data,
							'transactions'
						)
					)
				);

				thunkAPI.dispatch(
					setCollections(
						formatSearch(
							data?.data?.data?.collections?.collections_data,
							'collections'
						)
					)
				);

				thunkAPI.dispatch(
					setVirtualAccounts(
						formatSearch(
							data?.data?.data?.virtual_accounts?.virtual_accounts,
							'virtual_accounts'
						)
					)
				);
				return data;
			}
		} catch (error: any) {
			thunkAPI.dispatch(setWalletLoader(false));

			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const resolveRavenUsername = createAsyncThunk(
	'/resolve-raven-name',
	async (payload: { identifier: string }, thunkAPI) => {
		try {
			const data = await axios.get(
				`/web/transfers/validate_raven_user?identifier=${payload?.identifier}`
			);
			// logger.log(data?.data, 'validate_raven_user');

			if (data?.data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(capitalizeFirstLetter(data.data?.data));
				} else {
					toast.error(capitalizeFirstLetter(data?.data.message), {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const resolveAccountNumber = createAsyncThunk(
	'/resolve-account-number',
	async (
		payload: { account_number: string | number; bank?: number | string },
		thunkAPI
	) => {
		try {
			const data = await axios.post(`web/resolve_account_number`, payload);

			if (data?.data?.status === 'fail') {
				// toast.error(data?.data.message, {
				// 	position: 'top-right',
				// });
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const webpayTopup = createAsyncThunk(
	'/webpay-topup',
	async (
		payload: { account_number: string | number; bank?: number | string },
		thunkAPI
	) => {
		try {
			const data = await axios.post(`web/create_webpay_payment`, payload);

			if (data?.data?.status === 'fail') {
				// toast.error(data?.data.message, {
				// 	position: 'top-right',
				// });
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const initialState: TransactionState = {
	loading: false,
	searchLoading: false,
	isAuth: false,
	customer_transaction: {},
	periodic_summary: initialPeriodicSummary,
	banks: [],
	// initialize other state properties
};

export const transactionSlice = createSlice({
	name: 'auth',
	initialState,

	reducers: {
		setCustomerTransaction: (state, action) => {
			state.customer_transaction = action.payload;
			state.isAuth = true;
		},
		setPeriodicSummary: (state, action) => {
			state.periodic_summary = action.payload;
			state.isAuth = true;
		},
		setBanks: (state, action) => {
			state.banks = action.payload;
			state.isAuth = true;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(getCustomerTransaction.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getCustomerTransaction.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(getCustomerTransaction.rejected, () => {
			return initialState;
		});

		builder.addCase(getPeriodicSummary.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getPeriodicSummary.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(getPeriodicSummary.rejected, () => {
			return initialState;
		});

		builder.addCase(omniSearch.pending, (state) => {
			state.loading = true;
			state.searchLoading = true;
		});
		builder.addCase(omniSearch.fulfilled, (state) => {
			state.loading = false;
			state.searchLoading = false;
		});
		builder.addCase(omniSearch.rejected, () => {
			return initialState;
		});

		builder.addCase(resolveRavenUsername.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(resolveRavenUsername.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(resolveRavenUsername.rejected, () => {
			return initialState;
		});

		builder.addCase(initiateSingleTransfer.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(initiateSingleTransfer.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(initiateSingleTransfer.rejected, () => {
			return initialState;
		});

		builder.addCase(initiateMultipleTransfer.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(initiateMultipleTransfer.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(initiateMultipleTransfer.rejected, () => {
			return initialState;
		});
	},
});

// Action creators are generated for each case reducer function
export const { setCustomerTransaction, setBanks, setPeriodicSummary } =
	transactionSlice.actions;

export default transactionSlice.reducer;
