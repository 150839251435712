import { RavenButton, RavenToggleSlide } from '@ravenpay/raven-bank-ui';
import React, { useEffect, useState } from 'react';
import './styles/index.css';
import { brands, icons } from '../../../../../../../assets/icons';

interface PreviewDetails {
	onBtnClick?: (event?: any) => any;
	multi?: boolean;
	content?: any;
	currentIndex?: (d?: number) => any;
}

const PreviewDetailsBox = ({
	content,
	multi,
	currentIndex,
	onBtnClick,
}: PreviewDetails) => {
	const [currentItem, setCurrentItem] = useState<any>({});
	const [currentItemIndex, setCurrentItemIndex] = useState(0);

	useEffect(() => {
		if (multi) {
			setCurrentItem(content[currentItemIndex]);
			currentIndex ? currentIndex(currentItemIndex) : '';
		} else {
			setCurrentItem(content);
		}
	}, [multi ? currentItemIndex : '']);

	function handleNext() {
		if (currentItemIndex === content.length - 1) {
			setCurrentItemIndex(0);
		} else {
			setCurrentItemIndex(currentItemIndex + 1);
		}
	}

	function handlePrev() {
		if (currentItemIndex === 0) {
			setCurrentItemIndex(content.length - 1);
		} else {
			setCurrentItemIndex(currentItemIndex - 1);
		}
	}

	useEffect(() => {
		if (currentIndex) currentIndex(currentItemIndex);
	}, [currentItemIndex]);

	return (
		<div className="preview-bill">
			<div className="preview-bill__header">
				<h5>₦35,000.00</h5>
				<small>June, 3rd 2023</small>
			</div>

			{multi && (
				<div className="preview-bill__switch">
					<figure onClick={handlePrev} className="pages__current">
						{icons.chevron_right}
					</figure>
					<div className="switch__pages">
						<p>{currentItemIndex + 1}/</p>
						<p>{content.length}</p>
					</div>
					<figure onClick={handleNext} className="pages__total">
						{icons.chevron_right}
					</figure>
				</div>
			)}

			<div className="preview-bill__table">
				<div className="table__item">
					<p>Booking Platform</p>
					<p>{currentItem?.platform?.label}</p>
				</div>
				<div className="table__item">
					<p>Booking Number</p>
					<p>{currentItem?.booking_number}</p>
				</div>

				<div className="table__item">
					<p>Amount</p>
					<p>{currentItem?.amount}</p>
				</div>
				<div className="table__item">
					<p>Fee</p>
					<p>₦10.00</p>
				</div>
			</div>
			<RavenButton
				onClick={onBtnClick}
				className="preview-bill__button"
				style={{ width: '100%', marginTop: '5.7rem' }}
				label="Complete Transaction"
				color="deep-green-light"
			/>
		</div>
	);
};

export default PreviewDetailsBox;
