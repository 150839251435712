import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../redux/settings';
import RootState from '../redux/types';
import axios from '../utils/axios';
import bankboxAPI from '../utils/banbox_axios';
import { SET_TOKEN } from '../redux/auth';
import { getCookie, setCookie } from '../utils/helper/Helper';

const useUser = () => {
	const dispatch = useDispatch();
	const { profile } = useSelector((state: RootState) => state.wallet);
	const token =
		getCookie('token') ?? useSelector((state: RootState) => state.auth).token;

	const setAuthorizationHeaders = (token: string) => {
		axios.defaults.headers.common = {
			Authorization: `Bearer ${token}`,
			redirect: 'follow',
		};
		bankboxAPI.defaults.headers.common = {
			Authorization: `Bearer ${token}`,
			redirect: 'follow',
		};
	};

	if (token) {
		setAuthorizationHeaders(token);
	} else {
		const localToken = getCookie('token');
		if (localToken) {
			setAuthorizationHeaders(localToken);
			dispatch(SET_TOKEN(localToken));
		} else {
			delete axios.defaults.headers.common['Authorization'];
		}
	}
 
	useEffect(() => {
		if (Object.keys(profile).length === 0) {
			dispatch(getProfile());
		}
	}, [profile, dispatch]);
};

export default useUser;
