const CountryList: any = {
	id: 161,
	name: 'Nigeria',
	iso3: 'NGA',
	iso2: 'NG',
	numeric_code: '566',
	phone_code: '234',
	capital: 'Abuja',
	currency: 'NGN',
	currency_name: 'Nigerian naira',
	currency_symbol: '₦',
	tld: '.ng',
	native: 'Nigeria',
	region: 'Africa',
	region_id: '1',
	subregion: 'Western Africa',
	subregion_id: '3',
	nationality: 'Nigerian',
	timezones: [
		{
			zoneName: 'Africa/Lagos',
			gmtOffset: 3600,
			gmtOffsetName: 'UTC+01:00',
			abbreviation: 'WAT',
			tzName: 'West Africa Time',
		},
	],
	translations: {
		kr: '나이지리아',
		'pt-BR': 'Nigéria',
		pt: 'Nigéria',
		nl: 'Nigeria',
		hr: 'Nigerija',
		fa: 'نیجریه',
		de: 'Nigeria',
		es: 'Nigeria',
		fr: 'Nigéria',
		ja: 'ナイジェリア',
		it: 'Nigeria',
		cn: '尼日利亚',
		tr: 'Nijerya',
	},
	latitude: '10.00000000',
	longitude: '8.00000000',
	emoji: '🇳🇬',
	emojiU: 'U+1F1F3 U+1F1EC',
	states: [
		{
			id: 303,
			name: 'Abia',
			state_code: 'AB',
			latitude: '5.45273540',
			longitude: '7.52484140',
			type: 'state',
			cities: [
				{
					id: 76744,
					name: 'Aba',
					latitude: '5.10658000',
					longitude: '7.36667000',
				},
				{
					id: 76769,
					name: 'Amaigbo',
					latitude: '5.78917000',
					longitude: '7.83829000',
				},
				{
					id: 76779,
					name: 'Arochukwu',
					latitude: '5.38941000',
					longitude: '7.91235000',
				},
				{
					id: 76799,
					name: 'Bende',
					latitude: '5.55874000',
					longitude: '7.63359000',
				},
				{
					id: 77057,
					name: 'Ohafia-Ifigh',
					latitude: '5.61455000',
					longitude: '7.81191000',
				},
				{
					id: 77125,
					name: 'Umuahia',
					latitude: '5.52491000',
					longitude: '7.49461000',
				},
			],
		},
		{
			id: 293,
			name: 'Abuja Federal Capital Territory',
			state_code: 'FC',
			latitude: '8.89406910',
			longitude: '7.18604020',
			type: 'capital territory',
			cities: [
				{
					id: 76749,
					name: 'Abuja',
					latitude: '9.05785000',
					longitude: '7.49508000',
				},
				{
					id: 153367,
					name: 'Asokoro',
					latitude: '9.04790000',
					longitude: '7.51550000',
				},
				{
					id: 153362,
					name: 'Bamburu',
					latitude: '9.11360000',
					longitude: '7.10960000',
				},
				{
					id: 153366,
					name: 'Garki',
					latitude: '9.03190000',
					longitude: '7.48280000',
				},
				{
					id: 153364,
					name: 'Gwagwalada',
					latitude: '8.95080000',
					longitude: '7.07670000',
				},
				{
					id: 153363,
					name: 'Gwarinpa',
					latitude: '9.10990000',
					longitude: '7.40420000',
				},
				{
					id: 153371,
					name: 'Jikwoyi',
					latitude: '8.99130000',
					longitude: '7.55630000',
				},
				{
					id: 153368,
					name: 'Karu',
					latitude: '8.99280000',
					longitude: '7.57250000',
				},
				{
					id: 153369,
					name: 'Kubwa',
					latitude: '9.15380000',
					longitude: '7.32200000',
				},
				{
					id: 76990,
					name: 'Kuje',
					latitude: '8.87952000',
					longitude: '7.22756000',
				},
				{
					id: 153370,
					name: 'Kurunduma',
					latitude: '9.01510000',
					longitude: '7.53490000',
				},
				{
					id: 76997,
					name: 'Kwali',
					latitude: '8.88346000',
					longitude: '7.01858000',
				},
				{
					id: 77014,
					name: 'Madala',
					latitude: '9.10444000',
					longitude: '7.21333000',
				},
				{
					id: 153365,
					name: 'Maitama',
					latitude: '9.08820000',
					longitude: '7.49340000',
				},
				{
					id: 153373,
					name: 'Wuse',
					latitude: '9.07470000',
					longitude: '7.47600000',
				},
				{
					id: 153372,
					name: 'Zuba',
					latitude: '9.10230000',
					longitude: '7.19520000',
				},
			],
		},
		{
			id: 320,
			name: 'Adamawa',
			state_code: 'AD',
			latitude: '9.32647510',
			longitude: '12.39838530',
			type: 'state',
			cities: [
				{
					id: 76876,
					name: 'Ganye',
					latitude: '8.43497000',
					longitude: '12.05107000',
				},
				{
					id: 76885,
					name: 'Gombi',
					latitude: '10.16756000',
					longitude: '12.73684000',
				},
				{
					id: 76904,
					name: 'Holma',
					latitude: '9.89863000',
					longitude: '13.05450000',
				},
				{
					id: 76962,
					name: 'Jimeta',
					latitude: '9.27949000',
					longitude: '12.45819000',
				},
				{
					id: 77013,
					name: 'Madagali',
					latitude: '10.88942000',
					longitude: '13.62832000',
				},
				{
					id: 77027,
					name: 'Mayo-Belwa',
					latitude: '9.05421000',
					longitude: '12.05794000',
				},
				{
					id: 77035,
					name: 'Mubi',
					latitude: '10.26858000',
					longitude: '13.26701000',
				},
				{
					id: 77041,
					name: 'Ngurore',
					latitude: '9.28871000',
					longitude: '12.24026000',
				},
				{
					id: 77046,
					name: 'Numan',
					latitude: '9.46374000',
					longitude: '12.03062000',
				},
				{
					id: 77114,
					name: 'Toungo',
					latitude: '8.11733000',
					longitude: '12.04611000',
				},
				{
					id: 77146,
					name: 'Yola',
					latitude: '9.20839000',
					longitude: '12.48146000',
				},
			],
		},
		{
			id: 304,
			name: 'Akwa Ibom',
			state_code: 'AK',
			latitude: '4.90573710',
			longitude: '7.85366750',
			type: 'state',
			cities: [
				{
					id: 76858,
					name: 'Eket',
					latitude: '4.64231000',
					longitude: '7.92438000',
				},
				{
					id: 76865,
					name: 'Esuk Oron',
					latitude: '4.80293000',
					longitude: '8.25341000',
				},
				{
					id: 76937,
					name: 'Ikot Ekpene',
					latitude: '5.18194000',
					longitude: '7.71481000',
				},
				{
					id: 76955,
					name: 'Itu',
					latitude: '5.20131000',
					longitude: '7.98373000',
				},
				{
					id: 77129,
					name: 'Uyo',
					latitude: '5.05127000',
					longitude: '7.93350000',
				},
			],
		},
		{
			id: 315,
			name: 'Anambra',
			state_code: 'AN',
			latitude: '6.22089970',
			longitude: '6.93695590',
			type: 'state',
			cities: [
				{
					id: 76759,
					name: 'Agulu',
					latitude: '6.10045000',
					longitude: '7.06100000',
				},
				{
					id: 76781,
					name: 'Atani',
					latitude: '6.01277000',
					longitude: '6.74768000',
				},
				{
					id: 76785,
					name: 'Awka',
					latitude: '6.21269000',
					longitude: '7.07199000',
				},
				{
					id: 76863,
					name: 'Enugu-Ukwu',
					latitude: '6.17146000',
					longitude: '7.00525000',
				},
				{
					id: 76920,
					name: 'Igbo-Ukwu',
					latitude: '6.01798000',
					longitude: '7.02027000',
				},
				{
					id: 76924,
					name: 'Ihiala',
					latitude: '5.85475000',
					longitude: '6.85944000',
				},
				{
					id: 77043,
					name: 'Nkpor',
					latitude: '6.15038000',
					longitude: '6.83042000',
				},
				{
					id: 77044,
					name: 'Nnewi',
					latitude: '6.01962000',
					longitude: '6.91729000',
				},
				{
					id: 77068,
					name: 'Onitsha',
					latitude: '6.14978000',
					longitude: '6.78569000',
				},
				{
					id: 77083,
					name: 'Ozubulu',
					latitude: '5.95753000',
					longitude: '6.85305000',
				},
				{
					id: 77120,
					name: 'Uga',
					latitude: '5.93576000',
					longitude: '7.07930000',
				},
				{
					id: 77128,
					name: 'Uruobo-Okija',
					latitude: '5.90016000',
					longitude: '6.84312000',
				},
			],
		},
		{
			id: 312,
			name: 'Bauchi',
			state_code: 'BA',
			latitude: '10.77606240',
			longitude: '9.99919430',
			type: 'state',
			cities: [
				{
					id: 76786,
					name: 'Azare',
					latitude: '11.67478000',
					longitude: '10.19069000',
				},
				{
					id: 76796,
					name: 'Bauchi',
					latitude: '10.31032000',
					longitude: '9.84388000',
				},
				{
					id: 76810,
					name: 'Boi',
					latitude: '9.56109000',
					longitude: '9.50154000',
				},
				{
					id: 76818,
					name: 'Bununu',
					latitude: '9.88224000',
					longitude: '9.68058000',
				},
				{
					id: 76835,
					name: 'Darazo',
					latitude: '10.99920000',
					longitude: '10.41062000',
				},
				{
					id: 76836,
					name: 'Dass',
					latitude: '10.00065000',
					longitude: '9.51596000',
				},
				{
					id: 76843,
					name: 'Dindima',
					latitude: '10.22629000',
					longitude: '10.15132000',
				},
				{
					id: 76844,
					name: 'Disina',
					latitude: '11.48135000',
					longitude: '9.91903000',
				},
				{
					id: 76871,
					name: 'Gabarin',
					latitude: '11.10930000',
					longitude: '10.44410000',
				},
				{
					id: 76898,
					name: 'Gwaram',
					latitude: '10.23295000',
					longitude: '10.28572000',
				},
				{
					id: 76978,
					name: 'Kari',
					latitude: '11.24710000',
					longitude: '10.56100000',
				},
				{
					id: 77005,
					name: 'Lame',
					latitude: '10.44154000',
					longitude: '9.23955000',
				},
				{
					id: 77009,
					name: 'Lere',
					latitude: '9.71052000',
					longitude: '9.34029000',
				},
				{
					id: 77015,
					name: 'Madara',
					latitude: '11.77980000',
					longitude: '10.44790000',
				},
				{
					id: 77031,
					name: 'Misau',
					latitude: '11.31370000',
					longitude: '10.46664000',
				},
				{
					id: 77095,
					name: 'Sade',
					latitude: '11.35950000',
					longitude: '10.67320000',
				},
				{
					id: 77140,
					name: 'Yamrat',
					latitude: '10.11161000',
					longitude: '9.82604000',
				},
				{
					id: 77141,
					name: 'Yanda Bayo',
					latitude: '11.50710000',
					longitude: '10.74590000',
				},
				{
					id: 77147,
					name: 'Yuli',
					latitude: '9.69707000',
					longitude: '10.27350000',
				},
				{
					id: 77148,
					name: 'Zadawa',
					latitude: '11.44960000',
					longitude: '10.36720000',
				},
				{
					id: 77150,
					name: 'Zalanga',
					latitude: '10.61432000',
					longitude: '10.17647000',
				},
			],
		},
		{
			id: 305,
			name: 'Bayelsa',
			state_code: 'BY',
			latitude: '4.77190710',
			longitude: '6.06985260',
			type: 'state',
			cities: [
				{
					id: 76770,
					name: 'Amassoma',
					latitude: '4.97032000',
					longitude: '6.10915000',
				},
				{
					id: 77116,
					name: 'Twon-Brass',
					latitude: '4.31231000',
					longitude: '6.24091000',
				},
				{
					id: 77145,
					name: 'Yenagoa',
					latitude: '4.92675000',
					longitude: '6.26764000',
				},
			],
		},
		{
			id: 291,
			name: 'Benue',
			state_code: 'BE',
			latitude: '7.33690240',
			longitude: '8.74036870',
			type: 'state',
			cities: [
				{
					id: 76767,
					name: 'Aliade',
					latitude: '7.29627000',
					longitude: '8.48278000',
				},
				{
					id: 76811,
					name: 'Boju',
					latitude: '7.35572000',
					longitude: '7.89303000',
				},
				{
					id: 149066,
					name: 'Gboko',
					latitude: '7.33159530',
					longitude: '8.97600680',
				},
				{
					id: 76922,
					name: 'Igbor',
					latitude: '7.45123000',
					longitude: '8.60805000',
				},
				{
					id: 77021,
					name: 'Makurdi',
					latitude: '7.73375000',
					longitude: '8.52139000',
				},
				{
					id: 77049,
					name: 'Ochobo',
					latitude: '7.18045000',
					longitude: '7.98240000',
				},
				{
					id: 77077,
					name: 'Otukpa',
					latitude: '7.10168000',
					longitude: '7.65945000',
				},
				{
					id: 77110,
					name: 'Takum',
					latitude: '7.26667000',
					longitude: '9.98333000',
				},
				{
					id: 77121,
					name: 'Ugbokpo',
					latitude: '7.65321000',
					longitude: '7.88410000',
				},
				{
					id: 77142,
					name: 'Yandev',
					latitude: '7.36308000',
					longitude: '9.04235000',
				},
				{
					id: 77149,
					name: 'Zaki Biam',
					latitude: '7.50671000',
					longitude: '9.61040000',
				},
			],
		},
		{
			id: 307,
			name: 'Borno',
			state_code: 'BO',
			latitude: '11.88463560',
			longitude: '13.15196650',
			type: 'state',
			cities: [
				{
					id: 76793,
					name: 'Bama',
					latitude: '11.52134000',
					longitude: '13.68952000',
				},
				{
					id: 76801,
					name: 'Benisheikh',
					latitude: '11.80919000',
					longitude: '12.49151000',
				},
				{
					id: 76808,
					name: 'Biu',
					latitude: '10.61285000',
					longitude: '12.19458000',
				},
				{
					id: 76814,
					name: 'Bornu Yassu',
					latitude: '12.27503000',
					longitude: '12.56856000',
				},
				{
					id: 76825,
					name: 'Damasak',
					latitude: '13.10518000',
					longitude: '12.50854000',
				},
				{
					id: 76827,
					name: 'Damboa',
					latitude: '11.15534000',
					longitude: '12.75638000',
				},
				{
					id: 76842,
					name: 'Dikwa',
					latitude: '12.03609000',
					longitude: '13.91815000',
				},
				{
					id: 76874,
					name: 'Gamboru',
					latitude: '12.37299000',
					longitude: '14.20690000',
				},
				{
					id: 76901,
					name: 'Gwoza',
					latitude: '11.08313000',
					longitude: '13.69595000',
				},
				{
					id: 76991,
					name: 'Kukawa',
					latitude: '12.92475000',
					longitude: '13.56617000',
				},
				{
					id: 77016,
					name: 'Magumeri',
					latitude: '12.11451000',
					longitude: '12.82620000',
				},
				{
					id: 77018,
					name: 'Maiduguri',
					latitude: '11.84692000',
					longitude: '13.15712000',
				},
				{
					id: 77024,
					name: 'Marte',
					latitude: '12.36532000',
					longitude: '13.82930000',
				},
				{
					id: 77030,
					name: 'Miringa',
					latitude: '10.73115000',
					longitude: '12.14626000',
				},
				{
					id: 77033,
					name: 'Monguno',
					latitude: '12.67059000',
					longitude: '13.61224000',
				},
				{
					id: 77040,
					name: 'Ngala',
					latitude: '12.34053000',
					longitude: '14.18670000',
				},
				{
					id: 77101,
					name: 'Shaffa',
					latitude: '10.50673000',
					longitude: '12.33315000',
				},
				{
					id: 77103,
					name: 'Shani',
					latitude: '10.21824000',
					longitude: '12.06059000',
				},
				{
					id: 77113,
					name: 'Tokombere',
					latitude: '11.22135000',
					longitude: '13.48783000',
				},
				{
					id: 77117,
					name: 'Uba',
					latitude: '10.45509000',
					longitude: '13.22233000',
				},
				{
					id: 77138,
					name: 'Wuyo',
					latitude: '10.38522000',
					longitude: '11.69678000',
				},
				{
					id: 77139,
					name: 'Yajiwa',
					latitude: '11.38623000',
					longitude: '12.71992000',
				},
			],
		},
		{
			id: 314,
			name: 'Cross River',
			state_code: 'CR',
			latitude: '5.87017240',
			longitude: '8.59880140',
			type: 'state',
			cities: [
				{
					id: 76761,
					name: 'Akankpa',
					latitude: '5.12640000',
					longitude: '8.18980000',
				},
				{
					id: 76821,
					name: 'Calabar',
					latitude: '4.95893000',
					longitude: '8.32695000',
				},
				{
					id: 76873,
					name: 'Gakem',
					latitude: '6.76963000',
					longitude: '8.99120000',
				},
				{
					id: 76931,
					name: 'Ikang',
					latitude: '4.78978000',
					longitude: '8.53160000',
				},
				{
					id: 77122,
					name: 'Ugep',
					latitude: '5.80865000',
					longitude: '8.08098000',
				},
			],
		},
		{
			id: 316,
			name: 'Delta',
			state_code: 'DE',
			latitude: '33.74537840',
			longitude: '-90.73545080',
			type: 'state',
			cities: [
				{
					id: 76748,
					name: 'Abraka',
					latitude: '5.79023000',
					longitude: '6.10473000',
				},
				{
					id: 76756,
					name: 'Agbor',
					latitude: '6.25375000',
					longitude: '6.19420000',
				},
				{
					id: 76780,
					name: 'Asaba',
					latitude: '6.19824000',
					longitude: '6.73187000',
				},
				{
					id: 76813,
					name: 'Bomadi',
					latitude: '5.16073000',
					longitude: '5.92375000',
				},
				{
					id: 76820,
					name: 'Burutu',
					latitude: '5.35328000',
					longitude: '5.50826000',
				},
				{
					id: 76996,
					name: 'Kwale',
					latitude: '5.70773000',
					longitude: '6.43402000',
				},
				{
					id: 77047,
					name: 'Obiaruku',
					latitude: '5.84672000',
					longitude: '6.15290000',
				},
				{
					id: 77056,
					name: 'Ogwashi-Uku',
					latitude: '6.17811000',
					longitude: '6.52461000',
				},
				{
					id: 77071,
					name: 'Orerokpe',
					latitude: '5.63747000',
					longitude: '5.89013000',
				},
				{
					id: 77086,
					name: 'Patani',
					latitude: '5.22885000',
					longitude: '6.19139000',
				},
				{
					id: 77099,
					name: 'Sapele',
					latitude: '5.89405000',
					longitude: '5.67666000',
				},
				{
					id: 77123,
					name: 'Ughelli',
					latitude: '5.48956000',
					longitude: '6.00407000',
				},
				{
					id: 77126,
					name: 'Umunede',
					latitude: '6.26549000',
					longitude: '6.30962000',
				},
				{
					id: 77133,
					name: 'Warri',
					latitude: '5.51737000',
					longitude: '5.75006000',
				},
			],
		},
		{
			id: 311,
			name: 'Ebonyi',
			state_code: 'EB',
			latitude: '6.26492320',
			longitude: '8.01373020',
			type: 'state',
			cities: [
				{
					id: 76745,
					name: 'Abakaliki',
					latitude: '6.32485000',
					longitude: '8.11368000',
				},
				{
					id: 76754,
					name: 'Afikpo',
					latitude: '5.89258000',
					longitude: '7.93534000',
				},
				{
					id: 76852,
					name: 'Effium',
					latitude: '6.63105000',
					longitude: '8.05814000',
				},
				{
					id: 76867,
					name: 'Ezza-Ohu',
					latitude: '6.44094000',
					longitude: '8.08432000',
				},
				{
					id: 76954,
					name: 'Isieke',
					latitude: '6.38186000',
					longitude: '8.03736000',
				},
			],
		},
		{
			id: 318,
			name: 'Edo',
			state_code: 'ED',
			latitude: '6.63418310',
			longitude: '5.93040560',
			type: 'state',
			cities: [
				{
					id: 76757,
					name: 'Agenebode',
					latitude: '7.10512000',
					longitude: '6.69381000',
				},
				{
					id: 76782,
					name: 'Auchi',
					latitude: '7.06756000',
					longitude: '6.26360000',
				},
				{
					id: 76800,
					name: 'Benin City',
					latitude: '6.33815000',
					longitude: '5.62575000',
				},
				{
					id: 76859,
					name: 'Ekpoma',
					latitude: '6.74300000',
					longitude: '6.14029000',
				},
				{
					id: 76916,
					name: 'Igarra',
					latitude: '7.29366000',
					longitude: '6.10432000',
				},
				{
					id: 76944,
					name: 'Illushi',
					latitude: '6.67033000',
					longitude: '6.62907000',
				},
				{
					id: 77104,
					name: 'Siluko',
					latitude: '6.53589000',
					longitude: '5.16005000',
				},
				{
					id: 77118,
					name: 'Ubiaja',
					latitude: '6.65581000',
					longitude: '6.38494000',
				},
				{
					id: 77127,
					name: 'Uromi',
					latitude: '6.70000000',
					longitude: '6.33333000',
				},
			],
		},
		{
			id: 309,
			name: 'Ekiti',
			state_code: 'EK',
			latitude: '7.71898620',
			longitude: '5.31095050',
			type: 'state',
			cities: [
				{
					id: 76752,
					name: 'Ado-Ekiti',
					latitude: '7.62329000',
					longitude: '5.22087000',
				},
				{
					id: 76777,
					name: 'Aramoko-Ekiti',
					latitude: '7.70483000',
					longitude: '5.04054000',
				},
				{
					id: 76853,
					name: 'Efon-Alaaye',
					latitude: '7.65649000',
					longitude: '4.92235000',
				},
				{
					id: 76860,
					name: 'Emure-Ekiti',
					latitude: '7.43636000',
					longitude: '5.45925000',
				},
				{
					id: 76913,
					name: 'Ifaki',
					latitude: '7.78942000',
					longitude: '5.24852000',
				},
				{
					id: 76917,
					name: 'Igbara-Odo',
					latitude: '7.50251000',
					longitude: '5.06258000',
				},
				{
					id: 76923,
					name: 'Igede-Ekiti',
					latitude: '7.66850000',
					longitude: '5.12627000',
				},
				{
					id: 76930,
					name: 'Ijero-Ekiti',
					latitude: '7.81514000',
					longitude: '5.06716000',
				},
				{
					id: 76934,
					name: 'Ikere-Ekiti',
					latitude: '7.49748000',
					longitude: '5.23041000',
				},
				{
					id: 76950,
					name: 'Ipoti',
					latitude: '7.87377000',
					longitude: '5.07691000',
				},
				{
					id: 76953,
					name: 'Ise-Ekiti',
					latitude: '7.46478000',
					longitude: '5.42333000',
				},
				{
					id: 77058,
					name: 'Oke Ila',
					latitude: '7.95000000',
					longitude: '4.98333000',
				},
				{
					id: 77066,
					name: 'Omuo-Ekiti',
					latitude: '7.75833000',
					longitude: '5.72227000',
				},
			],
		},
		{
			id: 289,
			name: 'Enugu',
			state_code: 'EN',
			latitude: '6.53635300',
			longitude: '7.43561940',
			type: 'state',
			cities: [
				{
					id: 76750,
					name: 'Adani',
					latitude: '6.73971000',
					longitude: '7.01117000',
				},
				{
					id: 76762,
					name: 'Ake-Eze',
					latitude: '5.91677000',
					longitude: '7.67615000',
				},
				{
					id: 76764,
					name: 'Aku',
					latitude: '6.70902000',
					longitude: '7.31826000',
				},
				{
					id: 76768,
					name: 'Amagunze',
					latitude: '6.33063000',
					longitude: '7.65247000',
				},
				{
					id: 76784,
					name: 'Awgu',
					latitude: '6.07278000',
					longitude: '7.47739000',
				},
				{
					id: 76855,
					name: 'Eha Amufu',
					latitude: '6.65915000',
					longitude: '7.75961000',
				},
				{
					id: 76861,
					name: 'Enugu',
					latitude: '6.44132000',
					longitude: '7.49883000',
				},
				{
					id: 76862,
					name: 'Enugu-Ezike',
					latitude: '6.98270000',
					longitude: '7.45534000',
				},
				{
					id: 76866,
					name: 'Ete',
					latitude: '7.08956000',
					longitude: '7.45341000',
				},
				{
					id: 76933,
					name: 'Ikem',
					latitude: '6.77993000',
					longitude: '7.71484000',
				},
				{
					id: 77028,
					name: 'Mberubu',
					latitude: '6.17310000',
					longitude: '7.63017000',
				},
				{
					id: 77045,
					name: 'Nsukka',
					latitude: '6.85783000',
					longitude: '7.39577000',
				},
				{
					id: 77048,
					name: 'Obolo-Eke (1)',
					latitude: '6.88333000',
					longitude: '7.63333000',
				},
				{
					id: 77069,
					name: 'Opi',
					latitude: '6.78223000',
					longitude: '7.43319000',
				},
				{
					id: 77119,
					name: 'Udi',
					latitude: '6.31592000',
					longitude: '7.42086000',
				},
			],
		},
		{
			id: 310,
			name: 'Gombe',
			state_code: 'GO',
			latitude: '10.36377950',
			longitude: '11.19275870',
			type: 'state',
			cities: [
				{
					id: 76763,
					name: 'Akko',
					latitude: '10.28899000',
					longitude: '10.97320000',
				},
				{
					id: 76794,
					name: 'Bara',
					latitude: '10.37444000',
					longitude: '10.72884000',
				},
				{
					id: 76802,
					name: 'Billiri',
					latitude: '9.86545000',
					longitude: '11.22624000',
				},
				{
					id: 76823,
					name: 'Dadiya',
					latitude: '9.61667000',
					longitude: '11.43333000',
				},
				{
					id: 76839,
					name: 'Deba',
					latitude: '10.21187000',
					longitude: '11.38710000',
				},
				{
					id: 76846,
					name: 'Dukku',
					latitude: '10.82379000',
					longitude: '10.77221000',
				},
				{
					id: 76877,
					name: 'Garko',
					latitude: '10.17506000',
					longitude: '11.16458000',
				},
				{
					id: 76884,
					name: 'Gombe',
					latitude: '10.28969000',
					longitude: '11.16729000',
				},
				{
					id: 76903,
					name: 'Hinna',
					latitude: '10.30426000',
					longitude: '11.49905000',
				},
				{
					id: 76968,
					name: 'Kafarati',
					latitude: '10.38304000',
					longitude: '11.09567000',
				},
				{
					id: 76973,
					name: 'Kaltungo',
					latitude: '9.81998000',
					longitude: '11.30871000',
				},
				{
					id: 76993,
					name: 'Kumo',
					latitude: '10.04807000',
					longitude: '11.21055000',
				},
				{
					id: 77037,
					name: 'Nafada',
					latitude: '11.09596000',
					longitude: '11.33261000',
				},
				{
					id: 77088,
					name: 'Pindiga',
					latitude: '9.98433000',
					longitude: '10.95229000',
				},
			],
		},
		{
			id: 308,
			name: 'Imo',
			state_code: 'IM',
			latitude: '5.57201220',
			longitude: '7.05882190',
			type: 'state',
			cities: [
				{
					id: 76925,
					name: 'Iho',
					latitude: '5.58225000',
					longitude: '7.09896000',
				},
				{
					id: 77055,
					name: 'Oguta',
					latitude: '5.71044000',
					longitude: '6.80936000',
				},
				{
					id: 77062,
					name: 'Okigwe',
					latitude: '5.82917000',
					longitude: '7.35056000',
				},
				{
					id: 77073,
					name: 'Orlu',
					latitude: '5.79565000',
					longitude: '7.03513000',
				},
				{
					id: 77074,
					name: 'Orodo',
					latitude: '5.61667000',
					longitude: '7.03333000',
				},
				{
					id: 77078,
					name: 'Owerri',
					latitude: '5.48363000',
					longitude: '7.03325000',
				},
			],
		},
		{
			id: 288,
			name: 'Jigawa',
			state_code: 'JI',
			latitude: '12.22801200',
			longitude: '9.56158670',
			type: 'state',
			cities: [
				{
					id: 76788,
					name: 'Babura',
					latitude: '12.77256000',
					longitude: '9.01525000',
				},
				{
					id: 76806,
					name: 'Birnin Kudu',
					latitude: '11.45207000',
					longitude: '9.47856000',
				},
				{
					id: 76807,
					name: 'Birniwa',
					latitude: '12.79070000',
					longitude: '10.23614000',
				},
				{
					id: 76848,
					name: 'Dutse',
					latitude: '11.75618000',
					longitude: '9.33896000',
				},
				{
					id: 76872,
					name: 'Gagarawa',
					latitude: '12.40848000',
					longitude: '9.52881000',
				},
				{
					id: 76892,
					name: 'Gumel',
					latitude: '12.62690000',
					longitude: '9.38807000',
				},
				{
					id: 76897,
					name: 'Gwaram',
					latitude: '11.27727000',
					longitude: '9.88385000',
				},
				{
					id: 76902,
					name: 'Hadejia',
					latitude: '12.45347000',
					longitude: '10.04115000',
				},
				{
					id: 76969,
					name: 'Kafin Hausa',
					latitude: '12.23933000',
					longitude: '9.91105000',
				},
				{
					id: 76981,
					name: 'Kazaure',
					latitude: '12.64846000',
					longitude: '8.41178000',
				},
				{
					id: 76985,
					name: 'Kiyawa',
					latitude: '11.78442000',
					longitude: '9.60690000',
				},
				{
					id: 77022,
					name: 'Mallammaduri',
					latitude: '12.56427000',
					longitude: '9.95727000',
				},
				{
					id: 77091,
					name: 'Ringim',
					latitude: '12.15143000',
					longitude: '9.16216000',
				},
				{
					id: 77097,
					name: 'Samamiya',
					latitude: '11.34873000',
					longitude: '9.63989000',
				},
			],
		},
		{
			id: 294,
			name: 'Kaduna',
			state_code: 'KD',
			latitude: '10.37640060',
			longitude: '7.70945370',
			type: 'state',
			cities: [
				{
					id: 76772,
					name: 'Anchau',
					latitude: '10.96245000',
					longitude: '8.39233000',
				},
				{
					id: 76819,
					name: 'Burumburum',
					latitude: '11.39106000',
					longitude: '8.72341000',
				},
				{
					id: 76849,
					name: 'Dutsen Wai',
					latitude: '10.85009000',
					longitude: '8.19900000',
				},
				{
					id: 76905,
					name: 'Hunkuyi',
					latitude: '11.26680000',
					longitude: '7.64916000',
				},
				{
					id: 76965,
					name: 'Kachia',
					latitude: '9.87342000',
					longitude: '7.95407000',
				},
				{
					id: 76966,
					name: 'Kaduna',
					latitude: '10.52641000',
					longitude: '7.43879000',
				},
				{
					id: 76967,
					name: 'Kafanchan',
					latitude: '9.58126000',
					longitude: '8.29260000',
				},
				{
					id: 76970,
					name: 'Kagoro',
					latitude: '9.60776000',
					longitude: '8.39043000',
				},
				{
					id: 76972,
					name: 'Kajuru',
					latitude: '10.32281000',
					longitude: '7.68462000',
				},
				{
					id: 76989,
					name: 'Kujama',
					latitude: '10.45767000',
					longitude: '7.63808000',
				},
				{
					id: 77010,
					name: 'Lere',
					latitude: '10.38584000',
					longitude: '8.57286000',
				},
				{
					id: 77023,
					name: 'Mando',
					latitude: '10.71667000',
					longitude: '6.56667000',
				},
				{
					id: 77098,
					name: 'Saminaka',
					latitude: '10.41227000',
					longitude: '8.68748000',
				},
				{
					id: 77105,
					name: 'Soba',
					latitude: '10.98133000',
					longitude: '8.05749000',
				},
				{
					id: 77106,
					name: 'Sofo-Birnin-Gwari',
					latitude: '11.01537000',
					longitude: '6.78036000',
				},
				{
					id: 77151,
					name: 'Zaria',
					latitude: '11.11128000',
					longitude: '7.72270000',
				},
			],
		},
		{
			id: 300,
			name: 'Kano',
			state_code: 'KN',
			latitude: '11.74706980',
			longitude: '8.52471070',
			type: 'state',
			cities: [
				{
					id: 76828,
					name: 'Dan Gora',
					latitude: '11.53485000',
					longitude: '8.15224000',
				},
				{
					id: 76880,
					name: 'Gaya',
					latitude: '11.86064000',
					longitude: '9.00270000',
				},
				{
					id: 76977,
					name: 'Kano',
					latitude: '12.00012000',
					longitude: '8.51672000',
				},
			],
		},
		{
			id: 313,
			name: 'Katsina',
			state_code: 'KT',
			latitude: '12.37967070',
			longitude: '7.63057480',
			type: 'state',
			cities: [
				{
					id: 76831,
					name: 'Danja',
					latitude: '11.37710000',
					longitude: '7.56097000',
				},
				{
					id: 76833,
					name: 'Dankama',
					latitude: '13.29782000',
					longitude: '7.79492000',
				},
				{
					id: 76838,
					name: 'Daura',
					latitude: '13.03299000',
					longitude: '8.32351000',
				},
				{
					id: 76850,
					name: 'Dutsin-Ma',
					latitude: '12.45392000',
					longitude: '7.49723000',
				},
				{
					id: 76870,
					name: 'Funtua',
					latitude: '11.47196000',
					longitude: '7.30699000',
				},
				{
					id: 76887,
					name: 'Gora',
					latitude: '11.91294000',
					longitude: '7.66531000',
				},
				{
					id: 76960,
					name: 'Jibia',
					latitude: '13.09378000',
					longitude: '7.22624000',
				},
				{
					id: 76961,
					name: 'Jikamshi',
					latitude: '12.17328000',
					longitude: '7.77424000',
				},
				{
					id: 76976,
					name: 'Kankara',
					latitude: '11.93114000',
					longitude: '7.41115000',
				},
				{
					id: 76979,
					name: 'Katsina',
					latitude: '12.99082000',
					longitude: '7.60177000',
				},
				{
					id: 77026,
					name: 'Mashi',
					latitude: '12.98044000',
					longitude: '7.94703000',
				},
				{
					id: 77093,
					name: 'Ruma',
					latitude: '12.86260000',
					longitude: '7.23469000',
				},
				{
					id: 77094,
					name: 'Runka',
					latitude: '12.44788000',
					longitude: '7.30918000',
				},
				{
					id: 77131,
					name: 'Wagini',
					latitude: '12.68781000',
					longitude: '7.19579000',
				},
			],
		},
		{
			id: 290,
			name: 'Kebbi',
			state_code: 'KE',
			latitude: '11.49420030',
			longitude: '4.23333550',
			type: 'state',
			cities: [
				{
					id: 76778,
					name: 'Argungu',
					latitude: '12.74482000',
					longitude: '4.52514000',
				},
				{
					id: 76791,
					name: 'Bagudo',
					latitude: '11.40351000',
					longitude: '4.22571000',
				},
				{
					id: 76798,
					name: 'Bena',
					latitude: '11.28444000',
					longitude: '5.93472000',
				},
				{
					id: 76803,
					name: 'Bin Yauri',
					latitude: '10.78230000',
					longitude: '4.81135000',
				},
				{
					id: 76805,
					name: 'Birnin Kebbi',
					latitude: '12.45389000',
					longitude: '4.19750000',
				},
				{
					id: 76822,
					name: 'Dabai',
					latitude: '11.47651000',
					longitude: '5.20625000',
				},
				{
					id: 76824,
					name: 'Dakingari',
					latitude: '11.64809000',
					longitude: '4.06177000',
				},
				{
					id: 76891,
					name: 'Gulma',
					latitude: '12.64231000',
					longitude: '4.35545000',
				},
				{
					id: 76896,
					name: 'Gwandu',
					latitude: '12.50204000',
					longitude: '4.64295000',
				},
				{
					id: 76959,
					name: 'Jega',
					latitude: '12.22336000',
					longitude: '4.37971000',
				},
				{
					id: 76974,
					name: 'Kamba',
					latitude: '11.85172000',
					longitude: '3.65478000',
				},
				{
					id: 76975,
					name: 'Kangiwa',
					latitude: '12.55339000',
					longitude: '3.81814000',
				},
				{
					id: 76983,
					name: 'Kende',
					latitude: '11.51966000',
					longitude: '4.26030000',
				},
				{
					id: 77017,
					name: 'Mahuta',
					latitude: '11.55338000',
					longitude: '4.98138000',
				},
				{
					id: 77019,
					name: 'Maiyama',
					latitude: '12.08225000',
					longitude: '4.36907000',
				},
				{
					id: 77102,
					name: 'Shanga',
					latitude: '11.21374000',
					longitude: '4.57941000',
				},
				{
					id: 77134,
					name: 'Wasagu',
					latitude: '11.37640000',
					longitude: '5.79536000',
				},
				{
					id: 77153,
					name: 'Zuru',
					latitude: '11.43522000',
					longitude: '5.23494000',
				},
			],
		},
		{
			id: 298,
			name: 'Kogi',
			state_code: 'KO',
			latitude: '7.73373250',
			longitude: '6.69058360',
			type: 'state',
			cities: [
				{
					id: 76747,
					name: 'Abocho',
					latitude: '7.56770000',
					longitude: '6.98630000',
				},
				{
					id: 76753,
					name: 'Adoru',
					latitude: '6.97694000',
					longitude: '7.16262000',
				},
				{
					id: 76774,
					name: 'Ankpa',
					latitude: '7.40249000',
					longitude: '7.63196000',
				},
				{
					id: 76816,
					name: 'Bugana',
					latitude: '7.99917000',
					longitude: '7.58361000',
				},
				{
					id: 76840,
					name: 'Dekina',
					latitude: '7.68967000',
					longitude: '7.04380000',
				},
				{
					id: 76854,
					name: 'Egbe',
					latitude: '8.21667000',
					longitude: '5.51667000',
				},
				{
					id: 76909,
					name: 'Icheu',
					latitude: '7.70670000',
					longitude: '6.77180000',
				},
				{
					id: 76910,
					name: 'Idah',
					latitude: '7.11345000',
					longitude: '6.73866000',
				},
				{
					id: 76951,
					name: 'Isanlu-Itedoijowa',
					latitude: '8.27445000',
					longitude: '5.83528000',
				},
				{
					id: 76964,
					name: 'Kabba',
					latitude: '7.82719000',
					longitude: '6.07502000',
				},
				{
					id: 76988,
					name: 'Koton-Karfe',
					latitude: '8.09120000',
					longitude: '6.79782000',
				},
				{
					id: 77011,
					name: 'Lokoja',
					latitude: '7.79688000',
					longitude: '6.74048000',
				},
				{
					id: 77052,
					name: 'Ogaminana',
					latitude: '7.59383000',
					longitude: '6.21798000',
				},
				{
					id: 77054,
					name: 'Ogurugu',
					latitude: '6.78636000',
					longitude: '6.95017000',
				},
				{
					id: 77061,
					name: 'Okene',
					latitude: '7.55122000',
					longitude: '6.23589000',
				},
			],
		},
		{
			id: 295,
			name: 'Kwara',
			state_code: 'KW',
			latitude: '8.96689610',
			longitude: '4.38740510',
			type: 'state',
			cities: [
				{
					id: 76760,
					name: 'Ajasse Ipo',
					latitude: '8.23333000',
					longitude: '4.81667000',
				},
				{
					id: 76809,
					name: 'Bode Saadu',
					latitude: '8.93900000',
					longitude: '4.78227000',
				},
				{
					id: 76899,
					name: 'Gwasero',
					latitude: '9.48333000',
					longitude: '3.50000000',
				},
				{
					id: 76946,
					name: 'Ilorin',
					latitude: '8.49664000',
					longitude: '4.54214000',
				},
				{
					id: 76958,
					name: 'Jebba',
					latitude: '9.11972000',
					longitude: '4.82360000',
				},
				{
					id: 76971,
					name: 'Kaiama',
					latitude: '9.60530000',
					longitude: '3.94101000',
				},
				{
					id: 77001,
					name: 'Lafiagi',
					latitude: '8.85299000',
					longitude: '5.41641000',
				},
				{
					id: 77051,
					name: 'Offa',
					latitude: '8.14911000',
					longitude: '4.72074000',
				},
				{
					id: 77063,
					name: 'Okuta',
					latitude: '9.21667000',
					longitude: '3.18333000',
				},
				{
					id: 77065,
					name: 'Omu-Aran',
					latitude: '8.13857000',
					longitude: '5.10260000',
				},
				{
					id: 77087,
					name: 'Patigi',
					latitude: '8.72851000',
					longitude: '5.75561000',
				},
				{
					id: 77109,
					name: 'Suya',
					latitude: '9.46667000',
					longitude: '3.18333000',
				},
				{
					id: 77143,
					name: 'Yashikera',
					latitude: '9.76667000',
					longitude: '3.40000000',
				},
			],
		},
		{
			id: 306,
			name: 'Lagos',
			state_code: 'LA',
			latitude: '6.52437930',
			longitude: '3.37920570',
			type: 'state',
			cities: [
				{
					id: 76775,
					name: 'Apapa',
					latitude: '6.44880000',
					longitude: '3.35901000',
				},
				{
					id: 76789,
					name: 'Badagry',
					latitude: '6.41502000',
					longitude: '2.88132000',
				},
				{
					id: 76851,
					name: 'Ebute Ikorodu',
					latitude: '6.60086000',
					longitude: '3.48818000',
				},
				{
					id: 76857,
					name: 'Ejirin',
					latitude: '6.61423000',
					longitude: '3.90019000',
				},
				{
					id: 76864,
					name: 'Epe',
					latitude: '6.58412000',
					longitude: '3.98336000',
				},
				{
					id: 76932,
					name: 'Ikeja',
					latitude: '6.59651000',
					longitude: '3.34205000',
				},
				{
					id: 77002,
					name: 'Lagos',
					latitude: '6.45407000',
					longitude: '3.39467000',
				},
				{
					id: 77020,
					name: 'Makoko',
					latitude: '6.49611000',
					longitude: '3.38778000',
				},
			],
		},
		{
			id: 301,
			name: 'Nasarawa',
			state_code: 'NA',
			latitude: '8.49979080',
			longitude: '8.19969370',
			type: 'state',
			cities: [
				{
					id: 76815,
					name: 'Buga',
					latitude: '8.49056000',
					longitude: '7.34139000',
				},
				{
					id: 76845,
					name: 'Doma',
					latitude: '8.39307000',
					longitude: '8.35544000',
				},
				{
					id: 76982,
					name: 'Keffi',
					latitude: '8.84651000',
					longitude: '7.87354000',
				},
				{
					id: 77000,
					name: 'Lafia',
					latitude: '8.49390000',
					longitude: '8.51532000',
				},
				{
					id: 77038,
					name: 'Nasarawa',
					latitude: '8.53895000',
					longitude: '7.70821000',
				},
				{
					id: 77132,
					name: 'Wamba',
					latitude: '8.94153000',
					longitude: '8.60315000',
				},
			],
		},
		{
			id: 317,
			name: 'Niger',
			state_code: 'NI',
			latitude: '9.93092240',
			longitude: '5.59832100',
			type: 'state',
			cities: [
				{
					id: 76783,
					name: 'Auna',
					latitude: '10.18805000',
					longitude: '4.72318000',
				},
				{
					id: 76787,
					name: 'Babana',
					latitude: '10.42949000',
					longitude: '3.81495000',
				},
				{
					id: 76790,
					name: 'Badeggi',
					latitude: '9.05630000',
					longitude: '6.14300000',
				},
				{
					id: 76795,
					name: 'Baro',
					latitude: '8.61565000',
					longitude: '6.41850000',
				},
				{
					id: 76812,
					name: 'Bokani',
					latitude: '9.43333000',
					longitude: '5.20000000',
				},
				{
					id: 76847,
					name: 'Duku',
					latitude: '11.23610000',
					longitude: '4.90727000',
				},
				{
					id: 76907,
					name: 'Ibeto',
					latitude: '10.48536000',
					longitude: '5.14501000',
				},
				{
					id: 76986,
					name: 'Konkwesso',
					latitude: '10.84686000',
					longitude: '4.09835000',
				},
				{
					id: 76987,
					name: 'Kontagora',
					latitude: '10.40319000',
					longitude: '5.47080000',
				},
				{
					id: 76994,
					name: 'Kusheriki',
					latitude: '10.53283000',
					longitude: '6.44222000',
				},
				{
					id: 76995,
					name: 'Kuta',
					latitude: '9.86864000',
					longitude: '6.71042000',
				},
				{
					id: 77007,
					name: 'Lapai',
					latitude: '9.04439000',
					longitude: '6.57089000',
				},
				{
					id: 77029,
					name: 'Minna',
					latitude: '9.61524000',
					longitude: '6.54776000',
				},
				{
					id: 77039,
					name: 'New Shagunnu',
					latitude: '10.33957000',
					longitude: '4.46880000',
				},
				{
					id: 77108,
					name: 'Suleja',
					latitude: '9.18059000',
					longitude: '7.17939000',
				},
				{
					id: 77112,
					name: 'Tegina',
					latitude: '10.07060000',
					longitude: '6.19060000',
				},
				{
					id: 77124,
					name: 'Ukata',
					latitude: '10.83122000',
					longitude: '5.82494000',
				},
				{
					id: 77135,
					name: 'Wawa',
					latitude: '9.90222000',
					longitude: '4.41917000',
				},
				{
					id: 77152,
					name: 'Zungeru',
					latitude: '9.80726000',
					longitude: '6.15238000',
				},
			],
		},
		{
			id: 323,
			name: 'Ogun',
			state_code: 'OG',
			latitude: '6.99797470',
			longitude: '3.47373780',
			type: 'state',
			cities: [
				{
					id: 76746,
					name: 'Abeokuta',
					latitude: '7.15571000',
					longitude: '3.34509000',
				},
				{
					id: 76751,
					name: 'Ado Odo',
					latitude: '6.60000000',
					longitude: '2.93333000',
				},
				{
					id: 76912,
					name: 'Idi Iroko',
					latitude: '6.63333000',
					longitude: '2.73333000',
				},
				{
					id: 76914,
					name: 'Ifo',
					latitude: '6.81491000',
					longitude: '3.19518000',
				},
				{
					id: 76926,
					name: 'Ijebu-Ife',
					latitude: '6.77837000',
					longitude: '4.03386000',
				},
				{
					id: 76927,
					name: 'Ijebu-Igbo',
					latitude: '6.97198000',
					longitude: '3.99938000',
				},
				{
					id: 76929,
					name: 'Ijebu-Ode',
					latitude: '6.81944000',
					longitude: '3.91731000',
				},
				{
					id: 76940,
					name: 'Ilaro',
					latitude: '6.88901000',
					longitude: '3.01416000',
				},
				{
					id: 76947,
					name: 'Imeko',
					latitude: '7.44888000',
					longitude: '2.84289000',
				},
				{
					id: 76949,
					name: 'Iperu',
					latitude: '6.91002000',
					longitude: '3.66557000',
				},
				{
					id: 76952,
					name: 'Isara',
					latitude: '6.99345000',
					longitude: '3.68148000',
				},
				{
					id: 77080,
					name: 'Owode',
					latitude: '6.94851000',
					longitude: '3.50561000',
				},
			],
		},
		{
			id: 321,
			name: 'Ondo',
			state_code: 'ON',
			latitude: '6.91486820',
			longitude: '5.14781440',
			type: 'state',
			cities: [
				{
					id: 76755,
					name: 'Agbabu',
					latitude: '6.58862000',
					longitude: '4.83430000',
				},
				{
					id: 76765,
					name: 'Akure',
					latitude: '7.25256000',
					longitude: '5.19312000',
				},
				{
					id: 76911,
					name: 'Idanre',
					latitude: '7.11270000',
					longitude: '5.11590000',
				},
				{
					id: 76915,
					name: 'Ifon',
					latitude: '6.92973000',
					longitude: '5.77368000',
				},
				{
					id: 76939,
					name: 'Ilare',
					latitude: '7.35067000',
					longitude: '5.11333000',
				},
				{
					id: 77050,
					name: 'Ode',
					latitude: '7.78990000',
					longitude: '5.71170000',
				},
				{
					id: 77067,
					name: 'Ondo',
					latitude: '7.09316000',
					longitude: '4.83528000',
				},
				{
					id: 77070,
					name: 'Ore',
					latitude: '6.74716000',
					longitude: '4.87610000',
				},
				{
					id: 77079,
					name: 'Owo',
					latitude: '7.19620000',
					longitude: '5.58681000',
				},
			],
		},
		{
			id: 322,
			name: 'Osun',
			state_code: 'OS',
			latitude: '7.56289640',
			longitude: '4.51995930',
			type: 'state',
			cities: [
				{
					id: 76776,
					name: 'Apomu',
					latitude: '7.35156000',
					longitude: '4.18335000',
				},
				{
					id: 76856,
					name: 'Ejigbo',
					latitude: '7.90292000',
					longitude: '4.31419000',
				},
				{
					id: 76881,
					name: 'Gbongan',
					latitude: '7.47734000',
					longitude: '4.35351000',
				},
				{
					id: 76928,
					name: 'Ijebu-Jesa',
					latitude: '7.68287000',
					longitude: '4.81769000',
				},
				{
					id: 76935,
					name: 'Ikire',
					latitude: '7.36983000',
					longitude: '4.18630000',
				},
				{
					id: 76936,
					name: 'Ikirun',
					latitude: '7.91283000',
					longitude: '4.66741000',
				},
				{
					id: 76938,
					name: 'Ila Orangun',
					latitude: '8.01714000',
					longitude: '4.90421000',
				},
				{
					id: 76941,
					name: 'Ile-Ife',
					latitude: '7.48240000',
					longitude: '4.56032000',
				},
				{
					id: 76942,
					name: 'Ilesa',
					latitude: '7.62789000',
					longitude: '4.74161000',
				},
				{
					id: 76945,
					name: 'Ilobu',
					latitude: '7.84036000',
					longitude: '4.48557000',
				},
				{
					id: 76948,
					name: 'Inisa',
					latitude: '7.85000000',
					longitude: '4.33333000',
				},
				{
					id: 76956,
					name: 'Iwo',
					latitude: '7.63527000',
					longitude: '4.18156000',
				},
				{
					id: 77032,
					name: 'Modakeke',
					latitude: '7.38299000',
					longitude: '4.26031000',
				},
				{
					id: 77059,
					name: 'Oke Mesi',
					latitude: '7.81667000',
					longitude: '4.91667000',
				},
				{
					id: 77064,
					name: 'Olupona',
					latitude: '7.60000000',
					longitude: '4.18333000',
				},
				{
					id: 77075,
					name: 'Osogbo',
					latitude: '7.77104000',
					longitude: '4.55698000',
				},
				{
					id: 77076,
					name: 'Otan Ayegbaju',
					latitude: '7.94783000',
					longitude: '4.78836000',
				},
				{
					id: 77081,
					name: 'Oyan',
					latitude: '8.05000000',
					longitude: '4.76667000',
				},
			],
		},
		{
			id: 296,
			name: 'Oyo',
			state_code: 'OY',
			latitude: '8.15738090',
			longitude: '3.61465340',
			type: 'state',
			cities: [
				{
					id: 76758,
					name: 'Ago Are',
					latitude: '8.50000000',
					longitude: '3.41667000',
				},
				{
					id: 76766,
					name: 'Alapa',
					latitude: '8.61667000',
					longitude: '4.38333000',
				},
				{
					id: 76868,
					name: 'Fiditi',
					latitude: '7.71361000',
					longitude: '3.91722000',
				},
				{
					id: 76906,
					name: 'Ibadan',
					latitude: '7.37756000',
					longitude: '3.90591000',
				},
				{
					id: 76918,
					name: 'Igbeti',
					latitude: '8.74921000',
					longitude: '4.13113000',
				},
				{
					id: 76919,
					name: 'Igbo-Ora',
					latitude: '7.43383000',
					longitude: '3.28788000',
				},
				{
					id: 76921,
					name: 'Igboho',
					latitude: '8.83784000',
					longitude: '3.75628000',
				},
				{
					id: 76984,
					name: 'Kisi',
					latitude: '9.08297000',
					longitude: '3.85196000',
				},
				{
					id: 77004,
					name: 'Lalupon',
					latitude: '7.46791000',
					longitude: '4.06594000',
				},
				{
					id: 77053,
					name: 'Ogbomoso',
					latitude: '8.13373000',
					longitude: '4.24014000',
				},
				{
					id: 77060,
					name: 'Okeho',
					latitude: '8.03386000',
					longitude: '3.34759000',
				},
				{
					id: 77072,
					name: 'Orita Eruwa',
					latitude: '7.55000000',
					longitude: '3.43333000',
				},
				{
					id: 77082,
					name: 'Oyo',
					latitude: '7.85257000',
					longitude: '3.93125000',
				},
				{
					id: 77096,
					name: 'Saki',
					latitude: '8.66762000',
					longitude: '3.39393000',
				},
			],
		},
		{
			id: 302,
			name: 'Plateau',
			state_code: 'PL',
			latitude: '9.21820930',
			longitude: '9.51794880',
			type: 'state',
			cities: [
				{
					id: 76771,
					name: 'Amper',
					latitude: '9.35509000',
					longitude: '9.70121000',
				},
				{
					id: 76817,
					name: 'Bukuru',
					latitude: '9.79399000',
					longitude: '8.86397000',
				},
				{
					id: 76841,
					name: 'Dengi',
					latitude: '9.36872000',
					longitude: '9.96223000',
				},
				{
					id: 76963,
					name: 'Jos',
					latitude: '9.92849000',
					longitude: '8.89212000',
				},
				{
					id: 76999,
					name: 'Kwolla',
					latitude: '8.90361000',
					longitude: '9.29086000',
				},
				{
					id: 77006,
					name: 'Langtang',
					latitude: '9.14164000',
					longitude: '9.79101000',
				},
				{
					id: 77084,
					name: 'Pankshin',
					latitude: '9.32541000',
					longitude: '9.43520000',
				},
				{
					id: 77085,
					name: 'Panyam',
					latitude: '9.40756000',
					longitude: '9.21481000',
				},
				{
					id: 77130,
					name: 'Vom',
					latitude: '9.72910000',
					longitude: '8.79138000',
				},
				{
					id: 77144,
					name: 'Yelwa',
					latitude: '8.83333000',
					longitude: '9.63333000',
				},
			],
		},
		{
			id: 4926,
			name: 'Rivers',
			state_code: 'RI',
			latitude: '5.02134200',
			longitude: '6.43760220',
			type: 'state',
			cities: [
				{
					id: 148541,
					name: 'Abalama',
					latitude: '4.76305556',
					longitude: '6.84027778',
				},
				{
					id: 148542,
					name: 'Abonnema',
					latitude: '4.72311690',
					longitude: '6.77884610',
				},
				{
					id: 148543,
					name: 'Ahoada',
					latitude: '5.08333333',
					longitude: '6.65000000',
				},
				{
					id: 148544,
					name: 'Bori',
					latitude: '4.67277778',
					longitude: '7.37027778',
				},
				{
					id: 148545,
					name: 'Buguma',
					latitude: '4.73333333',
					longitude: '6.86666667',
				},
				{
					id: 148546,
					name: 'Chokocho',
					latitude: '4.99083300',
					longitude: '7.05444400',
				},
				{
					id: 148547,
					name: 'Elele',
					latitude: '5.10184167',
					longitude: '6.81902222',
				},
				{
					id: 148548,
					name: 'Obonoma',
					latitude: '4.71138889',
					longitude: '6.79083333',
				},
				{
					id: 148549,
					name: 'Omoku',
					latitude: '5.34200000',
					longitude: '6.65600000',
				},
				{
					id: 148550,
					name: 'Onne',
					latitude: '4.74444444',
					longitude: '7.03611111',
				},
				{
					id: 148551,
					name: 'Opobo',
					latitude: '4.51138889',
					longitude: '7.54000000',
				},
				{
					id: 148552,
					name: 'Port Harcourt',
					latitude: '4.75000000',
					longitude: '7.00000000',
				},
				{
					id: 148553,
					name: 'Rumuokoro',
					latitude: '4.86666667',
					longitude: '6.98333333',
				},
			],
		},
		{
			id: 292,
			name: 'Sokoto',
			state_code: 'SO',
			latitude: '13.05331430',
			longitude: '5.32227220',
			type: 'state',
			cities: [
				{
					id: 76804,
					name: 'Binji',
					latitude: '13.22294000',
					longitude: '4.90888000',
				},
				{
					id: 76830,
					name: 'Dange',
					latitude: '12.85313000',
					longitude: '5.34572000',
				},
				{
					id: 76875,
					name: 'Gandi',
					latitude: '12.96358000',
					longitude: '5.74337000',
				},
				{
					id: 76889,
					name: 'Goronyo',
					latitude: '13.44226000',
					longitude: '5.67234000',
				},
				{
					id: 76895,
					name: 'Gwadabawa',
					latitude: '13.35819000',
					longitude: '5.23812000',
				},
				{
					id: 76943,
					name: 'Illela',
					latitude: '13.73064000',
					longitude: '5.29777000',
				},
				{
					id: 77090,
					name: 'Rabah',
					latitude: '13.12257000',
					longitude: '5.50762000',
				},
				{
					id: 77107,
					name: 'Sokoto',
					latitude: '13.06269000',
					longitude: '5.24322000',
				},
				{
					id: 77111,
					name: 'Tambuwal',
					latitude: '12.40592000',
					longitude: '4.64605000',
				},
				{
					id: 77137,
					name: 'Wurno',
					latitude: '13.29048000',
					longitude: '5.42373000',
				},
			],
		},
		{
			id: 319,
			name: 'Taraba',
			state_code: 'TA',
			latitude: '7.99936160',
			longitude: '10.77398630',
			type: 'state',
			cities: [
				{
					id: 76792,
					name: 'Baissa',
					latitude: '7.23087000',
					longitude: '10.62444000',
				},
				{
					id: 76797,
					name: 'Beli',
					latitude: '7.85868000',
					longitude: '10.97187000',
				},
				{
					id: 76879,
					name: 'Gassol',
					latitude: '8.53535000',
					longitude: '10.44615000',
				},
				{
					id: 76883,
					name: 'Gembu',
					latitude: '6.72556000',
					longitude: '11.25652000',
				},
				{
					id: 76908,
					name: 'Ibi',
					latitude: '8.18122000',
					longitude: '9.74431000',
				},
				{
					id: 76957,
					name: 'Jalingo',
					latitude: '8.89367000',
					longitude: '11.35960000',
				},
				{
					id: 77008,
					name: 'Lau',
					latitude: '9.20827000',
					longitude: '11.27541000',
				},
				{
					id: 77036,
					name: 'Mutum Biyu',
					latitude: '8.64138000',
					longitude: '10.77355000',
				},
				{
					id: 77092,
					name: 'Riti',
					latitude: '7.90844000',
					longitude: '9.61688000',
				},
				{
					id: 77136,
					name: 'Wukari',
					latitude: '7.87139000',
					longitude: '9.77786000',
				},
			],
		},
		{
			id: 297,
			name: 'Yobe',
			state_code: 'YO',
			latitude: '12.29387600',
			longitude: '11.43904110',
			type: 'state',
			cities: [
				{
					id: 76826,
					name: 'Damaturu',
					latitude: '11.74697000',
					longitude: '11.96083000',
				},
				{
					id: 76832,
					name: 'Dankalwa',
					latitude: '11.74449000',
					longitude: '12.18545000',
				},
				{
					id: 76834,
					name: 'Dapchi',
					latitude: '12.49536000',
					longitude: '11.49977000',
				},
				{
					id: 76837,
					name: 'Daura',
					latitude: '11.55410000',
					longitude: '11.40600000',
				},
				{
					id: 76869,
					name: 'Fika',
					latitude: '11.37700000',
					longitude: '11.23746000',
				},
				{
					id: 76878,
					name: 'Gashua',
					latitude: '12.87398000',
					longitude: '11.04057000',
				},
				{
					id: 76882,
					name: 'Geidam',
					latitude: '12.89439000',
					longitude: '11.92649000',
				},
				{
					id: 76886,
					name: 'Goniri',
					latitude: '11.48451000',
					longitude: '12.31264000',
				},
				{
					id: 76888,
					name: 'Gorgoram',
					latitude: '12.63958000',
					longitude: '10.70422000',
				},
				{
					id: 76890,
					name: 'Gujba',
					latitude: '11.49959000',
					longitude: '11.93396000',
				},
				{
					id: 76900,
					name: 'Gwio Kura',
					latitude: '12.67479000',
					longitude: '11.06690000',
				},
				{
					id: 76992,
					name: 'Kumagunnam',
					latitude: '13.15498000',
					longitude: '10.63468000',
				},
				{
					id: 77003,
					name: 'Lajere',
					latitude: '11.98022000',
					longitude: '11.44002000',
				},
				{
					id: 77012,
					name: 'Machina',
					latitude: '13.13639000',
					longitude: '10.04924000',
				},
				{
					id: 77042,
					name: 'Nguru',
					latitude: '12.87695000',
					longitude: '10.45536000',
				},
				{
					id: 77089,
					name: 'Potiskum',
					latitude: '11.71391000',
					longitude: '11.08108000',
				},
			],
		},
		{
			id: 299,
			name: 'Zamfara',
			state_code: 'ZA',
			latitude: '12.12218050',
			longitude: '6.22358190',
			type: 'state',
			cities: [
				{
					id: 76773,
					name: 'Anka',
					latitude: '12.11347000',
					longitude: '5.92681000',
				},
				{
					id: 76829,
					name: 'Dan Sadau',
					latitude: '11.29621000',
					longitude: '6.49520000',
				},
				{
					id: 76893,
					name: 'Gummi',
					latitude: '12.14484000',
					longitude: '5.11776000',
				},
				{
					id: 76894,
					name: 'Gusau',
					latitude: '12.17024000',
					longitude: '6.66412000',
				},
				{
					id: 76980,
					name: 'Kaura Namoda',
					latitude: '12.59371000',
					longitude: '6.58648000',
				},
				{
					id: 76998,
					name: 'Kwatarkwashi',
					latitude: '12.14082000',
					longitude: '6.82196000',
				},
				{
					id: 77025,
					name: 'Maru',
					latitude: '12.33360000',
					longitude: '6.40372000',
				},
				{
					id: 77034,
					name: 'Moriki',
					latitude: '12.87405000',
					longitude: '6.48754000',
				},
				{
					id: 77100,
					name: 'Sauri',
					latitude: '11.72655000',
					longitude: '6.78374000',
				},
				{
					id: 77115,
					name: 'Tsafe',
					latitude: '11.95775000',
					longitude: '6.92083000',
				},
			],
		},
	],
};

export function getListOfState() {
	return CountryList.states.map((d: any) => {
		return {
			label: d.name,
			value: d.name.toLowerCase().replace(' ', '_'),
			id: d.id,
		};
	});
}
