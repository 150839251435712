import { RavenButton, RavenToolTip } from '@ravenpay/raven-bank-ui';
import React from 'react';
import { icons } from '../../../assets/icons';
import { trimLongString } from '../../../utils/helper/Helper';
import './styles/index.css';

type ActionProp = {
	onEdit: () => void;
	onDelete: () => void;
	editMode?: boolean;
	tooltipName?: string | undefined;
	onSave?: () => void;
};
const TableActionBlock = ({
	onEdit,
	onSave,
	onDelete,
	editMode,
	tooltipName,
}: ActionProp) => {
	return (
		<div className="table-action-wrap">
			{!editMode && (
				<>
					<figure
						className="table-action-wrap__icons grey-bg darken-action-bg tooltip-hover-wrap"
						onClick={onEdit}
					>
						<RavenToolTip
							color={`black-light`}
							text={`Edit recipient ${trimLongString(tooltipName, 15)}`}
							position={`left`}
						/>
						{icons.edit}
					</figure>
					<figure
						onClick={onDelete}
						className="table-action-wrap__icons delete-box darken-action-bg tooltip-hover-wrap"
					>
						<RavenToolTip
							color={`black-light`}
							text={`Delete recipient ${trimLongString(tooltipName, 15)}`}
							position={`top-left`}
						/>
						{icons.delete}
					</figure>
				</>
			)}
			{editMode && (
				<RavenButton
					onClick={onSave}
					label="Save"
					size="small"
					color="deep-green-light"
				/>
			)}
		</div>
	);
};

export default TableActionBlock;
