import { toast } from '@ravenpay/raven-bank-ui';
import React from 'react';
import { icons } from '../../assets/icons';
import './styles/legacy.css';
const LegacySwitch = () => {
	const [legacySwitch, setLegacySwitch] = React.useState(false);
	const [reload, setReload] = React.useState({
		on: false,
		text: 'Cancel',
	});

	React.useEffect(() => {
		setTimeout(() => {
			setLegacySwitch(true);
		}, 3000);
	}, []);

	function handleHideLegacySwitch() {
		setLegacySwitch(false);
	}

	const handleLegacy = async () => {
		setLegacySwitch(false);
		toast.info(
			(
				<div className="legacy_toast">
					<p>Redirecting you to Legacy Atlas in 5seconds...</p>
					<button
						onClick={() => {
							setReload({
								on: true,
								text: 'Reloading...',
							});
						}}
					>
						{reload.text}
					</button>
				</div>
			) as unknown as string
		);

		setTimeout(() => {
			window.location.replace(
				process.env.REACT_APP_LEGACY_APP_URL ||
					'https://legacy-atlas.getravenbank.com'
			);
		}, 5000);
	};

	React.useEffect(() => {
		if (reload.on) {
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [reload.on]);

	return (
		<div className={`auth-legacy-switch ${legacySwitch && 'show'}`}>
			<div>
				<figure className="info-icon">{icons.legacy_info}</figure>
				<p className="legacy-text">
					You're currently using our newly redesigned platform. If you prefer
					the previous version, simply click button.
				</p>
			</div>

			<div>
				<button onClick={handleLegacy}>
					<figure>{icons.legacy_auth_switch}</figure>
					<p>Switch to Legacy Atlas</p>
				</button>
				<span onClick={handleHideLegacySwitch}>
					<figure>{icons.legacy_cancel}</figure>
				</span>
			</div>
		</div>
	);
};

export default LegacySwitch;
