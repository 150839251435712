import { RavenInputField, RavenModal } from '@ravenpay/raven-bank-ui';
import React, { useEffect } from 'react';
import { icons } from '../../../assets/icons';
import './style/index.css';
import {
	exportAirtime,
	exportBetting,
	exportCable,
	exportCollections,
	exportCustomers,
	exportData,
	exportElectricity,
	exportTransactions,
	exportTransfers,
	exportVirtualAccount,
} from '../../../redux/export';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import {
	capitalizeFirstLetter,
	formatDate,
} from '../../../utils/helper/Helper';
import { logger } from '../../../services/logger';
import { bankboxAPI } from '../../../redux/bankbox';

const days = [
	'All',
	'Today',
	'Last 7 Days',
	'Last 14 Days',
	'Last 30 Days',
	'Custom',
];

const ExportModal = ({
	visible,
	onClose,
	onClick,
	onChange,
	value,
	page = 'wallet',
}: {
	visible: boolean;
	onClose?: () => void;
	onClick?: () => void;
	page?: string;
	loading?: boolean;
	value?: {
		date:
			| string
			| undefined
			| {
					start_date?: string;
					end_date?: string;
			  };
		fileType: string;
	};
	onChange?: any;
}) => {
	const [selectedDay, setSelectedDay] = React.useState<
		| string
		| undefined
		| {
				start_date?: string | undefined;
				end_date?: string | undefined;
		  }
	>('All');
	const [fileType, setFileType] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [dateRange, setDateRange] = React.useState<{
		start_date?: string | undefined;
		end_date?: string | undefined;
	}>({
		start_date: '2022-06-01',
		end_date: formatDate(addDays(new Date(), 1)),
	});

	const dispatch = useDispatch<AppDispatch>();
	function getNthDaysAgo(d: number) {
		const today = new Date(); // Get today's date
		const nthDay = new Date(today); // Create a new date object

		nthDay.setDate(nthDay.getDate() - d); // Subtract nth days

		return nthDay;
	}

	function downloadFile(url: any, filename: string) {
		const a = document.createElement('a');

		// logger.log(url.url ?? url, 'oinu');
		a.href = url.url ?? url;
		a.download = filename || 'download';
		const clickHandler = () => {
			setTimeout(() => {
				URL.revokeObjectURL(url);
				removeEventListener('click', clickHandler);
			}, 150);
		};

		a.addEventListener('click', clickHandler, false);

		a.click();

		return a;
	}

	function handleFilter(str?: string) {
		const seventhDayAgo = getNthDaysAgo(7);
		const last30Days = getNthDaysAgo(30);

		const formated30days = formatDate(last30Days);
		const formated7days = formatDate(seventhDayAgo);
		const formated14days = formatDate(getNthDaysAgo(14));

		setDateRange({
			start_date:
				str === 'All'
					? formatDate(new Date('2022-06-01'))
					: str === 'Today'
					? formatDate(new Date())
					: str === 'Last 7 Days'
					? formated7days
					: str === 'Last 14 Days'
					? formated14days
					: str === 'Last 30 Days'
					? formated30days
					: dateRange.start_date,
			end_date:
				str === 'All'
					? formatDate(addDays(new Date(), 1))
					: str === 'Today'
					? formatDate(addDays(new Date(), 1))
					: str === 'Last 7 Days'
					? formatDate(addDays(new Date(), 1))
					: str === 'Last 14 Days'
					? formatDate(addDays(new Date(), 1))
					: str === 'Last 30 Days'
					? formatDate(addDays(new Date(), 1))
					: dateRange.end_date,
		});
	}

	async function handleExport() {
		setLoading(true);
		handleFilter(selectedDay as string);
		let resp;

		if (page === 'wallet' || page === 'transactions') {
			resp = await dispatch(
				exportTransactions({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
				})
			);
		}

		if (page === 'collections') {
			resp = await dispatch(
				exportCollections({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
				})
			);
		}

		if (page === 'transfers') {
			resp = await dispatch(
				exportTransfers({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
				})
			);
		}

		if (page === 'customers') {
			resp = await dispatch(
				exportCustomers({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
				})
			);
		}

		if (page === 'airtime') {
			resp = await dispatch(
				exportAirtime({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					file_type: fileType,
				})
			);
		}

		if (page === 'data') {
			resp = await dispatch(
				exportData({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					file_type: fileType,
				})
			);
		}
		if (page === 'bankbox_users') {
			resp = await dispatch(
				bankboxAPI.exportUsers({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					stop_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					// file_type: fileType,
				})
			);
		}

		if (page === 'bankbox_transactions') {
			resp = await dispatch(
				bankboxAPI.exportBankboxTransaction({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					stop_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					// file_type: fileType,
				})
			);
		}
		if (page === 'bankbox_terminals') {
			resp = await dispatch(
				bankboxAPI.exportBankboxes({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					stop_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					// file_type: fileType,
				})
			);
		}

		if (page === 'electricity') {
			resp = await dispatch(
				exportElectricity({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					file_type: fileType,
				})
			);
		}

		if (page === 'betting') {
			resp = await dispatch(
				exportBetting({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					file_type: fileType,
				})
			);
		}

		if (page === 'cable') {
			resp = await dispatch(
				exportCable({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					file_type: fileType,
				})
			);
		}

		if (page === 'virtual-accounts') {
			resp = await dispatch(
				exportVirtualAccount({
					start_date:
						dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
					end_date:
						dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
					file_type: fileType,
				})
			);
		}
		// logger.log(resp, 'from here');

		if (resp?.payload?.status === 'success') {
			setLoading(false);
			onClose && onClose();

			downloadFile(
				resp?.payload?.data?.data,
				'Atlas  ' +
					capitalizeFirstLetter(page) +
					' - ' +
					dateRange.start_date +
					' / ' +
					dateRange.end_date
			);
		} else {
			setLoading(false);
		}
	}

	function addDays(date: Date | string, days: number) {
		// Function to add Days
		var result = new Date(date);
		result.setDate(result.getDate() + days);

		return result;
	}

	return (
		<RavenModal
			visble={visible}
			onClose={onClose}
			loading={loading}
			disabled={!fileType || !dateRange}
			btnLabel="Export Data"
			btnColor="deep-green-light"
			onBtnClick={handleExport}
		>
			<div className="export-modal">
				<div className="export-modal__title">
					<h5>
						Export{' '}
						{capitalizeFirstLetter(page)
							.replaceAll('_', ' ')
							.replaceAll('-', ' ')}
					</h5>
					<p>Choose how you would like to export your data</p>
				</div>

				<div className="export-modal__export-as">
					<label htmlFor="">Export as</label>
					<div className={'export-modal__export-as--selector-wrap'}>
						<div
							onClick={() => {
								setFileType('csv');
								onChange && onChange({ ...value, fileType: 'csv' });
							}}
							className={`selector-wrap__item ${
								fileType === 'csv' && 'selected'
							}`}
						>
							<figure>
								{fileType === 'csv' ? icons.radio_check : icons.radio_unchecked}
							</figure>
							<p>CSV File</p>
						</div>
						<div
							onClick={() => {
								setFileType('excel');
								onChange && onChange({ ...value, fileType: 'excel' });
							}}
							className={`selector-wrap__item ${
								fileType === 'excel' && 'selected'
							}`}
						>
							<figure>
								{fileType === 'excel'
									? icons.radio_check
									: icons.radio_unchecked}
							</figure>
							<p>Excel File</p>
						</div>
					</div>

					<div className="export-modal__days-selector">
						<label htmlFor="">Date</label>

						<div className={`days-selector__item-wrap `}>
							{days.map((d) => {
								return (
									<div
										onClick={() => {
											setSelectedDay(d);
											handleFilter(d as string);
											onChange && onChange({ ...value, date: d });
										}}
										className={`days-selector__item ${
											selectedDay === d && 'selected'
										}`}
									>
										<p>{d}</p>
										{selectedDay === d && 'selected' && (
											<figure>{icons.green_checkmark}</figure>
										)}
									</div>
								);
							})}
						</div>
					</div>

					<div
						className={`export-modal__custom-date ${
							selectedDay === 'Custom' && 'show'
						}`}
					>
						<RavenInputField
							color="deep-green-dark"
							label="From"
							placeholder="Select a Date"
							type="date"
							onChange={(e: string) =>
								setDateRange({
									...(dateRange as {
										start_date: string | undefined;
										end_date: string | undefined;
									}),
									start_date: formatDate(new Date(e)),
								})
							}
						/>
						<RavenInputField
							color="deep-green-dark"
							label="To"
							placeholder="Select a Date"
							type="date"
							onChange={(e: string) =>
								setDateRange({
									...(dateRange as {
										start_date: string | undefined;
										end_date: string | undefined;
									}),
									end_date: formatDate(new Date(e)),
								})
							}
						/>
					</div>
				</div>
			</div>
		</RavenModal>
	);
};

export default ExportModal;
