import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import { toast } from '@ravenpay/raven-bank-ui';
import {
	AuthState,
	SettingsState,
	TransactionState,
	VerificationState,
} from './types';
import { logger } from '../services/logger';

export const validateBVN = createAsyncThunk(
	'/settings-bvn',
	async (payload: { bvn?: string }, thunkAPI) => {
		try {
			const data = await axios.post(`/web/verify_bvn`, payload);

			logger.log(data, 'dare');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				thunkAPI.dispatch(setBvn(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const validateNIN = createAsyncThunk(
	'/settings-bvn',
	async (payload: { nin?: string }, thunkAPI) => {
		try {
			const data = await axios.post(`/web/verify_nin`, payload);

			logger.log(data, 'dare');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				thunkAPI.dispatch(setBvn(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const validatePassport = createAsyncThunk(
	'/verify-passport',
	async (payload: { nin?: string }, thunkAPI) => {
		try {
			const data = await axios.post(`/web/verify_passport`, payload);

			// logger.log(data, 'dare');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				thunkAPI.dispatch(setBvn(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);
export const getProfile = createAsyncThunk(
	'/support-profile',
	async (payload, thunkAPI) => {
		try {
			const data = await axios.get(`web/support_profile`);
			// logger.log(data);
			if (data?.data?.status === 'fail') {
				toast.error(data?.data.message, {
					position: 'top-right',
				});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setProfile(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);
const initialState: VerificationState = {
	loading: false,
	isAuth: false,
	bvn: {},
	profile: {},
	// initialize other state properties
};

export const verificationSlice = createSlice({
	name: 'verification',
	initialState,

	reducers: {
		setBvn: (state, action) => {
			state.bvn = action.payload;
			state.isAuth = true;
		},
		setProfile: (state, action) => {
			state.profile = action.payload;
			state.isAuth = true;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(validateBVN.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(validateBVN.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(validateBVN.rejected, () => {
			return initialState;
		});
	},
});

// Action creators are generated for each case reducer function
export const { setBvn, setProfile } = verificationSlice.actions;

export default verificationSlice.reducer;
