import {
	RavenButton,
	RavenCheckBox,
	RavenInputField,
	toast,
} from '@ravenpay/raven-bank-ui';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DashboardLayout from '../../../../../../../layout/dashboard/dashboardLayout';
import PreviewDetailsBox from './PreviewDetailsBox';
import {
	IconVault,
	generateReference,
} from '../../../../../../../utils/helper/Helper';

import useDebounce from '../../../../../../../hooks/UseDebounce';

import ContainerLayout from '../../../../../../../layout/dashboard/container';
import { brands, icons } from '../../../../../../../assets/icons';
import dstv from '../../../../../../../assets/images/dstv.png';
import gotv from '../../../../../../../assets/images/gotv.png';
import consat from '../../../../../../../assets/images/consat.png';
import startimes from '../../../../../../../assets/images/startimes.png';
import './styles/index.css';
import ViewAll from './ViewAll';
import ConfirmTransactionPinModal from '../../common/ConfirmPinModal';
import {
	getCablePlans,
	subscribeCable,
	validateCableAccount,
} from '../../../../../../../redux/bills';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../../../redux/store';
import RootState from '../../../../../../../redux/types';
import { logger } from '../../../../../../../services/logger';
import { verificationLoader } from '../../../../../../../components/common/verificationLoader';

type Modal = {
	pin: boolean;
	success: boolean;
};

type Provider = 'gotv' | 'dstv' | 'consat' | 'startimes' | '';

const NewCablePurchase = () => {
	const navigate = useNavigate();
	const [step, setStep] = useState<number | string>(1);
	const [selectedProvider, setSelectedProvider] = useState<{
		name: Provider;
		icon: any;
	}>({
		name: '',
		icon: '',
	});
	const [viewAll, setViewAll] = useState(false);

	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [showModal, setShowModal] = useState<Modal>({
		pin: false,
		success: false,
	});
	const [validatedName, setValidatedName] = useState('');

	const [details, setDetails] = useState<any>({
		plan: {},
		amount: '',
		provider: [
			{
				name: 'gotv',
				img: '',
			},
		],
		smart_card_number: '',
		transfer_pin: '',
		validated_name: validatedName,
	});

	const dispatch = useDispatch<AppDispatch>();
	const { cablePlans, loading } = useSelector(
		(state: RootState) => state.bills
	);

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...details, [name]: value };
		setDetails(obj);
	};

	const handleBulkAdd = () => {
		const checkList = details?.bulk_list?.some(
			(chi: any) => chi?.phone_number === details?.phone_number
		);
		if (!checkList) {
			const obj = {
				phone_number: details?.phone_number,
				amount: details?.amount,
				provider: details?.provider,
			};
			const newList = [obj, ...details?.bulk_list];
			setDetails((prev: any) => {
				return {
					...prev,
					bulk_list: newList,
					phone_number: '',
					amount: '',
					provider: '',
				};
			});
			setValidatedName('');
			// setVerify(false);
		} else {
			toast.error('Recepient already exist in bulk list');
		}
	};

	const [error, setError] = useState({
		plan: false,
		smart_card_number: false,
	});
	const [loadingVery, setLoadingVerify] = useState(false);

	const debouncedSearchTerm = useDebounce(details?.smart_card_number, 1000);
	useEffect(() => {
		let isMount = true;

		if (isMount && debouncedSearchTerm?.length > 3) {
			validateAccount();
		}

		if (isMount && debouncedSearchTerm?.length < 1) {
			setValidatedName('');
		}

		return () => {
			isMount = false;
		};
	}, [debouncedSearchTerm]);

	function disableAlgorithm() {
		if (!details.plan || !details.smart_card_number || !validatedName) {
			return true;
		} else {
			return false;
		}
	}

	function handleInitPayment() {
		if (!details.username) {
			return true;
		}
		if (!details.amount) {
			return true;
		} else return false;
	}

	const providerList = [
		{ name: 'dstv', icon: dstv },
		{ name: 'gotv', icon: gotv },
		{ name: 'startimes', icon: startimes },
		{ name: 'consat', icon: consat },
	];

	useEffect(() => {
		dispatch(getCablePlans({ provider: details?.provider?.name || 'gotv' }));
	}, [details?.provider]);

	function formatPlans() {
		const pl: { label: string; value: string; amount: number }[] = [];
		cablePlans?.map((e) => {
			pl?.push({
				...e,
				label: e?.name + ' - ' + e.amount,
				value: e?.code + ' - ' + e.amount,
				amount: e?.amount as unknown as number,
			});
		});

		return pl;
	}

	async function validateAccount() {
		setLoadingVerify(true);

		const rep = await dispatch(
			validateCableAccount({
				provider: details?.provider?.name,
				smart_card_number: details?.smart_card_number,
			})
		);

		if (rep?.payload?.status === 'success') {
			setLoadingVerify(false);
			setDetails({
				...details,
				validated_name: rep.payload.data?.data?.customer_name,
			});
			setValidatedName(rep.payload.data?.data?.customer_name);
		} else {
			setLoadingVerify(false);
		}
	}

	async function handlePayment() {
		logger.log(details);
		const resp: any = await dispatch(
			subscribeCable({
				provider: details?.provider?.name,
				smart_card_number: details?.smart_card_number,
				phone_number: details?.phone_number,
				code: details?.plan?.code,
				merchant_reference: generateReference(),
			})
		);

		if (resp?.payload?.status === 'success') {
			navigate('/dashboard-payment/bill-payments?biller-type=cable');
		}
	}

	const plans = formatPlans();
	const stepList = ['Subscription detail', 'Confirm Transactions'];

	return (
		<>
			<DashboardLayout>
				{step !== 'view-all' && (
					<ContainerLayout
						isLatest
						steps={stepList}
						btnLabel={step === 2 ? 'Complete Transaction' : 'Continue'}
						pageTitle="Buy Cable Plan"
						onBtnClick={() => {
							if (handleInitPayment()) setStep(2);
							else setStep(2), handleBulkAdd();
						}}
						onBack={() => {
							if (step === 1) navigate('/dashboard-payment/bill-payments');
							else if (step === 2) setStep(step - 1);
						}}
						btnColor="deep-green-light"
						onPrev={() => {
							step === 1
								? navigate(`/dashboard-payment/bill-payments`)
								: setStep(1);
							setDetails({ ...details, bulk_list: [] });
						}}
						pageSubtitle="Quickly purchase cable TV subscriptions from your preffered provider"
						style={{
							width: '100%',
							maxHeight: '95%',
							backgroundColor: step === 2 ? 'unset' : '',
							border: step === 2 ? 'unset' : '',
							padding: step === 2 ? 'unset' : '',
						}}
					>
						<div
							className={`bill-purchase  atlas-payment-container animate-move-up-class ${
								step === 2 && 'step2'
							} `}
						>
							<form
								onSubmit={(e) => {
									e.preventDefault();
								}}
								action=""
								className="form"
							>
								{/* step 1 start ------------------------------ */}
								{step === 1 && (
									<>
										{/* end of bg box */}
										<div className="select-network-provider_wrap">
											<label>Select Cable Provider</label>
											<div className="select-network-provider">
												{providerList.map(
													(chi: { name: any; icon: any }, idx: number) => {
														return (
															<div
																onClick={() => {
																	setDetails({
																		...details,
																		provider: chi,
																	});
																	setSelectedProvider(chi);
																}}
																key={idx}
																className={`select-network-provider__item ${
																	selectedProvider.name === chi.name && 'active'
																}`}
															>
																<img src={chi.icon} alt="" />
															</div>
														);
													}
												)}
											</div>
										</div>

										<RavenInputField
											type={`select`}
											style={{ zIndex: '676' }}
											color={`deep-green-light`}
											label={`Subscriptiion Plans `}
											value={details?.plan}
											onChange={(e: any) => {
												setDetails({
													...details,
													plan: e,
												});
												setError((prev) => {
													return { ...prev, plan: false };
												});
											}}
											placeholder={`Select Subscription Plan`}
											labelColor={`purple-light`}
											showError={error?.plan}
											name={`plan`}
											selectOption={plans}
										/>

										<RavenInputField
											type={`text`}
											color={`deep-green-light`}
											label={`${selectedProvider.name.toUpperCase()} Smart Card Number `}
											value={details?.meter_number}
											onChange={(e: any) => {
												handleChange(e);
												setError((prev) => {
													return { ...prev, meter_number: false };
												});
											}}
											labelSpanText={verificationLoader(loadingVery)}
											labelClassName={`label-class-span ${
												details?.smart_card_number?.length >= 9 &&
												'label-class-span-active'
											}`}
											placeholder={`E.g ‘5676904735589467586736’`}
											labelColor={`purple-light`}
											showError={error?.smart_card_number}
											name={`smart_card_number`}
											onActionClick={() => {
												details?.smart_card_number.length > 3 &&
													validateAccount();
											}}
										/>

										{/* verify name box start */}
										<div
											className={`verify-id-box ${
												validatedName && 'verify-id-box-show'
											}`}
										>
											<RavenCheckBox
												checked={true}
												onChange={() => {}}
												id={1}
												color={`black-light`}
												className={`check`}
											/>
											<p className="text">{validatedName}</p>
										</div>
										{/* verify name box end */}

										<RavenInputField
											type={`phone`}
											color={`deep-green-light`}
											label={`Phone Number `}
											value={details?.phone_number}
											onChange={(e: any) => {
												handleChange(e);
												setError((prev) => {
													return { ...prev, meter_number: false };
												});
											}}
											placeholder={`E.g ‘5676904735589467586736’`}
											labelColor={`purple-light`}
											// showError={error?.phone_number}
											name={`phone_number`}
										/>
										{/* <RavenButton
											className="bill-purchase-button"
											style={{ width: '100%' }}
											label="Proceed"
											disabled={disableAlgorithm()}
											onClick={() => {
												if (handleInitPayment()) setStep(2);
												else setStep(2), handleBulkAdd();
											}}
											color="deep-green-light"
										/> */}
										{/* recepient box end */}
									</>
								)}
								{/* step 1 end -------------------------------- */}

								{/* step 2 start ----------------------------- */}
								{step === 2 && (
									<div className="step-two-bills animate-move-up-class">
										<div className="preview-bill__logo">
											<figure>
												{
													<img
														src={IconVault(icons.electricity_purchase)}
														alt=""
													/>
												}
											</figure>
										</div>

										<PreviewDetailsBox
											currentIndex={(d: any) => setCurrentIndex(d)}
											loading={loading}
											onBtnClick={() =>
												// setShowModal({
												// 	pin: true,
												// 	success: false,
												// })
												handlePayment()
											}
											content={details}
										/>
									</div>
								)}
							</form>

							{/* view all end ----------------------------- */}
						</div>
					</ContainerLayout>
				)}

				{step === 'view-all' && (
					<ViewAll
						onBack={() => {
							setStep(1);
						}}
						onChange={(e: any) => setDetails(e)}
						items={details}
					/>
				)}
			</DashboardLayout>
			<ConfirmTransactionPinModal
				onBtnClick={() => {
					toast.success(
						'Your Airtime Purchase of Airtel - 200 has been successful'
					);
					setShowModal({
						pin: false,
						success: false,
					});
					navigate('/dashboard-payment/bill-payments');
				}}
				onClose={() =>
					setShowModal({
						pin: false,
						success: false,
					})
				}
				visible={showModal.pin}
			/>
		</>
	);
};

export default NewCablePurchase;
