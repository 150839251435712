import React, { Fragment, useRef, useState } from 'react';
import DashboardLayout from '../../../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../../../layout/dashboard/tableLayout';
import { icons } from '../../../../../assets/icons';
import { Bar } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	BarElement,
	CategoryScale,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from 'chart.js';
import { useDarkMode } from '../../../../../hooks/useDarkMode';
import { faker } from '@faker-js/faker';
import '../styles/index.css';

const TransactionReport = () => {
	const [demo, setDemo] = useState(false);
	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const chartRef: any = useRef(null);

	ChartJS.register(
		CategoryScale,
		LinearScale,
		BarElement,
		Title,
		Tooltip,
		Legend
	);

	// Register the plugin
	// ChartJS.register(ChartDataLabels);

	function Graph() {
		const options: any = {
			plugins: {
				legend: {
					display: false,
					labels: {
						position: 'left',
						align: 'start',
						font: {
							size: 14,
							style: (ctx: any, options: any) => {
								// You can customize the logic here to determine the style dynamically
								return ctx.chart?.data?.datasets?.[options.datasetIndex]
									?.label === 'Collection'
									? 'italic'
									: 'normal';
							},
							color: 'black',
						},
					},
				},
			},
			responsive: true,
			scales: {
				x: {
					grid: {
						drawBorder: false,
						display: false,
					},
					ticks: {
						display: false,
					},
				},
				y: {
					ticks: {
						callback: function (value: number, index: number, values: number) {
							if (value >= 1000000) {
								return (value / 1000000).toFixed(1) + 'M'; // Convert to millions
							} else if (value >= 1000) {
								return (value / 1000).toFixed(1) + 'k'; // Convert to thousands
							} else {
								return value; // Use the original value
							}
						},
					},
					border: { dash: [6, 6] }, // for the grid lines

					grid: {
						color: '#E1E1E1', // for the grid lines
						tickColor: 'white', // for the tick mark
						tickBorderDash: [2, 3], // also for the tick, if long enough
						tickLength: 15, // just to see the dotted line
						tickWidth: 2.5,
						offset: true,
						drawTicks: true, // true is default
						drawOnChartArea: true, // true is default
					},

					beginAtZero: true,
				},
			},
		};

		const labels = [
			'Today',
			'Yesterday',
			'19th May',
			'18, May',
			'17, May',
			'16, May',
			'15, May',
		];

		const data = {
			labels,
			datasets: [
				{
					label: 'Collection',
					data: labels?.map(() =>
						faker.datatype.number({ min: 10000, max: 30000 })
					),
					backgroundColor: theme === 'dark' ? '#0B8376' : '#DBD6FC',
				},
				{
					label: 'Transfers',
					data: labels?.map(() =>
						faker.datatype.number({ min: 10000, max: 30000 })
					),
					backgroundColor: theme === 'dark' ? '#333333' : '#755AE2',
				},
			],
		};

		return (
			<Bar
				height={50}
				width={180}
				options={options}
				ref={chartRef}
				data={data}
			/>
		);
	}

	return (
		<Fragment>
			<DashboardLayout>
				<PageLayout
					style={{ backgroundColor: 'unset', padding: '0' }}
					pageSubtitle="All transfers made on Raven Atlas"
					pageTitle="Report and Metrics"
				>
					<div className="report-chart-wrap">
						<div className="report-chart-wrap__top">
							<div className="top__income-n-expense">
								<div className="income-n-expense__total">
									<p className="total__title">TOTAL INCOME</p>
									<div className="total__amount">
										<h5>N589,209.89</h5>
										<figure>{icons.total_income}</figure>
									</div>
									<small className="total__summary">
										<span style={{ color: '#1ACE37' }}>20%</span> increase
										compared to last week
									</small>
									<div className="total__filter-transaction">
										<p>Filter transaction by income only</p>
									</div>
								</div>
								<div className="income-n-expense__total">
									<p className="total__title">TOTAL INCOME</p>
									<div className="total__amount">
										<h5>N589,209.89</h5>
										<figure>{icons.total_expense}</figure>
									</div>
									<small className="total__summary">
										<span style={{ color: '#1ACE37' }}>20%</span> increase
										compared to last week
									</small>
									<div className="total__filter-transaction">
										<p>Filter transaction by expense only</p>
									</div>
								</div>
							</div>
							<div className="top__graph">
								<div className="charts__graph">
									<div className="graph__top">
										<div className="top__title ">
											<p className="title__total-wallet-balance">
												Total Wallet Balance
											</p>
											<h5 className="title__wallet-balance">N40.090.334</h5>
											<p className="title__last-updated">
												Last updated 1m ago{' '}
												<figure>{icons.refresh_purple}</figure>
											</p>
										</div>
										<div className="top__filter">
											<p>A Week</p>
											<span>
												<figure>{icons.chevron_down}</figure>
											</span>
										</div>
									</div>

									{demo && (
										<div className="graph__no-record">
											<div className="no-record__text">
												<h5>No Data at the moment</h5>
												<p>
													Transaction data chart show here once transfers and
													collections are been made.
												</p>
											</div>
										</div>
									)}
									{!demo && (
										<div className="graph__main">
											<Graph />
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="report-chart-wrap__bottom">
							<div className="bottom__item-wrap">
								<h5>400k</h5>
								<span>
									<p>Succesful Transactions</p>
									<figure>{icons.help_circle}</figure>{' '}
								</span>
							</div>

							<div className="bottom__item-wrap">
								<h5>400k</h5>
								<span>
									<p>Pending Transactionss</p>
									<figure>{icons.help_circle}</figure>{' '}
								</span>
							</div>
							<div className="bottom__item-wrap">
								<h5>400k</h5>
								<span>
									<p>Failed Transaction</p>
									<figure>{icons.help_circle}</figure>{' '}
								</span>
							</div>
							<div className="bottom__item-wrap">
								<h5>0</h5>
								<span>
									<p>Total Transactions</p>
									<figure>{icons.help_circle}</figure>{' '}
								</span>
							</div>
							<div className="bottom__item-wrap">
								<h5>4.5M</h5>
								<span>
									<p>Registered Customers</p>
									<figure>{icons.help_circle}</figure>{' '}
								</span>
							</div>
							<div className="bottom__item-wrap">
								<h5>3.54M</h5>
								<span>
									<p>API Calls</p>
									<figure>{icons.help_circle}</figure>{' '}
								</span>
							</div>
						</div>
					</div>
				</PageLayout>
			</DashboardLayout>
		</Fragment>
	);
};

export default TransactionReport;
