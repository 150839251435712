import { RavenInputField, RavenModal } from '@ravenpay/raven-bank-ui';
import React from 'react';
import '../styles/confirmPin/index.css';
const ConfirmTransactionPinModal = ({
	visible,
	disabled,
	onClose,
	onBtnClick,
}: any) => {
	return (
		<RavenModal
			visble={visible}
			effect={`fadeInDown`}
			className={`modal-transaction-main-wrap`}
			btnColor={`deep-green-light`}
			btnLabel={`Pay`}
			disabled={disabled}
			onBtnClick={onBtnClick}
			onClose={onClose}
		>
			<div className="transaction-pin-wrap">
				<p className="title">Complete Transaction</p>
				<p className="text grey-white-color">
					Please kindly provide your 6-Digit transaction PIN to complete
					Transaction
				</p>

				<RavenInputField
					className={`form-pin`}
					color={`deep-green-light`}
					type={`pin`}
				/>
			</div>
		</RavenModal>
	);
};

export default ConfirmTransactionPinModal;
