import React, { useState } from 'react';
import DashboardLayout from '../../../layout/dashboard/dashboardLayout';
import ContainerLayout from '../../../layout/dashboard/container';
import './styles/index.css';
import { RavenInputField } from '@ravenpay/raven-bank-ui';
import KYCForm, { KYCFormProps } from './components/Form';
import VerifiedDetails from './components/VerifiedDetails';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import {
	validateBVN,
	validateNIN,
	validatePassport,
} from '../../../redux/verification';
import { logger } from '../../../services/logger';

export interface KYC {
	value: string;
	label: string;
}

const DashboardKYC = () => {
	const [selected, setSelected] = useState<KYC>({
		value: 'nin-verification',
		label: 'NIN Verification',
	});
	const [stage, setStage] = useState('form');
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState<KYCFormProps>();
	const [details, setDetails] = useState<KYCFormProps>();

	const dispatch = useDispatch<AppDispatch>();

	function formatID(e?: string) {
		return e && e.slice(0, 4) + '****' + e.slice(-4);
	}
	async function handleReq() {
		setLoading(true);

		if (selected.value === 'bvn-verification') {
			const resp: any = await dispatch(validateBVN({ bvn: formData?.bvn }));
			if (resp.payload.status === 'success') {
				setLoading(false);
				setStage('details');
				setDetails({
					...resp.payload?.data?.data,
					identity: formatID(formData?.bvn),
				});
			} else {
				setLoading(false);
			}
		}
		if (selected.value === 'nin-verification') {
			const resp: any = await dispatch(validateNIN({ nin: formData?.nin }));
			if (resp.payload.status === 'success') {
				setLoading(false);
				setStage('details');
				setDetails({
					...resp.payload?.data?.data,
					identity: formatID(formData?.nin),
				});
			} else {
				setLoading(false);
			}
		}
		if (selected.value === 'passport-verification') {
			const resp: any = await dispatch(
				validatePassport({ nin: formData?.passport_number })
			);
			if (resp.payload.status === 'success') {
				setLoading(false);
				setStage('details');
				setDetails({
					...resp.payload?.data?.data,
					identity: formData?.passport_number,
				});
			} else {
				setLoading(false);
			}
		}
	}

	function disabledAlgo() {
		let value;
		if (selected.value === 'bvn-verification') {
			if (Number(formData?.bvn?.length) < 11) {
				value = true;
			} else {
				value = false;
			}
		}
		if (selected.value === 'nin-verification') {
			if (Number(formData?.nin?.length) < 11) {
				value = true;
			} else {
				value = false;
			}
		}
		if (selected.value === 'passport-verification') {
			if (formData?.issuing_state && formData?.passport_number) {
				return false;
			} else return true;
		}

		return value;
	}

	// console.log(disabledAlgo());
	return (
		<DashboardLayout>
			{stage === 'form' ? (
				<ContainerLayout style={{ width: '100%' }}>
					<form className="kyc-dashboard-wrapper">
						<div className="kyc-dashboard-wrapper__title">
							<h5>What would you like to verify</h5>
							<span>Choose the verification you would like to do</span>
						</div>

						<form
							onSubmit={(e: any) => {
								e.preventDefault();
								handleReq();
							}}
							className="kyc-dashboard-wrapper__select-verification-type"
						>
							<RavenInputField
								style={{ zIndex: '400' }}
								type="select"
								color="deep-green-light"
								// selectValue={selected}

								placeholder="Select Verification type"
								selectOption={[
									{ value: 'nin-verification', label: 'NIN Verification' },
									{ value: 'bvn-verification', label: 'BVN Verification' },
									{
										value: 'passport-verification',
										label: 'Passport Verification',
									},
									// {
									// 	value: 'drivers-license-verification',
									// 	label: 'Driver`s License Verification',
									// },
									// { value: 'cac-business', label: 'Business CAC' },
								]}
								onChange={(e: KYC) => setSelected(e)}
							/>
						</form>

						<KYCForm onChange={setFormData} selected={selected} />

						<div className="kyc-dashboard-wrapper__form-btn">
							<RavenInputField
								onSubmit={(e: any) => {
									e.preventDefault();
									handleReq();
								}}
								disabled={disabledAlgo()}
								loading={loading}
								type="submit"
								color="deep-green-light"
							/>
						</div>
					</form>
				</ContainerLayout>
			) : (
				<VerifiedDetails
					onBack={(e: string) => setStage(e)}
					details={details}
				/>
			)}
		</DashboardLayout>
	);
};

export default DashboardKYC;
