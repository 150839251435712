import React from 'react';
import ContainerLayout from '../../../../../layout/dashboard/container';
import '../styles/index.css';
import { icons } from '../../../../../assets/icons';
import {
	formatDateTime,
	formatNumWithComma,
	symbol,
} from '../../../../../utils/helper/Helper';
import { RavenButton } from '@ravenpay/raven-bank-ui';
import { useNavigate } from 'react-router-dom';
import RootState, { BankBoxUser } from '../../../../../redux/types';
import { bankboxAPI } from '../../../../../redux/bankbox';
import { useDispatch, useSelector } from 'react-redux';

const BankBoxUserPreview = ({
	onBack,
	chi,
	onAssign,
}: {
	onBack: () => void;
	chi: BankBoxUser;
	onAssign: (e: BankBoxUser) => void;
}) => {
	const searchParams = new URLSearchParams(window.location.search);
	const email = searchParams.get('user_email');
	const navigate = useNavigate();
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (email) {
			dispatch(bankboxAPI.getSingleUser({ email: email as string }));
		}
	}, [email]);

	const [el, setEl] = React.useState<BankBoxUser>({
		id: 0,
		fname: '',
		lname: '',
		poseidon_email: '',
		bvn: '',
		nin: '',
		phone: '',
		affiliate_app: '',
		affiliate_app_id: '',
		poseidon_business_name: '',
		poseidon_business_address: '',
		poseidon_business_type: null,
		poseidon_business_description: '',
		poseidon_profile_reference: '',
		business_category: '',
		region: '',
		created_at: '',
		updated_at: '',
	});
	const { loading, user, single_user_bankboxes } = useSelector(
		(state: RootState) => state.bankbox
	);

	React.useEffect(() => {
		setEl(user);
	}, [user]);

	const all_bankbox = single_user_bankboxes;

	return (
		<>
			<ContainerLayout
				pageTitle="User Details"
				pageMode
				loading={loading}
				loadingText="Fetching user..."
				onBack={onBack}
				style={{
					height: '100%',
					backgroundColor: loading ? 'white' : 'unset',
					borderRadius: '1rem',
				}}
				topRightContent={
					<RavenButton
						onClick={() => onAssign(user)}
						color="deep-green-light"
						label="Assign Box"
						className="bankbox-assign-btn"
					/>
				}
				pageSubtitle="All details about this user"
			>
				<div className="bankbox-user-view">
					<div className="bankbox-user-view__left">
						<div className="bankbox-user-view__left--title">
							<h6>{el.fname + ' ' + el?.lname}</h6>
							<p>{el.poseidon_email}</p>
						</div>
						<div className="bankbox-user-view__left--date-location">
							<div className="item">
								<figure>{icons.calendar_rounded_grey}</figure>
								<p>{formatDateTime(el.created_at)}</p>
							</div>

							<div className="item">
								<figure>{icons.location_rounded_grey}</figure>
								<p>{el.region}</p>
							</div>
						</div>
						<div className="bankbox-user-view__left--user-details">
							<div className="details-row">
								<div className="details">
									<span>User's NIN</span>
									<p>{el?.nin}</p>
								</div>
								<div className="details">
									<span>User's BVN</span>
									<p>{el?.bvn}</p>
								</div>
							</div>
							<div className="details-row">
								<div className="details">
									<span>Business Name</span>
									<p>{el.poseidon_business_name}</p>
								</div>
								<div className="details">
									<span>Business Description</span>
									<p>{el.poseidon_business_description}</p>
								</div>
							</div>
							<div className="details-row">
								<div className="details">
									<span>Business Category</span>
									<p>{el.business_category}</p>
								</div>
								<div className="details">
									<span>Business Address</span>
									<p>{el.poseidon_business_address}</p>
								</div>
							</div>
						</div>
					</div>
					<div className="bankbox-user-view__right">
						<div className="bankbox-user-view__right--title">
							<figure>{icons.calendar_rounded_grey}</figure>
							<h6>Devices • {all_bankbox?.length}</h6>
						</div>
						<div className="bankbox-user-view__right--devices-contain">
							{all_bankbox.length === 0 ? (
								<div className="empty_all_bb">
									<h6>No Devices Found</h6>
									<p>
										It appears that no bankbox device has been assigned to this
										user yet.
									</p>
								</div>
							) : (
								all_bankbox?.map((chi, idx) => {
									return (
										<div
											onClick={() =>
												navigate(
													`/dashboard-bankbox/terminals/view?serial=${chi.serial}`
												)
											}
											key={idx}
											className="device cursor-pointer"
										>
											<div className="device__business">
												<h6>{chi.business_name}</h6>
												<p>Serial No: {chi.serial}</p>
											</div>
											<div className="device__owner">
												<div className="device__owner--email">
													<figure>{icons.people_grey}</figure>
													<p>Assignee • {chi.poseidon_business_email}</p>
												</div>
												<div className="device__owner--trx-volume">
													<p>Volume in the last 30 Days</p>
													<h6>
														{symbol('ngn') +
															formatNumWithComma(chi.volume, 'ngn')}
													</h6>
												</div>
											</div>
										</div>
									);
								})
							)}
						</div>
					</div>
				</div>
			</ContainerLayout>
		</>
	);
};

export default BankBoxUserPreview;
