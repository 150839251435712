import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import { toast } from '@ravenpay/raven-bank-ui';
import { AuthState, RegisterPayload } from './types';
import { logger } from '../services/logger';
import { TeamFormData } from '../pages/dashboard/settings/components/team';
import { setCookie } from '../utils/helper/Helper';

interface LoginPayload {
	// Add appropriate types for the payload
}

export const registerUser = createAsyncThunk(
	'/register',
	async (payload: RegisterPayload, thunkAPI) => {
		try {
			const data = await axios.post('/web/register', payload);
			logger.log(data, 'reg');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else {
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				}
				return thunkAPI.rejectWithValue(data);
			}

			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const loginUser = createAsyncThunk(
	'web/login_with_otp',
	async (payload: LoginPayload, thunkAPI) => {
		try {
			const { data } = await axios.post('web/login_with_otp', payload);

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				SET_TOKEN(data.data.data.token);
				await setCookie('login_grace', 'active', 500);
				await setCookie('token', data.data.data.token, 3300); // set the token to the cookie, with auto expiry
				// localStorage.setItem('token', data.data.data.token);
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const checkEmailAvailability = createAsyncThunk(
	'web/checkEmail',
	async (payload: LoginPayload, thunkAPI) => {
		try {
			const { data } = await axios.post('central/check_email', payload);

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					// toast.error(data.data);
				}
				// toast.error(data?.data?.message, {
				// 	position: 'top-right',
				// });
				else return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				// toast.success(data?.data?.message, {
				// 	position: 'top-right',
				// });

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const initiateForgotPassword = createAsyncThunk(
	'/web/init_forgot_password',
	async (payload: { email?: string }, thunkAPI) => {
		try {
			const { data } = await axios.post('/web/forgot_password', payload);

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const validateResetPasswordOTP = createAsyncThunk(
	'/web/verify_password_reset_otp',
	async (payload: { otp?: string }, thunkAPI) => {
		try {
			const { data } = await axios.post(
				'/web/verify_password_reset_otp',
				payload
			);

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const resetPassword = createAsyncThunk(
	'web/reset_password',
	async (payload: { otp: string; password: string }, thunkAPI) => {
		try {
			const { data } = await axios.post('web/reset_password', payload);
			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const addTeamMember = createAsyncThunk(
	'web/add-support',
	async (payload: { identifier: string; password: string }, thunkAPI) => {
		try {
			const { data } = await axios.post('web/add_support_to_team', payload);

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const updateTeamMember = createAsyncThunk(
	'/web/update_support_clearance',
	async (payload: TeamFormData, thunkAPI) => {
		try {
			const { data } = await axios.post(
				'/web/update_support_clearance',
				payload
			);

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const authenticateLogin = createAsyncThunk(
	'web/authenticate',
	async (payload: { email: string; password: string }, thunkAPI) => {
		try {
			const { data } = await axios.post('web/authenticate', payload);
			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const validateRegistrationOTP = createAsyncThunk(
	'/web/validate_email_verification_code',
	async (payload: LoginPayload, thunkAPI) => {
		try {
			const { data } = await axios.post(
				'/web/validate_email_verification_code',
				payload
			);

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const sendEmailVerification = createAsyncThunk(
	'/web/send_email_verification_code',
	async (payload: LoginPayload, thunkAPI) => {
		try {
			const { data } = await axios.post(
				'/web/send_email_verification_code',
				payload
			);

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const logoutUser = createAsyncThunk(
	'/web/logout',
	async (payload: any, thunkAPI) => {
		try {
			const { data } = await axios.post('/web/logout');

			if (data?.status === 'fail') {
				if (typeof data.data === 'string') {
					toast.error(data.data);
				} else
					toast.error(data?.data?.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data?.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response?.data?.status === 'fail' &&
				error.response?.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);
const initialState: AuthState = {
	loading: false,
	isAuth: false,
	logging_out: false,
	token: '',
	// initialize other state properties
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,

	reducers: {
		LOGIN: (state, action) => {
			state.user = action.payload;
			state.isAuth = true;
		},
		SET_TOKEN: (state, action) => {
			state.token = action.payload;
		},
		LOGOUT: (state, action) => {
			state.logging_out = action.payload;
			state.isAuth = true;
		},
	},

	extraReducers: (builder) => {
		// pending state changes
		builder.addCase(registerUser.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(loginUser.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(authenticateLogin.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(addTeamMember.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(updateTeamMember.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(initiateForgotPassword.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(validateResetPasswordOTP.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(resetPassword.pending, (state) => {
			state.loading = true;
		});

		//fullfilled state changes
		builder.addCase(registerUser.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(loginUser.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(authenticateLogin.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(addTeamMember.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(updateTeamMember.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(initiateForgotPassword.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(validateResetPasswordOTP.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(resetPassword.fulfilled, (state) => {
			state.loading = false;
		});

		//rejected state changes
		builder.addCase(registerUser.rejected, () => {
			return initialState;
		});
		builder.addCase(loginUser.rejected, () => {
			return initialState;
		});
		builder.addCase(authenticateLogin.rejected, () => {
			return initialState;
		});
		builder.addCase(addTeamMember.rejected, () => {
			return initialState;
		});
		builder.addCase(updateTeamMember.rejected, () => {
			return initialState;
		});
		builder.addCase(initiateForgotPassword.rejected, (state) => {
			return initialState;
		});
		builder.addCase(validateResetPasswordOTP.rejected, (state) => {
			return initialState;
		});
		builder.addCase(resetPassword.rejected, (state) => {
			return initialState;
		});
	},
});

// Action creators are generated for each case reducer function
export const { LOGIN, LOGOUT, SET_TOKEN } = authSlice.actions;

export default authSlice.reducer;
