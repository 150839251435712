import {
	RavenButton,
	RavenCheckBox,
	RavenInputField,
	toast,
} from '@ravenpay/raven-bank-ui';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DashboardLayout from '../../../../../../../layout/dashboard/dashboardLayout';
import { FaTimes } from 'react-icons/fa';
// import ConfirmTransactionPinModal from "../ConfirmPinModal";

import { useDispatch, useSelector } from 'react-redux';
import {
	IconVault,
	formatNumWithComma,
	generateReference,
	removeCommaAndNairaSign,
	removeSpace,
} from '../../../../../../../utils/helper/Helper';

import { formatNumWithoutCommaNaira } from '../../../../../../../utils/helper/Helper';

import useDebounce from '../../../../../../../hooks/UseDebounce';
import ContainerLayout from '../../../../../../../layout/dashboard/container';
import { brands, icons } from '../../../../../../../assets/icons';
import './styles/index.css';
import ViewAll from './ViewAll';
import ConfirmTransactionPinModal from '../../common/ConfirmPinModal';
import { DetectNetwork } from '../../../../../../../utils/helper/DetectNetwork';
import glo from '../../../../../../../assets/images/glo.png';
import airtel from '../../../../../../../assets/images/airtel.png';
import mobile from '../../../../../../../assets/images/9mobile.png';
import mtn from '../../../../../../../assets/images/mtn.png';
import { AppDispatch } from '../../../../../../../redux/store';
import { getDataPlans, purchaseData } from '../../../../../../../redux/bills';
import RootState from '../../../../../../../redux/types';
import { logger } from '../../../../../../../services/logger';
import PreviewDetailsBox from '../../../../../../../components/common/previewDetailsBox';
type Modal = {
	pin: boolean;
	success: boolean;
};
const NewDataPurchase = () => {
	const navigate = useNavigate();
	const [step, setStep] = useState<number | string>(1);
	const [selectedProvider, setSelectedProvider] = useState<{
		name: string;
		icon: string | ReactElement;
	}>({
		name: '',
		icon: '',
	});

	const [viewAll, setViewAll] = useState(false);
	const [ravenName, setRavenName] = useState('');
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [showModal, setShowModal] = useState<Modal>({
		pin: false,
		success: false,
	});
	const [details, setDetails] = useState<any>({
		phone: '',
		data_plan: '',
		bulk_list: [],
		transfer_pin: '',
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...details, [name]: value };
		setDetails(obj);
	};

	const handleBulkAdd = () => {
		const checkList = details?.bulk_list?.some(
			(chi: any) => chi?.phone === details?.phone
		);
		if (!checkList) {
			const obj = {
				phone: details?.phone,
				data_plan: details?.data_plan,
				provider: details?.provider,
			};
			const newList = [obj, ...details?.bulk_list];
			setDetails((prev: any) => {
				return {
					...prev,
					bulk_list: newList,
					phone: '',
					data_plan: '',
					provider: '',
				};
			});
			setRavenName('');
			// setVerify(false);
		} else {
			toast.error('Recepient already exist in bulk list');
		}
	};

	const handleRemoveFromList = (obj: { phone: any }) => {
		if (details?.bulk_list?.length === 1) {
			setViewAll(false);
		}
		const newList = details?.bulk_list?.filter(
			(chi: { phone: any }) => chi?.phone !== obj?.phone
		);
		setDetails((prev: any) => {
			return {
				...prev,
				bulk_list: newList,
			};
		});
	};

	const [error, setError] = useState({
		phone: false,
	});
	const [loadingVery, setLoadingVerify] = useState(false);
	const validateUsername = async (param: { phone: string | number }) => {
		setLoadingVerify(true);
		setRavenName('Emmanuel Ezeani');
	};

	const debouncedSearchTerm = useDebounce(details?.phone, 1000);
	useEffect(() => {
		let isMount = true;

		if (isMount && debouncedSearchTerm?.length > 3) {
			const payload = {
				phone: details?.phone,
			};
			validateUsername(payload);
		}

		if (isMount && debouncedSearchTerm?.length < 1) {
			setRavenName('');
		}

		return () => {
			isMount = false;
		};
	}, [debouncedSearchTerm]);

	function disableAlgorithm() {
		if (!details.phone || !details.data_plan) {
			return true;
		} else if (details.data_plan && !details.phone) {
			return true;
		} else {
			return false;
		}
	}

	function handleInitPayment() {
		if (!details.phone) {
			return true;
		}
		if (!details.data_plan) {
			return true;
		} else return false;
	}

	DetectNetwork(
		formatNumWithoutCommaNaira(`0${details?.phone}`),
		setSelectedProvider
	);

	const providerList = [
		{ name: 'mtn', icon: mtn },
		{ name: 'mobile', icon: mobile },
		{ name: 'airtel', icon: brands.airtel },
		{ name: 'glo', icon: glo },
	];

	const dispatch = useDispatch<AppDispatch>();
	const { dataPlans, loading } = useSelector((state: RootState) => state.bills);
	const { dashboard_data } = useSelector((state: RootState) => state.wallet);

	useEffect(() => {
		dispatch(getDataPlans());
	}, []);

	function formatDataPlan() {
		const l: any = [];
		dataPlans.map((e) => {
			l.push({
				...e,
				label: e?.name,
				value: e?.code,
			});
		});

		return l;
	}

	async function buyPlan() {
		const e: {
			amount: string | number;
			phone: string;
			value: string;
			provider_code: string;
			data_plan: any;
		} = details?.bulk_list[0];
		const response: any = await dispatch(
			purchaseData({
				phone_number: `0${removeSpace(e.phone)}`,
				merchant_reference: generateReference(),
				provider_code: e?.data_plan?.provider_code.toString() as string,
				code: e.data_plan?.value,
			})
		);

		if (response?.payload?.status === 'success') {
			navigate('/dashboard-payment/bill-payments/');
		}
	}

	// logger.log(details?.bulk_list, 'didi');

	function smartNetworkDetect(e: string) {
		if (e.toLowerCase() === 'mtn') {
			setDetails({
				...details,
				provider: {
					...providerList[0],
				},
			});
		}

		if (e.toLowerCase() === '9mobile') {
			setDetails({
				...details,
				provider: {
					...providerList[1],
				},
			});
		}

		if (e.toLowerCase() === 'airtel') {
			setDetails({
				...details,
				provider: {
					...providerList[2],
				},
			});
		}

		if (e.toLowerCase() === 'glo') {
			setDetails({
				...details,
				provider: {
					...providerList[3],
				},
			});
		}
	}

	useEffect(() => {
		smartNetworkDetect(selectedProvider?.name);
	}, [selectedProvider]);
	const stepList = ['Transfer detail', 'Confirm Transactions'];

	return (
		<>
			<DashboardLayout>
				{step !== 'view-all' && (
					<ContainerLayout
						isLatest
						activeStep={step as number}
						steps={stepList}
						btnLoading={loading}
						btnLabel={step === 2 ? 'Complete Transaction' : 'Continue'}
						btnDisabled={step === 1 ? disableAlgorithm() : false}
						onBtnClick={() => {
							if (step === 2) {
								buyPlan();
							} else if (handleInitPayment()) setStep(2);
							else setStep(2), handleBulkAdd();
						}}
						onPrev={() => {
							step === 1
								? navigate(`/dashboard-payment/bill-payments`)
								: setStep(1);
							setDetails({ ...details, bulk_list: [] });
						}}
						btnColor="deep-green-light"
						onBack={() => {
							if (step === 1) navigate('/dashboard-payment/bill-payments');
							else if (step === 2) setStep(step - 1);
						}}
						pageTitle="Buy Data"
						pageSubtitle="Quickly purchase airtime from your preffered provider"
						style={{
							width: '100%',
							maxHeight: '90%',
							backgroundColor: step === 2 ? 'unset' : '',
							border: step === 2 ? 'unset' : '',
							padding: step === 2 ? 'unset' : '',
						}}
					>
						<div
							className={`bill-purchase  atlas-payment-container  animate-move-up-class ${
								step === 2 && 'expanded'
							} `}
						>
							<form
								onSubmit={(e) => {
									e.preventDefault();
								}}
								action=""
								className="form data-bill-form"
							>
								{/* step 1 start ------------------------------ */}
								{step === 1 && (
									<>
										<div className="raven__form-global">
											{/* end of bg box */}
											<RavenInputField
												type={`phone`}
												color={`black-light`}
												label={`Mobile Number `}
												value={details?.phone}
												onChange={(e: any) => {
													handleChange(e);
													setError((prev) => {
														return { ...prev, phone: false };
													});
												}}
												placeholder={`E.g 803492xxxx`}
												labelColor={`purple-light`}
												showError={error?.phone}
												// labelSpanText={'Verify'}
												// labelClassName={`label-class-span ${
												// 	details?.account_num?.length === 10 &&
												// 	'label-class-span-active'
												// }`}
												name={`phone`}
												onActionClick={() => {
													details?.phone.length > 3 &&
														validateUsername(details?.phone);
												}}
											/>
											{/* verify name box start */}
											{dashboard_data?.virtual_account?.phone && (
												<div className={`verify-name-box `}>
													<p
														onClick={() =>
															setDetails({
																...details,
																phone:
																	dashboard_data?.virtual_account?.phone ??
																	null,
															})
														}
														className="text"
													>
														Use my Business Number
													</p>
												</div>
											)}

											<div className="select-network-provider_wrap">
												<label>Select Network Provider</label>
												<div className="select-network-provider">
													{providerList.map((chi, idx) => {
														return (
															<div
																onClick={() => {
																	setDetails({
																		...details,
																		provider: chi,
																	});
																	setSelectedProvider(chi);
																}}
																key={idx}
																className={`select-network-provider__item ${
																	selectedProvider.name === chi.name && 'active'
																}`}
															>
																{typeof chi.icon !== 'string' ? (
																	chi.icon
																) : (
																	<img src={chi.icon} alt="" />
																)}
															</div>
														);
													})}
												</div>
											</div>
											{/* verify name box end */}
											<RavenInputField
												type={`select`}
												className="data-plan-select-option"
												color={`deep-green-light`}
												label={`Data Plan* `}
												name={`data_plan`}
												value={details?.data_plan}
												onChange={(d: any) =>
													setDetails({
														...details,
														data_plan: d,
													})
												}
												placeholder={`Select a Data Plan`}
												labelColor={`black-light`}
												labelSpanText={
													typeof dashboard_data?.balances !== 'undefined'
														? `Bal: ₦ ${formatNumWithComma(
																dashboard_data?.balances[0]?.available_bal,
																'ngn'
														  )} `
														: 'Bal: ₦ ••••••'
												}
												menuPlacement={'top'}
												selectOption={formatDataPlan()}
												labelClassName={`label-span-data_plan label-span-theme`}
												// thousandFormat
												// selectMenuOpen
												// numberPrefix={`₦`}
											/>

											{/* add another text start */}
											{/* <div
												onClick={() => {
													details?.data_plan &&
														details?.phone &&
														handleBulkAdd();
												}}
												className={`add-another-box ${
													details?.data_plan &&
													details?.phone &&
													'add-another-box-active'
												}`}
											>
												<figure className="img-box">
													<img
														src={IconVault(icons.plus_circle_white)}
														alt=""
														className="img"
													/>
													{}
												</figure>
												<p className="text">Add New Recipient</p>
											</div> */}
											{/* add another text end */}
											{/* recepient box start */}
											<div
												className={`recepient-box ${
													details?.bulk_list?.length > 0 && 'recepient-box-show'
												}`}
											>
												<div className="text-line">
													<span></span>
													<p className="text">Recipients</p>
													<span></span>
												</div>
												<div className="view-total-box">
													<div className="view-wrap">
														{viewAll ? (
															<p
																onClick={() => {
																	setViewAll(false);
																	details?.bulk_list?.length === 1 &&
																		setViewAll(false);
																}}
																className="text"
															>
																Close
															</p>
														) : (
															<div className="view-wrap__container">
																{details?.bulk_list?.length > 2 && (
																	<span>
																		<p>+{details?.bulk_list?.length - 2}</p>
																	</span>
																)}
																<p
																	onClick={() => {
																		setStep('view-all');
																	}}
																	className="text"
																>
																	View all
																	<figure>{icons.arrow_right_purple}</figure>
																</p>
															</div>
														)}
													</div>
												</div>
												{/* end of view total box */}

												{/* preview list start */}
												{!viewAll && (
													<div className="preview-box">
														{details?.bulk_list?.slice(0, 2).map(
															(
																chi: {
																	phone: any;
																	data_plan: any;
																	provider: any;
																},
																idx: React.Key | null | undefined
															) => {
																const { phone, data_plan, provider } = chi;
																return (
																	<div
																		className="box box-card grey-bg"
																		key={idx}
																	>
																		<div
																			onClick={() => {
																				handleRemoveFromList(chi);
																			}}
																			className="cancel-box"
																		>
																			<FaTimes className="icon" />
																		</div>
																		<figure className="img-box">
																			<img
																				src={provider?.icon}
																				alt=""
																				className="img"
																			/>
																		</figure>
																		<p className="name  raven-name">{phone}</p>
																		<p className="text grey-white-color">{`${
																			provider?.name?.toUpperCase() || '---'
																		} • ${data_plan}`}</p>
																	</div>
																);
															}
														)}
													</div>
												)}

												{/* preview list end */}
											</div>
										</div>

										{/* recepient box end */}
									</>
								)}
								{/* step 1 end -------------------------------- */}

								{/* step 2 start ----------------------------- */}
								{step === 2 && (
									<div className="step-two-payments step-two-transfer-raven animate-move-up-class">
										{details?.bulk_list.length === 1 ? (
											<>
												{details?.bulk_list?.map(
													(chi: any, idx: React.Key | null | undefined) => {
														return (
															<PreviewDetailsBox
																key={idx}
																loading={loading}
																onBtnClick={
																	() => buyPlan()
																	// setShowModal({
																	// 	pin: true,
																	// 	success: false,
																	// })
																}
																content={chi}
															/>
														);
													}
												)}
											</>
										) : (
											<>
												<PreviewDetailsBox
													multi
													loading={loading}
													onBtnClick={
														() => buyPlan()
														// setShowModal({
														// 	pin: true,
														// 	success: false,
														// })
													}
													content={details?.bulk_list}
												/>
											</>
										)}
									</div>
								)}
							</form>

							{/* view all end ----------------------------- */}
						</div>
					</ContainerLayout>
				)}

				{step === 'view-all' && (
					<ViewAll
						onBack={() => {
							setStep(1);
						}}
						onChange={(e: any) => setDetails(e)}
						items={details}
					/>
				)}
			</DashboardLayout>
			<ConfirmTransactionPinModal
				onBtnClick={() => {
					toast.success(
						'Your Airtime Purchase of Airtel - 200 has been successful'
					);
					setShowModal({
						pin: false,
						success: false,
					});
					navigate('/dashboard-payment/bill-payments');
				}}
				onClose={() =>
					setShowModal({
						pin: false,
						success: false,
					})
				}
				visible={showModal.pin}
			/>
		</>
	);
};

export default NewDataPurchase;
