import React, { ChangeEvent, useRef } from 'react';
import './styles/index.css';
import { RavenButton, RavenInputField } from '@ravenpay/raven-bank-ui';
import uploadPlaceholder from '../../../../../assets/images/upload-logo-placeholder.png';
import { icons } from '../../../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { bankboxAPI } from '../../../../../redux/bankbox';
import RootState from '../../../../../redux/types';
import { validateEmail } from '../../../../../utils/helper/ValidateInput';
import { businessCategories } from '../../data/businessCategory';
import { nigerianStates } from '../../data/statesInNigeria';
import { RavenInputProps } from '@ravenpay/raven-bank-ui/dist/esm/components/types';

const CreateBankboxUser = ({ onComplete }: { onComplete: () => void }) => {
	const [step, setStep] = React.useState<{
		id: string;
		completed: string[];
		label: string;
	}>({
		id: 'personal',
		completed: [],
		label: 'Personal Details',
	});

	const fileInputRef = useRef<HTMLInputElement>(null);
	const imgPreviewRef = useRef<HTMLImageElement>(null);
	const [formData, setFormData] = React.useState({
		fname: '',
		lname: '',
		bvn: '',
		nin: '',
		email: '',
		phone: '',
		business_category: { value: undefined, label: undefined },
		business_description: '',
		region: { value: undefined, label: undefined },
		business_name: '',
		business_address: '',
	});

	const { loading } = useSelector((state: RootState) => state.bankbox);
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...formData, [name]: value };
		setFormData(obj);
	};
	const dispatch = useDispatch();
	const handleSubmit = async () => {
		const resp = await dispatch(
			bankboxAPI.createUser({
				...formData,
				business_category: formData.business_category
					.label as unknown as string,
				region: formData.region.label as unknown as string,
			})
		);

		if (resp.payload?.status === 'success') {
			await dispatch(
				bankboxAPI.getAllUsers({
					per_page: 20,
					page: 1,
				})
			);
			onComplete();
		}
	};

	const brandColors = ['0B8376', '0B8376', 'EA872D', 'B9DEFE'];

	function disabledAlgo() {
		let bool: boolean = false;
		if (step.id === 'personal') {
			if (
				!validateEmail(formData.email) ||
				!formData.lname ||
				!formData.fname ||
				formData.phone.length < 8 ||
				formData.bvn.length !== 11 ||
				formData.nin.length !== 11
			) {
				bool = true;
			} else {
				bool = false;
			}
		}

		if (step.id === 'business') {
			if (
				!formData.business_name ||
				!formData.business_category ||
				!formData.business_description
			) {
				bool = true;
			} else {
				bool = false;
			}
		}

		if (step.id === 'address') {
			if (!formData.business_address || !formData.region) {
				bool = true;
			} else {
				bool = false;
			}
		}
		return bool;
	}

	return (
		<div className="bankbox-modals">
			<div className="bankbox-modals__left">
				<div className="bankbox-modals__left--bg-absolute"></div>
				<div className="bankbox-modals__left--stepper">
					<div
						onClick={() =>
							setStep({
								...step,
								id: 'personal',
							})
						}
						className={`stepper-item ${'active'} ${
							step.completed.includes('personal') && 'completed'
						}`}
					>
						<span
							onClick={() =>
								setStep({
									...step,
									id: 'personal',
								})
							}
						>
							<p>1</p>
						</span>
						<h6>Personal Details</h6>
					</div>

					<div
						onClick={() => {
							step.completed.includes('business') &&
								'completed' &&
								setStep({
									...step,
									id: 'business',
								});
						}}
						className={`stepper-item ${
							(step.id === 'business' || step.id === 'address') && 'active'
						} ${step.completed.includes('business') && 'completed'}`}
					>
						<span>
							<p>2</p>
						</span>
						<h6>Business Details</h6>
					</div>

					<div
						onClick={() => {
							step.completed.includes('address') &&
								'completed' &&
								setStep({
									...step,
									id: 'address',
								});
						}}
						className={`stepper-item ${step.id === 'address' && 'active'} ${
							step.completed.includes('address') && 'completed'
						}`}
					>
						<span>
							<p>3</p>
						</span>
						<h6>Address Details</h6>
					</div>
				</div>
			</div>

			<div className="bankbox-modals__right">
				{step.id === 'personal' && (
					<div className="bankbox-modals__right--content">
						<div className="basic-content__title">
							<h5>Create a User</h5>
							<h6>{step.label}</h6>
						</div>

						<div className="price-content__input-contain">
							<div className="price-content__input-contain--grouped">
								<RavenInputField
									name="fname"
									onChange={handleChange}
									placeholder="Enter First Name"
									label="First Name"
									value={formData.fname}
									color="deep-green-light"
								/>
								<RavenInputField
									name="lname"
									onChange={handleChange}
									placeholder="Enter Last Name"
									value={formData.lname}
									label="Last Name"
									color="deep-green-light"
								/>
							</div>

							<RavenInputField
								name="email"
								onChange={handleChange}
								placeholder="Enter Email Address"
								label="Email Address"
								value={formData.email}
								type="email"
								color="deep-green-light"
							/>

							<RavenInputField
								name="phone"
								onChange={handleChange}
								label="Enter Phone No."
								value={formData.phone}
								placeholder="e.g 903 xxx xxx"
								type="phone"
								color="deep-green-light"
							/>

							<RavenInputField
								name="bvn"
								onChange={(e: { target: { value: string | any[] } }) => {
									if (e.target.value.length < 12) {
										handleChange(e);
									}
								}}
								placeholder="Enter BVN"
								label="BVN"
								value={formData.bvn}
								maxLength={11}
								max={11}
								maxSize={11}
								type="text"
								color="deep-green-light"
							/>

							<RavenInputField
								name="nin"
								placeholder="Enter NIN"
								value={formData.nin}
								onChange={(e: { target: { value: string | any[] } }) => {
									if (e.target.value.length < 12) {
										handleChange(e);
									}
								}}
								maxLength={11}
								max={11}
								maxSize={11}
								// pattern={'^[0-9a-f]'}
								label="NIN"
								type="text"
								color="deep-green-light"
							/>
						</div>
						<div className="price-content__form-submit">
							<RavenButton
								onClick={() =>
									setStep({
										id: 'business',
										label: 'Business Details',
										completed: ['personal, business'],
									})
								}
								disabled={disabledAlgo()}
								color="deep-green-light"
								label="Continue"
							/>{' '}
						</div>
					</div>
				)}

				{step.id === 'business' && (
					<div className="bankbox-modals__right--content">
						<div className="basic-content__title">
							<h5>Create User</h5>
							<h6>{step.label}</h6>
						</div>

						<div className="price-content__input-contain">
							<RavenInputField
								name="business_name"
								onChange={handleChange}
								value={formData.business_name}
								placeholder="Business Name"
								label="Business Name"
								type="text"
								style={{ zIndex: '4400' }}
								color="deep-green-light"
							/>
							<RavenInputField
								name="business_category"
								onChange={(e: any) => {
									setFormData({
										...formData,
										business_category: e,
									});
								}}
								placeholder="Select a Category"
								label="Business Category"
								value={formData.business_category}
								type="select"
								selectOption={businessCategories}
								style={{ zIndex: '4400' }}
								color="deep-green-light"
							/>
							<RavenInputField
								name="business_description"
								placeholder="Describe your business"
								onChange={handleChange}
								value={formData.business_description}
								label="Business Description"
								type="textarea"
								color="deep-green-light"
							/>
						</div>
						<div className="price-content__form-submit">
							<RavenButton
								onClick={() => {
									setStep({
										id: 'address',
										label: 'Address Details',
										completed: ['personal', 'business'],
									});
								}}
								disabled={disabledAlgo()}
								color="deep-green-light"
								label="Continue"
							/>{' '}
						</div>
					</div>
				)}

				{step.id === 'address' && (
					<div className="bankbox-modals__right--content">
						<div className="basic-content__title">
							<h5>Create User</h5>
							<h6>{step.label}</h6>
						</div>

						<div className="price-content__input-contain">
							<RavenInputField
								name="region"
								placeholder="e.g 'Abia State'"
								label="Region"
								value={{
									value: formData.region.value ?? null,
									label: formData.region.label ?? null,
								}}
								type="select"
								onChange={(e: { label: any }) => {
									handleChange({
										target: {
											name: 'region',
											value: e,
										},
									});
								}}
								selectOption={nigerianStates}
								style={{ zIndex: '4400' }}
								color="deep-green-light"
							/>
							<RavenInputField
								name="business_address"
								placeholder="Write your address here"
								label="Address* "
								type="textarea"
								onChange={handleChange}
								value={formData.business_address}
								color="deep-green-light"
							/>
						</div>
						<div className="price-content__form-submit">
							<RavenButton
								onClick={handleSubmit}
								color="deep-green-light"
								label="Complete Setup"
								disabled={disabledAlgo()}
								loading={loading}
								loaderText="Creating User..."
							/>{' '}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default CreateBankboxUser;
