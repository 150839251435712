import React, { useState } from 'react';
import AuthTwoLayoutWrap from '../../../layout/auth/AuthTwoLayoutWrap';
import { brands, icons } from '../../../assets/icons';
import {
	RavenModal,
	RavenInputField,
	RavenButton,
} from '@ravenpay/raven-bank-ui';
import { useNavigate } from 'react-router-dom';
import { validatePassword } from '../../../utils/helper/ValidateInput';
import './styles/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateLogin, loginUser } from '../../../redux/auth';
import { Dispatch } from '@reduxjs/toolkit';
import RootState from '../../../redux/types';
import { logger } from '../../../services/logger';
import { AppDispatch } from '../../../redux/store';
import ReCAPTCHA from 'react-google-recaptcha';
import { debounce } from 'lodash';
import {
	genCaptchaKeyAsync,
	getCookie,
	setCookie,
} from '../../../utils/helper/Helper';

const LoginIndex = () => {
	const [formData, setFormData] = useState({
		email: '',
		password: '',
		otp: '',
	});
	const [showModal, setShowModal] = useState(false);
	const [completeToken, setCompleteToken] = useState(false);

	const { loading } = useSelector((state: RootState) => state.auth);
	const navigate = useNavigate();

	const captchaRef: any = React.useRef(null);

	const SITE_KEY = process.env.REACT_APP_gCAPTCHA_SITE_KEY;

	function handleFieldChange(e: any) {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	}

	const dispatch: Dispatch = useDispatch<AppDispatch>();

	const handleLogin = debounce(async (e?: number) => {
		let login;

		if (e) {
			login = await dispatch(loginUser({ ...formData, otp: e }) as any);
		} else {
			login = await dispatch(loginUser(formData) as any);
		}

		if (login?.payload?.status === 'success') {
			navigate('/dashboard-overview');
		}
	}, 50);

	async function initToken() {
		genCaptchaKeyAsync(SITE_KEY).then((d) => {
			if (d) {
				setCookie('g-rec-response', d.toString(), 30);
				dispatch(authenticateLogin(formData)).then((resp) => {
					if (resp.payload?.status === 'success') {
						setShowModal(true);
					}
				});
			}
		});
	}

	React.useEffect(() => {
		const loadScriptByURL = (id: string, url: string, callback: any) => {
			const isScriptExist = document.getElementById(id);
			if (!isScriptExist) {
				var script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = url;
				script.id = id;
				script.onload = function () {
					if (callback) callback();
				};
				document.body.appendChild(script);
			}

			if (isScriptExist && callback) callback();
		};

		// load the script by passing the URL

		if (SITE_KEY)
			loadScriptByURL(
				'recaptcha-key',
				`https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
				function () {
					// logger.log('Script loaded!');
				}
			);
	}, []);

	return (
		<AuthTwoLayoutWrap className="auth-login-mobile-layout">
			<div className="login">
				<div className="login__atlas-logo">
					<figure>{brands.raven_atlas}</figure>
					<p>Atlas</p>
				</div>

				<div className="login__create-account">
					<h5>Welcome Back</h5>
					<p>
						Explore the limitless possibilities and <br /> functionality that
						awaits you.
					</p>
				</div>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						initToken();
					}}
					className="login__form"
				>
					<RavenInputField
						placeholder="johnbull@gmail.com"
						label="Email Address*"
						color="green-dark"
						type="email"
						onChange={handleFieldChange}
						name="email"
					/>

					<div className="form__password">
						<RavenInputField
							placeholder="********"
							label="Password*"
							color="green-dark"
							showColor="purple-dark"
							type="password"
							onChange={handleFieldChange}
							name="password"
						/>

						<div
							onClick={() => navigate('/forgot-password')}
							className="form__password--forgot-password"
						>
							<span>Forgot Password?</span>
						</div>
					</div>

					<div className="form__action">
						<div className="action__button">
							<RavenInputField
								type="submit"
								className="action__button--submit"
								value="Continue"
								color="deep-green-light"
								disabled={!formData.email || formData.password.length < 4}
								loading={loading}
								// onSubmit={initToken}
							/>
						</div>

						<div className="action__have-an-account">
							<p>Don't have an account ?</p>
							<span onClick={() => navigate('/register')}>
								Sign Up {icons.arrow_right_purple}
							</span>
						</div>
					</div>
				</form>
			</div>
			{/* <figure className="login__live-chat">{icons.live_chat}</figure> */}
			<RavenModal
				onClose={() => setShowModal(false)}
				visble={showModal}
				loading={loading}
				btnLabel="Verify Account"
				btnColor={`deep-green-light`}
				effect={`fadeInUp`}
				outerClose={true}
				closeColor
				disabled={!completeToken}
				onBtnClick={handleLogin}
				className={`auth-pin-modal-wrap`}
			>
				<form
					onSubmit={(e) => {
						e.preventDefault();
					}}
					action=""
					className="form form-modal-auth"
				>
					<div className="text-box">
						<p className="big-title">Login Verification</p>
						<p className="small-title">
							Please kindly provide the 6-Digit OTP sent to the email provided
							to verify account.{' '}
							<span style={{ color: '#014345', fontWeight: 500 }}>
								{formData.email}
							</span>
						</p>
					</div>
					<RavenInputField
						type={`pin`}
						color={`black-light`}
						onChange={(e: any) => {
							setCompleteToken(false);
							setFormData((prev) => {
								return { ...prev, otp: e };
							});
						}}
						onComplete={(pin: number) => {
							setCompleteToken(true);
							handleLogin(pin);
						}}
						loading={loading}
						value={formData?.otp}
						showCountDown
						// countDownTime={50}
						key={showModal as any}
						countdownFinishClick={() => {
							('');
						}}
						countdownFinishText={` Resend OTP`}
						countdownFinishClassName={`resend-text`}
						// onCountdownFinish
						countdownLabelClassName={`black-white-color`}
					/>
				</form>
			</RavenModal>
		</AuthTwoLayoutWrap>
	);
};

export default LoginIndex;
