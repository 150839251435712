import { RavenInputField } from '@ravenpay/raven-bank-ui';
import React, { useState } from 'react';
import { InviteTeamPayload } from '../../../../../redux/types';
import { InviteTeamState } from '../..';

const AddTeamMember = ({
	onChange,
	formData,
}: {
	onChange: (e: InviteTeamState) => any;
	formData: InviteTeamState;
}) => {
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...formData, [name]: value };
		onChange(obj);
	};

	return (
		<div className="settings-modal-wrap">
			<div className="settings-modal-wrap__title">
				<h5>Add Team Member</h5>
				<p>Add a new team member to your account</p>
			</div>

			<div className="settings-modal-wrap__form">
				<div className="form__grouped">
					<RavenInputField
						color="deep-green-light"
						value={formData.fname}
						type="text"
						label="Firstname"
						placeholder="E.g 'John'"
						name="fname"
						onChange={handleChange}
					/>
					<RavenInputField
						color="deep-green-light"
						type="text"
						value={formData.lname}
						placeholder="E.g 'Doe'"
						label="Lastname"
						name="lname"
						onChange={handleChange}
					/>
				</div>

				<RavenInputField
					color="deep-green-light"
					type="email"
					label="Email "
					value={formData.email}
					name="email"
					placeholder="E.g ‘johndoe@mail.com’"
					onChange={handleChange}
				/>

				<RavenInputField
					color="deep-green-light"
					// value={formData.clearance}
					type="select"
					label="Role"
					name="clearance"
					selectOption={[
						{ value: 'operation', label: 'Operations' },
						{ value: 'manager', label: 'Manager' },
						{ value: 'support', label: 'Support' },
					]}
					placeholder="Select a role"
					onChange={(r: { value: string; label: string }) =>
						onChange({
							...formData,
							clearance: r,
						})
					}
				/>
			</div>
		</div>
	);
};

export default AddTeamMember;
