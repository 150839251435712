import { RavenInputField } from '@ravenpay/raven-bank-ui';
import React, { useState } from 'react';
import { InviteTeamPayload } from '../../../../../redux/types';
import { InviteTeamState } from '../..';

const ChangePassword = ({
	onChange,
	formData,
	onStepChange,
	step,
}: {
	onChange: (e: any) => any;
	onStepChange: (e: number) => any;
	formData: {
		old_password: string;
		new_password: string;
		confirm_password: string;
	};
	step: number;
}) => {
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...formData, [name]: value };
		onChange(obj);
	};

	return (
		<div className="settings-modal-wrap">
			<div className="settings-modal-wrap__title">
				<h5>Change Password</h5>
				<p>Reset and Change your Atlas Account Password</p>
			</div>

			<span className="settings-modal-wrap__stepper">
				<p
					onClick={() => onStepChange(1)}
					style={{ backgroundColor: step === 1 ? '#014345' : '' }}
					className={step === 1 ? 'active' : ''}
				/>
				<p
					onClick={() => onStepChange(2)}
					style={{ backgroundColor: step === 2 ? '#014345' : '' }}
					className={step === 2 ? 'active' : ''}
				/>
			</span>

			<div className="settings-modal-wrap__form">
				{step === 1 && (
					<RavenInputField
						color="deep-green-light"
						type="password"
						label="Current Password"
						value={formData.old_password}
						name="old_password"
						labelColor="deep-green-light"
						placeholder="**********"
						onChange={handleChange}
						showColor="purple-light"
					/>
				)}

				{step === 2 && (
					<>
						<RavenInputField
							color="deep-green-light"
							type="password"
							label="New Password"
							value={formData.new_password}
							name="new_password"
							placeholder="**********"
							onChange={handleChange}
							showColor="purple-light"
							showPasswordStrength={true as unknown as string}
						/>
						<RavenInputField
							color="deep-green-light"
							type="password"
							label="Confirm New Password"
							showColor="purple-light"
							value={formData.confirm_password}
							name="confirm_password"
							placeholder="**********"
							onChange={handleChange}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default ChangePassword;
